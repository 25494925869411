//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapGetters } from 'vuex';
import ListItem from '@/components/ui/ListItem.vue';
import IconLink from '@/components/ui/IconLink.vue';

export default {
  name: 'ProductsDetails',
  components: {
    IconLink,
    ListItem,
  },
  async created() {
    await this.getProductData();
  },
  data() {
    return {
      selectedIndex: 0,
    };
  },
  computed: {
    ...mapGetters('products', ['getProductById']),
    product() {
      return this.getProductById(this.productId);
    },
    productId() {
      return this.$route.params.productId;
    },
    category() {
      return this.$route.params.deviceCategory;
    },
    pictures() {
      return (this.product.attachments && this.product.attachments.pictures) || [];
    },
    hasMorePictures() {
      return this.pictures.length > 1;
    },
    hasDatasheet() {
      return this.product.productProperties?.datasheet && this.product.productProperties.datasheet.length > 0;
    },
    orderedProperties() {
      const properties = [...new Set(this.product.productProperties)];
      return properties.sort((a, b) => ((a.order > b.order) ? 1 : -1));
    },
    constantProperties() {
      return [
        { label: this.$t('product.modelStringIdentifier'), value: this.product.modelStringIdentifier || null },
        { label: this.$t('product.model'), value: this.product.model || null },
        { label: this.$t('product.manufacturer'), value: this.product.manufacturer || null },
      ];
    },
  },
  methods: {
    ...mapActions('products', ['getProduct']),
    async getProductData() {
      await this.getProduct(this.productId);
    },
    onImageChange(image, index) {
      if (image === 'prev') {
        if (this.selectedIndex === 0) {
          this.selectedIndex = this.pictures.length - 1;
          return;
        }
        this.selectedIndex -= 1;
        return;
      }
      if (image === 'next') {
        if (this.pictures.length === this.selectedIndex + 1) {
          this.selectedIndex = 0;
          return;
        }
        this.selectedIndex += 1;
        return;
      }
      this.selectedIndex = index;
    },
    localizedKey(prop) {
      return prop.propType === 'property' ? this.$t(`productPropertyKeys.${prop.key}`) : this.$t(`productCapabilityKeys.${prop.key}`);
    },
    formattedValue(prop) {
      if (prop.type === 'boolean') {
        return prop.value ? this.$t('generalYes') : this.$t('generalNo');
      }
      return `${prop.value} ${prop.unit}`;
    },
    propertyValueByKey(key) {
      const property = this.product.productProperties.find((prop) => prop.key === key);
      return property && property.value.toString().length > 0 ? `${property.value} ${property.unit}` : null;
    },
  },
};

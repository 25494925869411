/* global axios */

const listRooms = async (uuid) => axios({
  method: 'GET',
  url: `/projects/${uuid}/rooms`,
});

const getRoom = async ({ uuid, roomId }) => axios({
  method: 'GET',
  url: `/projects/${uuid}/rooms/${roomId}`,
});

const createRoom = async ({ uuid, data }) => axios({
  method: 'POST',
  url: `/projects/${uuid}/rooms`,
  lock: { uuid },
  data,
  logEvent: () => ({ eventName: 'create_room' }),
});

const updateRoom = async ({ uuid, roomId, data }) => axios({
  method: 'PUT',
  url: `/projects/${uuid}/rooms/${roomId}`,
  lock: { uuid },
  data,
  logEvent: () => ({ eventName: 'edit_room' }),
});

const deleteRoom = async ({ uuid, roomId }) => axios({
  method: 'DELETE',
  url: `/projects/${uuid}/rooms/${roomId}`,
  lock: { uuid },
  data: {},
  logEvent: () => ({ eventName: 'delete_room' }),
});

const addProductToRoom = async ({ uuid, roomId, product }) => axios({
  method: 'POST',
  url: `/projects/${uuid}/rooms/${roomId}/products`,
  lock: { uuid },
  data: product,
  logEvent: () => ({ eventName: 'add_device_to_room' }),
});

export default {
  listRooms,
  getRoom,
  createRoom,
  updateRoom,
  deleteRoom,
  addProductToRoom,
};

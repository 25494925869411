//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapGetters } from 'vuex';
import { ValidationObserver } from 'vee-validate';
import InputField from '@/components/ui/InputField.vue';
import ExistingCoworkerModal from '@/views/projects/coworkers/modals/ExistingCoworkerModal.vue';

export default {
  props: ['coworker'],
  components: {
    ExistingCoworkerModal,
    InputField,
    ValidationObserver,
  },
  data() {
    return {
      projectId: this.$route.params.projectId,
      data: {
        name: '',
        email: '',
        phone: '',
        role: 'admin',
        invitationMessage: '',
      },
    };
  },
  async created() {
    this.resetForm();
    await this.getProfile();
    await this.listProjectCoworkers();
  },
  computed: {
    ...mapGetters('user', ['getProfileData']),
    ...mapGetters('coworkers', ['getCoworkersByProject']),
    profileEmail() {
      return this.getProfileData.email;
    },
    options() {
      const opts = [];
      if (this.$canI('inviteAdmin', 'coworkers')) {
        opts.push({ value: 'admin', text: this.$t('coworkerRoleAdmin') });
      }
      if (this.$canI('invitePlanner', 'coworkers')) {
        opts.push({ value: 'planner', text: this.$t('coworkerRolePlanner') });
      }
      if (this.$canI('inviteInstaller', 'coworkers')) {
        opts.push({ value: 'installer', text: this.$t('coworkerRoleInstaller') });
      }
      if (this.$canI('inviteReadonly', 'coworkers')) {
        opts.push({ value: 'readonly', text: this.$t('coworkerRoleReadOnly') });
      }
      return opts;
    },
    coworkers() {
      return this.getCoworkersByProject(this.projectId).map((coworker) => {
        let invitationState = 'Pending';
        if (coworker.invitationAccepted) {
          invitationState = 'Accepted';
        }
        if (coworker.invitationRejected) {
          invitationState = 'Rejected';
        }
        return {
          ...coworker,
          ...(coworker.user || {}),
          uuid: coworker.uuid,
          invitationState,
        };
      });
    },
    forbiddenEmails() {
      return [...new Set([...this.coworkers.map((coworker) => coworker.email), this.profileEmail])];
    },
  },
  methods: {
    ...mapActions('coworkers', ['createCoworker', 'listCoworkers', 'updateCoworker']),
    ...mapActions('user', ['getProfile']),
    async onCreate() {
      const valid = await this.$refs.observer.validate();
      const existingEmail = this.forbiddenEmails.includes(this.data.email) && !this.coworker.uuid;

      if (!valid || existingEmail) {
        if (existingEmail) {
          this.$bvModal.show('existing-coworker-modal');
        }
        return;
      }
      this.disableCreate = true;
      this.$bvModal.hide('invite-coworker-modal');

      if (this.coworker && this.coworker.uuid) {
        await this.updateCoworker({ uuid: this.projectId, coworkerId: this.coworker.uuid, data: this.data }).catch((e) => {
          this.resetForm();
          throw e;
        });
      } else {
        await this.createCoworker({ uuid: this.projectId, data: this.data }).catch((e) => {
          this.resetForm();
          throw e;
        });
      }

      await this.listProjectCoworkers();
      if (!this.coworker.invitationAccepted) {
        this.$bvModal.show('invite-sent-modal');
      }
      this.resetForm();
    },
    onCancel() {
      this.$bvModal.hide('invite-coworker-modal');
      this.resetForm();
    },
    resetForm() {
      this.disableCreate = false;
      const coworker = this.coworker || {};
      this.data = {
        name: '',
        email: '',
        phone: '',
        role: 'admin',
        invitationMessage: '',
        ...coworker,
      };
    },
    async listProjectCoworkers() {
      await this.listCoworkers({ uuid: this.projectId });
    },
  },
};

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapGetters } from 'vuex';
import { WizardBus } from '@/bus';
import RoomRulesDefaultDetailUI from '@/components/ui/roomUI/RoomRulesDefaultDetailUI.vue';
import RoomRulesLimitation from '@/components/ui/roomUI/modals/RoomRulesLimitation.vue';

export default {
  name: 'WizardRoom',
  components: {
    RoomRulesLimitation,
    RoomRulesDefaultDetailUI,
  },
  data() {
    return {
      dataLoaded: false,
      selectedRule: {},
      currentStep: 1,
      rerender: 1,
      disableCreate: false,
      repeatError: false,
      data: {},
      internalMode: 'manual',
    };
  },
  async created() {
    WizardBus.$on('next', this.onNextStep);
    await this.onListRules();
    await this.onListGroups();
    await this.onGetProjectConfig();
    this.internalMode = this.mode;
  },
  beforeDestroy() {
    WizardBus.$off('next', this.onNextStep);
  },
  computed: {
    ...mapGetters('rules', ['getRulesByRoom']),
    ...mapGetters('groups', ['getGroupsByRoom']),
    ...mapGetters('configs', ['getProjectConfigById']),
    ...mapGetters('rooms', ['getRoomById']),
    ...mapGetters('rules', ['getDefaultRuleByRoom']),
    projectId() {
      return this.$route.params.projectId;
    },
    roomId() {
      return this.$route.params.roomId;
    },
    room() {
      return this.getRoomById(this.roomId);
    },
    rules() {
      return this.getRulesByRoom(this.projectId, this.roomId);
    },
    defaultRule() {
      return this.getDefaultRuleByRoom(this.roomId);
    },
    mode() {
      return this.room.defaultRoomBehaviour || 'manual';
    },
    projectConfig() {
      return this.getProjectConfigById(this.projectId);
    },
    roomSensors() {
      if (!this.projectConfig?.devices) {
        return [];
      }
      const room = (this.projectConfig?.rooms || []).find((r) => r.uuid === this.roomId);
      if (!room) {
        return [];
      }
      return (this.projectConfig.devices.sensors || []).filter((sensor) => room.sensors.includes(sensor.uuid));
    },
    groups() {
      return this.getGroupsByRoom(this.projectId, this.roomId);
    },
    rule() {
      return {
        name: '',
        duration: '08:00-18:00',
        repeat: 0,
        actions: { target: { type: 'room' }, value: {} },
      };
    },
    options() {
      return [
        { value: 'room', text: this.$t('roomRulesWholeRoom') },
        ...this.groups.map((g) => ({ value: g.uuid, text: g.name })),
      ];
    },
    modalTitle() {
      return `${this.currentStep}/3. ${this.$t('wizardCreateRoomBehaviour')}`;
    },
    modalButtonLabel() {
      return this.currentStep === 3 ? this.$t('wizardFinishRoomBehaviour') : this.$t('wizardNext');
    },
  },
  watch: {
    'data.dlh.selected': function selectedWatcher(dlhEnabled) {
      if (dlhEnabled) {
        this.$set(this.data.autoOn, 'selected', true);
        this.$set(this.data.autoOff, 'selected', true);
        this.rerender += 1;
      }
    },
    projectConfig() {
      this.resetForm();
    },
  },
  methods: {
    ...mapActions('rules', ['listRules', 'createRule', 'deleteRule', 'getDefaultRule', 'updateDefaultRule']),
    ...mapActions('groups', ['listGroups']),
    ...mapActions('configs', ['getProjectConfig']),
    ...mapActions('rooms', ['getRoom']),
    async fetchData() {
      await this.getRoom({ uuid: this.projectId, roomId: this.roomId });
      await this.getDefaultRule({ uuid: this.projectId, roomId: this.roomId });
    },
    async saveDefaultSettings(data) {
      await this.updateDefaultRule({
        uuid: this.projectId,
        roomId: this.roomId,
        data,
      });
      await this.fetchData();
    },
    async onListRules() {
      await this.listRules({ uuid: this.projectId, roomId: this.roomId });
      this.dataLoaded = true;
      this.$nextTick(this.isSkippable);
    },
    showDeleteModal(rule) {
      this.selectedRule = rule;
      this.$bvModal.show('delete-rule-modal');
    },
    async onDeleteRule() {
      await this.deleteRule({ uuid: this.projectId, roomId: this.roomId, ruleId: this.selectedRule.uuid });
      await this.onListRules();
      this.$bvModal.hide('delete-rule-modal');
    },
    async onGetProjectConfig() {
      await this.getProjectConfig({ uuid: this.projectId });
    },
    async onListGroups() {
      await this.listGroups({ uuid: this.projectId, roomId: this.roomId });
    },
    async onNextStep() {
      this.$parent.$parent.$data.disabledFooterButton = true;
      await this.$refs.defaultDetailUi.onSetDefaultMode();
      this.$parent.$parent.$data.disabledFooterButton = false;
      await this.$router.push({ name: this.$route.meta.nextRoute });
    },
    modeChange(mode) {
      this.internalMode = mode;
      this.isSkippable();
    },
    async modalClick() {
      if (this.currentStep === 1) {
        const valid = await this.$refs.observer.validate();
        if (!valid) {
          return;
        }
      }
      if (this.currentStep === 2 && !this.data.repeat) {
        this.repeatError = true;
        return;
      }
      if (this.currentStep === 3) {
        await this.onCreate();
      } else {
        this.currentStep += 1;
      }
    },
    onCancel() {
      this.$bvModal.hide('room-rule-modal');
    },
    async onCreate() {
      this.disableSave = true;
      const {
        name, duration, repeat, autoOn, autoOff, dlh,
      } = this.data;
      const actions = {
        target: {
          type: 'room',
          uuid: this.roomId,
        },
        value: {
          'auto-on': autoOn.selected || false,
          // eslint-disable-next-line no-nested-ternary
          level: autoOn.selected ? (autoOn.dimSelected ? (autoOn.value || 100) : 100) : undefined,
          'auto-off': autoOff.selected || false,
          delay: autoOff.selected ? autoOff.offMin * 60 : undefined,
          'delay-dim': autoOff.selected && autoOff.dimSelected ? autoOff.downMin * 60 : undefined,
          'level-dim': autoOff.selected && autoOff.dimSelected ? autoOff.value : undefined,
          'dlh-enabled': dlh.selected,
        },
      };
      const data = {
        name, duration, repeat, actions, dlh: dlh.selected ? this.getDLHData() : undefined,
      };
      try {
        await this.createRule({ uuid: this.projectId, roomId: this.roomId, data });
        await this.onListRules();
        this.$bvModal.hide('room-rule-modal');
      } finally {
        this.disableCreate = false;
      }
    },
    onChange(item, data) {
      if (item === 'autoOn' && this.data.autoOff.value > data.value) {
        this.data = { ...this.data, autoOff: { ...this.data.autoOff, value: data.value } };
      }
      if (item === 'autoOff' && data.value >= this.data.autoOn.value) {
        this.data.autoOn.value = data.value;
      }
      this.data[item] = { ...this.data[item], ...data };
      this.data = { ...this.data };
    },
    onDLHChange(i, dlh) {
      this.data.dlh.values[i] = { ...this.data.dlh.values[i], ...dlh };
      this.onChange('dlh', this.data.dlh);
    },
    getDLHData() {
      let dlhData;
      if (this.data.dlh.values.length) {
        const { values } = this.data.dlh;
        if (values[0].type === 'room') {
          dlhData = {
            room: {
              uuid: values[0].uuid,
              'regulation-value': values[0].value,
              'geometry-factor': values[0].geometry,
            },
          };
        } else {
          dlhData = {
            groups: values.map((item) => ({
              uuid: item.uuid,
              'regulation-value': item.value,
              'geometry-factor': item.geometry,
            })),
          };
        }
      }
      return dlhData;
    },
    getDLHValues() {
      if (!this.projectConfig) {
        return [];
      }
      const room = (this.projectConfig?.rooms || []).find((r) => r.uuid === this.roomId);
      if (!room || !room.sensors.length) {
        return [];
      }
      const groups = (this.projectConfig?.groups || []).filter((group) => room.groups.includes(group.uuid));
      const groupsWithSensor = groups.filter((group) => group.sensors && group.sensors.length);

      if (!groupsWithSensor.length) {
        const dlh = (room.dlh && room.dlh[0]) || {};
        return [{
          type: 'room',
          uuid: room.uuid,
          label: room.name,
          value: dlh['regulation-value'] || 80,
          geometry: dlh['geometry-factor'] || 3,
        }];
      }

      return groupsWithSensor.map((group) => {
        const dlh = (group.dlh && group.dlh[0]) || {};

        return {
          type: 'group',
          uuid: group.uuid,
          label: group.name,
          value: dlh['regulation-value'] || 0,
          geometry: dlh['geometry-factor'] || 3,
        };
      });
    },
    resetForm() {
      this.currentStep = 1;
      this.repeatError = false;
      this.data = {};
      const {
        name, duration, repeat, actions,
      } = this.rule;
      let target = 'room';
      if (actions.target && actions.target.type !== 'room') {
        target = actions.target.uuid;
      }
      const dlh = {
        selected: actions.value['dlh-enabled'],
        values: this.getDLHValues(),
      };
      const autoOn = {
        selected: dlh.selected || actions.value['auto-on'],
        value: actions.value.level || 100,
        dimSelected: !!actions.value.level,
      };
      const autoOff = {
        selected: dlh.selected || actions.value['auto-off'],
        dimSelected: !!actions.value['level-dim'],
        value: actions.value['level-dim'] || 30,
        downMin: (actions.value['delay-dim'] || 0) / 60,
        offMin: (actions.value.delay || 0) / 60,
      };
      this.data = {
        name, duration, repeat, target, autoOn, autoOff, dlh,
      };
      this.rerender += 1;
    },
    isSkippable() {
      this.$parent.$parent.$data.skippable = this.internalMode === this.mode;
    },
  },
};

/* global axios */

const getUser = async (userId) => axios({
  method: 'GET',
  url: `/users/${userId}`,
});

const deleteUser = async (userId) => axios({
  method: 'DELETE',
  url: `/users/${userId}`,
  logEvent: () => ({ eventName: 'delete_account' }),
});

const updateUser = async ({ userUUID, data }) => axios({
  method: 'PUT',
  url: `/users/${userUUID}`,
  data,
  logEvent: () => ({ eventName: 'edit_account' }),
});

const updatePassword = async ({ oldPassword, password }) => axios({
  method: 'PUT',
  url: '/users/password',
  data: { oldPassword, password },
  logEvent: () => ({ eventName: 'change_password' }),
});

const confirmEmailChange = async ({ email, token }) => axios({
  method: 'PATCH',
  url: `/users/${email}/confirm`,
  data: { token },
  logEvent: () => ({ eventName: 'confirm_email_change' }),
});

export default {
  getUser,
  deleteUser,
  updateUser,
  updatePassword,
  confirmEmailChange,
};

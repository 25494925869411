//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import deviceHelpers from '../../../../../mixins/deviceHelpers';
import ActionSelector from './ActionSelector.vue';
import ChooseLightModal from './ChooseLightModal.vue';

export default {
  name: 'EditSwitchChannelsUI',
  components: { ChooseLightModal, ActionSelector },
  props: ['device', 'typeAndLayout', 'dataOptions', 'editMode'],
  mixins: [deviceHelpers],
  data() {
    return {
      buttonData: [],
      selectedButtonIndex: null,
    };
  },
  computed: {
    buttonType() {
      return this.typeAndLayout?.buttonType || this.BUTTON_TYPES.EXPERT;
    },
    layout() {
      return this.typeAndLayout?.layout || 4;
    },
    buttons() {
      return this.serializedButtons(this.layout);
    },
    renderedButtons() {
      return this.buttons.map((button) => {
        this.setDefaultTarget(button);
        this.setDefaultActions(button);
        const targetValue = this.getTargetValue(button.target);
        return {
          ...button,
          computed: {
            targetValue,
          },
        };
      });
    },
  },
  watch: {
    renderedButtons: {
      handler(buttons) {
        this.buttonData = [...buttons.map((button) => JSON.parse(JSON.stringify(button)))];
      },
      immediate: true,
    },
    editMode: {
      handler() {
        this.buttonData = [...this.renderedButtons.map((button) => JSON.parse(JSON.stringify(button)))];
      },
      immediate: true,
    },
  },
  methods: {
    getButtonData() {
      return this.buttonData.filter((button) => !button.inactive);
    },
    showChooseLightModal(buttonIndex) {
      this.selectedButtonIndex = buttonIndex;
      this.$bvModal.show('light-select-modal');
    },
    onLightSelected(uuid) {
      this.$set(this.buttonData[this.selectedButtonIndex].target, 'uuid', uuid);
      this.buttonData = [...this.buttonData];
      this.$bvModal.hide('light-select-modal');
    },
    getTargetValue(target) {
      const { uuid, type } = target;
      let groupOption;
      let roomOption;
      switch (type) {
        case 'light':
          return (this.dataOptions?.lights[uuid]?.product || {}).name;
        case 'group':
          groupOption = this.dataOptions?.groups.find((g) => g.value === uuid) || {};
          if (!groupOption || !groupOption.text) {
            return '';
          }

          return `${groupOption.text || ''} (${this.$t('roomDevicesGroup')})`;
        case 'room':
          roomOption = this.dataOptions?.rooms.find((r) => r.value === uuid) || {};
          return `${roomOption.text || ''} (${this.$t('roomDevicesRoom')})`;
        default:
          return '';
      }
    },
    setDefaultTarget(button, clearUUID = false) {
      if (clearUUID) {
        button.target.uuid = null;
      }
      const targetUUID = button.target.uuid;
      const { inactive } = button;
      if (!targetUUID && !inactive) {
        if (button.target.type === 'room') {
          button.target.uuid = this.dataOptions?.currentRoom;
        }
      }
    },
    setDefaultActions(button) {
      const { inactive } = button;
      if (inactive || this.isSwitchConfigured()) {
        return;
      }

      if (this.buttonType === this.BUTTON_TYPES.PUSH_BUTTON) {
        switch (button.number) {
          case 1:
            button.events.pressed.action = 'on';
            break;
          case 2:
            button.events.pressed.action = 'off';
            break;
          case 3:
            button.events.hold_pressed.action = 'brighten';
            break;
          case 4:
            button.events.hold_pressed.action = 'dimming';
            break;
          default:
            break;
        }
      }

      if (this.buttonType === this.BUTTON_TYPES.ROCKER) {
        switch (button.number) {
          case 1:
            button.events.hold_pressed.action = 'on';
            button.events.released.action = 'off';
            break;
          case 2:
            button.events.hold_pressed.action = 'on';
            button.events.released.action = 'off';
            break;
          case 3:
            button.events.hold_pressed.action = 'on';
            button.events.released.action = 'off';
            break;
          case 4:
            button.events.hold_pressed.action = 'on';
            button.events.released.action = 'off';
            break;
          default:
            break;
        }
      }
    },
    options(button) {
      const options = {
        targets: [
          { value: null, text: '' },
          { value: 'light', text: this.$t('roomDevicesLight') },
          { value: 'room', text: this.$t('roomDevicesRoom') },
          { value: 'group', text: this.$t('roomDevicesGroup') },
        ],
        actions: [
          { value: null, text: '' },
          { value: 'on', text: this.$t('roomDevicesActionOn') },
          { value: 'off', text: this.$t('roomDevicesActionOff') },
          { value: 'set-dim', text: this.$t('roomDevicesActionSetDim') },
          { value: 'set-cct', text: this.$t('roomDevicesActionSetCCT') },
          { value: 'toggle', text: this.$t('roomDevicesActionToggle') },
          { value: 'brighten', text: this.$t('roomDevicesActionDimUp') },
          { value: 'dimming', text: this.$t('roomDevicesActionDimDown') },
          { value: 'stop', text: this.$t('roomDevicesActionStop') },
          { value: 'cct-colder', text: this.$t('roomDevicesActionCCTColder') },
          { value: 'cct-warmer', text: this.$t('roomDevicesActionCCTWarmer') },
          { value: 'cct-stop', text: this.$t('roomDevicesActionStopCCT') },
        ],
      };

      if (button.target.type === 'room') {
        options.actions.push({
          value: 'scene',
          text: this.$t('roomDevicesScene'),
          disabled: !this.dataOptions?.scenes?.filter((scene) => scene.room === button.target.uuid).length,
        });
      }

      return options;
    },
  },
};

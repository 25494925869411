var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"observer",staticClass:"d-block login-form",attrs:{"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var handleSubmit = ref.handleSubmit;
return [_c('b-form',{attrs:{"novalidate":""},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onRegister)}}},[(_vm.emailIsUsed)?_c('b-alert',{attrs:{"show":"","variant":"danger"}},[_c('div',[_vm._v(" "+_vm._s(_vm.$t('authRegistrationFailedPart1'))+" "),_c('b-link',{attrs:{"to":{ name: 'login', query: { forgotpassword: true } }}},[_vm._v(" "+_vm._s(_vm.$t('authRegistrationFailedPart2'))+" ")])],1)]):_vm._e(),_c('ValidationProvider',{attrs:{"rules":"required|email_noplus|max:255","vid":"email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return [_c('b-input-group',[_c('b-form-input',{attrs:{"id":"reg-email","type":"email","placeholder":_vm.$t('authEmailAddress')+'*',"autocomplete":"off","state":errors[0] ? false : null},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"space",32,$event.key,[" ","Spacebar"])){ return null; }$event.preventDefault();}},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":"required|password|min:8|max:32","vid":"password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return [_c('b-input-group',[_c('b-form-input',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.left",value:({ customClass: 'tooltip-auth', title: _vm.$t('generalPasswordTooltip') }),expression:"{ customClass: 'tooltip-auth', title: $t('generalPasswordTooltip') }",modifiers:{"left":true}}],attrs:{"id":"reg-password","type":_vm.passwordFieldType,"placeholder":_vm.$t('authPassword')+'*',"autocomplete":"new-password","state":errors[0] ? false : null},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('button',{staticClass:"password-toggle",attrs:{"type":"button"},on:{"click":function($event){_vm.showPassword = !_vm.showPassword}}},[(_vm.showPassword)?_c('img',{attrs:{"src":require("../../assets/icons/password/hide.svg"),"alt":"Hide password"}}):_c('img',{attrs:{"src":require("../../assets/icons/password/show.svg"),"alt":"Show password"}})]),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":"required|confirmed:password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return [_c('b-input-group',[_c('b-form-input',{attrs:{"id":"reg-password-again","type":_vm.passwordFieldType,"required":"","placeholder":_vm.$t('authRepeatPassword')+'*',"autocomplete":"new-password","state":errors[0] ? false : null},model:{value:(_vm.passwordRepeat),callback:function ($$v) {_vm.passwordRepeat=$$v},expression:"passwordRepeat"}}),_c('button',{staticClass:"password-toggle",attrs:{"type":"button"},on:{"click":function($event){_vm.showPassword = !_vm.showPassword}}},[(_vm.showPassword)?_c('img',{attrs:{"src":require("../../assets/icons/password/hide.svg"),"alt":"Hide password"}}):_c('img',{attrs:{"src":require("../../assets/icons/password/show.svg"),"alt":"Show password"}})]),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":"required|max:128"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return [_c('b-input-group',[_c('b-form-input',{attrs:{"id":"reg-name","placeholder":_vm.$t('authRegistrationName')+'*',"state":errors[0] ? false : null},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":"required|max:128"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return [_c('b-input-group',[_c('b-form-input',{attrs:{"id":"reg-company","placeholder":_vm.$t('authCompany')+'*',"state":errors[0] ? false : null},model:{value:(_vm.company),callback:function ($$v) {_vm.company=$$v},expression:"company"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('InputField',{attrs:{"type":"phone","simpleInputLabel":true,"placeholder":_vm.$t('authMobilePhone')+'*',"rules":"required|phone|max:16","tooltip":_vm.$t('generalPhoneNumberTooltip'),"schema":"phone"},model:{value:(_vm.phone),callback:function ($$v) {_vm.phone=$$v},expression:"phone"}}),_c('p',{staticClass:"text-center"},[_c('b-link',{attrs:{"to":"/legal-page/privacy","target":"_blank"}},[_vm._v(_vm._s(_vm.$t('pagePrivacyPolicy')))]),_c('br'),_c('b-link',{attrs:{"to":"/legal-page/tos","target":"_blank"}},[_vm._v(_vm._s(_vm.$t('pageTermsOfUse')))])],1),_c('ValidationProvider',{attrs:{"rules":{ required: { allowFalse: false } }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return [_c('b-form-group',{staticClass:"text-center"},[_c('b-form-checkbox',{attrs:{"state":errors[0] ? false : null},model:{value:(_vm.acceptTerms),callback:function ($$v) {_vm.acceptTerms=$$v},expression:"acceptTerms"}},[_vm._v(_vm._s(_vm.$t('authReadAndAccept'))+"* "),_c('span',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(_vm.$t('authReadAndAcceptError')))])])],1)]}}],null,true)}),_c('div',{staticClass:"text-center mb-2"},[_c('b-button',{attrs:{"disabled":!valid||_vm.submitted,"type":"submit","pill":"","variant":"primary"}},[_vm._v(_vm._s(_vm.$t('authRegister')))])],1),_c('p',{staticClass:"text-center"},[_c('b-link',{attrs:{"to":"login"}},[_vm._v(_vm._s(_vm.$t('generalBack')))])],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions } from 'vuex';
import { ValidationObserver } from 'vee-validate';
import InputField from '../../InputField.vue';
import TemplateVariables from '../TemplateVariables.vue';

export default {
  props: ['room', 'deviceCounts', 'sections', 'template'],
  name: 'CreateRoomByTemplate',
  components: {
    TemplateVariables,
    InputField,
    ValidationObserver,
    RoomDetailsUI: () => import('../RoomDetailsUI.vue'),
  },
  data() {
    return {
      projectId: this.$route.params.projectId,
      roomId: this.$route.params.roomId,

      disableCreate: false,
      name: '',
      description: '',
      templateData: {
        uuid: this.template && this.template.uuid,
        locale: 'en',
      },
    };
  },
  methods: {
    ...mapActions('rooms', ['createRoom']),
    ...mapActions('groups', ['createGroup', 'listGroups', 'addDevicesToGroup']),
    async onCreate() {
      const valid = await this.$refs.observer.validate();
      if (!valid) {
        return;
      }
      this.disableCreate = true;

      const { name, description } = this;
      let template;
      if (this.template) {
        template = this.templateData;
      }
      const data = { name, description, template };

      try {
        await this.createRoom({ uuid: this.projectId, data });
        await this.$router.push(`/projects/${this.projectId}/rooms`);
      } catch (e) {
        this.disableCreate = false;
      }
      this.$bvModal.hide('create-room-by-template');
      this.resetForm();
    },
    onCancel() {
      this.$bvModal.hide('create-room-by-template');
      this.resetForm();
    },
    resetForm() {
      this.disableCreate = false;
      this.name = '';
      this.description = '';
    },
    onTemplateChange(template) {
      this.templateData = template;
    },
  },
};

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapState } from 'vuex';
import CustomSection from '@/components/ui/CustomSection.vue';
import FloorplanItem from '@/components/ui/floorplan/FloorplanItem.vue';
import IconLink from '@/components/ui/IconLink.vue';
import AddFloorplanModal from './modals/AddFloorplanModal.vue';
import EditFloorplanModal from './modals/EditFloorplanModal.vue';
import DeleteFloorplanModal from './modals/DeleteFloorplanModal.vue';

export default {
  name: 'RoomDevices',
  components: {
    IconLink,
    CustomSection,
    FloorplanItem,
    AddFloorplanModal,
    EditFloorplanModal,
    DeleteFloorplanModal,
  },
  data() {
    return {
      dataLoaded: false,
      projectId: this.$route.params.projectId,
      list: [],
      roomList: [],
      selectedFloorplan: null,
    };
  },
  async created() {
    await this.getFloorplans();
  },
  computed: {
    ...mapState('projects', ['floorplans', 'roomFloorplans']),
    ...mapState('app', ['floorplanOpened']),
  },
  methods: {
    ...mapActions('projects', ['listFloorplans', 'deleteFloorplan', 'updateFloorplan']),
    ...mapActions('app', ['floorplanViewerUpdate']),
    async getFloorplans() {
      await this.listFloorplans({ uuid: this.projectId });
      this.list = [...this.floorplans];
      this.roomList = [...this.roomFloorplans]
        .filter((room) => room.floorplans.length)
        .map((room) => ({
          ...room,
          floorplans: room.floorplans.map((floorplan) => ({ ...floorplan, room: room.uuid })),
        }));
    },
    async onMove({ from, to, room }) {
      let toIndex;
      let attachment;
      if (!room) {
        attachment = this.list.find((item) => item.attachmentUUID === from);
        toIndex = this.list.findIndex((item) => item.attachmentUUID === to);
      } else {
        const roomList = this.roomList.find((r) => r.uuid === room);
        if (!roomList) {
          return;
        }
        attachment = roomList.floorplans.find((item) => item.attachmentUUID === from);
        toIndex = roomList.floorplans.findIndex((item) => item.attachmentUUID === to);
      }
      await this.updateFloorplan({
        uuid: this.projectId,
        attachmentUUID: from,
        data: { name: attachment.name, order: toIndex + 1, roomUUID: room },
      });
      await this.getFloorplans();
      this.onFloorplanViewerUpdate();
    },
    onEdit(floorplan, roomUUID, index) {
      this.selectedFloorplan = { ...floorplan, roomUUID, order: index + 1 };
      this.$bvModal.show('edit-floorplan-modal');
    },
    openDeleteModal(floorplan, roomUUID) {
      this.selectedFloorplan = { ...floorplan, roomUUID };
      this.$bvModal.show('delete-floorplan-modal');
    },
    async onSave() {
      await this.getFloorplans();
      this.onFloorplanViewerUpdate();
    },
    async onDelete() {
      await this.deleteFloorplan({
        uuid: this.projectId,
        roomUUID: this.selectedFloorplan.roomUUID,
        attachmentUUID: this.selectedFloorplan.attachmentUUID,
      });
      await this.getFloorplans();
      this.onFloorplanViewerUpdate();
      this.$bvModal.hide('delete-floorplan-modal');
    },
    onFloorplanViewerUpdate(status = true) {
      if (this.floorplanOpened) {
        this.floorplanViewerUpdate(status);
      }
    },
  },
};

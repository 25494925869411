//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapGetters } from 'vuex';
import { ValidationObserver } from 'vee-validate';
import InputField from '@/components/ui/InputField.vue';
import CheckboxSelect from '@/components/ui/CheckboxSelect.vue';

export default {
  props: ['hideLightEdit', 'hideSensorEdit'],
  components: {
    InputField,
    CheckboxSelect,
    ValidationObserver,
  },
  data() {
    return {
      projectId: this.$route.params.projectId,
      roomId: this.$route.params.roomId,

      disableCreate: false,
      name: '',
      description: '',
      items: [],
      sensorItems: [],
    };
  },
  async mounted() {
    await this.getProjectConfig({ uuid: this.projectId });
    await this.listDevices({ uuid: this.projectId, roomId: this.roomId });
    this.resetForm();
  },
  computed: {
    ...mapGetters('devices', ['getDevicesByRoom']),
    ...mapGetters('configs', ['getProjectConfigById']),
    lights() {
      return this.getDevicesByRoom(this.projectId, this.roomId).lights;
    },
    sensors() {
      return this.getDevicesByRoom(this.projectId, this.roomId).sensors;
    },
    projectConfig() {
      return this.getProjectConfigById(this.projectId);
    },
    room() {
      return (this.projectConfig?.rooms || []).find((r) => r.uuid === this.roomId);
    },
    groups() {
      return (this.projectConfig?.groups || []).filter((group) => this.room.groups.includes(group.uuid));
    },
  },
  methods: {
    ...mapActions('devices', ['listDevices']),
    ...mapActions('groups', ['createGroup', 'listGroups', 'addDevicesToGroup']),
    ...mapActions('configs', ['getProjectConfig']),
    async onCreate() {
      const valid = await this.$refs.observer.validate();
      if (!valid) {
        return;
      }
      this.disableCreate = true;
      const { name, description } = this;
      const members = this.items.filter((i) => i.checked).map((i) => i.value);
      const sensors = this.sensorItems.filter((i) => i.checked).map((i) => i.value);
      const data = {
        name, description, members, sensors,
      };
      try {
        await this.createGroup({ uuid: this.projectId, roomId: this.roomId, data });
        await this.listGroups({ uuid: this.projectId, roomId: this.roomId });
        await this.getProjectConfig({ uuid: this.projectId });
        this.$bvModal.hide('add-group-modal');
        this.resetForm();
      } finally {
        this.disableCreate = false;
      }
    },
    onCancel() {
      this.$bvModal.hide('add-group-modal');
      this.resetForm();
    },
    resetForm() {
      this.disableCreate = false;
      this.name = '';
      this.description = '';
      this.items = this.lights.map((light) => {
        const groupsWithLight = this.groups.filter((group) => group.members && group.members.length);
        const disabled = groupsWithLight.some((group) => (group.members || []).includes(light.uuid));
        return {
          value: light.uuid,
          text: `${light.sequenceNumber}. ${light.product.name}`,
          checked: false,
          description: light.description,
          disabled,
          group: light.groups[0] || null,
        };
      });
      this.sensorItems = this.sensors.map((sensor) => {
        const groupsWithSensor = this.groups.filter((group) => group.sensors && group.sensors.length);
        const disabled = groupsWithSensor.some((group) => (group.sensors || []).includes(sensor.uuid));
        return {
          value: sensor.uuid,
          text: `${sensor.sequenceNumber}. ${sensor.product.name}`,
          checked: false,
          description: sensor.description,
          disabled,
          group: sensor.groups[0] || null,
        };
      });
    },
  },
};

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import { mapActions, mapGetters } from 'vuex';
import IconLink from '@/components/ui/IconLink.vue';
import CustomModal from '@/components/ui/CustomModal.vue';
import AdvancedTable from '@/components/ui/AdvancedTable.vue';

export default {
  name: 'RemoteControlBar',
  components: { AdvancedTable, CustomModal, IconLink },
  data() {
    return {
      loading: false,
      cancelModal: false,
      applyModal: false,
      progressModal: false,
      uploadFailed: false,
      uploadFailedModal: false,
      uploadTimeout: null,
      progressStart: 0,
      uploadSuccessModal: false,
    };
  },
  computed: {
    ...mapGetters('projects', ['getWorkInProgressData', 'getConfigDiffById', 'getRemoteControlLeaveRoute']),
    ...mapGetters(['getMaxProgress']),
    progress() {
      return this.getMaxProgress(this.projectId);
    },
    projectId() {
      return this.$route.params.projectId;
    },
    configDiff() {
      return this.getConfigDiffById(this.projectId);
    },
    configRoomDiffs() {
      return (this.configDiff.rooms || [])
        .map((room) => {
          room.key = 'room';
          if (room.state !== 'changed') {
            room.changes = [{ ...room, key: 'room', changes: [] }, ...room.changes];
          }
          return room;
        });
    },
    configScheduleDiffs() {
      return (this.configDiff.schedules || [])
        .map((schedule) => {
          schedule.key = 'schedule';
          if (schedule.state !== 'changed') {
            schedule.changes = [{ ...schedule, key: 'schedules', changes: [] }, ...schedule.changes];
          }
          return schedule;
        });
    },
    configBMSDiffs() {
      return (this.configDiff.bms || [])
        .map((item) => {
          item.key = 'Cloud Service';
          if (item.state !== 'changed') {
            item.changes = [{ ...item, key: 'Cloud Service', changes: [] }, ...item.changes];
          }
          return item;
        });
    },
    configProjectDiffs() {
      if (!this.configDiff.project?.changes?.length) {
        return [];
      }
      const item = this.configDiff.project;
      item.key = 'Project';
      item.changes = [...item.changes].map((c) => {
        c.name = '';
        return c;
      });
      return [item];
    },
    allConfigDiffs() {
      return [
        ...this.configProjectDiffs,
        ...this.configRoomDiffs,
        ...this.configScheduleDiffs,
        ...this.configBMSDiffs];
    },
    workInProgressData() {
      return this.getWorkInProgressData(this.projectId);
    },
    cancelModalProps() {
      return {
        title: this.$t('remoteControlDismissModalTitle'),
        message: this.$t('remoteControlDismissModalMessage'),
        buttons: {
          main: { title: this.$t('remoteControlDismiss'), click: this.onRemoteControlCancel },
          secondary: { title: this.$t('generalCancel'), click: this.closeModals },
        },
      };
    },
    applyModalProps() {
      return {
        title: this.$t('remoteControlChangesModalTitle'),
        buttons: {
          main: {
            title: this.$t('remoteControlApply'),
            click: this.onRemoteControlCommit,
            disabled: this.loading || !this.allConfigDiffs.length,
          },
          secondary: { title: this.$t('remoteControlContinueConfiguration'), click: this.closeModals },
        },
      };
    },
    progressModalProps() {
      return {
        title: this.$t('remoteControlApplyChangesModalTitle'),
        message: this.$t('remoteControlApplyChangesModalMessage'),
        buttons: {
          main: { title: this.$t('generalClose'), click: this.closeModals },
        },
      };
    },
    uploadFailedModalProps() {
      return {
        title: this.$t('generalSomethingWentWrong'),
        buttons: {
          main: { title: this.$t('generalRetry'), click: this.openApplyModal, disabled: this.loading },
          secondary: { title: this.$t('generalCancel'), click: this.closeModals },
        },
      };
    },
    uploadSuccessModalProps() {
      return {
        title: this.$t('remoteControlUploadedSuccessfully'),
        buttons: {
          main: { title: this.$t('generalDone'), click: this.onProgressDone, disabled: this.loading },
        },
      };
    },
    remoteControlLeaveRoute() {
      return this.getRemoteControlLeaveRoute;
    },
    showRemoteControlAlert() {
      return !!this.remoteControlLeaveRoute;
    },
    leaveModalProps() {
      return {
        title: this.$t('generalConfirmation'),
        message: this.$t('remoteControlLeaveModalMessage'),
        buttons: {
          main: { title: this.$t('generalYes'), click: () => this.leaveRemoteControl(true) },
          secondary: { title: this.$t('generalCancel'), click: () => this.leaveRemoteControl(false) },
        },
      };
    },
  },
  watch: {
    progress(val) {
      if (val === 100) {
        this.loading = false;
        this.progressModal = false;
        this.uploadSuccessModal = true;
      }
    },
  },
  methods: {
    ...mapActions('projects', ['getProject', 'cancelEditMode', 'commitEditMode', 'unlockProject', 'getConfigDiff', 'updateWipConfig', 'setRemoteControlLeaveRoute']),
    ...mapActions(['subscribeToProject', 'unsubscribeFromProject', 'resetConfigUpdates']),
    openModal(val) {
      this.closeModals();
      this[val] = true;
    },
    async openApplyModal() {
      this.closeModals();
      await this.getConfigDiff({ uuid: this.projectId });
      this.applyModal = true;
    },
    onUploadFailed() {
      this.uploadFailed = true;
      this.loading = false;
      this.closeModals();
      this.uploadFailedModal = true;
    },
    closeModals() {
      this.cancelModal = false;
      this.applyModal = false;
      this.progressModal = false;
      this.uploadFailedModal = false;
    },
    async leaveRemoteControl(status) {
      if (status) {
        await this.onRemoteControlCancel();
        await this.$router.push(this.remoteControlLeaveRoute);
      }
      await this.setRemoteControlLeaveRoute(null);
    },
    async onRemoteControlCancel() {
      if (this.loading) {
        return;
      }
      this.loading = true;
      const { workInProgressRevision } = this.workInProgressData;
      await this.cancelEditMode({
        uuid: this.projectId,
        subjectConfigRevision: workInProgressRevision,
      }).catch((e) => {
        this.finishLoading();
        throw e;
      });
      await this.getProject(this.projectId).catch((e) => {
        this.finishLoading();
        throw e;
      });
      await this.unlockProject(this.projectId).catch((e) => {
        this.finishLoading();
        throw e;
      });
      this.finishLoading();
      this.closeModals();
      await this.checkRouteOnEnd();
    },
    async onRemoteControlCommit() {
      this.closeModals();
      this.resetConfigUpdates(this.projectId);
      this.uploadFailed = false;
      this.loading = true;
      this.progressModal = true;
      this.progressStart = this.progress;
      await this.getProject(this.projectId);
      const { workInProgressRevision } = this.workInProgressData;
      await this.subscribeToProject(this.projectId).catch((e) => {
        this.onUploadFailed();
        throw e;
      });
      await this.updateWipConfig({
        uuid: this.projectId,
        revision: workInProgressRevision,
      }).catch((e) => {
        this.onUploadFailed();
        throw e;
      });
      await this.commitEditMode({
        uuid: this.projectId,
        subjectConfigRevision: workInProgressRevision,
      }).catch((e) => {
        this.onUploadFailed();
        throw e;
      });
      clearTimeout(this.uploadTimeout);
      this.uploadTimeout = setTimeout(() => {
        if (this.progress === 0 || this.progressStart === this.progress) {
          this.onProgressDone();
        }
      }, 25000);
    },
    async onProgressDone() {
      this.loading = true;
      await this.getProject(this.projectId).catch((e) => {
        this.finishLoading();
        throw e;
      });
      await this.unlockProject(this.projectId).catch((e) => {
        this.finishLoading();
        throw e;
      });
      this.finishLoading();
      this.uploadSuccessModal = false;
      await this.checkRouteOnEnd();
    },
    finishLoading() {
      this.loading = false;
    },
    translateByKey(key) {
      return {
        members: this.$t('generalLights'),
        groups: this.$t('generalGroups'),
        switches: this.$t('generalSwitches'),
        sensors: this.$t('generalSensors'),
        scenes: this.$t('generalScenes'),
        rules: this.$t('generalRules'),
        schedules: this.$t('generalSchedules'),
        schedule: this.$t('generalSchedules'),
        rooms: this.$t('generalRooms'),
        room: this.$t('generalRooms'),
        added: this.$t('remoteControlItemAdded'),
        changed: this.$t('remoteControlItemChanged'),
        removed: this.$t('remoteControlItemRemoved'),
        repeat: this.$t('generalDays'),
      }[key] || key;
    },
    getChangesFromName(name) {
      if (typeof name === 'string') {
        return name;
      }
      return '';
    },
    async checkRouteOnInit() {
      const { remoteDisabled } = this.$route.meta;
      if (remoteDisabled) {
        await this.$router.push({ name: 'project-rooms' });
      }
    },
    async checkRouteOnEnd() {
      const { remoteRedirect } = this.$route.meta;
      if (remoteRedirect && remoteRedirect.length) {
        await this.$router.push({ name: remoteRedirect });
      }
    },
  },
  async created() {
    await this.checkRouteOnInit();
  },
};

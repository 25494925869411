//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapGetters } from 'vuex';
import SectionTitle from '@/components/ui/SectionTitle.vue';
import FixedFooter from '@/components/ui/FixedFooter.vue';
import MaintenanceErrorList from '@/components/ui/MaintenanceErrorList.vue';

export default {
  name: 'MaintenanceDetails',
  components: { MaintenanceErrorList, SectionTitle, FixedFooter },
  data() {
    return {
      projectId: this.$route.params.projectId,
    };
  },
  async created() {
    await this.getProject(this.projectId);
  },
  computed: {
    ...mapGetters('projects', ['getHealthEntries', 'getProjectById']),
    project() {
      return this.getProjectById(this.projectId);
    },
    healthIssues() {
      return this.getHealthEntries(this.projectId).allErrors || [];
    },
    footerButtons() {
      return {
        main: {
          title: this.$t('projectGoTo'),
          to: { name: 'project-rooms', params: { projectId: this.projectId } },
        },
      };
    },
  },
  methods: {
    ...mapActions('projects', ['getProject']),
    getHealthIssues(uuid) {
      return this.getHealthEntries(uuid).allErrors;
    },
    goToEntry(objType, roomId, deviceId) {
      if (roomId) {
        return objType === 'rule' ? `/projects/${this.projectId}/rooms/${roomId}/rules` : `/projects/${this.projectId}/rooms/${roomId}/devices/${deviceId}`;
      }
      return '';
    },
    formattedAddress(address) {
      const city = address.city && address.city.length ? `${address.city}, ` : '';
      const country = address.country && address.country.length ? address.country : '';
      const county = address.county && address.county.length ? `${address.county}, ` : '';
      const line1 = address.line1 && address.line1.length ? `${address.line1}, ` : '';
      const line2 = address.line2 && address.line2.length ? `${address.line2}, ` : '';
      const state = address.state && address.state.length ? `${address.state}, ` : '';
      const zipCode = address.zipCode && address.zipCode.length ? `${address.zipCode} ` : '';
      const formatted = line1 + line2 + zipCode + city + county + state + country;
      return Object.keys(address).length ? formatted : '';
    },
  },
};

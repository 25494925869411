//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapGetters } from 'vuex';
import { ValidationObserver } from 'vee-validate';
import MaintenanceErrorDetails from '@/components/ui/MaintenanceErrorDetails.vue';
import InputField from '../InputField.vue';
import IconLink from '../IconLink.vue';
import InputTimeField from '../InputTimeField.vue';
import InputDaysField from '../InputDaysField.vue';
import CustomSection from '../CustomSection.vue';
import CustomSlider from '../CustomSlider.vue';

export default {
  name: 'RoomScheduleDetailsUI',
  props: ['schedule', 'readOnly', 'options', 'saveSchedule', 'createMode', 'rooms', 'groups', 'lights', 'projectId', 'hideEdit', 'hideDelete', 'healthIssues'],
  components: {
    ValidationObserver,
    InputField,
    IconLink,
    InputTimeField,
    InputDaysField,
    CustomSection,
    CustomSlider,
    MaintenanceErrorDetails,
  },
  data() {
    return {
      rerender: 1,
      editMode: this.createMode,
      disableSave: false,
      data: {},
      repeatError: false,
      targetsError: false,
      targetsErrorType: '',
      targets: [],
      selectedScene: null,
      dim: false,
      cct: false,
      warningAccepted: false,
      defaultLevel: 100,
      defaultCct: 4000,
      levelRange: {
        from: 1,
        to: 100,
        unit: '%',
      },
    };
  },
  watch: {
    'data.target': function clearTarget(val) {
      this.targets = [];
      if (val === 'gateway') {
        this.targets = [{ target: 'gateway', uuid: this.projectId }];
      }
    },
  },
  created() {
    this.resetForm();
    this.listSelectedRoomScenes();
    this.$nextTick(() => this.updateTargets());
  },
  computed: {
    ...mapGetters('scenes', ['getScenesByRoom']),
    showSceneAction() {
      return this.data.target === 'room' && this.targets.length === 1;
    },
    actionTypes() {
      const actions = [{ value: 'On', text: this.$t('generalOn') }, { value: 'Off', text: this.$t('generalOff') }];
      if (this.showSceneAction) {
        actions.push({ value: 'Scene', text: this.$t('scheduleScene') });
      }
      return actions;
    },
    scenes() {
      if (this.showSceneAction) {
        return this.getScenesByRoom(this.projectId, this.targets[0].uuid);
      }
      return [];
    },
    sceneOptions() {
      return this.scenes.map((scene) => ({ value: scene.uuid, text: scene.name }));
    },
    showLightsOffWarning() {
      return this.editMode && this.data.target === 'gateway' && this.data.actionType === 'Off';
    },
    showLightsOffWarningAlertOnCreate() {
      return this.createMode && this.showLightsOffWarning && !this.warningAccepted;
    },
    showLightsOffWarningAlertOnEdit() {
      if (!this.editMode) {
        return false;
      }
      return this.data.target === 'gateway'
          && ((this.schedule.actions.length > 0 && this.schedule.actions[0].target.type !== 'gateway') || (this.schedule.actions.length > 0 && this.schedule.actions[0].value.on))
          && this.data.actionType === 'Off'
          && !this.warningAccepted;
    },
    roomError() {
      return this.targetsError && this.targetsErrorType === 'room' && this.targets.length === 0;
    },
    groupError() {
      return this.targetsError && this.targetsErrorType === 'group' && this.targets.length === 0;
    },
    lightError() {
      return this.targetsError && this.targetsErrorType === 'light' && this.targets.length === 0;
    },
  },
  methods: {
    ...mapActions('scenes', ['listScenes']),
    isInterminate(type, roomId) {
      if (type === 'rooms') {
        return this[type].some((item) => this.targets.some((T) => T.uuid === item.uuid));
      }
      return this[type][roomId].some((item) => this.targets.some((T) => T.uuid === item.uuid));
    },
    selectAllChange(add, target, items) {
      const targets = items.map((i) => ({ target, uuid: i.uuid }));
      if (add) {
        this.targets = this.targets.concat(targets.filter((item) => !this.targets.find((t) => t.uuid === item.uuid)));
      } else {
        this.targets = this.targets.filter((item) => !targets.find((t) => t.uuid === item.uuid));
      }
    },
    targetChange(add, target, uuid) {
      this.selectedScene = null;
      if (add) {
        this.targets = [...this.targets, { target, uuid }];
      } else {
        this.targets = this.targets.filter((T) => !(T.target === target && T.uuid === uuid));
      }
      this.listSelectedRoomScenes();
    },
    async listSelectedRoomScenes() {
      if (this.targets.length === 1 && this.targets[0].target === 'room') {
        const scenes = this.getScenesByRoom(this.projectId, this.targets[0].uuid);
        if (!scenes || !scenes.length) {
          await this.listScenes({ uuid: this.projectId, roomId: this.targets[0].uuid });
        }
      }
    },
    isAllSelected(target, items) {
      return items.every((item) => this.targets.find((t) => t.target === target && t.uuid === item.uuid));
    },
    isTargetSelected(targets) {
      return this.targets.some((T1) => targets.some((T2) => T1.target === T2.target && T1.uuid === T2.uuid));
    },
    getRoomById(roomId) {
      return this.rooms.find((r) => r.uuid === roomId) || {};
    },
    switchMode(mode) {
      this.resetForm();
      this.$emit('switchMode', mode);
      this.editMode = mode;
    },
    async onSave() {
      if (this.disableSave) {
        return;
      }
      const valid = await this.$refs.observer.validate();
      if (!valid) {
        return;
      }
      if (this.data.repeat < 1) {
        this.repeatError = true;
        return;
      }
      if (this.targets.length === 0) {
        this.targetsErrorType = this.data.target;
        this.targetsError = true;
        return;
      }
      if (this.showLightsOffWarningAlertOnCreate || this.showLightsOffWarningAlertOnEdit) {
        this.$bvModal.show('schedule-warning-modal');
        return;
      }
      this.disableSave = true;
      const {
        name, time, repeat, action, actionType,
      } = this.data;
      let actions = this.targets.map((T) => ({
        target: { type: T.target, uuid: T.uuid },
        value: {
          on: actionType === 'On',
          level: actionType === 'On' && action.selected.level ? action.value.level : undefined,
          cct: actionType === 'On' && action.selected.cct ? action.value.cct : undefined,
        },
      }));
      let room;
      if (this.data.actionType === 'Scene') {
        if (!this.selectedScene) {
          return;
        }
        actions = [{
          target: { type: 'scene', uuid: this.selectedScene },
        }];
        room = this.targets[0].uuid;
      }
      const data = {
        name, time, actions, repeat, room,
      };
      try {
        await this.saveSchedule(data);
      } catch (err) {
        this.disableSave = false;
        throw err;
      }
      this.disableSave = false;
      this.switchMode(false);
      this.warningAccepted = false;
    },
    onDelete() {
      this.$emit('delete');
    },
    onChange(data, type) {
      if (type === 'level') {
        this.data.action.value.level = Number.isInteger(data.value) ? data.value : this.defaultLevel;
        if (this.data.action.selected) {
          this.data.action.selected.level = data.selected;
        }
      }
      if (type === 'cct') {
        this.data.action.value.cct = Number.isInteger(data.value) ? data.value : this.defaultCct;
        if (this.data.action.selected) {
          this.data.action.selected.cct = data.selected;
        }
      }
    },
    resetForm() {
      const {
        name, time, repeat, actions, room,
      } = this.schedule;
      const firstAction = actions[0] || {};
      const value = firstAction.value || {};
      let target = (firstAction.target && firstAction.target.type) || '';
      let actionType = value.on ? 'On' : 'Off';
      const action = {
        on: value.on,
        selected: {
          level: !!value.level,
          cct: !!value.cct,
        },
        value: {
          level: value.level || this.defaultLevel,
          cct: value.cct || this.defaultCct,
        },
      };
      this.updateTargets();
      this.selectedScene = null;
      if (target === 'scene') {
        target = 'room';
        actionType = 'Scene';
        this.targets = [{ target: 'room', uuid: room }];
        this.selectedScene = (firstAction.target && firstAction.target.uuid) || null;
      }
      this.data = {
        name, time, target, repeat, action, actionType,
      };
      this.rerender += 1;
      this.dim = !!value.level;
      this.cct = !!value.cct;
    },
    valueLabel(label, value, unit) {
      return `${label}: ${value}${unit}`;
    },
    updateTargets() {
      this.targets = this.schedule.actions.map((a) => ({ target: a.target.type, uuid: a.target.uuid }));
    },
    getTargetLabel(type) {
      if (type === 'group') {
        return this.$t('generalGroups');
      }
      if (type === 'room') {
        return this.$t('generalRooms');
      }
      if (type === 'light') {
        return this.$t('generalLights');
      }
      if (type === 'gateway') {
        return this.$t('generalProject');
      }
      return '';
    },
    acceptWarningModal() {
      this.warningAccepted = true;
      this.hideWarningModal();
      this.onSave();
    },
    hideWarningModal() {
      this.$bvModal.hide('schedule-warning-modal');
    },
  },
};

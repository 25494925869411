//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from 'vuex';
import { formatDate, OTA_STATUS } from '@/utils/helpers';
import Spinner from '@/components/ui/Spinner.vue';
import FixedFooter from '../../../components/ui/FixedFooter.vue';
import CustomModal from '../../../components/ui/CustomModal.vue';
import AdvancedTable from '../../../components/ui/AdvancedTable.vue';

export default {
  name: 'ProductFirmwareUpdate',
  data() {
    return {
      projectId: this.$route.params.projectId,
      loading: false,
      updateAllModal: false,
      errorModal: false,

      progressModal: false,
      progressArrived: false,

      selectFirmwareModal: false,
      selectedFirmwareUpdate: null,

      headers: [{
        title: this.$t('generalName'), key: 'productName', type: 'text', sortable: true, searchable: true,
      }, {
        title: this.$t('generalDescription'), key: 'description', type: 'text', sortable: true, searchable: true,
      }, {
        title: this.$t('firmwareVersion'), key: 'version', type: 'text', sortable: true, searchable: true,
      }],

      selectedDevice: null,
      singleDeviceUpdate: false,
    };
  },
  components: {
    Spinner,
    AdvancedTable,
    FixedFooter,
    CustomModal,
  },
  async created() {
    await this.init();
  },
  watch: {
    progress(newValue) {
      this.progressArrived = true;
      if (newValue === 100) {
        this.$bvModal.show('update-firmware-done-modal');
      }
    },
  },
  computed: {
    ...mapGetters('devices', ['getProjectDevices']),
    ...mapGetters('projects', ['getProjectById', 'isReadOnly']),
    ...mapGetters(['getConfigUpdatesById']),
    progress() {
      const configUpdateMessage = this.getConfigUpdatesById(this.projectId);
      return configUpdateMessage?.job ? configUpdateMessage.job.progress : 0;
    },
    project() {
      return this.getProjectById(this.projectId);
    },
    productId() {
      return this.$route.params.productId;
    },
    projectName() {
      return this.project.name;
    },
    readOnly() {
      return this.isReadOnly(this.projectId);
    },
    transferringOTAFiles() {
      return !this.progress || !this.progressArrived;
    },
    footerButtons() {
      const buttons = {
        main: {
          title: this.$t('firmwareUpdateAll'),
          disabled: this.everythingUpToDate || !this.$canI('otaDevice', 'firmwares') || !this.$canI('otaGateway', 'firmwares'),
          click: () => this.openUpdateModal(),
        },
      };
      return buttons;
    },
    updateAllButtons() {
      const buttons = {
        main: {
          title: this.$t('firmwareUpdate'),
          // In summary, the button is disabled if everything is up to date and there's no OTA error,
          // except when a firmware selection modal is active and the project is eligible for test firmware images. (aka. downgrade)
          disabled: (this.everythingUpToDate
            && this.selectedDevice?.otaStatusInfo.otaStatus !== OTA_STATUS.ERRORED
            && !(this.selectFirmwareModal && this.project.eligibleForTestFwImages))
            || !this.$canI('otaDevice', 'firmwares') || !this.$canI('otaGateway', 'firmwares'),
          click: this.onUpdateAll,
        },
      };

      buttons.secondary = {
        title: this.$t('generalCancel'),
        click: () => {
          this.updateAllModal = false;
        },
      };

      return buttons;
    },
    selectFirmwareButtons() {
      return {
        main: {
          title: this.$t('generalClose'),
          click: () => {
            this.selectFirmwareModal = false;
          },
        },
      };
    },
    progressModalProps() {
      return {
        title: this.$t('firmwareFirmwareUpdateToVersion', { version: this.selectedFirmwareUpdate?.displayVersion }),
        message: this.transferringOTAFiles ? this.$t('firmwareTransferFilesMessage') : this.$t('firmwareRestoreToVersionMessage'),
        buttons: {
          main: {
            title: this.$t('generalClose'),
            click: () => {
              this.progressModal = false;
            },
          },
        },
      };
    },
    errorModalButtons() {
      const buttons = {
        main: {
          title: this.$t('generalOk'),
          click: () => {
            this.errorModal = false;
          },
        },
      };

      return buttons;
    },
    devices() {
      const projectDevices = this.getProjectDevices(this.projectId);
      return projectDevices.filter((device) => device.productUuid === this.productId)
        .map((device) => ({
          ...device,
          otaError: device.otaStatusInfo.otaStatus === OTA_STATUS.ERRORED,
          otaUpdating: device.otaStatusInfo.otaStatus === OTA_STATUS.UPDATING,
          otaWaitForUpdate: device.otaStatusInfo.otaStatus === OTA_STATUS.WAITING,
        }));
    },
    devicesToUpdate() {
      return this.devices;
    },
    everythingUpToDate() {
      return Object.values(this.devices).every((device) => !device.hasNewFirmware);
    },
  },
  methods: {
    ...mapActions('products', ['getProduct']),
    ...mapActions('projects', ['updateProject']),
    ...mapActions('devices', ['listProjectDevices', 'updateDeviceFirmware']),
    async init() {
      await this.getProduct(this.productId);
      await this.listProjectDevices({ uuid: this.projectId });
    },
    openUpdateModal(device = null, selectedFirmwareUpdate = null) {
      // eslint-disable-next-line prefer-destructuring
      this.selectedDevice = device || this.devicesToUpdate[0];
      this.selectedFirmwareUpdate = selectedFirmwareUpdate || this.selectedDevice.firmwareUpdates[0];

      this.$nextTick(() => {
        const { releaseNotes } = this.selectedFirmwareUpdate;
        this.selectedFirmwareUpdate.releaseNotes = releaseNotes.replaceAll('\n', '<br>');
        this.singleDeviceUpdate = !!device;
        this.updateAllModal = true;
      });
    },
    openDowngradeModal(device = null) {
      // eslint-disable-next-line prefer-destructuring
      this.selectedDevice = device || this.devicesToUpdate[0];
      this.selectFirmwareModal = true;
    },

    openErrorModal(device) {
      this.selectedDevice = device || this.devicesToUpdate[0];
      this.errorModal = true;
    },
    async onUpdateAll() {
      this.loading = true;
      const version = this.selectedFirmwareUpdate?.identity?.version;
      const isDowngrade = this.selectedDevice?.firmwareUpdates?.[0]?.identity?.version !== version;

      let data = {
        updateItems: [{ productUuid: this.selectedDevice.productUuid }],
      };
      if (this.singleDeviceUpdate) {
        const updateItem = {
          deviceUuid: this.selectedDevice.deviceUuid,
        };

        if (isDowngrade) {
          updateItem.downgrade = true;
          updateItem.version = version;
        }

        data = {
          updateItems: [updateItem],
        };
      }

      this.progressArrived = false;
      await this.updateDeviceFirmware({ uuid: this.projectId, data })
        .then(() => {
          if (this.selectedDevice.category === 'gateways') {
            this.progressModal = true;
          } else {
            this.$router.push({ name: 'project-firmware-update-details' });
          }
        })
        .finally(async () => {
          this.loading = false;
          this.updateAllModal = false;
          await this.init();
        });
    },

    humanFileSize(size) {
      const i = Math.floor(Math.log(size) / Math.log(1024));
      return `${(size / (1024 ** i)).toFixed(2) * 1} ${['B', 'kB', 'MB', 'GB', 'TB'][i]}`;
    },
    getDate(d) {
      return formatDate(d);
    },
    onCancel() {
      this.$bvModal.hide('update-firmware-done-modal');
    },
  },
};

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import AdvancedTable from '../AdvancedTable.vue';

/* eslint-disable */
const STATUS_ICONS = {
  PLANNING: require('../../../assets/icons/projects/planning.svg'), // new project, until at least 1 device is scanned
  SCANNING_QR_CODES: require('../../../assets/icons/projects/scanning_info.svg'), // at least 1 device is scanned
  READY_TO_UPLOAD: require('../../../assets/icons/projects/ready_to_upload.svg'), // all devices are scanned
  UPLOADED_TO_GATEWAY: require('../../../assets/icons/projects/uploaded_to_gateway.svg') // gateway is connected to the project / config is uploaded
};
/* eslint-enable */

export default {
  name: 'ProjectListView',
  components: { AdvancedTable },
  props: {
    projectList: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      projectId: this.$route.params.projectId,
      headers: [{
        title: this.$t('projectProjectName'),
        key: 'name',
        type: 'text',
        sortable: false,
        searchable: false,
      }, {
        title: this.$t('projectAddress'), key: 'address.formatted', type: 'text', sortable: false, searchable: false,
      }, {
        title: this.$t('projectConnection'), key: 'gatewayConnectedToCloud', type: 'text', sortable: false, searchable: false,
      }, {
        title: this.$t('projectsStatus'), key: 'status.text', type: 'text', sortable: false, searchable: false,
      }],
    };
  },
  computed: {
    computedProjectList() {
      return this.projectList.map((project) => {
        let { status } = project;

        if (!status || !STATUS_ICONS[status]) {
          status = 'PLANNING';
        }

        project.statusObject = {
          text: this.$t(`projectsStatus_${status}`),
          icon: STATUS_ICONS[status],
        };

        return project;
      });
    },
  },
  methods: {
    onOpenProject(project) {
      this.$emit('onProjectClick', project);
    },
  },
};

import projectService from '../services/projects';
import roomService from '../services/rooms';
import { compareVersionStrings } from '../../utils/helpers';

const lockTTLInMinutes = 15;
const timersForLockRenewals = {};

const initialState = {
  projects: [],
  projectFilters: {},
  projectView: 'table',
  projectsById: {},
  projectLocks: {},
  floorplans: [],
  roomFloorplans: [],
  configDiffById: {},
  siteWorxByProjectId: {},
  checkboxErrorFilters: {
    light: true,
    sensor: true,
    switch: true,
    schedule: true,
    scene: true,
    gateway: true,
    rule: true,
    group: true,
    room: true,
  },
  remoteControlLeaveRoute: null,
};

let controllers = [];
const abort = () => {
  if (controllers.length) {
    controllers.forEach((controller) => {
      try {
        controller.abort();
      } catch (err) {
        // silent
      }
    });
    controllers = [];
  }
};
let listProjectPromise = Promise.resolve();

const actions = {
  async updateProjectFilters({ commit }, filters) {
    commit('updateProjectFilters', filters);
  },
  async updateProjectView({ commit }, view) {
    commit('updateProjectView', view);
  },
  async listProjects({ commit }) {
    abort();
    const controller = new AbortController();
    controllers.push(controller);
    listProjectPromise = projectService.listProjects({}, { signal: controller.signal })
      .then(({ data }) => {
        commit('listProjects', data.edges);
      }).catch(() => {});
    await listProjectPromise;
  },
  async getProject({ commit }, uuid) {
    const { data } = await projectService.getProject(uuid);
    commit('getProject', { data, uuid });
  },
  // projectDetails: { name, address, description, contacts }
  async createProject(ctx, projectDetails) {
    const { data } = await projectService.createProject(projectDetails);
    return data;
  },
  async updateProject({ commit }, {
    uuid, data, forceLock = false, contactsUpdated = false,
  }) {
    const response = await projectService.updateProject({
      uuid, data, forceLock, contactsUpdated,
    });
    commit('getProject', { data: response.data, uuid });
  },
  async listFloorplans({ commit }, { uuid }) {
    const project = await projectService.getProject(uuid);
    const rooms = await roomService.listRooms(uuid);
    const roomFloorplans = rooms.data.edges
      .map((edge) => ({
        uuid: edge.node.uuid,
        name: edge.node.name,
        floorplans: edge.node.attachments.floorplans,
      }));
    commit('listFloorplans', { floorplans: project.data.attachments.floorplans, roomFloorplans });
  },
  async uploadFloorplans(ctx, { uuid, files }) {
    const results = [];
    for (let i = 0; i < files.length; i += 1) {
      const { file: fileData, name, room: roomUUID } = files[i];
      const file = new FormData();
      file.append('floorplan', fileData);
      file.append('name', name);
      results.push(projectService.uploadFloorplan({ uuid, roomUUID, file }));
    }
    await Promise.all(results);
  },
  async updateFloorplan(ctx, { uuid, attachmentUUID, data }) {
    await projectService.updateFloorplan(uuid, attachmentUUID, data);
  },
  async deleteFloorplan(ctx, { uuid, roomUUID, attachmentUUID }) {
    if (roomUUID) {
      await projectService.deleteRoomFloorplan(uuid, roomUUID, attachmentUUID);
    } else {
      await projectService.deleteProjectFloorplan(uuid, attachmentUUID);
    }
  },
  async deleteProject(ctx, uuid) {
    await projectService.deleteProject(uuid);
  },
  async lockProject({ commit, dispatch }, { uuid }) {
    abort();
    const { data } = await projectService.lockProject({ uuid, lockTTLInMinutes })
      .catch((error) => {
        dispatch('app/setError', error.response.data, { root: true });
        throw error;
      });
    commit('changeLockState', { uuid, locked: true, token: data.accessToken });
    dispatch('setTimersForLock', uuid);
  },
  async renewProjectLock({ commit, dispatch, state }, uuid) {
    if (state.projectLocks[uuid]?.inProgress) {
      return;
    }
    commit('renewProjectLockInProgress', { uuid, inProgress: true });
    await projectService
      .renewProjectLock({ uuid, lockTTLInMinutes })
      .then(({ data }) => {
        commit('changeLockState', { uuid, locked: true, token: data.accessToken });
        dispatch('setTimersForLock', uuid);
      }).catch((err) => {
        if (!err.resourceLocked) {
          commit('changeLockState', { uuid, locked: false });
          clearTimeout(timersForLockRenewals[uuid]);
          delete timersForLockRenewals[uuid];
        }
      }).finally(() => {
        commit('renewProjectLockInProgress', { uuid, inProgress: false });
      });
  },
  async setTimersForLock({ dispatch }, uuid) {
    clearTimeout(timersForLockRenewals[uuid]);
    delete timersForLockRenewals[uuid];
    timersForLockRenewals[uuid] = setTimeout(() => {
      dispatch('renewProjectLock', uuid);
    }, Math.max((lockTTLInMinutes - 1) * 60000, 30000));
  },
  async unlockProject({ commit, dispatch, getters }, uuid) {
    abort();
    if (!getters.isUnlockableProject(uuid)) {
      return;
    }
    let failToUnlock = false;
    await projectService.unlockProject(uuid).catch((error) => {
      if (!error.response.data || error.response.data.errorCode !== 'project-is-not-locked') {
        dispatch('app/setError', { errorMessage: 'unlock-failed' }, { root: true });
        failToUnlock = true;
      }
    });
    if (failToUnlock) {
      return;
    }
    commit('changeLockState', { uuid, locked: false });
    clearTimeout(timersForLockRenewals[uuid]);
    delete timersForLockRenewals[uuid];
  },
  async forceUnlockProject({ commit, dispatch }, uuid) {
    let failToUnlock = false;
    await projectService.forceUnlockProject(uuid).catch((error) => {
      if (!error.response.data || error.response.data.errorCode !== 'project-is-not-locked') {
        dispatch('app/setError', { errorMessage: 'unlock-failed' }, { root: true });
        failToUnlock = true;
      }
    });
    if (failToUnlock) {
      return;
    }
    commit('changeLockState', { uuid, locked: false });
    clearTimeout(timersForLockRenewals[uuid]);
    delete timersForLockRenewals[uuid];
  },
  async updateWipConfig(ctx, { uuid, revision }) {
    await projectService.updateWipConfig({ uuid, revision });
  },
  async enterEditMode(ctx, { uuid }) {
    await projectService.enterEditMode({ uuid });
  },
  async cancelEditMode(ctx, { uuid, subjectConfigRevision }) {
    await projectService.cancelEditMode({ uuid, subjectConfigRevision });
  },
  async getConfigDiff(ctx, { uuid }) {
    const { data } = await projectService.getConfigDiff({ uuid });
    ctx.commit('getConfigDiff', { data, uuid });
  },
  async commitEditMode(ctx, { uuid, subjectConfigRevision }) {
    await projectService.commitEditMode({ uuid, subjectConfigRevision });
  },
  async downloadDocument(ctx, { uuid, type }) {
    const { data } = await projectService.downloadDocument({ uuid, type });
    return data;
  },
  async getSiteWorx(ctx, { uuid }) {
    const { data } = await projectService.getSiteWorx({ uuid });
    ctx.commit('getSiteWorx', { data, uuid });
  },
  async editSiteWorx(ctx, { uuid, enabled, organization }) {
    const { data } = await projectService.editSiteWorx({ uuid, enabled, organization });
    return data;
  },
  checkboxErrorFiltersUpdate({ commit }, filters) {
    commit('checkboxErrorFiltersUpdate', filters);
  },
  async getTimeZone(ctx, { latitude, longitude }) {
    const { data } = await projectService.getTimeZone({ latitude, longitude });
    return data.timezones[0];
  },
  async setRemoteControlLeaveRoute({ commit }, value) {
    commit('setRemoteControlLeaveRoute', value);
  },
  ensureMinimumVersion({ state, dispatch }, { uuid, version }) {
    const currentVersion = state.projectsById[uuid]?.gatewayVersion;
    if (!currentVersion) {
      return true;
    }
    // returns true if currentVersion is greater or equal to version
    const supported = compareVersionStrings(currentVersion, version);

    if (!supported) {
      dispatch('app/setError', {
        errorMessage: 'project-version-not-supported',
        errorData: { currentVersion, version },
      }, { root: true });
    }

    return supported;
  },
};

const mutations = {
  updateProjectFilters(state, filter) {
    state.projectFilters = filter;
  },
  updateProjectView(state, view) {
    state.projectView = view;
  },
  listProjects(state, projects) {
    // remove lock tokens if projects are not locked
    const lockedUUIDs = projects
      .filter((p) => p.node.locked)
      .map((p) => p.node.uuid);
    const projectLock = {};
    const entries = Object.entries(state.projectLocks);
    for (let i = 0; i < entries.length; i += 1) {
      const [uuid, lock] = entries[i];
      if (lockedUUIDs.includes(uuid)) {
        projectLock[uuid] = lock;
      } else {
        clearTimeout(timersForLockRenewals[uuid]);
        delete timersForLockRenewals[uuid];
      }
    }
    state.projectLocks = projectLock;
    state.projects = projects;
    state.projectsById = projects
      .reduce((acc, item) => ({ ...acc, [item.node.uuid]: item.node }), {});
  },
  getProject(state, payload) {
    state.projectsById = { ...state.projectsById, [payload.uuid]: payload.data };
    state.projects = state.projects.map((p) => {
      if (p.node.uuid === payload.uuid) {
        p.node = payload.data;
      }
      return p;
    });
  },
  renewProjectLockInProgress(state, { uuid, inProgress }) {
    if (state.projectLocks[uuid]) {
      state.projectLocks[uuid].inProgress = inProgress;
    }
  },
  changeLockState(state, { uuid, locked, token }) {
    if (locked && state.projectLocks[uuid] && state.projectLocks[uuid].token) {
      state.projectLocks[uuid] = { token };
      return;
    }
    const project = { ...state.projectsById[uuid], locked };
    state.projectsById = { ...state.projectsById, [uuid]: project };
    state.projects = state.projects.map((p) => {
      if (p.node.uuid === uuid) {
        p.node = project;
      }
      return p;
    });
    if (locked) {
      state.projectLocks[uuid] = { token };
    } else if (state.projectLocks[uuid]) {
      const projectLocks = { ...state.projectLocks };
      delete projectLocks[uuid];
      state.projectLocks = projectLocks;
    }
  },
  listFloorplans(state, payload) {
    state.floorplans = [...payload.floorplans];
    state.roomFloorplans = [...payload.roomFloorplans];
  },
  getConfigDiff(state, payload) {
    state.configDiffById = { ...state.configDiffById, [payload.uuid]: payload.data };
  },
  getSiteWorx(state, payload) {
    state.siteWorxByProjectId = { ...state.siteWorxByProjectId, [payload.uuid]: payload.data };
  },
  checkboxErrorFiltersUpdate: (state, filters) => {
    state.checkboxErrorFilters = filters;
  },
  setRemoteControlLeaveRoute: (state, value) => {
    state.remoteControlLeaveRoute = value;
  },
};

const getters = {
  getProjectById: (state) => (uuid) => state.projectsById[uuid] || {},
  getHealthEntries: (state) => (uuid) => {
    const project = state.projectsById[uuid] || {};
    const entityHealthEntries = project.healthEntries || [];
    const byEntityId = {};
    const allErrors = entityHealthEntries.reduce((acc, { healthEntries = [], entity = {} }) => {
      const entityErrors = healthEntries.map((entityError) => ({ ...entityError, uuid: entityError.uuid, entity }));
      if (entity !== null) {
        byEntityId[entity.uuid] = entityErrors;
      }
      return [...acc, ...entityErrors];
    }, []);

    const byCategory = { /* rule: [], light: [], ... */ };
    const byRoom = { /* roomId1: [], roomId2: [], ... */ };
    const byRoomAndType = { /* roomId1: { devices: [], rules: [] }, roomId2: { ... }, ... */ };

    const typeMap = {
      light: 'devices',
      sensor: 'devices',
      switch: 'devices',
      rule: 'rules',
      scene: 'scenes',
      group: 'groups',
    };

    allErrors.forEach((error) => {
      if (error && error.objType) {
        byCategory[error.objType] = byCategory[error.objType] || [];
        byCategory[error.objType] = [...byCategory[error.objType], error];
      }

      if (error && error.roomUuid) {
        byRoom[error.roomUuid] = byRoom[error.roomUuid] || [];
        byRoom[error.roomUuid] = [...byRoom[error.roomUuid], error];

        const type = typeMap[error.objType];

        if (type) {
          byRoomAndType[error.roomUuid] = byRoomAndType[error.roomUuid] || {};
          byRoomAndType[error.roomUuid][type] = byRoomAndType[error.roomUuid][type] || [];
          byRoomAndType[error.roomUuid][type] = [...byRoomAndType[error.roomUuid][type], error];
        }
      }
    });

    return {
      byId: byEntityId, byCategory, byRoom, allErrors, byRoomAndType,
    };
  },
  isReadOnly: (state) => (uuid) => {
    const project = state.projectsById[uuid];
    if (!project) {
      return false;
    }
    const lock = state.projectLocks[uuid] || {};
    if (project.hasWorkInProgressChanges || lock.token) {
      return false;
    }
    return !project.configEditAllowed || !lock.token;
  },
  projectView: (state) => state.projectView,
  hasToken: (state) => (uuid) => {
    const lock = state.projectLocks[uuid] || {};
    return !!lock.token;
  },
  isUploadedAndConnected: (state) => (uuid) => {
    const project = state.projectsById[uuid];
    return project && project.status === 'UPLOADED_TO_GATEWAY' && project.gatewayConnectedToCloud;
  },
  isConfigEditAllowed: (state) => (uuid) => state.projectsById[uuid] && state.projectsById[uuid].configEditAllowed,
  projectFilters: (state) => state.projectFilters || {},
  filteredProjectList: (state) => {
    let projects = [...state.projects];
    const projectFilters = state.projectFilters || {};
    projects = projects.filter((projectItem) => {
      const project = projectItem.node;
      return project.name.toLowerCase().includes((projectFilters.searchText || '').toLowerCase())
          || project.address.formatted.toLowerCase().includes((projectFilters.searchText || '').toLowerCase());
    });

    projects.sort((A, B) => {
      const a = A.node;
      const b = B.node;
      const aCountry = a.address && a.address.country ? a.address.country : '';
      const bCountry = b.address && b.address.country ? b.address.country : '';
      const aCity = a.address && a.address.city ? a.address.city : '';
      const bCity = b.address && b.address.city ? b.address.city : '';
      const aStreet = a.address && a.address.street ? a.address.street : '';
      const bStreet = b.address && b.address.street ? b.address.street : '';

      switch (projectFilters.sort) {
        case 'created-asc':
          return new Date(b.createdAt) - new Date(a.createdAt);
        case 'created-desc':
          return new Date(a.createdAt) - new Date(b.createdAt);
        case 'name-asc':
          return a.name.localeCompare(b.name);
        case 'name-desc':
          return -a.name.localeCompare(b.name);
        case 'location-asc':
          return aCountry.localeCompare(bCountry)
              || aCity.localeCompare(bCity)
              || aStreet.localeCompare(bStreet);
        case 'location-desc':
          return -aCountry.localeCompare(bCountry)
              || -aCity.localeCompare(bCity)
              || -aStreet.localeCompare(bStreet);
        case 'online':
          return a.gatewayConnectedToCloud ? -1 : 1;
        case 'offline':
          return a.gatewayConnectedToCloud ? 1 : -1;
        default:
          return new Date(a.createdAt) - new Date(b.createdAt);
      }
    });
    return projects;
  },
  maintainedProjects: (state) => [...state.projects]
    .filter((project) => project.node.maintenanceContract),
  projectsCount: (state) => state.projects.length,
  getWorkInProgressData: (state) => (uuid) => {
    const project = state.projectsById[uuid] || {};
    const lock = state.projectLocks[uuid] || {};
    const lockedByOthers = project.locked && !lock.token;
    return {
      remoteEditAllowed: uuid && !project.configEditAllowed && project.gatewayConnectedToCloud,
      hasWorkInProgressChanges: project.hasWorkInProgressChanges,
      workInProgressRevision: project.workInProgressRevision,
      lockedByOthers,
    };
  },
  getConfigDiffById: (state) => (uuid) => ((state.configDiffById || {})[uuid]) || {},
  isUnlockableProject: (state) => (uuid) => {
    const project = state.projectsById[uuid] || {};
    const projectLock = state.projectLocks[uuid];

    return project.locked && (projectLock && projectLock.token);
  },
  getPermissionsByProject: (state) => (uuid) => {
    const project = state.projectsById[uuid] || {};
    const meta = project.meta || {};
    return meta.permissions || [];
  },
  getSiteWorxByProject: (state) => (uuid) => ((state.siteWorxByProjectId || {})[uuid]) || {},
  getRemoteControlLeaveRoute: (state) => state.remoteControlLeaveRoute,
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  mutations,
};

import productService from '../services/products';

const initialState = {
  products: [],
  productsByCategory: {},
  productsById: {},
};

const actions = {
  async listProducts({ commit }, {
    projectUUID, scopes, category, locale,
  } = {}) {
    const { data } = await productService.listProducts({
      projectUUID, scopes, category, locale,
    });
    commit('listProducts', data);
  },
  async getProduct({ commit }, productUUID) {
    const { data } = await productService.getProduct(productUUID);
    commit('getProduct', data);
  },
};

const mutations = {
  listProducts(state, data) {
    const deviceCategories = ['lights', 'switches', 'sensors'];
    const emptyState = { lights: [], switches: [], sensors: [] };
    state.products = { ...emptyState, ...data };
    state.productsById = deviceCategories.map((cat) => {
      const devicesByCat = data[cat] || [];
      state.productsByCategory = { ...state.productsByCategory, [cat]: devicesByCat };
      return devicesByCat
        .reduce((acc, item) => ({ ...acc, [item.uuid]: item }), {});
    }).reduce((acc, item) => ({ ...acc, ...item }), {});
  },
  getProduct(state, data) {
    state.productsById = { ...state.productsById, [data.uuid]: data };
  },
};

const getters = {
  getProductById: (state) => (productId) => state.productsById[productId] || { },
  // eslint-disable-next-line max-len
  getProductsByCategory: (state, actGetters, rootState, rootGetters) => (category, projectUuid = null) => (state.productsByCategory[category] || [])
    .filter((product) => {
      if (projectUuid) {
        // eslint-disable-next-line max-len
        return product.countries.some((country) => country.countryId === rootGetters['projects/getProjectById'](projectUuid).countryId);
      }
      return product.countries.some((country) => country.countryId === rootGetters['user/getProfileData'].countryId);
    }),
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  mutations,
};

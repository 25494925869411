//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapGetters } from 'vuex';
import RoomDetailsUI from '@/components/ui/roomUI/RoomDetailsUI.vue';
import SaveRoomTemplateModal from './SaveRoomTemplateModal.vue';

export default {
  name: 'RoomsDetails',
  components: {
    SaveRoomTemplateModal,
    RoomDetailsUI,
  },
  data() {
    return {
      projectId: this.$route.params.projectId,
      roomId: this.$route.params.roomId,
      categories: ['switches', 'sensors', 'lights'],

      roomLoaded: false,
      devicesLoaded: false,
      deviceCounts: {},
      groups: [],
      name: '',
      description: '',
      originalData: {},
      errors: [],
    };
  },
  async created() {
    await this.getRoomDetails();
    await this.onGetDevices();
  },
  computed: {
    ...mapGetters('devices', ['getDevicesByRoom']),
    ...mapGetters('rooms', ['getRoomById']),
    ...mapGetters('scenes', ['getScenesByRoom']),
    ...mapGetters('rules', ['getRulesByRoom']),
    ...mapGetters('projects', ['getProjectById', 'isReadOnly']),
    readOnly() {
      return this.isReadOnly(this.projectId);
    },
    project() {
      return this.getProjectById(this.projectId);
    },
    room() {
      return this.getRoomById(this.roomId);
    },
    devices() {
      return this.getDevicesByRoom(this.projectId, this.roomId);
    },
    sections() {
      return {
        groups: this.room.groups,
        scenes: this.getScenesByRoom(this.projectId, this.roomId),
        rules: this.getRulesByRoom(this.projectId, this.roomId),
      };
    },
    breadcrumbs() {
      return {
        show: true,
        showSeparator: true,
        parents: [{
          name: this.project.name,
          link: { name: this.$route.meta.parent },
        }],
        current: this.room.name,
      };
    },
  },
  methods: {
    ...mapActions('devices', ['listDevices']),
    ...mapActions('rooms', ['getRoom', 'updateRoom', 'deleteRoom']),
    setRoomData() {
      this.name = this.room.name;
      this.description = this.room.description;
      this.groups = this.room.groups;
      this.originalData.name = this.room.name;
      this.originalData.description = this.room.description;
    },
    async getRoomDetails() {
      await this.getRoom({ uuid: this.projectId, roomId: this.roomId });
      this.setRoomData();
      this.roomLoaded = true;
    },
    async onGetDevices() {
      await this.listDevices({ uuid: this.projectId, roomId: this.roomId });
      Object.keys(this.devices).forEach((category) => {
        this.deviceCounts[category] = {};
        Object.values(this.devices[category]).forEach((device) => {
          this.deviceCounts[category][device.product.uuid] = this.deviceCounts[category][device.product.uuid]
              || { ...device, count: 0 };
          this.deviceCounts[category][device.product.uuid].count += 1;
        });
      });
      this.deviceCounts = { ...this.deviceCounts };
      this.devicesLoaded = true;
    },
    async onUpdateRoom(updatedField) {
      this.roomLoaded = false;
      this.name = updatedField.name || this.name;
      this.description = updatedField.description || this.description;
      const data = { name: this.name, description: this.description };
      await this.updateRoom({ uuid: this.projectId, roomId: this.roomId, data })
        .catch((error) => {
          this.errors = error.response.data.errorDetails;
          this.roomLoaded = true;
          throw error;
        });
      this.setRoomData();
      this.roomLoaded = true;
    },
    async onDeleteRoom() {
      await this.deleteRoom({ uuid: this.projectId, roomId: this.roomId });
      await this.$router.push({ name: 'project-rooms' });
    },
    checkErrors() {
      // reset data if errors persist and editing is cancelled
      if (!this.errors) {
        return;
      }
      this.errors.forEach((item) => {
        this[item.path] = this.originalData[item.path];
      });
      this.errors = [];
    },
  },
};

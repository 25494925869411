//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapGetters } from 'vuex';
import AdvancedTable from '@/components/ui/AdvancedTable.vue';
import DeleteCoworkerModal from './modals/DeleteCoworkerModal.vue';
import InviteCoworkerModal from './modals/InviteCoworkerModal.vue';
import InviteSentModal from './modals/InviteSentModal.vue';

export default {
  name: 'CoworkerList',
  components: {
    AdvancedTable,
    InviteCoworkerModal,
    DeleteCoworkerModal,
    InviteSentModal,
  },
  data() {
    return {
      loading: false,
      projectId: this.$route.params.projectId,
      selectedCoworker: {},
      headers: [{
        title: 'Name', key: 'name', type: 'text', sortable: true, searchable: true,
      }, {
        title: 'Email', key: 'email', type: 'text', sortable: true, searchable: true,
      }, {
        title: 'Role', key: 'role', type: 'text', sortable: true, searchable: true,
      }, {
        title: 'Invitation State', key: 'invitationState', type: 'text', sortable: true, searchable: false,
      }],
    };
  },
  async created() {
    this.loading = true;
    await this.listCoworkers({ uuid: this.projectId }).finally(() => {
      this.loading = false;
    });
  },
  computed: {
    ...mapGetters('coworkers', ['getCoworkersByProject']),
    canIInvite() {
      return this.$canI('inviteAdmin', 'coworkers')
          || this.$canI('inviteInstaller', 'coworkers')
          || this.$canI('invitePlanner', 'coworkers')
          || this.$canI('inviteReadonly', 'coworkers');
    },
    coworkers() {
      return this.getCoworkersByProject(this.projectId).map((coworker) => {
        let invitationState = 'Pending';
        if (coworker.invitationAccepted) {
          invitationState = 'Accepted';
        }
        if (coworker.invitationRejected) {
          invitationState = 'Rejected';
        }
        return {
          ...coworker,
          ...(coworker.user || {}),
          uuid: coworker.uuid,
          invitationState,
        };
      });
    },
    createIllustration() {
      return {
        // eslint-disable-next-line global-require
        icon: require('../../../assets/icons/no-coworkers.svg'),
        texts: [this.$t('coworkerNoUserMessage'), this.$t('coworkerStartInvitingCoworkers')],
        button: this.$t('coworkerInviteCoworker'),
      };
    },
  },
  methods: {
    ...mapActions('coworkers', ['listCoworkers', 'deleteCoworker']),
    onOpenItem(coworker) {
      this.$router.push({
        name: 'coworker-detail',
        params: { projectId: this.projectId, coworkerId: coworker.uuid },
      });
    },
    onCreateItem() {
      this.$bvModal.show('invite-coworker-modal');
    },
    openDeleteModal(coworker) {
      this.selectedCoworker = coworker;
      this.$bvModal.show('delete-coworker-modal');
    },
    async onDelete() {
      await this.deleteCoworker({ uuid: this.projectId, coworkerId: this.selectedCoworker.uuid });
      await this.listCoworkers({ uuid: this.projectId });
      this.$bvModal.hide('delete-coworker-modal');
    },
  },
};

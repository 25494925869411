// TODO: use server API
const listDeviceErrors = async () => ({
  data: {
    gateway: [],
    devices: {},
  },
});

export default {
  listDeviceErrors,
};

/* global axios */

const listGroups = async ({ uuid, roomId }) => axios({
  method: 'GET',
  url: `/projects/${uuid}/rooms/${roomId}/groups`,
});

const createGroup = async ({ uuid, roomId, data }) => axios({
  method: 'POST',
  url: `/projects/${uuid}/rooms/${roomId}/groups`,
  lock: { uuid },
  data,
  logEvent: () => ({ eventName: 'create_group' }),
});

const updateGroup = async ({
  uuid, roomId, groupId, data,
}) => axios({
  method: 'PUT',
  url: `/projects/${uuid}/rooms/${roomId}/groups/${groupId}`,
  lock: { uuid },
  data,
  logEvent: () => ({ eventName: 'edit_group' }),
});

const deleteGroup = async ({ uuid, roomId, groupId }) => axios({
  method: 'DELETE',
  url: `/projects/${uuid}/rooms/${roomId}/groups/${groupId}`,
  lock: { uuid },
  logEvent: () => ({ eventName: 'delete_group' }),
});

const addDeviceToGroup = async ({
  uuid, roomId, groupId, deviceId,
}) => axios({
  method: 'POST',
  url: `/projects/${uuid}/rooms/${roomId}/groups/${groupId}/devices`,
  lock: { uuid },
  data: { device: deviceId },
  logEvent: () => ({ eventName: 'add_devices_to_group' }),
});

const deleteDeviceFromGroup = async ({
  uuid, roomId, groupId, deviceId,
}) => axios({
  method: 'DELETE',
  url: `/projects/${uuid}/rooms/${roomId}/groups/${groupId}/devices/${deviceId}`,
  lock: { uuid },
  logEvent: () => ({ eventName: 'remove_devices_from_group' }),
});

export default {
  listGroups,
  createGroup,
  updateGroup,
  deleteGroup,
  addDeviceToGroup,
  deleteDeviceFromGroup,
};

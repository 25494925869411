//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapGetters, mapState } from 'vuex';
import { extend } from 'vee-validate';
import {
  confirmed, email, max, min, required,
} from 'vee-validate/dist/rules';
import PhoneNumber from 'awesome-phonenumber';
import ErrorMessage from '@/components/ErrorMessage.vue';
import NotificationToaster from '@/components/NotificationToaster.vue';

import bus from './bus';
import ConsentModals from './components/ui/consents/ConsentModals.vue';
import CheckboxSelect from './components/ui/CheckboxSelect.vue';

export default {
  components: {
    CheckboxSelect, ConsentModals, NotificationToaster, ErrorMessage,
  },
  data() {
    return {
      rerenderKey: 1,
      consents: [],
    };
  },
  async mounted() {
    this.getAppData().catch(console.error);

    // set locale to previously saved locale
    await this.changeLocale();

    // vee-validate extensions
    const phone = {
      validate(value) {
        const isValid = new Promise((resolve) => {
          const number = new PhoneNumber(value);
          resolve({ valid: number.isValid() });
        });
        const allowedChars = /^(\+?)([0-9]+)$/.test(value);
        if (!allowedChars) {
          return false;
        }
        return isValid;
      },
    };
    const password = {
      validate(value) {
        return /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/.test(value);
      },
    };
    const emailNoplus = {
      validate(value) {
        return /^(([^+<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3})|(([a-zA-Z\-\d]+\.)+[a-zA-Z]{2,}))$/.test(value);
      },
    };
    const decimal = {
      validate(value) {
        return /^[+-]?\d+(\.\d+)?$/.test(value);
      },
    };
    extend('required', { ...required, message: () => this.$t('errorMessageRequired') });
    extend('min', { ...min, message: (_, { value }) => this.$t('errorMessageMinLength', { value }) });
    extend('max', { ...max, message: (_, { value }) => this.$t('errorMessageMaxLength', { value }) });
    extend('email', { ...email, message: () => this.$t('errorMessageEmail') });
    extend('email_noplus', { ...emailNoplus, message: () => this.$t('errorMessageEmail') });
    extend('phone', { ...phone, message: () => this.$t('errorMessagePhone') });
    extend('password', { ...password, message: () => this.$t('errorMessagePassword') });
    extend('confirmed', { ...confirmed, message: () => this.$t('errorMessageConfirmed') });
    extend('decimal', { ...decimal, message: () => this.$t('errorMessageDecimal') });

    bus.$on('forceRerender', this.forceRerender.bind(this));
    bus.$on('projectUpdated', this.onProjectUpdated.bind(this));
    window.addEventListener('beforeunload', this.beforeWindowUnload);

    this.$options.sockets.onopen = () => {
      if (!this.isAuthenticated) {
        return;
      }
      this.socketAuth();
    };
    this.$options.sockets.onmessage = (message) => {
      JSON.parse(message.data); // TODO
    };
    if (!this.isAuthenticated) {
      return;
    }
    await this.getProfile();
    await this.checkConsents();
    if (this.socket.socketConnected) {
      await this.socketAuth();
    }
    await this.listProjects();
    if (this.projectUUID) {
      await this.getProject(this.projectUUID);
    }
    const results = [];
    const entries = Object.entries(this.projectLocks);
    for (let i = 0; i < entries.length; i += 1) {
      const [uuid, lock] = entries[i];
      if (lock && lock.token) {
        results.push(this.renewProjectLock(uuid));
      }
    }
    await Promise.all(results);
  },
  watch: {
    async isAuthenticated(isAuthed) {
      if (!isAuthed) {
        return;
      }
      this.$nextTick(async () => {
        await this.getProfile();
        await this.checkConsents();
      });
      if (this.socket.socketConnected) {
        await this.socketAuth();
      }
      await this.listProjects();
    },
    async projectUUID(current, old) {
      if (current && current !== old) {
        await this.subscribeToProject(current);
        return;
      }

      if (old && current !== old) {
        await this.unsubscribeFromProject(old);
        await this.unlockProject(old);
        await this.listProjects();
      }
    },
    getWsUrl() {
      // reload page when changing websocket url, because it is used in main.js
      window.location.reload();
    },
  },
  async beforeDestroy() {
    await this.unsubscribeFromProject(this.projectUUID);
  },
  computed: {
    ...mapState(['socket']),
    ...mapState('projects', ['projectLocks']),
    ...mapGetters('auth', ['isAuthenticated']),
    ...mapGetters('projects', ['hasToken']),
    ...mapGetters('locale', ['getWsUrl']),
    ...mapGetters('user', ['getProfileData']),
    projectUUID() {
      return this.$route.params && this.$route.params.projectId;
    },
    layout() {
      let layout = { component: 'div', options: {} };
      if (this.$route.meta.layout) {
        if (this.$route.meta.layout.component && this.$route.meta.layout.options) {
          layout = this.$route.meta.layout;
        } else if (this.$route.meta.layout.component) {
          layout = { component: this.$route.meta.layout.component, options: {} };
        } else {
          layout = { component: this.$route.meta.layout, options: {} };
        }
      }
      return layout;
    },
  },
  methods: {
    ...mapActions('app', ['getAppData', 'projectUpdatedEvent']),
    ...mapActions(['socketAuth']),
    ...mapActions('projects', ['listProjects', 'renewProjectLock', 'unlockProject', 'getProject']),
    ...mapActions('locale', ['changeLocale']),
    ...mapActions(['subscribeToProject', 'unsubscribeFromProject']),
    ...mapActions('user', ['getProfile', 'updateUser']),
    async checkConsents() {
      if (this.$route.name === 'LegalPage') {
        return;
      }
      this.consents = this.getProfileData?.consents || [];
    },
    async consentOk(params) {
      await this.updateUser(params);
      this.$nextTick(async () => {
        await this.getProfile();
        await this.checkConsents();
      });
    },
    async beforeWindowUnload() {
      if (this.$route.params && this.$route.params.projectId) {
        // await this.unlockProject(this.$route.params.projectId);
      }
      if (this.projectUUID) {
        await this.unsubscribeFromProject(this.projectUUID);
      }
    },
    forceRerender() {
      this.rerenderKey += 1;
    },
    onProjectUpdated(message) {
      if (this.projectUUID && !this.hasToken(this.projectUUID)) {
        const code = message && message.event && message.event.code;
        if (code === 'unlocked' || code === 'locked') {
          this.getProject(this.projectUUID);
          return;
        }
        this.projectUpdatedEvent({ updated: true });
      }
    },
  },
};

/* global axios */
import config from '@/config';

const getAppData = async (data) => {
  let url = config.API_URL;

  [url] = url.match(/^(?:https?:\/\/)?(?:[^@\n]+@)?(?:www\.)?([^:/\n?]+)(:\d{2,5})?/img);
  return axios({
    baseURL: url,
    method: 'GET',
    data,
    skipGlobalErrorHandling: true,
  });
};

export default {
  getAppData,
};

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapGetters } from 'vuex';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { WizardBus } from '@/bus';
import WizardListUI from '@/components/ui/wizard/WizardListUI.vue';
import DeleteGroupModal from '@/views/projects/rooms/groups/modals/DeleteGroupModal.vue';
import CheckboxSelect from '@/components/ui/CheckboxSelect.vue';

export default {
  name: 'WizardRoom',
  components: {
    WizardListUI,
    DeleteGroupModal,
    CheckboxSelect,
    ValidationObserver,
    ValidationProvider,
  },
  data() {
    return {
      dataLoaded: false,
      selectedGroup: {},
      currentStep: 1,
      disableCreate: false,
      name: '',
      description: '',
      lightItems: [],
      sensorItems: [],
    };
  },
  async created() {
    WizardBus.$on('next', this.onNextStep);
    await this.onListGroups();
    await this.onGetProjectConfig();
    await this.onListDevices();
  },
  beforeDestroy() {
    WizardBus.$off('next', this.onNextStep);
  },
  computed: {
    ...mapGetters('groups', ['getGroupsByRoom']),
    ...mapGetters('devices', ['getDevicesByRoom']),
    ...mapGetters('configs', ['getProjectConfigById']),
    projectId() {
      return this.$route.params.projectId;
    },
    roomId() {
      return this.$route.params.roomId;
    },
    groups() {
      return this.getGroupsByRoom(this.projectId, this.roomId);
    },
    lights() {
      return this.getDevicesByRoom(this.projectId, this.roomId).lights;
    },
    sensors() {
      return this.getDevicesByRoom(this.projectId, this.roomId).sensors;
    },
    projectConfig() {
      return this.getProjectConfigById(this.projectId);
    },
    modalTitle() {
      if (this.currentStep === 2) {
        return `${this.currentStep}/3. ${this.$t('roomGroupsAddSensorsGroup')}`;
      }
      if (this.currentStep === 3) {
        return `${this.currentStep}/3. ${this.$t('roomGroupsAddLightsGroup')}`;
      }
      return `${this.currentStep}/3. ${this.$t('roomGroupsCreateGroup')}`;
    },
    modalButtonLabel() {
      return this.currentStep === 3 ? this.$t('wizardFinishGroup') : this.$t('wizardNext');
    },
  },
  methods: {
    ...mapActions('devices', ['listDevices']),
    ...mapActions('groups', ['listGroups', 'createGroup', 'deleteGroup']),
    ...mapActions('configs', ['getProjectConfig']),
    async onListGroups() {
      await this.listGroups({ uuid: this.projectId, roomId: this.roomId });
      this.dataLoaded = true;
      this.$nextTick(this.isSkippable);
    },
    async onListDevices() {
      await this.listDevices({ uuid: this.projectId, roomId: this.roomId });
    },
    async onGetProjectConfig() {
      await this.getProjectConfig({ uuid: this.projectId });
    },
    async onDeleteGroup() {
      await this.deleteGroup({ uuid: this.projectId, roomId: this.roomId, groupId: this.selectedGroup.uuid });
      await this.onListGroups();
      await this.onGetProjectConfig();
      this.$bvModal.hide('delete-group-modal');
    },
    async showGroupModal() {
      await this.onListDevices();
      await this.resetForm();
      this.$bvModal.show('room-group-modal');
    },
    showDeleteModal(group) {
      this.selectedGroup = group;
      this.$bvModal.show('delete-group-modal');
    },
    onNextStep() {
      this.$router.push({ name: this.$route.meta.nextRoute });
    },
    async modalClick() {
      if (this.currentStep === 1) {
        const valid = await this.$refs.observer.validate();
        if (!valid) {
          return;
        }
      }
      if (this.currentStep === 3) {
        await this.onCreate();
      } else {
        this.currentStep += 1;
      }
    },
    onCancel() {
      this.$bvModal.hide('room-group-modal');
    },
    async onCreate() {
      this.disableCreate = true;
      const { name, description } = this;
      const members = this.lightItems.filter((i) => i.checked).map((i) => i.value);
      const sensors = this.sensorItems.filter((i) => i.checked).map((i) => i.value);
      const data = {
        name, description, members, sensors,
      };
      try {
        await this.createGroup({ uuid: this.projectId, roomId: this.roomId, data });
        await this.onListGroups();
        await this.onGetProjectConfig();
        this.$bvModal.hide('room-group-modal');
      } finally {
        this.disableCreate = false;
      }
    },
    async resetForm() {
      this.currentStep = 1;
      this.name = '';
      this.description = '';
      this.lightItems = this.lights.map((light) => ({
        value: light.uuid,
        text: light.product.name,
        seq: light.sequenceNumber,
        checked: false,
        description: light.description,
      }));
      this.sensorItems = this.sensors.map((sensor) => {
        const room = (this.projectConfig?.rooms || []).find((r) => r.uuid === this.roomId);
        const groups = (this.projectConfig?.groups || []).filter((group) => room.groups.includes(group.uuid));
        const groupsWithSensor = groups.filter((group) => group.sensors && group.sensors.length);
        const disabled = groupsWithSensor.some((group) => (group.sensors || []).includes(sensor.uuid));
        return {
          value: sensor.uuid,
          text: sensor.product.name,
          checked: false,
          seq: sensor.sequenceNumber,
          description: sensor.description,
          disabled,
        };
      });
    },
    isSkippable() {
      this.$parent.$parent.$data.skippable = !this.groups.length;
    },
  },
};

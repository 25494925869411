//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions } from 'vuex';
import { ValidationObserver } from 'vee-validate';
import InputField from '@/components/ui/InputField.vue';
import RoomDetailsUI from '@/components/ui/roomUI/RoomDetailsUI.vue';

export default {
  components: {
    RoomDetailsUI,
    InputField,
    ValidationObserver,
  },
  props: ['room', 'deviceCounts', 'sections'],
  data() {
    return {
      projectId: this.$route.params.projectId,
      roomId: this.$route.params.roomId,

      disableCreate: false,
      name: '',
      description: '',
      userScope: false,
      scope: 'project',
    };
  },
  methods: {
    ...mapActions('templates', ['createTemplateByRoom']),
    async onCreate() {
      const valid = await this.$refs.observer.validate();
      if (!valid) {
        return;
      }
      this.disableCreate = true;
      const {
        name, description, scope, projectId: uuid, roomId,
      } = this;
      const data = {
        name, description, scope, uuid, roomId,
      };
      if (this.userScope) {
        data.scope = 'user';
      }
      await this.createTemplateByRoom(data);

      this.$bvModal.hide('save-room-as-template-modal');
      this.resetForm();
    },
    onCancel() {
      this.$bvModal.hide('save-room-as-template-modal');
      this.resetForm();
    },
    resetForm() {
      this.disableCreate = false;
      this.name = '';
      this.description = '';
      this.userScope = false;
      this.scope = 'project';
    },
  },
};

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import AdvancedTable from '@/components/ui/AdvancedTable.vue';
import SectionTitle from '@/components/ui/SectionTitle.vue';
import RoomRulesLimitation from '@/components/ui/roomUI/modals/RoomRulesLimitation.vue';

export default {
  name: 'RoomRulesUI',
  components: { RoomRulesLimitation, AdvancedTable, SectionTitle },
  props: ['rules', 'loading', 'route', 'readOnly', 'roomSensors'],
  data() {
    return {
      projectId: this.$route.params.projectId,
      roomId: this.$route.params.roomId,
      headers: [{
        title: 'Name', key: 'name', type: 'text', sortable: true, searchable: true,
      }],
    };
  },
  computed: {
    createIllustration() {
      if (!this.rules.length && this.roomSensors && !this.roomSensors.length) {
        return {
          // eslint-disable-next-line global-require
          icon: require('../../../assets/icons/sensors.svg'),
          texts: [this.$t('roomRulesNoSensors')],
          button: this.$t('roomDevicesAddSensors'),
        };
      }
      return {
        // eslint-disable-next-line global-require
        icon: require('../../../assets/icons/no_sensor.svg'),
        texts: this.readOnly ? [this.$t('roomRulesNoSensorConfig')] : [this.$t('roomRulesNoSensorConfig'), this.$t('generalCreateMessage')],
        button: this.$t('roomRulesCreateRule'),
      };
    },
    cDays() {
      return this.$t('generalWeekdays').split(',');
    },
  },
  methods: {
    onDelete(rule) {
      this.$emit('delete', rule);
    },
    onOpenItem(rule) {
      this.$router.push(`${this.route}/${rule.uuid}`);
    },
    onCreateItem() {
      if (!this.rules.length && this.roomSensors && !this.roomSensors.length) {
        this.$router.push({ name: 'room-add-device', params: { projectId: this.projectId, roomId: this.roomId, deviceCategory: 'sensors' } });
        return;
      }
      if (this.rules.length > 0) {
        this.$bvModal.show('room-rules-limitation');
        return;
      }
      this.$bvModal.show('add-rule-modal');
    },
    decToBin(dec) {
      // eslint-disable-next-line no-bitwise
      return (dec >>> 0).toString(2).padStart(7, '0');
    },
    repeatDays(rule) {
      const bin = this.decToBin(rule.repeat).split('').reverse();
      return this.cDays
        .reduce((acc, item, i) => (bin[(i + 1) % 7] === '1' ? [...acc, item] : acc), [])
        .join().trim();
    },
    formatWeek(repeatDays) {
      const weekFormatsShort = [this.$t('generalWeekdaysShort'), this.$t('generalWeekendShort'), this.$t('generalAllWeekShort')];
      const weekFormats = [this.$t('generalWeekWeekdays'), this.$t('generalWeekWeekend'), this.$t('generalWeekAllWeek')];
      const index = weekFormatsShort.indexOf(repeatDays);
      return index !== -1 ? weekFormats[index] : repeatDays;
    },
  },
};

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// import { mapActions, mapGetters } from 'vuex';
import { mapActions, mapGetters } from 'vuex';
import ListItem from '@/components/ui/ListItem.vue';

export default {
  name: 'Support',
  data() {
    return {
    };
  },
  components: {
    ListItem,
  },
  methods: {
    ...mapActions('projects', ['getProject']),
  },
  async created() {
    if (this.projectId.length) {
      await this.getProject(this.projectId);
    }
  },
  computed: {
    ...mapGetters('projects', ['getProjectById']),
    ...mapGetters('locale', ['getCountry']),
    projectId() {
      return this.$route.params.projectId || '';
    },
    project() {
      return this.projectId.length ? this.getProjectById(this.projectId) : {};
    },
    country() {
      return this.getCountry;
    },
    hasSupportEmail() {
      return this.country?.supportEmail && this.country.supportEmail.length > 0;
    },
    hasSupportPhone() {
      return this.country?.supportPhone && this.country.supportPhone.length > 0;
    },
    email() {
      return this.hasSupportEmail ? this.country.supportEmail : '–';
    },
    phone() {
      return this.hasSupportPhone ? this.country.supportPhone : '–';
    },
  },
};

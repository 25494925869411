//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import FixedFooter from '@/components/ui/FixedFooter.vue';
import { mapActions } from 'vuex';
import IconLink from '../IconLink.vue';
import CustomSlider from '../CustomSlider.vue';
import CustomSection from '../CustomSection.vue';
import AutoOffSlider from '../AutoOffSlider.vue';
import DLHSlider from '../DLHSlider.vue';

export default {
  name: 'RoomGroupsRulesDefaultDetailUI',
  components: {
    FixedFooter,
    IconLink,
    CustomSlider,
    CustomSection,
    AutoOffSlider,
    DLHSlider,
  },
  props: ['loading', 'readOnly', 'groupSensors', 'mode', 'hideEdit', 'projectConfig',
    'defaultRule', 'saveDefaultSettings', 'wizard', 'group', 'hclLightWarning'],
  data() {
    return {
      projectId: this.$route.params.projectId,
      roomId: this.$route.params.roomId,
      groupId: this.$route.params.groupId,
      internalMode: 'manual',
      editMode: false,
      disableSave: false,
      rerender: 1,
      data: {},
      modes: [
        { value: 'manual', text: this.$t('roomRulesDefaultModeManual') },
        { value: 'semi-automatic', text: this.$t('roomRulesDefaultModeSemi') },
        { value: 'automatic', text: this.$t('roomRulesDefaultModeAutomatic') },
      ],
      levelRange: {
        from: 1,
        to: 100,
        unit: '%',
      },
    };
  },
  created() {
    this.internalMode = this.mode;
    this.resetForm();
  },
  watch: {
    internalMode(newValue) {
      this.$emit('modeChange', newValue);
    },
  },
  computed: {
    isManual() {
      return this.internalMode === 'manual';
    },
    isSemi() {
      return this.internalMode === 'semi-automatic';
    },
    isAutomatic() {
      return this.internalMode === 'automatic';
    },
    sensorError() {
      return !this.groupSensors?.length && (this.isSemi || this.isAutomatic);
    },
    disabledSetBehavior() {
      return this.internalMode === this.mode;
    },
    configParamError() {
      return this.getConfigParamError('latitude')
          || this.getConfigParamError('longitude')
          || this.getConfigParamError('timeZone');
    },
    footerButtons() {
      return {
        main: {
          title: this.$t('roomRulesGroupDefaultSet'),
          click: () => this.onSetDefaultMode(),
          class: this.disabledSetBehavior ? 'disabled' : '',
        },
        tertiary: {
          title: this.$t('roomRulesGroupMembers'),
          to: { name: 'group-detail', params: { projectId: this.projectId, roomId: this.roomId, groupId: this.groupId } },
        },
      };
    },
    rule() {
      return this.defaultRule || {
        actions: { target: { type: 'room' }, value: {} },
      };
    },
    hclSelected() {
      return this.data.hcl.selected;
    },
  },
  methods: {
    ...mapActions('projects', ['ensureMinimumVersion']),
    onDLHOrHCLEnable(name) {
      const isDLH = name === 'dlh';
      const { projectId } = this.$route.params;

      this.$nextTick(async () => {
        if (isDLH && this.isSemi) {
          // LSC-6560 - semiAutoDlh: 2.3.1
          const supported = await this.ensureMinimumVersion({ uuid: projectId, version: '2.3.1' });
          if (!supported) {
            this.data.dlh.selected = false;
          }
        } else if (this.data.dlh.selected && this.data.hcl.selected) {
          // LSC-6560 - dlhAndHcl: 2.3.1
          const supported = await this.ensureMinimumVersion({ uuid: projectId, version: '2.3.1' });
          if (!supported) {
            this.data[name].selected = false;
          }
        }
      });
    },
    getConfigParamError(property) {
      if (!this.projectConfig?.parameters) {
        return false;
      }
      return !this.projectConfig.parameters[property]
          || (this.projectConfig.parameters[property] && this.projectConfig.parameters[property].length === 0);
    },
    switchEditMode(mode = false) {
      this.editMode = mode;
    },
    onCancel() {
      this.resetForm();
      this.switchEditMode();
    },
    async onSave() {
      this.disableSave = true;
      if (!this.editMode) {
        this.resetForm();
      }
      const {
        autoOn, autoOff, dlh, hcl,
      } = this.data;
      const actions = {
        value: {
          'auto-on': this.isAutomatic,
          // eslint-disable-next-line no-nested-ternary
          level: this.isAutomatic ? (autoOn.dimSelected ? (autoOn.value || 100) : 100) : undefined,
          'auto-off': !this.isManual,
          delay: autoOff.selected && !this.isManual ? autoOff.offMin * 60 : undefined,
          'delay-dim': autoOff.selected && autoOff.dimSelected && !this.isManual ? autoOff.downMin * 60 : undefined,
          'level-dim': autoOff.selected && autoOff.dimSelected && !this.isManual ? autoOff.value : undefined,
          'dlh-enabled': dlh.selected && (this.isAutomatic || this.isSemi),
          'hcl-enabled': hcl.selected,
          'hcl-max-dim': hcl.selected ? hcl.dimMax : undefined,
        },
      };
      const data = {
        actions,
        dlh: dlh.selected ? this.getDLHData() : undefined,
        defaultBehaviour: this.internalMode,
      };
      await this.saveDefaultSettings(data);
      this.disableSave = false;
      this.switchEditMode();
    },
    async onSetDefaultMode() {
      if (this.disabledSetBehavior) {
        return;
      }
      await this.onSave();
      if (this.wizard) {
        return;
      }
      await this.$router.push({ name: 'room-rules' });
    },
    onChange(item, data) {
      if (item === 'autoOn' && this.data.autoOff.value > data.value) {
        this.data = { ...this.data, autoOff: { ...this.data.autoOff, value: data.value } };
      }
      if (item === 'autoOff' && data.value >= this.data.autoOn.value) {
        this.data.autoOn.value = data.value;
      }
      this.data[item] = { ...this.data[item], ...data };
      this.data = { ...this.data };
    },
    onDLHChange(i, dlh) {
      this.data.dlh.values[i] = { ...this.data.dlh.values[i], ...dlh };
      this.onChange('dlh', this.data.dlh);
    },
    onHCLChange(hcl) {
      this.data.hcl.dimMax = hcl.value;
    },
    getDLHData() {
      let dlhData;
      if (this.data.dlh.values.length) {
        const { values } = this.data.dlh;
        dlhData = {
          groups: values.map((item) => ({
            uuid: item.uuid,
            'regulation-value': item.value,
            'geometry-factor': item.geometry,
          })),
        };
      }
      return dlhData;
    },
    getDLHValues() {
      if (!this.projectConfig) {
        return [];
      }
      const room = (this.projectConfig?.rooms || []).find((r) => r.uuid === this.roomId);
      if (!room || !this.groupSensors?.length) {
        return [];
      }
      const groups = (this.projectConfig?.groups || []).filter((group) => room.groups.includes(group.uuid));
      const groupsWithSensor = groups.filter((group) => group.sensors && group.sensors.length && group.uuid === this.groupId);

      return groupsWithSensor.map((group) => {
        const dlh = (group.dlh && group.dlh[0]) || {};

        return {
          type: 'group',
          uuid: group.uuid,
          label: group.name,
          value: dlh['regulation-value'] || 0,
          geometry: dlh['geometry-factor'] || 3,
        };
      });
    },
    resetForm() {
      const {
        actions,
      } = this.rule;
      const dlh = {
        selected: actions.value['dlh-enabled'],
        values: this.getDLHValues(),
      };
      const autoOn = {
        selected: this.isAutomatic,
        value: actions.value.level || 100,
        dimSelected: !!actions.value.level || true,
      };
      const autoOff = {
        selected: !this.isManual,
        dimSelected: !!actions.value['level-dim'],
        value: actions.value['level-dim'] || 30,
        downMin: (actions.value['delay-dim'] || 300) / 60,
        offMin: (actions.value.delay || 600) / 60,
      };
      const hcl = {
        selected: actions.value['hcl-enabled'],
        dimMax: actions.value['hcl-max-dim'] || 100,
      };
      this.data = {
        autoOn, autoOff, dlh, hcl,
      };
      this.rerender += 1;
    },
  },
};

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapGetters } from 'vuex';
import { WizardBus } from '@/bus';
import WizardDeleteDeviceModal from '@/components/ui/wizard/WizardDeleteDeviceModal.vue';
import WizardListUI from '@/components/ui/wizard/WizardListUI.vue';
import WizardDeviceModal from '@/components/ui/wizard/WizardDeviceModal.vue';

export default {
  name: 'WizardRoom',
  components: {
    WizardDeviceModal,
    WizardListUI,
    WizardDeleteDeviceModal,
  },
  data() {
    return {
      dataLoaded: false,
      selectedDevice: {},
    };
  },
  async created() {
    await this.onCreated();
    WizardBus.$on('next', this.onNextStep);
  },
  beforeDestroy() {
    WizardBus.$off('next', this.onNextStep);
  },
  computed: {
    ...mapGetters('devices', ['getDevicesByRoom']),
    ...mapGetters('rooms', ['getRoomById']),
    projectId() {
      return this.$route.params.projectId;
    },
    roomId() {
      return this.$route.params.roomId;
    },
    room() {
      return this.getRoomById(this.roomId);
    },
    devices() {
      return this.getDevicesByRoom(this.projectId, this.roomId);
    },
  },
  methods: {
    ...mapActions('devices', ['listDevices', 'bulkDeleteDevices']),
    async onCreated() {
      await this.onGetDevices(true);
    },
    async onGetDevices(needLoader = false) {
      this.dataLoaded = !needLoader;
      await this.listDevices({ uuid: this.projectId, roomId: this.roomId });
      this.dataLoaded = true;
      this.$nextTick(this.isSkippable);
    },
    showSelectModal() {
      this.$bvModal.show('room-device-modal');
    },
    showDeleteModal(device) {
      this.selectedDevice = device;
      this.$bvModal.show('delete-device-modal');
    },
    onNextStep() {
      this.$router.push({ name: this.$route.meta.nextRoute });
    },
    async onDeleteDevice() {
      this.dataLoaded = false;
      await this.bulkDeleteDevices({ uuid: this.projectId, roomId: this.roomId, deviceUUIDs: this.selectedDevice.uuids }).catch(() => {
        this.dataLoaded = true;
        this.selectedDevice = {};
      });
      await this.onGetDevices(true);
      this.$bvModal.hide('delete-device-modal');
      this.dataLoaded = true;
      this.selectedDevice = {};
    },
    isSkippable() {
      this.$parent.$parent.$data.skippable = !this.devices.lights.length;
    },
  },
};

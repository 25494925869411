/* eslint-disable */
import {
  required,
  confirmed,
  length,
  email,
  min_value,
  max_value,
  integer,
  numeric,
} from 'vee-validate/dist/rules';
import { extend } from 'vee-validate';

extend('required', {
  ...required,
  message: 'This field is required',
});

extend('email', {
  ...email,
  message: 'This field must be a valid email',
});

extend('numeric', {
  ...numeric,
  message: 'This field must contain only numbers',
});

extend('confirmed', {
  ...confirmed,
  message: 'This field confirmation does not match',
});

extend('length', {
  ...length,
  message: 'This field must have 2 options',
});

extend('min_value', {
  ...min_value,
  message: (_, { min }) => `The minimum amount is ${min}`,
});

extend('max_value', {
  ...max_value,
  message: (_, { max }) => `The maximum amount is ${max}`,
});

extend('integer', {
  ...integer,
  message: 'Only integer values allowed',
});

const initialState = {
  projectState: {
    name: '',
    description: '',
    address: {
      country: '',
      state: '',
      county: '',
      city: '',
      zipCode: '',
      line1: '',
      line2: '',
    },
    contact: {
      name: '',
      email: '',
      phone: '',
      description: '',
      primary: true,
      onsite: false,
    },
    files: [],
  },
};

const actions = {
  /**
   * @param state { name, description, address, contact, files, ... }
   */
  updateProjectState({ commit }, state) {
    commit('updateProjectState', state);
  },
  resetState({ commit }) {
    commit('resetState');
  },
};

const mutations = {
  updateProjectState(state, payload) {
    state.projectState = { ...state.projectState, ...payload };
  },
  resetState(state) {
    state.projectState = { ...initialState.projectState };
  },
};

const getters = {
  isContactEmpty(state) {
    return !state.projectState.contact.name.length
      && !state.projectState.contact.email.length
      && !state.projectState.contact.phone.length
      && !state.projectState.contact.description.length;
  },
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  mutations,
};

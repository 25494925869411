/* global axios */

const register = async (data) => axios({
  method: 'POST',
  url: '/users',
  data,
  logEvent: () => ({ eventName: 'register' }),
});

const confirmRegistration = async ({ email, token }) => axios({
  method: 'PATCH',
  url: `users/${email}/confirm`,
  data: { token },
  logEvent: () => ({ eventName: 'register_email_confirmed' }),
});

const login = async ({ email, password }) => axios({
  method: 'POST',
  url: '/auth/login',
  data: { email, password },
  logEvent: () => ({ eventName: 'sign_in' }),
  skipGlobalErrorHandling: true,
});

/*
  Always waits the pending renewToken request
 */
let renewTokenPromise = null;
const renewToken = async () => {
  if (renewTokenPromise) {
    return renewTokenPromise;
  }
  renewTokenPromise = axios({
    method: 'POST',
    url: '/auth/renew-token',
    data: { },
    preventRefreshing: true,
    noAuth: true,
  }).finally(() => {
    renewTokenPromise = null;
  });
  return renewTokenPromise;
};

const forgotPassword = async (email) => axios({
  method: 'POST',
  url: `users/${email}/resetpassword`,
  data: {},
  logEvent: () => ({ eventName: 'forgot_password' }),
});

const resetPassword = async ({ email, token, password }) => axios({
  method: 'PATCH',
  url: `users/${email}/resetpassword`,
  data: { token, password },
  logEvent: () => ({ eventName: 'reset_password' }),
});

const logout = async ({ refreshToken }) => axios({
  method: 'POST',
  url: '/auth/logout',
  data: { refreshToken },
  isLogout: true,
  logEvent: () => ({ eventName: 'sign_out' }),
});

export default {
  register,
  confirmRegistration,
  login,
  renewToken,
  forgotPassword,
  resetPassword,
  logout,
};

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapGetters } from 'vuex';
import AdvancedTable from '@/components/ui/AdvancedTable.vue';

export default {
  name: 'ProjectRooms',
  components: {
    AdvancedTable,
  },
  data() {
    return {
      projectId: this.$route.params.projectId,
      loading: true,
      headers: [{
        title: this.$t('roomRoomName'), key: 'name', type: 'text', sortable: true, searchable: true,
      }, {
        title: this.$t('roomTemplateType'), key: 'templateName', type: 'text', sortable: true, searchable: true,
      }, {
        title: this.$t('roomStatus'), key: 'status', type: 'text', sortable: false, searchable: false,
      }, {}],
    };
  },
  async created() {
    await this.getProject(this.projectId);
    await this.listTemplates({ scopes: ['project', 'global', 'user'], projectUUID: this.projectId });
    await this.onGetRooms();
    await this.checkboxFiltersReset();
    this.loading = false;
  },
  computed: {
    ...mapGetters('rooms', ['getRoomsByProjectId']),
    ...mapGetters('projects', ['getProjectById', 'isReadOnly']),
    ...mapGetters('templates', ['getTemplateById']),
    ...mapGetters('projects', ['getHealthEntries']),
    entityHealth() {
      return this.getHealthEntries(this.projectId);
    },
    readOnly() {
      return this.isReadOnly(this.projectId);
    },
    project() {
      return this.getProjectById(this.projectId);
    },
    rooms() {
      return [...this.getRoomsByProjectId(this.projectId)]
        .map((room) => ({
          ...room,
          templateName: this.getTemplateName(room.template),
          hasIssue: !!((this.entityHealth.byRoom || {})[room.uuid] || []).length,
        }));
    },
  },
  methods: {
    ...mapActions('projects', ['getProject']),
    ...mapActions('rooms', ['listRooms']),
    ...mapActions('templates', ['listTemplates']),
    ...mapActions('devices', ['checkboxFiltersReset']),
    async onGetRooms() {
      await this.listRooms(this.projectId);
    },
    getTemplateName(roomTemplate) {
      if (!roomTemplate || !roomTemplate.uuid) {
        return '–';
      }
      return this.getTemplateById(roomTemplate.uuid).name || '-';
    },
    getDevicesCountByRoom(room) {
      const devices = [...(room.members || []), ...(room.switches || []), ...(room.sensors || [])];
      return {
        total: devices.length,
        scanned: devices.filter((d) => d.mac).length,
      };
    },
    onDelete(room) {
      this.$emit('delete', room);
    },
    onOpenItem(room) {
      this.$router.push(`/projects/${this.projectId}/rooms/${room.uuid}/devices`);
    },
    onCreateItem() {
      this.$router.push({ name: 'rooms-create', params: { projectId: this.projectId } });
    },
  },
};

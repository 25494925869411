//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  data() {
    return {
      acceptedConsents: [],
      userSettings: {},
    };
  },
  props: {
    consents: {
      type: Array,
      required: true,
    },
  },
  computed: {
    texts() {
      return {
        analytics: { title: this.$t('consentAnalyticsTitle'), text: this.$t('consentAnalyticsText') },
        privacy: { title: this.$t('consentPrivacyPolicyTitle'), text: this.$t('consentPrivacyPolicyText') },
        tos: { title: this.$t('consentTOSTitle'), text: this.$t('consentTOSText') },
      };
    },
    visible() {
      return !!this.activeConsent;
    },
    activeConsent() {
      // return the first consent that is not accepted
      const order = ['tos', 'privacy', 'analytics'];
      const filteredConsents = order.filter((consent) => this.consents.includes(consent));
      return filteredConsents.find((consent) => !this.acceptedConsents.includes(consent));
    },
  },
  methods: {
    consentOk(settings = {}) {
      this.userSettings = { ...this.userSettings, ...settings };
      this.acceptedConsents.push(this.activeConsent);
      this.$nextTick(() => {
        if (!this.activeConsent && this.acceptedConsents.length > 0) {
          this.$emit('update-user', {
            ...this.userSettings,
            consents: this.acceptedConsents,
          });
        }
      });
    },
  },
};

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapGetters } from 'vuex';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { WizardBus } from '@/bus';
import TemplateVariables from '@/components/ui/roomUI/TemplateVariables.vue';

export default {
  name: 'WizardRoom',
  components: {
    ValidationObserver,
    ValidationProvider,
    TemplateVariables,
  },
  data() {
    return {
      disableCreate: false,
      name: '',
      description: '',
      template: undefined,
      roomTemplate: null,
      templateFields: {},
      templateValues: {},
      templates: {},
      devices: {},
      selectedField: {},
      selectedDeviceName: '',
    };
  },
  async created() {
    WizardBus.$on('createRoom', this.onCreateRoomWizard);
    WizardBus.$on('createRoomManually', this.onCreateRoomManually);
    WizardBus.$on('showTemplateModal', this.onShowTemplateModal);
    await this.onGetRooms();
    await this.listTemplates();
  },
  beforeDestroy() {
    WizardBus.$on('createRoom', this.onCreateRoomWizard);
    WizardBus.$on('createRoomManually', this.onCreateRoomManually);
    WizardBus.$on('showTemplateModal', this.onShowTemplateModal);
  },
  computed: {
    ...mapGetters('templates', ['getTemplatesByScope', 'getTemplateById']),
    ...mapGetters('rooms', ['getRoomsByProjectId']),
    projectId() {
      return this.$route.params.projectId;
    },
    rooms() {
      return [...this.getRoomsByProjectId(this.projectId)].map((room) => ({ ...room }));
    },
    templateOptions() {
      const defaultOptions = [{ text: this.$t('roomNoTemplate'), value: null }];
      return this.getTemplatesByScope(['project', 'user', 'global']).reduce((acc, item) => [...acc, {
        text: (item.config.body && item.config.body.template && item.config.body.template.name) || item.name,
        value: item.uuid,
      }], defaultOptions);
    },
    selectedTemplate() {
      if (!this.roomTemplate) {
        return null;
      }
      return this.getTemplateById(this.roomTemplate);
    },
    disableTemplateUse() {
      return this.roomTemplate === null;
    },
  },
  methods: {
    ...mapActions('rooms', ['createRoom', 'listRooms']),
    ...mapActions('templates', ['listTemplates']),
    async onGetRooms() {
      await this.listRooms(this.projectId);
    },
    getRoomByName(name) {
      return this.rooms.filter((room) => room.name === name)[0];
    },
    onTemplateChange(template) {
      this.template = template;
    },
    async onCreateRoom(target) {
      const valid = await this.$refs.observer.validate();
      if (!valid) {
        return;
      }
      this.disableCreate = true;

      const {
        name, description, template, roomTemplate,
      } = this;
      const data = { name, description };
      if (roomTemplate) {
        data.template = template;
      }

      try {
        await this.createRoom({ uuid: this.projectId, data });
        await this.onGetRooms();
        const { uuid } = this.getRoomByName(name);
        if (target === 'wizard') {
          await this.$router.push({ name: 'wizard-room-switches', params: { projectId: this.projectId, roomId: uuid } });
        } else if (target === 'overview') {
          await this.$router.push({ name: 'wizard-room-overview', params: { projectId: this.projectId, roomId: uuid } });
        } else {
          await this.$router.push({ name: 'room-details', params: { projectId: this.projectId, roomId: uuid } });
        }
      } catch (e) {
        console.error(e);
        this.disableCreate = false;
      }
    },
    async onCreateRoomWizard() {
      await this.onCreateRoom('wizard');
    },
    async onCreateRoomManually() {
      await this.onCreateRoom('');
    },
    async onCreateRoomTemplate() {
      await this.onCreateRoom('overview');
    },
    async onShowTemplateModal() {
      const valid = await this.$refs.observer.validate();
      if (!valid) {
        return;
      }
      await this.$bvModal.show('room-template-modal');
    },
    onCancel() {
      this.template = undefined;
      this.roomTemplate = null;
      this.$bvModal.hide('room-template-modal');
    },
  },
};

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: ['label', 'description', 'selectable', 'value', 'disabled', 'defaultSelected', 'range', 'checkboxDisabled', 'sequenceNumber', 'cctSlider', 'customPadding', 'maxWidth', 'inputClass'],
  data() {
    return {
      num: this.value,
      selected: !this.selectable || this.defaultSelected,
      internalValue: this.value,
    };
  },
  computed: {
    rangeUnit() {
      return {
        from: this.cctSlider ? 2700 : 0,
        to: this.cctSlider ? 6500 : 100,
        unit: this.cctSlider ? 'K' : '%',
        ...this.range,
      };
    },
    rangeStyle() {
      return `--value: ${this.value}; --min: ${this.rangeUnit.from || 0}; --max: ${this.rangeUnit.to || 100};`;
    },
    sliderClass() {
      if (this.cctSlider) {
        return this.rangeUnit.to > 5000 ? 'slider-cct' : 'slider-cct-limited';
      }
      return 'slider-progress';
    },
  },
  methods: {
    onChange(selected, v) {
      if (v !== undefined) {
        this.num = v;
        this.internalValue = v;
      }
      const val = parseInt(this.num, 10);
      // eslint-disable-next-line no-nested-ternary
      const value = Math.min(this.rangeUnit.to, Math.max(this.rangeUnit.from, val));
      const data = { selected, value };
      this.$emit('input', data);
    },
    onInputChange() {
      this.internalValue = this.internalValue.replace(/[^0-9]/, '');
      if (!this.internalValue.length) {
        return;
      }
      this.internalValue = parseInt(this.internalValue, 10);
    },
    onInputBlur() {
      if (this.internalValue < this.rangeUnit.from) {
        this.internalValue = this.rangeUnit.from;
      }
      if (this.internalValue > this.rangeUnit.to) {
        this.internalValue = this.rangeUnit.to;
      }
      this.onChange(true, this.internalValue);
    },
  },
};

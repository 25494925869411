//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import deviceHelpers from '../../../../mixins/deviceHelpers';

import InputField from '../../InputField.vue';
import TitleWithDeviceActions from './TitleWithDeviceActions.vue';
import RoomDeviceGroupsUI from '../RoomDeviceGroupsUI.vue';
import FixedFooter from '../../FixedFooter.vue';
import MaintenanceErrorDetails from '../../MaintenanceErrorDetails.vue';

export default {
  name: 'DeviceDetails',
  props: ['device', 'groups', 'editMode', 'readOnly', 'useFooterEdit', 'hideOptions', 'loading', 'healthIssues'],
  mixins: [deviceHelpers],
  components: {
    MaintenanceErrorDetails,
    FixedFooter,
    RoomDeviceGroupsUI,
    TitleWithDeviceActions,
    InputField,
  },
  data() {
    return {
      updateData: {},
    };
  },
  computed: {
    footerButtons() {
      return {
        main: {
          title: this.$t('generalSave'),
          click: () => {
            this.$emit('onAction', 'SAVE_EDIT', this.updateData);
          },
          disabled: this.loading,
          loading: this.loading,
        },
        secondary: {
          title: this.$t('generalCancel'),
          click: () => {
            this.updateData = {};
            this.$emit('onAction', 'CANCEL_EDIT');
          },
          disabled: this.loading,
        },
      };
    },
  },
  methods: {
    onAction(action) {
      this.$emit('onAction', action);
    },
    onDescriptionChange(description) {
      this.updateData.description = description;
    },
    onGroupUpdate(groups) {
      this.updateData.groups = groups;
    },
  },
};

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapGetters } from 'vuex';
import RoomScheduleDetailsUI from '@/components/ui/roomUI/RoomScheduleDetailsUI.vue';

export default {
  components: {
    RoomScheduleDetailsUI,
  },
  props: [],
  data() {
    return {
      projectId: this.$route.params.projectId,
      disableCreate: false,
    };
  },
  async created() {
    await this.listRooms(this.projectId);
  },
  computed: {
    ...mapGetters('rooms', ['getRoomsByProjectId']),
    schedule() {
      return {
        name: '',
        time: '08:00',
        repeat: 0,
        actions: [],
      };
    },
    rooms() {
      return this.getRoomsByProjectId(this.projectId);
    },
    groups() {
      return this.rooms.reduce((acc, item) => ({ ...acc, [item.uuid]: item.groups }), {});
    },
    lights() {
      return this.rooms.reduce((acc, item) => ({ ...acc, [item.uuid]: item.members }), {});
    },
    options() {
      return [
        { value: null, text: '' },
        { value: 'room', text: this.$t('generalRooms') },
        { value: 'group', text: this.$t('generalGroups') },
        { value: 'light', text: this.$t('generalLights') },
        { value: 'gateway', text: this.$t('generalProject') },
      ];
    },
  },
  methods: {
    ...mapActions('rooms', ['listRooms']),
    ...mapActions('schedules', ['createSchedule', 'listSchedules']),
    async onCreate(data) {
      await this.createSchedule({ uuid: this.projectId, roomId: this.roomId, data });
      await this.listSchedules({ uuid: this.projectId, roomId: this.roomId });
      this.$bvModal.hide('add-schedule-modal');
    },
    async onCreateClicked() {
      this.disableCreate = true;
      try {
        await this.$refs.form.onSave.bind(this.$refs.form)();
      } finally {
        this.disableCreate = false;
      }
    },
    onCancel() {
      this.$bvModal.hide('add-schedule-modal');
    },
  },
};

//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapState } from 'vuex';

export default {
  name: 'ErrorMessage',
  computed: {
    ...mapState('app', ['error']),
  },
  watch: {
    error(error) {
      if (error.hasError && this.$route.name === 'login') {
        this.clearError();
      }
    },
  },
  methods: {
    ...mapActions('app', ['clearError']),
    goToFirmwareUpdate() {
      this.clearError();
      this.$router.push({ name: 'project-firmware-update-details', params: { projectId: this.$route.params.projectId } });
    },
  },
};

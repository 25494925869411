//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import { getCountryCode } from '@/utils/helpers';
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'LocaleCountrySelect',
  components: {
  },
  props: ['loading', 'white', 'isAuthenticated'],
  data() {
    return {
      locale: 'en',
      countryCode: '',
    };
  },
  created() {
    this.$nextTick(() => {
      this.init();
    });
  },
  computed: {
    ...mapGetters('locale', ['getLocale', 'getCountryCode', 'hasCountryCode', 'getLocales', 'getCountryList', 'getCountry', 'getRegion']),
    locales() {
      return this.getLocales;
    },
    countries() {
      const selectedCountry = this.getCountryList.find((country) => country.code === this.selectedCountryCode);
      if (selectedCountry) {
        return this.getCountryList.filter((c) => c.businessRegionName === selectedCountry.businessRegionName);
      }
      return this.getCountryList;
    },
    selectedLocale() {
      return this.getLocale;
    },
    selectedCountryCode() {
      return this.getCountryCode;
    },
    selectedRegion() {
      return this.getRegion;
    },
    isCountrySelected() {
      return this.hasCountryCode;
    },
    isLocaleByCountryAvailable() {
      return this.locales.some((locale) => locale.code === this.countryCode);
    },
    defaultLocaleByCountry() {
      return this.countries.find((country) => country.code === this.countryCode)?.defaultLanguage || 'en';
    },
  },
  methods: {
    ...mapActions('locale', ['changeLocale', 'changeCountry']),
    init() {
      this.locale = this.selectedLocale;
      if (this.isCountrySelected) {
        this.countryCode = this.selectedCountryCode;
      } else {
        this.countryCode = getCountryCode();
        this.setLocaleByCountry();
      }
    },
    async onChangeLocale(locale) {
      await this.changeLocale(locale);
    },
    async onChangeCountry(countryCode) {
      if (this.isAuthenticated && this.checkRegion(countryCode)) {
        this.$emit('otherCountrySelected', countryCode);
        this.countryCode = this.selectedCountryCode;
        return;
      }
      await this.changeCountry(countryCode);
    },
    async setCountry(countryCode) {
      if (this.isCountrySelected) {
        await this.onChangeCountry(countryCode);
      }
    },
    async setLocaleByCountry() {
      if (!this.isCountrySelected) {
        this.locale = this.isLocaleByCountryAvailable ? this.countryCode : this.defaultLocaleByCountry;
        await this.onChangeLocale(this.locale);
      }
    },
    checkRegion(countryCode) {
      const { region } = this.countries.find((country) => country.code === countryCode);
      return this.selectedRegion !== region;
    },
  },
};

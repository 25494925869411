/* global axios */

import axios from 'axios';

const listProducts = async ({
  projectUUID, scopes, category, locale,
}) => axios({
  method: 'GET',
  url: '/products',
  params: {
    category,
    project: projectUUID,
    locale: locale || 'en',
    scopes: scopes || ['global', 'user'],
  },
});

const getProduct = async (productUUID) => axios({
  method: 'GET',
  url: `/products/${productUUID}`,
});

export default {
  listProducts,
  getProduct,
};

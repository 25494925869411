//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import deviceHelpers from '../../../../mixins/deviceHelpers';

export default {
  name: 'EditSwitchLayoutUI',
  props: ['device', 'typeAndLayout'],
  components: { },
  mixins: [deviceHelpers],
  data() {
    return {
      selectedButtonType: null,
      isExpert: false,

      selectedLayout: null,
    };
  },
  mounted() {
    if (this.typeAndLayout?.layout) {
      this.selectedLayout = this.typeAndLayout.layout;
    }
    if (this.typeAndLayout?.buttonType) {
      this.selectedButtonType = this.typeAndLayout.buttonType;
      this.isExpert = this.selectedButtonType === this.BUTTON_TYPES.EXPERT;
    }
  },
  methods: {
    onChange() {
      this.$emit('change', {
        buttonType: this.selectedButtonType,
        layout: this.selectedLayout,
      });
    },
    onExpertChange() {
      if (this.isExpert) {
        this.selectedButtonType = this.BUTTON_TYPES.EXPERT;
      } else {
        this.selectedButtonType = this.BUTTON_TYPES.PUSH_BUTTON;
      }
      this.onChange();
    },
    onTypeChange() {
      this.isExpert = this.selectedButtonType === this.BUTTON_TYPES.EXPERT;
      this.onChange();
    },
    onLayoutSelect(layout) {
      this.selectedLayout = layout;
      this.onChange();
    },
  },
};

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapGetters } from 'vuex';
import SearchField from '@/components/ui/SearchField.vue';
import NumberField from '@/components/ui/NumberField.vue';

export default {
  name: 'WizardDeviceModal',
  props: ['title', 'text', 'category'],
  components: {
    SearchField,
    NumberField,
  },
  data() {
    return {
      searchText: '',
      deviceCollection: {},
      deviceCount: 0,
      progress: 0,
    };
  },
  async created() {
    await this.onGetProducts();
  },
  computed: {
    ...mapGetters('products', ['getProductsByCategory']),
    projectId() {
      return this.$route.params.projectId;
    },
    roomId() {
      return this.$route.params.roomId;
    },
    products() {
      return this.getProductsByCategory(this.category);
    },
    mappedProducts() {
      return this.products
        .map((product) => product).filter((product) => (product.name || '').toLowerCase().includes(this.searchText.toLowerCase()))
        .sort((a, b) => a.name.localeCompare(b.name));
    },
    isProductSelected() {
      return !Object.keys(this.deviceCollection).length || !Object.values(this.deviceCollection).some((value) => value !== 0);
    },
  },
  methods: {
    ...mapActions('products', ['listProducts']),
    ...mapActions('rooms', ['addProductsToRoom']),
    async onGetProducts() {
      await this.listProducts({ category: this.category });
    },
    async addProducts() {
      const {
        projectId, roomId,
      } = this;

      const products = Object.entries(this.deviceCollection)
        .filter(([, count]) => count)
        .map(([product, count]) => ({ product, count, description: '-' }));
      this.deviceCount = products.reduce((acc, prod) => acc + prod.count, 0);

      this.progress = 0;
      this.$bvModal.show('add-device-progress-modal');
      for (let i = 0; i < products.length; i += 1) {
        // eslint-disable-next-line no-await-in-loop
        await this.addProductsToRoom({ uuid: projectId, roomId, products: [products[i]] });
        this.progress = Math.floor((i / products.length) * 100);
      }

      this.$bvModal.hide('add-device-progress-modal');
      this.$bvModal.hide('room-device-modal');
      this.$emit('success');
      this.reset();
    },
    onFilterChange(e) {
      this.searchText = e.searchText;
    },
    onCancel() {
      this.$bvModal.hide('room-device-modal');
      this.reset();
    },
    reset() {
      this.searchText = '';
      this.deviceCollection = {};
      this.deviceCount = 0;
      this.progress = 0;
    },
  },
};

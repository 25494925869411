//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


export default {
  name: 'ProjectProductsFirmware',
  props: ['products', 'summary'],
  data() {
    return {
      expanded: {
        gateways: true,
        lights: true,
        sensors: true,
        switches: true,
      },
    };
  },
  computed: {

  },
  methods: {
    onProductClick(product) {
      if (this.summary) {
        return;
      }
      this.$emit('productClick', product);
    },
    onToggleClick(category) {
      if (this.summary) {
        return;
      }
      this.expanded[category] = !this.expanded[category];
    },
  },
};

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import NavBarMain from '@/components/layouts/navbars/NavBarMain.vue';
import NavBarLocal from '@/components/layouts/navbars/NavBarLocal.vue';

/*
Valid layout options:
  - breadcrumbs (array)
  - browserBack (bool)
  - showProjectFilter (bool)
 */

export default {
  name: 'MainLayout',
  props: ['options'],
  components: {
    NavBarMain,
    NavBarLocal,
  },
};

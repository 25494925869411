//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import CustomTable from '@/components/ui/CustomTable.vue';

export default {
  name: 'RoomGroupRulesDefaultUI',
  components: { CustomTable },
  props: ['rules', 'loading', 'route', 'readOnly', 'groupSensors', 'mode', 'healthIssues', 'groups'],
  data() {
    return {
      projectId: this.$route.params.projectId,
      roomId: this.$route.params.roomId,
    };
  },
  methods: {
    onOpenItem(group) {
      this.$router.push({
        name: 'group-rule-default-detail',
        params: { projectId: this.projectId, roomId: this.roomId, groupId: group.uuid },
      });
    },
    modeLocale(mode) {
      if (mode === 'manual') {
        return this.$t('roomRulesDefaultModeManual');
      }
      if (mode === 'semi-automatic') {
        return this.$t('roomRulesDefaultModeSemi');
      }
      if (mode === 'automatic') {
        return this.$t('roomRulesDefaultModeAutomatic');
      }
      return '';
    },
  },
};

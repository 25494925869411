//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapGetters } from 'vuex';
import { ValidationObserver } from 'vee-validate';
import InputField from '@/components/ui/InputField.vue';
import CustomSlider from '@/components/ui/CustomSlider.vue';

export default {
  components: {
    InputField,
    ValidationObserver,
    CustomSlider,
  },
  props: [],
  data() {
    return {
      projectId: this.$route.params.projectId,
      roomId: this.$route.params.roomId,

      disableCreate: false,
      name: '',
      type: 'group',
      lightItems: [],
      groupItems: [],
      wholeRoom: {},
      defaultLevel: 0,
      defaultInitialLevel: 100,
      defaultCct: 4000,
      levelRange: {
        from: 0,
        to: 100,
        unit: '%',
      },
    };
  },
  async created() {
    await this.listDevices({ uuid: this.projectId, roomId: this.roomId });
    await this.listGroups({ uuid: this.projectId, roomId: this.roomId });
    this.resetForm();
  },
  computed: {
    ...mapGetters('devices', ['getDevicesByRoom']),
    ...mapGetters('groups', ['getGroupsByRoom']),
    ...mapGetters('rooms', ['getRoomById']),
    options() {
      return [
        { value: 'group', text: this.$t('roomScenesCreateSceneBasedOnGroups') },
        { value: 'light', text: this.$t('roomScenesCreateSceneBasedOnLights') },
      ];
    },
    roomSelectDisabled() {
      return this.groupItems.some((i) => i.selected);
    },
    groupSliders() {
      const selectedGroups = this.groupItems
        .reduce((acc, item) => [...acc, ...(item.selected ? item.group.members : [])], []);
      return this.groupItems.map((item) => {
        const selectDisabled = !item.selected && (selectedGroups.some((r) => item.group.members.includes(r)) || this.wholeRoom.selected);
        return { ...item, selectDisabled };
      });
    },
    lights() {
      return this.getDevicesByRoom(this.projectId, this.roomId).lights;
    },
    groups() {
      return this.getGroupsByRoom(this.projectId, this.roomId);
    },
    room() {
      return this.getRoomById(this.roomId);
    },
    values() {
      let values = [];
      if (this.type === 'light') {
        values = this.lightItems
          .map((i) => {
            const on = !!i.data.value.level && !!i.data.value.cct;
            i.data.value.on = on;
            i.data.value.level = on ? i.data.value.level : undefined;
            i.data.value.cct = on ? i.data.value.cct : undefined;
            return i.data;
          });
      }
      if (this.type === 'group') {
        values = this.groupItems
          .filter((g) => g.selected)
          .map((i) => {
            const on = !!i.data.value.level && !!i.data.value.cct;
            i.data.value.on = on;
            i.data.value.level = on ? i.data.value.level : undefined;
            i.data.value.cct = on ? i.data.value.cct : undefined;
            return i.data;
          });
        const { wholeRoom } = this;
        const on = !!wholeRoom.data.value.level && !!wholeRoom.data.value.cct;
        wholeRoom.data.value.on = on;
        wholeRoom.data.value.level = on ? wholeRoom.data.value.level : undefined;
        wholeRoom.data.value.cct = on ? wholeRoom.data.value.cct : undefined;
        if (wholeRoom.selected) {
          values.push(wholeRoom.data);
        }
      }
      return values;
    },
    disableCreateButton() {
      return this.disableCreate || (!this.wholeRoom.selected && !this.groupItems.some((g) => g.selected) && this.type === 'group');
    },
  },
  methods: {
    ...mapActions('devices', ['listDevices']),
    ...mapActions('groups', ['listGroups']),
    ...mapActions('scenes', ['createScene', 'listScenes']),
    async onCreate() {
      const valid = await this.$refs.observer.validate();
      if (!valid) {
        return;
      }
      if (!this.wholeRoom.selected && !this.groupItems.some((g) => g.selected) && this.type === 'group') {
        return;
      }
      this.disableCreate = true;
      const { name, description } = this;
      const data = { name, description, values: this.values };
      try {
        await this.createScene({ uuid: this.projectId, roomId: this.roomId, data });
        await this.listScenes({ uuid: this.projectId, roomId: this.roomId });
        this.$bvModal.hide('add-scene-modal');
        this.resetForm();
      } finally {
        this.disableCreate = false;
      }
    },
    onCancel() {
      this.$bvModal.hide('add-scene-modal');
      this.resetForm();
    },
    onChange(item, data, type) {
      if (type === 'selected') {
        item.selected = data;
        item.data.value.on = !!data;
        item.data.value.level = item.data.value.on ? this.defaultInitialLevel : undefined;
        item.data.value.cct = item.data.value.on ? this.defaultCct : undefined;
        return;
      }
      item.selected = data.selected;
      item.data.value.on = !!data.value;
      if (type === 'level') {
        item.data.value.level = data.value;
        if (item.data.value.cct === undefined) {
          item.data.value.cct = this.defaultCct;
        }
      }
      if (type === 'cct') {
        item.data.value.cct = data.value;
        if (item.data.value.level === undefined) {
          item.data.value.level = this.defaultLevel;
        }
      }
    },
    cctRange(item) {
      return {
        from: this.getPropertyValue(item, 'cctMin') || 2700,
        to: this.getPropertyValue(item, 'cctMax') || 6500,
      };
    },
    resetForm() {
      this.disableCreate = false;
      this.name = '';
      this.lightItems = this.lights.map((light) => ({
        light,
        selected: false,
        data: {
          target: {
            type: 'light',
            uuid: light.uuid,
          },
          value: {
            on: true,
            level: this.defaultInitialLevel,
            cct: this.defaultCct,
          },
        },
      }));
      this.groupItems = this.groups.map((group) => ({
        group,
        selected: false,
        data: {
          target: {
            type: 'group',
            uuid: group.uuid,
          },
          value: {
            on: true,
            level: this.defaultInitialLevel,
            cct: this.defaultCct,
          },
        },
      }));
      this.wholeRoom = {
        room: this.room,
        selected: false,
        data: {
          target: {
            type: 'room',
            uuid: this.room.uuid,
          },
          value: {
            on: true,
            level: this.defaultInitialLevel,
            cct: this.defaultCct,
          },
        },
      };
    },
    getPropertyValue(item, propertyKey) {
      return item.product?.productProperties?.find((prop) => prop.key === propertyKey)?.value || null;
    },
  },
};

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from 'vuex';
import FixedFooter from '../../../components/ui/FixedFooter.vue';
import CustomModal from '../../../components/ui/CustomModal.vue';
import ProjectProductsFirmware from '../../../components/ui/firmwareUpdate/ProjectProductsFirmware.vue';
import FirmwareUpdateProgress from '../../../components/ui/firmwareUpdate/FirmwareUpdateProgress.vue';

export default {
  name: 'FirmwareUpdateDetails',
  data() {
    return {
      loading: false,
      projectId: this.$route.params.projectId,
      updateAllModal: false,
      updateStarted: false,

      categories: ['gateways', 'lights', 'sensors', 'switches'],
    };
  },
  components: {
    FirmwareUpdateProgress,
    ProjectProductsFirmware,
    FixedFooter,
    CustomModal,
  },
  async created() {
    await this.init();
    if (this.$route.query.updateAll === 'true' && !this.everythingUpToDate && !this.updateInProgress) {
      this.updateAllModal = true;
    }
  },
  computed: {
    ...mapGetters('devices', ['getProjectDevices', 'getProductUpdates']),
    ...mapGetters('projects', ['getProjectById', 'isReadOnly']),
    ...mapGetters(['getConfigUpdatesById', 'getAllOtaUpdateStatus']),
    project() {
      return this.getProjectById(this.projectId);
    },
    projectName() {
      return this.project.name;
    },
    readOnly() {
      return this.isReadOnly(this.projectId);
    },
    progress() {
      const configUpdateMessage = this.getConfigUpdatesById(this.projectId);
      return configUpdateMessage?.job ? configUpdateMessage.job.progress : 0;
    },
    footerButtons() {
      return {
        main: {
          title: this.$t('firmwareUpdateAll'),
          disabled: this.everythingUpToDate || this.updateInProgress || !this.$canI('otaDevice', 'firmwares') || !this.$canI('otaGateway', 'firmwares'),
          click: () => {
            this.updateAllModal = true;
          },
        },
      };
    },
    updateAllButtons() {
      return {
        main: {
          title: this.$t('firmwareUpdate'),
          disabled: this.everythingUpToDate || this.updateInProgress || this.loading,
          click: this.onUpdateAll,
        },
        secondary: {
          title: this.$t('generalCancel'),
          click: () => {
            this.updateAllModal = false;
          },
        },
      };
    },
    projectDevices() {
      return this.getProjectDevices(this.projectId);
    },
    otaUpdateStatus() {
      return this.getAllOtaUpdateStatus(this.projectId);
    },
    products() {
      return this.getProductUpdates(this.projectId);
    },
    productsToUpdate() {
      const prods = {};
      Object.entries(this.products)
        .filter(([, item]) => !item.allUpdated)
        .forEach(([category, item]) => {
          prods[category] = {
            ...item,
            products: item.products
              .filter((product) => product.updatedCount !== product.totalCount),
          };
        });
      return prods;
    },
    devicesToUpdate() {
      return this.projectDevices.filter((device) => device.hasNewFirmware);
    },
    everythingUpToDate() {
      return Object.values(this.products).every((item) => item.allUpdated);
    },
    updateInProgress() {
      return Object.values(this.products).some((item) => item.updating);
    },
  },
  methods: {
    ...mapActions('projects', ['updateProject']),
    ...mapActions('devices', ['listProjectDevices', 'updateDeviceFirmware']),
    async init() {
      this.loading = true;
      await this.listProjectDevices({ uuid: this.projectId }).finally(() => {
        this.loading = false;
      });
    },
    async onUpdateAll() {
      this.loading = true;
      const productUuids = [...new Set(this.devicesToUpdate.map((device) => device.productUuid))];
      const data = {
        updateItems: productUuids.map((productUuid) => ({ productUuid })),
      };
      await this.updateDeviceFirmware({ uuid: this.projectId, data, all: true })
        .then(() => {
          this.updateStarted = true;
        })
        .finally(async () => {
          this.loading = false;
          this.updateAllModal = false;
          await this.init();
        });
    },
    onProductClick(product) {
      this.$router.push({ name: 'project-product-firmware-update', params: { productId: product.productUuid } });
    },
  },
};

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapActions, mapGetters } from 'vuex';
import InlineTextEditor from '@/components/ui/InlineTextEditor.vue';
import AdvancedTable from '@/components/ui/AdvancedTable.vue';

export default {
  name: 'RoomDevicesUI',
  props: ['devices', 'rooms', 'groups', 'readOnly', 'loading', 'recommends', 'onDeleteDevice', 'onBulkDeleteDevices', 'onBulkMoveDevices', 'routeName', 'addDeviceRoute',
    'hideAddLight', 'hideAddSensor', 'hideAddSwitch', 'hideDeleteLight', 'hideDeleteSensor', 'hideDeleteSwitch', 'hideEditDescription', 'hasEntityHealthIssue', 'updatingDevice'],
  components: {
    AdvancedTable,
    'inline-text-editor': InlineTextEditor,
  },
  data() {
    return {
      categories: ['lights', 'switches', 'sensors'],
      categoryTranslate: {
        lights: 'generalLights',
        sensors: 'generalSensors',
        switches: 'generalSwitches',
      },
      addCategoryTranslate: {
        lights: 'roomDevicesAddLights',
        sensors: 'roomDevicesAddSensors',
        switches: 'roomDevicesAddSwitches',
      },
      selectedDevice: {},
      headers: [{
        title: this.$t('roomDevicesProduct'), key: 'productName', type: 'text', sortable: true, searchable: false,
      }, {
        title: this.$t('generalAdded'), key: 'sequenceNumber', type: 'number', sortable: true, searchable: false,
      }, {
        title: this.$t('generalName'), key: 'name', type: 'text', sortable: true, searchable: true,
      }, {
        title: this.$t('generalDescription'), key: 'description', type: 'text', sortable: true, searchable: true,
      }, {
        title: this.$t('productEan'), key: 'ean', type: 'text', sortable: false, searchable: true,
      }],
      editMode: '',
    };
  },
  created() {
    this.checkHealthIssue(this.hasEntityHealthIssue);
  },
  watch: {
    hasEntityHealthIssue(newValue) {
      this.checkHealthIssue(newValue);
    },
  },
  computed: {
    ...mapState('app', ['floorplanOpened']),
    ...mapState('devices', ['checkboxFilters', 'savedSortByCategory', 'productRowsByRoom']),
    ...mapGetters(['getSensorStatus', 'getLightStatus']),
    filteredDevices() {
      return Object.keys(this.devices).reduce((acc, category) => ({
        ...acc,
        [category]: this.devices[category]
          .filter((d) => !this.deviceFilters.healthIssues || d.hasIssue)
          .map((device) => ({
            ...device,
            name: ((device.product || {}).name) || device.product,
            productName: ((device.product || {}).name) || device.product,
            productUuid: device.product.uuid,
          })),
      }), {});
    },
    deviceFilters() {
      return this.checkboxFilters;
    },
    categorySavedSort() {
      return this.savedSortByCategory;
    },
    productRowsExpanded() {
      return this.productRowsByRoom;
    },
    noCategorySelected() {
      return !this.checkboxFilters.lights && !this.checkboxFilters.switches && !this.checkboxFilters.sensors;
    },
    showResetFilters() {
      return !this.checkboxFilters.lights || !this.checkboxFilters.switches || !this.checkboxFilters.sensors || this.checkboxFilters.healthIssues;
    },
    editModeEnabled() {
      return this.editMode.length > 0;
    },
    roomId() {
      return this.$route.params.roomId;
    },
  },
  methods: {
    ...mapActions('devices', ['checkboxFiltersUpdate', 'checkboxFiltersReset', 'savedSortByCategoryUpdate', 'productRowsByRoomUpdate']),
    sortByProduct(savedSort) {
      if (!savedSort || !savedSort.split) {
        return false;
      }
      return savedSort.split('##')[0] === 'productName';
    },
    async deleteDevice() {
      await this.onDeleteDevice(this.selectedDevice.uuid);
      this.$bvModal.hide('delete-device-modal');
    },
    async updateDevice({ deviceId, data }) {
      this.$emit('update', { deviceId, data });
    },
    async onDelete(device) {
      this.selectedDevice = device;
      this.$bvModal.show('delete-device-modal');
    },
    async onBulkDelete(devices) {
      await this.onBulkDeleteDevices(devices);
    },
    async onBulkMove(devices, room, group) {
      await this.onBulkMoveDevices(devices, room, group);
    },
    onProductRowToggle(roomId, productUuid) {
      let internalStates = this.productRowsExpanded;
      if (!internalStates[roomId]) {
        internalStates = { ...internalStates, [roomId]: [productUuid] };
        this.productRowsByRoomUpdate(internalStates);
        return;
      }
      if (internalStates[roomId].includes(productUuid)) {
        internalStates[roomId] = internalStates[roomId].filter((id) => id !== productUuid);
        this.productRowsByRoomUpdate(internalStates);
        return;
      }
      internalStates[roomId].push(productUuid);
      this.productRowsByRoomUpdate(internalStates);
    },
    disableDeviceDetailNavigation(category) {
      return this.$route.params.templateId && category !== 'switches';
    },
    async onOpenItem(device) {
      if (this.disableDeviceDetailNavigation(device.product.category)) {
        return;
      }
      await this.$router.push({
        name: this.routeName || 'device-detail',
        params: { deviceId: device.uuid },
      });
    },
    onCreateItem(category) {
      this.$router.push(`${this.addDeviceRoute}/${category}`);
    },
    hideDeviceAdd(category) {
      const hideCategories = {
        lights: this.hideAddLight,
        sensors: this.hideAddSensor,
        switches: this.hideAddSwitch,
      };
      return hideCategories[category];
    },
    hideDeviceDelete(category) {
      const hideCategories = {
        lights: this.hideDeleteLight,
        sensors: this.hideDeleteSensor,
        switches: this.hideDeleteSwitch,
      };
      return hideCategories[category];
    },
    onCheckboxFilterChange(reset = false) {
      if (reset) {
        this.checkboxFiltersReset();
        return;
      }
      this.checkboxFiltersUpdate(this.deviceFilters);
    },
    onEditModeChange(value) {
      this.editMode = value;
    },
    checkHealthIssue(value) {
      if (!value) {
        this.deviceFilters.healthIssues = false;
        this.onCheckboxFilterChange();
      }
    },
    onFilterChange(category, sort) {
      this.categorySavedSort[category] = sort;
      this.savedSortByCategoryUpdate(this.categorySavedSort);
    },
  },
};

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { ValidationObserver } from 'vee-validate';
import Spinner from '@/components/ui/Spinner.vue';
import InputField from '../InputField.vue';
import CheckboxSelect from '../CheckboxSelect.vue';
import IconLink from '../IconLink.vue';
import FixedFooter from '../FixedFooter.vue';
import SectionTitle from '../SectionTitle.vue';

export default {
  name: 'RoomGroupsUI',
  props: ['name', 'description', 'loaded', 'items', 'sensorItems', 'saveGroup', 'readOnly', 'hideEdit',
    'hideDelete', 'hideLightEdit', 'hideSensorEdit', 'defaultRule', 'mode', 'hclLightWarning'],
  components: {
    Spinner,
    SectionTitle,
    FixedFooter,
    ValidationObserver,
    InputField,
    CheckboxSelect,
    IconLink,
  },
  data() {
    return {
      projectId: this.$route.params.projectId,
      roomId: this.$route.params.roomId,
      groupId: this.$route.params.groupId,
      editMode: false,
      data: {},
      loading: false,
    };
  },
  created() {
    const {
      name, description, items, sensorItems,
    } = this;
    this.data = {
      name, description, items, sensorItems,
    };
  },
  watch: {
    name(val) {
      this.data.name = val;
    },
    description(val) {
      this.data.description = val;
    },
    items(val) {
      this.data.items = val;
    },
    sensorItems(val) {
      this.data.sensorItems = val;
    },
  },
  computed: {
    footerButtons() {
      if (this.editMode) {
        return {
          main: {
            title: this.$t('generalSave'),
            click: () => this.onSave(),
            loading: this.loading,
            disabled: this.loading,
          },
          secondary: {
            title: this.$t('generalCancel'),
            click: () => this.switchMode(false),
            disabled: this.loading,
          },
        };
      }
      return {
        main: {
          title: this.$t('roomRulesGoToGroupBehavior'),
          to: { name: 'group-rule-default-detail', params: { projectId: this.projectId, roomId: this.roomId, groupId: this.groupId } },
        },
      };
    },
    dlhOn() {
      return this.defaultRule && this.defaultRule.actions && this.defaultRule.actions.value && this.defaultRule.actions.value['dlh-enabled'];
    },
    hclOn() {
      return this.defaultRule && this.defaultRule.actions && this.defaultRule.actions.value && this.defaultRule.actions.value['hcl-enabled'];
    },
    showHclWarning() {
      return this.hclOn && this.hclLightWarning;
    },
  },
  methods: {
    switchMode(mode) {
      this.$emit('switchMode', mode);
      this.editMode = mode;
    },
    async onSave() {
      const valid = await this.$refs.observer.validate();
      if (!valid) {
        return;
      }
      this.loading = true;
      await this.saveGroup(this.data).finally(() => {
        this.loading = false;
      });
      this.switchMode(false);
    },
    onDelete() {
      this.$emit('delete');
    },
    modeLocale(mode) {
      if (mode === 'manual') {
        return this.$t('roomRulesDefaultModeManual');
      }
      if (mode === 'semi-automatic') {
        return this.$t('roomRulesDefaultModeSemi');
      }
      if (mode === 'automatic') {
        return this.$t('roomRulesDefaultModeAutomatic');
      }
      return '';
    },
  },
};

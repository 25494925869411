//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from 'vuex';
import RoomRuleDetailsUI from '@/components/ui/roomUI/RoomRuleDetailsUI.vue';

import DeleteRuleModal from './modals/DeleteRuleModal.vue';

export default {
  name: 'RoomRuleDetail',
  components: {
    RoomRuleDetailsUI,
    DeleteRuleModal,
  },
  data() {
    return {
      projectId: this.$route.params.projectId,
      roomId: this.$route.params.roomId,
      ruleId: this.$route.params.ruleId,
    };
  },
  async created() {
    await this.getProjectConfig({ uuid: this.projectId });
    await this.listGroups({ uuid: this.projectId, roomId: this.roomId });
  },
  computed: {
    ...mapGetters('rooms', ['getRoomById']),
    ...mapGetters('rules', ['getRuleById']),
    ...mapGetters('groups', ['getGroupsByRoom']),
    ...mapGetters('projects', ['isReadOnly', 'getHealthEntries']),
    ...mapGetters('configs', ['getProjectConfigById']),
    readOnly() {
      return this.isReadOnly(this.projectId);
    },
    groups() {
      return this.getGroupsByRoom(this.projectId, this.roomId);
    },
    options() {
      return [
        { value: 'room', text: this.$t('roomRulesWholeRoom') },
        ...this.groups.map((g) => ({ value: g.uuid, text: g.name })),
      ];
    },
    rule() {
      return this.getRuleById(this.ruleId);
    },
    room() {
      return this.getRoomById(this.roomId);
    },
    projectConfig() {
      return this.getProjectConfigById(this.projectId);
    },
    healthIssues() {
      return this.getHealthEntries(this.projectId).byId[this.ruleId] || [];
    },
  },
  methods: {
    ...mapActions('groups', ['listGroups']),
    ...mapActions('rules', ['listRules', 'updateRule', 'deleteRule', 'getRule']),
    ...mapActions('configs', ['getProjectConfig']),
    openDeleteModal() {
      this.$bvModal.show('delete-rule-modal');
    },
    async onDeleteRule() {
      await this.deleteRule({ uuid: this.projectId, roomId: this.roomId, ruleId: this.ruleId });
      this.$bvModal.hide('delete-rule-modal');
      await this.$router.push({ name: 'room-rules', params: { projectId: this.projectId, roomId: this.roomId } });
    },
    resetForm() {
      this.editMode = false;
      this.disableUpdate = false;
    },
    async onSaveRule(data) {
      await this.updateRule({
        uuid: this.projectId, roomId: this.roomId, ruleId: this.ruleId, data,
      });
      await Promise.all([
        this.getProjectConfig({ uuid: this.projectId }),
        this.listRules({ uuid: this.projectId, roomId: this.roomId }),
        this.getRule({ uuid: this.projectId, roomId: this.roomId, ruleId: this.ruleId }),
      ]);
    },
  },
};

//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from 'vuex';
import RoomGroupsUI from '@/components/ui/roomUI/RoomGroupsUI.vue';

import AddGroupModal from './modals/AddGroupModal.vue';
import DeleteGroupModal from './modals/DeleteGroupModal.vue';

export default {
  name: 'RoomGroups',
  components: {
    RoomGroupsUI,
    AddGroupModal,
    DeleteGroupModal,
  },
  data() {
    return {
      dataLoaded: false,
      projectId: this.$route.params.projectId,
      roomId: this.$route.params.roomId,

      name: '',
      description: '',

      selectedGroup: '',
      selectedUuid: '',
    };
  },
  async created() {
    await this.listRooms(this.projectId);
    await this.onListGroups();
  },
  computed: {
    ...mapGetters('rooms', ['getRoomById']),
    ...mapGetters('groups', ['getGroupsByRoom']),
    ...mapGetters('projects', ['getProjectById', 'isReadOnly']),
    readOnly() {
      return this.isReadOnly(this.projectId);
    },
    groups() {
      return this.getGroupsByRoom(this.projectId, this.roomId);
    },
  },
  methods: {
    ...mapActions('groups', ['listGroups', 'createGroup', 'deleteGroup']),
    ...mapActions('rooms', ['listRooms']),
    async onListGroups() {
      await this.listGroups({ uuid: this.projectId, roomId: this.roomId });
      this.dataLoaded = true;
    },
    openDeleteModal(group) {
      this.selectedGroup = group;
      this.selectedUuid = group.uuid;
      this.$bvModal.show('delete-group-modal');
    },
    async onDeleteGroup() {
      await this.deleteGroup({ uuid: this.projectId, roomId: this.roomId, groupId: this.selectedUuid });
      await this.onListGroups();
      this.$bvModal.hide('delete-group-modal');
    },
  },
};

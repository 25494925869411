import Vue from 'vue';
import { ClickBus } from '@/bus';

Vue.directive('outside-click', {
  bind(el, binding, vnode) {
    el.handleOutsideClick = (event) => {
      if (event.clickedElement === el) {
        return;
      }
      ClickBus.$emit('outside-click', vnode.context._uid);
    };
    document.addEventListener('click', el.handleOutsideClick, true);
    el.addEventListener('click', (event) => {
      event.clickedElement = el;
    });
  },
  unbind(el) {
    document.removeEventListener('click', el.handleOutsideClick);
  },
});

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import InlineTextEditor from '@/components/ui/InlineTextEditor.vue';
import CustomTable from '../CustomTable.vue';
import SectionTitle from '../SectionTitle.vue';
import CreateRoomByTemplate from './modals/CreateRoomByTemplate.vue';

export default {
  name: 'RoomDetailsUI',
  props: ['room', 'deviceCounts', 'sections', 'readOnly', 'loading', 'createRommButton', 'onlySettings', 'template', 'removable', 'hideEdit', 'hideEditDescription', 'hideDelete', 'hideCreate', 'hideCreateTemplate'],
  components: {
    CreateRoomByTemplate,
    CustomTable,
    SectionTitle,
    'inline-text-editor': InlineTextEditor,
  },
  data() {
    return {
      templateId: this.$route.params.templateId,
      projectId: this.$route.params.projectId,
      categories: ['lights', 'switches', 'sensors'],
      categoryTranslate: {
        lights: 'generalLights',
        switches: 'generalSwitches',
        sensors: 'generalSensors',
      },
      sectionCategories: ['scenes', 'groups', 'rules', 'schedules'],
      sectionCategoryTranslate: {
        scenes: 'pageScenes',
        groups: 'generalGroups',
        rules: 'roomDetailsSensorConfigurations',
        schedules: 'pageSchedules',
      },
      name: '',
      description: '',
    };
  },
  computed: {
    filteredSections() {
      return this.sectionCategories.filter((s) => !!this.sections[s]);
    },
  },
  methods: {
    onUpdate(data) {
      this.$emit('onUpdate', data);
    },
    onCancel() {
      this.$bvModal.hide('delete-group-modal');
    },
    onDeleteRoom() {
      this.$emit('onDelete');
    },
  },
};

export default (modules) => {
  let persistedStateInitiated = false;
  return (state, force = false) => {
    if (persistedStateInitiated && !force) {
      return state;
    }
    // eslint-disable-next-line no-restricted-syntax
    for (const [key, value] of Object.entries(modules)) {
      state[key] = { ...value.state, ...state?.[key] };
    }
    persistedStateInitiated = true;
    return state;
  };
};

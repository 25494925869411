//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import { mapActions, mapGetters, mapState } from 'vuex';
import SearchField from '@/components/ui/SearchField.vue';
import ViewSelector from '../../ui/ViewSelector.vue';

export default {
  name: 'NavBarLocal',
  components: { ViewSelector, SearchField },
  data() {
    return {
      loading: false,
      customBackAction() {},
      dismissCountDown: 0,
    };
  },
  props: {
    showFloorplan: Boolean,
    showProjectFilter: Boolean,
    browserBack: Boolean,
    customBack: Boolean,
    breadcrumbs: Array,
    centerTitle: String,
    projectOnly: Boolean,
  },
  computed: {
    ...mapGetters('projects', ['getProjectById', 'getWorkInProgressData', 'isReadOnly', 'projectFilters', 'projectView']),
    ...mapGetters('rooms', ['getRoomById']),
    ...mapGetters('groups', ['getGroupById']),
    ...mapGetters('scenes', ['getSceneById']),
    ...mapGetters('rules', ['getRuleById']),
    ...mapGetters('coworkers', ['getCoworkerById']),
    ...mapGetters('schedules', ['getScheduleById']),
    ...mapGetters('devices', ['getDeviceById']),
    ...mapGetters('templates', ['getTemplateById']),
    ...mapGetters('products', ['getProductById']),
    ...mapState('deviceHealth', ['deviceErrors']),
    ...mapState('app', ['floorplanOpened']),
    ...mapState('projects', ['projectFilters']),
    sortFields() {
      return [
        { sort: 'created-asc', text: this.$t('generalSortByCreatedAsc') },
        { sort: 'created-desc', text: this.$t('generalSortByCreatedDesc') },
        { sort: 'name-asc', text: this.$t('generalSortByNameAsc') },
        { sort: 'name-desc', text: this.$t('generalSortByNameDesc') },
        { sort: 'online', text: this.$t('generalSortByOnlineFirst') },
        { sort: 'offline', text: this.$t('generalSortByOfflineFirst') },
        { sort: 'location-asc', text: this.$t('generalSortByLocationAsc') },
        { sort: 'location-desc', text: this.$t('generalSortByLocationDesc') },
      ];
    },
    sort() {
      return this.projectFilters.sort || '';
    },
    workInProgressData() {
      return this.getWorkInProgressData(this.$route.params.projectId);
    },
    readOnly() {
      return this.isReadOnly(this.$route.params.projectId);
    },
    breadcrumbsObject() {
      // Example 1: A(link to b) >> B (current) [back button to b]
      // const breadcrumbs = [{ title: 'A', routeName: 'b', back: true }, { title: 'B' }]

      // Example 2: no breadcrumbs [back button to b]
      // const breadcrumbs = [{ routeName: 'b', back: true }]

      // Example 3: A(link to a) >> B(link to b) >> C(link to c) >> D (current) [back button to c]
      // const breadcrumbs = [{ title: 'A', routeName: 'a' },
      //            { title: 'B', routeName: 'b' },
      //            { title: 'C', routeName: 'c', back: true },
      //            { title: 'D' }]

      if (!this.breadcrumbs || !this.breadcrumbs.length) {
        return {
          browserBack: this.browserBack,
        };
      }

      const backRoute = this.breadcrumbs.find((bc) => bc.back && bc.routeName);
      return {
        backRoute,
        browserBack: !backRoute && this.browserBack,
        customBack: !backRoute && this.customBack,
        parents: this.breadcrumbs.filter((bc) => bc.title && bc.routeName).map(this.mapBreadcrumbTitle),
        current: this.mapBreadcrumbTitle(this.breadcrumbs.find((bc) => bc.title && !bc.routeName)),
      };
    },
    breadcrumbTitleMap() {
      return {
        $CURRENT_PROJECT_NAME: () => this.getProjectById(this.$route.params.projectId).name,
        $CURRENT_ROOM_NAME: () => this.getRoomById(this.$route.params.roomId).name,
        $CURRENT_GROUP_NAME: () => this.getGroupById(this.$route.params.groupId).name,
        $CURRENT_SCENE_NAME: () => this.getSceneById(this.$route.params.sceneId).name,
        $CURRENT_RULE_NAME: () => this.getRuleById(this.$route.params.ruleId).name,
        $DEFAULT_RULE_NAME: () => this.$t('generalRules'),
        $DEFAULT_GROUP_RULE_NAME: () => this.$t('pageGroupBehaviour'),
        $CURRENT_COWORKER: () => this.getCoworkerById(this.$route.params.coworkerId).name,
        $CURRENT_SCHEDULE_NAME: () => this.getScheduleById(this.$route.params.scheduleId).name,
        $CURRENT_DEVICE_PRODUCT_NAME: () => {
          const { product } = this.getDeviceById(this.$route.params.deviceId);
          return product ? product.name : '?';
        },
        $CURRENT_ADD_DEVICE_CATEGORY: () => `Add ${this.$route.params.deviceCategory}`,
        $CURRENT_CREATE_DEVICE_CATEGORY: () => `Create ${this.$route.params.deviceCategory}`,
        $CURRENT_DEVICE_CATEGORY: () => `${this.$route.params.deviceCategory}`,
        $CURRENT_DEVICE_FAMILY: () => `${this.$route.params.deviceFamily.replace(/-/g, ' ')}`, // todo: family localization
        $CURRENT_TEMPLATE_NAME: () => this.getTemplateById(this.$route.params.templateId).name,
        $CURRENT_PRODUCT_NAME: () => this.getProductById(this.$route.params.productId).name,
        $CURRENT_LEGAL_PAGE: () => this.$t(({
          imprint: 'pageImprint',
          tos: 'pageTermsOfUse',
          privacy: 'pagePrivacyPolicy',
          gdpr: 'pageGdpr',
          licenses: 'pageLicenses',
          troubleshooting: 'pageTroubleshootingGuide',
        })[this.$route.params.type]),
      };
    },
  },
  methods: {
    ...mapActions('deviceHealth', ['listDeviceErrors']),
    ...mapActions('app', ['floorplanUpdate']),
    ...mapActions('projects', ['updateProjectFilters', 'updateProjectView', 'getProject', 'enterEditMode', 'cancelEditMode', 'lockProject']),
    onLockClick() {
      if (this.workInProgressData.remoteEditAllowed) {
        return;
      }
      this.lockProject({ uuid: this.$route.params.projectId });
    },
    selectSort(item) {
      this.selectedSortItem = item;
      this.onFilterChange();
    },
    mapBreadcrumbTitle(breadcrumb) {
      if (!breadcrumb) {
        return {};
      }
      breadcrumb = { ...breadcrumb };

      if (breadcrumb.title && breadcrumb.title[0] !== '$') {
        breadcrumb.title = this.$t(breadcrumb.title);
      }

      const mappedTitle = this.breadcrumbTitleMap[breadcrumb.title] && this.breadcrumbTitleMap[breadcrumb.title]();
      if (mappedTitle) {
        breadcrumb.title = mappedTitle;
      }

      if (!breadcrumb.title || breadcrumb.title[0] === '$') {
        breadcrumb.title = '';
      }

      return breadcrumb;
    },
    toggleFloorplan() {
      this.floorplanUpdate(!this.floorplanOpened);
    },
    async onRemoteControl() {
      this.loading = true;
      await this.enterEditMode({ uuid: this.$route.params.projectId }).catch((e) => {
        this.finishLoading();
        throw e;
      });
      await this.getProject(this.$route.params.projectId).catch((e) => {
        this.finishLoading();
        throw e;
      });
      this.finishLoading();
    },
    finishLoading() {
      this.loading = false;
    },
    onFilterChange(filters) {
      this.updateProjectFilters(filters);
    },
    onViewChange(view) {
      this.updateProjectView(view);
    },
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown;
    },
  },
};

import { initializeApp } from 'firebase/app';
import { getAnalytics, logEvent as analyticsLogEvent, setAnalyticsCollectionEnabled } from 'firebase/analytics';
import config from './config';
// eslint-disable-next-line import/no-cycle
import store from './store';

let _app = null;
let _analytics = null;
function initialize() {
  if (!config.FIREBASE_ENABLE_EVENT_LOG) {
    return {};
  }

  if (!_app) {
    const firebaseConfig = {
      apiKey: config.FIREBASE_API_KEY,
      authDomain: config.FIREBASE_AUTH_DOMAIN,
      projectId: config.FIREBASE_PROJECT_ID,
      storageBucket: config.FIREBASE_STORAGE_BUCKET,
      messagingSenderId: config.FIREBASE_MESSAGING_SENDER_ID,
      appId: config.FIREBASE_APP_ID,
      measurementId: config.FIREBASE_MEASUREMENT_ID,
    };

    _app = initializeApp(firebaseConfig, { automaticDataCollectionEnabled: false });
    _analytics = getAnalytics(_app);
    setAnalyticsCollectionEnabled(_analytics, false);
  }
  return { app: _app, analytics: _analytics };
}

export default { app: _app, analytics: _analytics };
export const logEvent = (eventName, eventParams) => {
  const { analytics, app } = initialize();
  if (!analytics) {
    return;
  }

  const profile = store.getters['user/getProfileData'];

  console.log('automaticDataCollectionEnabled', app.automaticDataCollectionEnabled);

  if (!profile || !profile.analyticsEnabled) {
    setAnalyticsCollectionEnabled(analytics, false);
    app.automaticDataCollectionEnabled = false;
    return;
  }
  setAnalyticsCollectionEnabled(analytics, true);
  app.automaticDataCollectionEnabled = true;

  console.log('logEvent', eventName, eventParams, profile);

  analyticsLogEvent(analytics, eventName, eventParams);
};

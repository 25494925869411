import Vue from 'vue';
import BootstrapVue from 'bootstrap-vue';
import VueNativeSock from 'vue-native-websocket';
import VueTimepicker from 'vue2-timepicker';
import PortalVue from 'portal-vue';
import { LMap, LTileLayer, LMarker } from 'vue2-leaflet';
import vSelect from 'vue-select';
import '@/directives/OutsideClickDirective';

import 'bootstrap';
import './main.scss';
import 'leaflet/dist/leaflet.css';

import './firebase';

import App from './App.vue';
import router from './router';
import store from './store';

import 'vue2-timepicker/dist/VueTimepicker.css';

import './utils/axios';
import './utils/vee-validate';
import './mixins';

import i18n from './i18n';

Vue.use(PortalVue);
Vue.use(BootstrapVue);
Vue.config.productionTip = false;

Vue.use(VueNativeSock, store.getters['locale/getWsUrl'], {
  store,
  format: 'json',
  reconnection: true,
});

vSelect.props.components.default = () => ({
  Deselect: {
    render: (createElement) => createElement('span', ''),
  },
  OpenIndicator: {
    render: (createElement) => createElement('span', ''),
  },
});

Vue.component('vue-timepicker', VueTimepicker);
Vue.component('l-map', LMap);
Vue.component('l-tile-layer', LTileLayer);
Vue.component('l-marker', LMarker);
Vue.component('v-select', vSelect);

new Vue({
  router,
  store,
  render: (h) => h(App),
  i18n,
}).$mount('#app');

Vue.mixin({
  beforeRouteLeave(to, from, next) {
    this.$leaveGuard(to, from, next);
  },
});

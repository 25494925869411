//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from 'vuex';
import RoomDetailsUI from '@/components/ui/roomUI/RoomDetailsUI.vue';

export default {
  name: 'TemplateDetails',
  components: {
    RoomDetailsUI,
  },
  data() {
    return {
      projectId: this.$route.params.projectId,
      templateId: this.$route.params.templateId,
      categories: ['lights', 'switches', 'sensors'],
      sections: ['scenes', 'groups'],
      loading: false,
    };
  },
  async created() {
    this.loading = true;
    await this.listProducts();
    this.loading = false;
  },
  computed: {
    ...mapGetters('templates', ['getTemplateById']),
    ...mapGetters('products', ['getProductById']),
    ...mapGetters('projects', ['isReadOnly']),
    readOnly() {
      return this.projectId ? this.isReadOnly(this.projectId) : true;
    },
    template() {
      return this.getTemplateById(this.templateId) || {};
    },
    templateConfig() {
      if (this.template.config && this.template.config.body) {
        return this.template.config.body;
      }
      return {};
    },
    deviceCounts() {
      return this.categories.reduce((devices, cat) => {
        const catDevices = (this.templateConfig.devices[cat] || [])
          .map((device) => ({ ...device, product: this.getProductById(device.product) }))
          .reduce((acc, item) => {
            if (!item.product || !item.product.uuid) {
              return acc;
            }
            acc[item.product.uuid] = acc[item.product.uuid] || { ...item, count: 0 };
            acc[item.product.uuid].count += 1;
            return acc;
          }, {});
        return { ...devices, [cat]: catDevices };
      }, {});
    },
  },
  methods: {
    ...mapActions('products', ['listProducts']),
    ...mapActions('templates', ['deleteTemplate']),
    async onDeleteRoom() {
      await this.deleteTemplate({ templateId: this.templateId });
      if (this.projectId) {
        await this.$router.push({ name: 'project-templates', params: { projectId: this.projectId } });
      } else {
        await this.$router.push({ name: 'templates' });
      }
    },
  },
};

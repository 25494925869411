//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import NumberField from '@/components/ui/NumberField.vue';

export default {
  name: 'ProductItem',
  props: ['product', 'projectMode', 'value', 'propsByCategory'],
  components: { NumberField },
  computed: {
    properties() {
      return [
        { key: this.$t('product.ean'), value: this.product.props.ean || null },
        { key: this.$t('product.vendor'), value: this.product.props.vendor || null },
      ];
    },
  },
  methods: {
    onAction(...args) {
      this.$emit('action', ...args);
    },
  },
};

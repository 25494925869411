/* global axios */

const listTemplates = async ({ scopes = ['global'], locale = 'en', projectUUID } = {}) => axios({
  method: 'GET',
  url: '/templates',
  params: {
    resolveWithBody: true,
    locale,
    scopes,
    projectUUID,
  },
});

const createTemplateByRoom = async ({
  uuid, roomId, scope, name, description,
}) => axios({
  method: 'POST',
  url: '/templates',
  data: {
    projectUUID: uuid,
    roomUUID: roomId,
    scope,
    name,
    description,
  },
  logEvent: () => ({ eventName: 'save_as_room_template' }),
});

const updateTemplate = async ({ templateId, data }) => axios({
  method: 'PUT',
  url: `/templates/${templateId}`,
  data,
  logEvent: () => ({ eventName: 'edit_room_template' }),
});

const deleteTemplate = async ({ templateId }) => axios({
  method: 'DELETE',
  url: `/templates/${templateId}`,
  data: {},
  logEvent: () => ({ eventName: 'delete_room_template' }),
});

export default {
  listTemplates,
  createTemplateByRoom,
  deleteTemplate,
  updateTemplate,
};

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'WizardListUI',
  props: ['items', 'addLabel', 'indexBased', 'type', 'rooms', 'groups', 'lights', 'projectName'],
  data() {
    return {
      hasRooms: false,
      allGroups: [],
      allLights: [],
    };
  },
  created() {
    if (this.type === 'schedule' && this.rooms.length) {
      this.scheduleInit();
      this.hasRooms = true;
    }
  },
  computed: {
    devices() {
      if (this.type === 'device') {
        const groupedDevices = this.items.map((device) => ({ name: device.product.name, uuid: device.uuid }))
          .reduce((acc, item) => ({ ...acc, [item.name]: [...(acc[item.name] || []), item.uuid] }), {});
        return Object.keys(groupedDevices).map((name) => ({ name, uuids: groupedDevices[name] }));
      }
      return [];
    },
    cDays() {
      if (this.type === 'rule' || this.type === 'schedule') {
        return this.$t('generalWeekdays').split(',');
      }
      return '';
    },
  },
  methods: {
    decToBin(dec) {
      // eslint-disable-next-line no-bitwise
      return (dec >>> 0).toString(2).padStart(7, '0');
    },
    repeatDays(obj) {
      const bin = this.decToBin(obj.repeat).split('').reverse();
      return this.cDays
        .reduce((acc, item, i) => (bin[(i + 1) % 7] === '1' ? [...acc, item] : acc), [])
        .join().trim();
    },
    formatWeek(repeatDays) {
      const weekFormatsShort = [this.$t('generalWeekdaysShort'), this.$t('generalWeekendShort'), this.$t('generalAllWeekShort')];
      const weekFormats = [this.$t('generalWeekWeekdays'), this.$t('generalWeekWeekend'), this.$t('generalWeekAllWeek')];
      const index = weekFormatsShort.indexOf(repeatDays);
      return index !== -1 ? weekFormats[index] : repeatDays;
    },
    scheduleInit() {
      this.allGroups = [...Object.values(this.groups).reduce((a, b) => a.concat(b), [])];
      this.allLights = [...Object.values(this.lights).reduce((a, b) => a.concat(b), [])];
    },
    getRoomById(roomId) {
      return this.rooms.find((r) => r.uuid === roomId) || {};
    },
    getGroupById(groupId) {
      return this.allGroups.find((g) => g.uuid === groupId) || {};
    },
    getLightById(deviceId) {
      return this.allLights.find((d) => d.uuid === deviceId) || {};
    },
    getTargetName(type, uuid) {
      if (type === 'group') {
        return this.getGroupById(uuid).name;
      }
      if (type === 'room') {
        return this.getRoomById(uuid).name;
      }
      if (type === 'light') {
        const { product } = this.getLightById(uuid);
        return product ? product.name : this.$t('roomDevicesProduct');
      }
      if (type === 'gateway') {
        return this.projectName;
      }
      return '';
    },
    getTargetLabel(type) {
      if (type === 'group') {
        return this.$t('generalGroups');
      }
      if (type === 'room') {
        return this.$t('generalRooms');
      }
      if (type === 'light') {
        return this.$t('generalLights');
      }
      if (type === 'gateway') {
        return this.$t('generalProject');
      }
      return '';
    },
    getActionValue(value) {
      const actionOn = value.level ? `${this.$t('generalOn')}, ${this.$t('roomScenesDimLevel')}: ${value.level}%` : this.$t('generalOn');
      return value.on ? actionOn : this.$t('generalOff');
    },
  },
};

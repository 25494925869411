//
//
//
//
//
//
//
//
//
//

import { mapActions, mapGetters } from 'vuex';

import RoomDevicesUI from '@/components/ui/roomUI/RoomDevicesUI.vue';

export default {
  name: 'TemplateDevices',
  components: {
    RoomDevicesUI,
  },
  data() {
    return {
      categories: ['lights', 'switches', 'sensors'],
      templateId: this.$route.params.templateId,
      projectId: this.$route.params.projectId,
      loading: false,
    };
  },
  async created() {
    this.loading = true;
    await this.listProducts();
    this.loading = false;
  },
  computed: {
    ...mapGetters('templates', ['getTemplateById']),
    ...mapGetters('products', ['getProductById']),
    template() {
      return this.getTemplateById(this.templateId) || {};
    },
    templateConfig() {
      if (this.template.config && this.template.config.body) {
        return this.template.config.body;
      }
      return {};
    },
    devices() {
      return this.categories.reduce((devices, cat) => {
        const catDevices = (this.templateConfig.devices[cat] || [])
          .map((device) => ({ ...device, product: this.getProductById(device.product) }))
          .filter((device) => device.product.name);
        return { ...devices, [cat]: catDevices };
      }, {});
    },
  },
  methods: {
    ...mapActions('products', ['listProducts']),
  },
};

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from 'vuex';
import InputField from '@/components/ui/InputField.vue';
import CustomSection from '@/components/ui/CustomSection.vue';
import { formatDate } from '@/utils/helpers';
import InviteSentModal from './modals/InviteSentModal.vue';
import InviteCoworkerModal from './modals/InviteCoworkerModal.vue';
import DeleteCoworkerModal from './modals/DeleteCoworkerModal.vue';

export default {
  name: 'RoomGroupDetail',
  components: {
    InputField,
    CustomSection,
    InviteSentModal,
    InviteCoworkerModal,
    DeleteCoworkerModal,
  },
  data() {
    return {
      projectId: this.$route.params.projectId,
      coworkerId: this.$route.params.coworkerId,

      options: [
        { value: 'admin', text: 'Admin' },
        { value: 'planner', text: 'Planner' },
        { value: 'installer', text: 'Installer' },
        { value: 'readonly', text: 'Read-only' },
      ],
      editMode: false,
    };
  },
  async created() {
    this.resetForm();
    await this.listCoworkers({ uuid: this.projectId });
    this.resetForm();
  },
  computed: {
    ...mapGetters('coworkers', ['getCoworkerById']),
    coworker() {
      const coworker = this.getCoworkerById(this.coworkerId);
      return {
        ...coworker,
        ...(coworker.user || {}),
        uuid: coworker.uuid,
      };
    },
    invitationState() {
      if (this.coworker.invitationAccepted) {
        return 'Accepted';
      }
      if (this.coworker.invitationRejected) {
        return 'Rejected';
      }
      return 'Pending';
    },
    canIInvite() {
      return this.$canI('inviteAdmin', 'coworkers')
          || this.$canI('inviteInstaller', 'coworkers')
          || this.$canI('invitePlanner', 'coworkers')
          || this.$canI('inviteReadonly', 'coworkers');
    },
    showInvitation() {
      return this.$canI(this.coworker.invitationAccepted ? 'deleteCoworker' : 'deleteInvitation', 'coworkers') || this.$canI('update', 'coworkers');
    },
  },
  methods: {
    ...mapActions('coworkers', ['listCoworkers', 'deleteCoworker']),
    openDeleteModal() {
      this.$bvModal.show('delete-coworker-modal');
    },
    async onDeleteCoworker() {
      await this.deleteCoworker({ uuid: this.projectId, coworkerId: this.coworkerId });
      this.$bvModal.hide('delete-coworker-modal');
      await this.$router.push({ name: 'project-coworkers', params: { projectId: this.projectId } });
    },
    resetForm() { },
    getDate(value) {
      return formatDate(value);
    },
  },
};

//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';

export default {
  name: 'NavUserDropdown',
  computed: {
    ...mapGetters('auth', ['isAuthenticated']),
  },
};

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions } from 'vuex';

export default {
  name: 'ProductsCategories',
  data() {
    return {
      categories: [
        'switches',
        'sensors',
        'lights',
      ],
      translates: {
        switches: 'generalSwitches',
        sensors: 'generalSensors',
        lights: 'generalLights',
      },
    };
  },
  created() {
    this.resetProductFilter();
  },
  methods: {
    ...mapActions('app', ['resetProductFilter']),
  },
};

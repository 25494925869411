//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { ValidationProvider } from 'vee-validate';
import PhoneNumberInput from './PhoneNumberInput.vue';

export default {
  props: ['type', 'label', 'rules', 'value', 'rows', 'placeholder', 'readMode', 'options', 'editable', 'simpleInputLabel', 'tooltip', 'schema'],
  components: {
    PhoneNumberInput,
    ValidationProvider,
  },
  data() {
    return {
      internalValue: null,
    };
  },
  watch: {
    value(newValue) {
      this.internalValue = newValue;
    },
  },
  computed: {
    textValue() {
      if (this.type === 'select') {
        const val = this.options.find((o) => o.value === this.value);
        if (!val) {
          return this.val;
        }
        return val.text;
      }
      return this.value;
    },
    isEmailInput() {
      return this.rules?.includes('email');
    },
  },
  created() {
    this.internalValue = this.value;
  },
  methods: {
    formatInput() {
      if (this.schema === 'phone') {
        this.internalValue = this.internalValue.replace(/[^\d+]/, '');
      }
    },
  },
};

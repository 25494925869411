//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from 'vuex';
import RoomRulesDefaultUI from '@/components/ui/roomUI/RoomRulesDefaultUI.vue';
import RoomGroupRulesDefaultUI from '@/components/ui/roomUI/RoomGroupRulesDefaultUI.vue';

export default {
  name: 'RoomRules',
  components: {
    RoomGroupRulesDefaultUI,
    RoomRulesDefaultUI,
  },
  data() {
    return {
      dataLoaded: false,
      projectId: this.$route.params.projectId,
      roomId: this.$route.params.roomId,
      name: '',
      description: '',
      selectedRule: '',
      selectedUuid: '',
    };
  },
  async created() {
    await this.getProjectConfig({ uuid: this.projectId });
    await this.getRoom({ uuid: this.projectId, roomId: this.roomId });
    await this.onListRules();
  },
  computed: {
    ...mapGetters('rooms', ['getRoomById']),
    ...mapGetters('groups', ['getGroupsByRoom']),
    ...mapGetters('rules', ['getRulesByRoom']),
    ...mapGetters('configs', ['getProjectConfigById']),
    ...mapGetters('projects', ['getProjectById', 'isReadOnly', 'getHealthEntries']),
    healthIssues() {
      return ((this.getHealthEntries(this.projectId).byRoomAndType || {})[this.roomId] || {}).rules || [];
    },
    readOnly() {
      return this.isReadOnly(this.projectId);
    },
    rules() {
      return this.getRulesByRoom(this.projectId, this.roomId);
    },
    room() {
      return this.getRoomById(this.roomId);
    },
    groups() {
      return this.getGroupsByRoom(this.projectId, this.roomId);
    },
    extendedGroups() {
      return this.groups.map((group) => ({ ...group, dlhEnabled: this.behaviorEnabled(group.uuid, 'dlh'), hclEnabled: this.behaviorEnabled(group.uuid, 'hcl') }));
    },
    mode() {
      return this.room.defaultRoomBehaviour || 'manual';
    },
    projectConfig() {
      return this.getProjectConfigById(this.projectId);
    },
    roomSensors() {
      if (!this.projectConfig?.devices) {
        return [];
      }
      const room = (this.projectConfig?.rooms || []).find((r) => r.uuid === this.roomId);
      if (!room) {
        return [];
      }
      return (this.projectConfig?.devices?.sensors || []).filter((sensor) => room.sensors.includes(sensor.uuid));
    },
    hasGroups() {
      return this.groups.length > 0;
    },
  },
  methods: {
    ...mapActions('rules', ['listRules', 'createRule', 'deleteRule']),
    ...mapActions('groups', ['listGroups']),
    ...mapActions('configs', ['getProjectConfig']),
    ...mapActions('rooms', ['getRoom']),
    async onListRules() {
      await this.listRules({ uuid: this.projectId, roomId: this.roomId });
      await this.listGroups({ uuid: this.projectId, roomId: this.roomId });
      this.dataLoaded = true;
    },
    openDeleteModal(rule) {
      this.selectedRule = rule;
      this.selectedUuid = rule.uuid;
      this.$bvModal.show('delete-rule-modal');
    },
    async onDeleteRule() {
      await this.deleteRule({ uuid: this.projectId, roomId: this.roomId, ruleId: this.selectedUuid });
      await this.onListRules();
      this.$bvModal.hide('delete-rule-modal');
    },
    behaviorEnabled(groupId, type) {
      if (!this.rules.length) {
        return false;
      }
      const rule = this.rules.filter((r) => r.actions.target.uuid === groupId)[0];
      return rule && !!rule.actions.value[`${type}-enabled`];
    },
  },
};

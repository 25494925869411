//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapState } from 'vuex';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { WizardBus } from '@/bus';

export default {
  name: 'WizardProject',
  components: {
    ValidationObserver,
    ValidationProvider,
  },
  data() {
    return {
      name: '',
      description: '',
    };
  },
  created() {
    this.name = this.projectState.name;
    this.description = this.projectState.description;
    WizardBus.$on('next', this.onNextStep);
    WizardBus.$on('abort', this.onAbort);
  },
  beforeDestroy() {
    WizardBus.$off('next', this.onNextStep);
    WizardBus.$off('abort', this.onAbort);
  },
  computed: {
    ...mapState('wizard', ['projectState']),
  },
  methods: {
    ...mapActions('wizard', ['updateProjectState', 'resetState']),
    async onNextStep() {
      const isValid = await this.$refs['observer-1'].validate();
      if (!isValid) {
        return;
      }
      this.updateProjectState({ name: this.name, description: this.description });
      await this.$router.push({ name: this.$route.meta.nextRoute });
    },
    onAbort() {
      this.resetState();
    },
  },
};

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import deviceHelpers from '../../../../../mixins/deviceHelpers';
import CustomModal from '../../../CustomModal.vue';
import CustomSlider from '../../../CustomSlider.vue';

export default {
  components: { CustomSlider, CustomModal },
  mixins: [deviceHelpers],
  props: ['editMode', 'button', 'selectedButtonType', 'event', 'options', 'dataOptions'],
  name: 'ActionSelector',
  data() {
    return {
      showSetDimLevel: false,
      tempValue: undefined,
      setActionType: undefined,
    };
  },
  computed: {
    modalOptions() {
      const { action } = this.button.events[this.event];
      if (action === 'set-dim') {
        return {
          title: this.$t('roomDevicesActionSetDim'),
          buttons: {
            main: { title: this.$t('generalSave'), click: this.modalOnSave },
            secondary: { title: this.$t('generalCancel'), click: this.modalOnCancel },
          },
          type: 'level',
          range: { from: 1, to: 100, unit: '%' },
        };
      }
      if (action === 'set-cct') {
        const item = this.dataOptions.lights[this.button.target.uuid];
        return {
          title: this.$t('roomDevicesActionSetCCT'),
          buttons: {
            main: { title: this.$t('generalSave'), click: this.modalOnSave },
            secondary: { title: this.$t('generalCancel'), click: this.modalOnCancel },
          },
          type: 'cct',
          range: {
            from: item?.product?.capabilities?.cctMin || 2700,
            to: item?.product?.capabilities?.cctMax || 6500,
          },
        };
      }
      return {};
    },
    scenes() {
      return this.dataOptions?.scenes?.filter((scene) => {
        if (this.button?.target.type !== 'room' || !scene.room) {
          return false;
        }
        return scene.room === this.button?.target?.uuid;
      });
    },
  },
  methods: {
    onSetAction(type) {
      this.showSetDimLevel = true;
      this.setActionType = type;
      this.tempValue = this.button.events[this.event].value[type];
    },
    onSliderInput(data, type) {
      this.button.events[this.event].value[type] = data.value;
    },
    modalOnSave() {
      this.showSetDimLevel = false;
      this.tempValue = undefined;
    },
    modalOnCancel() {
      this.showSetDimLevel = false;
      if (this.setActionType) {
        this.$set(this.button.events[this.event].value, this.setActionType, this.tempValue);
      }
      this.tempValue = undefined;
    },
    validActions(button, event) {
      const actualSelected = button.events[event].action;
      const pressedToggle = button.events.pressed.action === 'toggle';
      const holdPressedBrightenDimming = button.events.hold_pressed.action === 'brighten' || button.events.hold_pressed.action === 'dimming';

      if (this.selectedButtonType === this.BUTTON_TYPES.PUSH_BUTTON && event === this.EVENT_TYPES.PRESSED) {
        const actions = this.options(button).actions
          .filter((action) => [null, 'on', 'off', 'set-dim', 'set-cct', 'scene', 'toggle'].includes(action.value));
        if (actions.length > 0) {
          // enabling/disabling toggle based on hold pressed brighten/dimming
          actions[this.getActionIndex(actions, 'toggle')].disabled = !!holdPressedBrightenDimming;
        }
        return actions;
      }

      if (this.selectedButtonType === this.BUTTON_TYPES.PUSH_BUTTON && event === this.EVENT_TYPES.HOLD_PRESSED) {
        button.events.released.action = '';
        if (['brighten', 'dimming'].includes(actualSelected)) {
          button.events.released.action = 'stop';
        }
        if (['cct-warmer', 'cct-colder'].includes(actualSelected)) {
          button.events.released.action = 'cct-stop';
        }
        const actions = this.options(button).actions
          .filter((action) => button.events.pressed.action !== 'off' && !['toggle', 'stop', 'cct-stop'].includes(action.value));
        if (actions.length > 0) {
          // enabling/disabling brighten/dimming based on pressed toggle
          actions[this.getActionIndex(actions, 'brighten')].disabled = !!pressedToggle;
          actions[this.getActionIndex(actions, 'dimming')].disabled = !!pressedToggle;
        }
        return actions;
      }

      if (this.selectedButtonType === this.BUTTON_TYPES.ROCKER && event === this.EVENT_TYPES.HOLD_PRESSED) {
        return this.options(button).actions
          .filter((action) => ['on', 'set-dim', 'scene'].includes(action.value));
      }

      if (this.selectedButtonType === this.BUTTON_TYPES.ROCKER && event === this.EVENT_TYPES.RELEASED) {
        return this.options(button).actions
          .filter((action) => ['off'].includes(action.value));
      }

      if (this.selectedButtonType === this.BUTTON_TYPES.EXPERT && event === this.EVENT_TYPES.PRESSED) {
        const { actions } = this.options(button);
        if (actions.length > 0) {
          // enabling toggle if it was disabled previously
          actions[this.getActionIndex(actions, 'toggle')].disabled = false;
        }
        return actions;
      }

      if (this.selectedButtonType === this.BUTTON_TYPES.EXPERT && event === this.EVENT_TYPES.HOLD_PRESSED) {
        const { actions } = this.options(button);
        if (actions.length > 0) {
          // enabling brighten/dimming if it was disabled previously
          actions[this.getActionIndex(actions, 'brighten')].disabled = false;
          actions[this.getActionIndex(actions, 'dimming')].disabled = false;
        }
        return actions;
      }

      return this.options(button).actions;
    },

    getActionIndex(obj, value) {
      return obj.map((a) => a.value).indexOf(value);
    },

    getValueForEvent(event) {
      const { action, value, sceneId } = this.button.events[event];
      if (!action) {
        return '';
      }
      if (action === 'set' || action === 'set-dim' || action === 'set-cct') {
        if (action === 'set-cct') {
          return `${this.$t('roomDevicesActionSet')}: ${this.valueLabel(this.$t('generalCCT'), value.cct, 'K')}`;
        }
        return `${this.$t('roomDevicesActionSet')}: ${this.valueLabel(this.$t('generalDimLevel'), value.level, '%')}`;
      }
      const actualAction = this.options(this.button).actions.find((act) => act.value === action);
      if (action === 'scene') {
        const scene = this.dataOptions.scenes?.find((sc) => sc.value === sceneId);
        if (scene) {
          return `${actualAction.text}: ${scene.text}`;
        }
      }

      return actualAction.text;
    },

    valueLabel(label, value, unit) {
      return `${label} ${value}${unit}`;
    },
  },
};

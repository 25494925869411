//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { formatDate } from '@/utils/helpers';

export default {
  name: 'MaintenanceErrorDetails',
  components: {
  },
  props: ['healthIssues', 'borderBottom'],
  data() {
    return {
      severityTranslate: {
        recoverable: 'maintenanceRecoverable',
        maintenancereq: 'maintenanceMaintenanceReq',
        info: 'maintenanceInfo',
        fatal: 'maintenanceFatal',
      },
    };
  },
  computed: {
    hasIssues() {
      return this.healthIssues.length !== 0;
    },
  },
  methods: {
    getDate(value) {
      return formatDate(value);
    },
  },
};

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapState } from 'vuex';
import config from '../config';
import bus from '../bus';

export default {
  name: 'HiddenSettings',
  data() {
    return {
      hiddenSettingsEnabled: config.ENABLE_HIDDEN_SETTINGS,
      flagLabels: [
        'ENABLE_WIZARD',
        'ENABLE_ORIGINAL_FIRMWARE_UPDATE',
      ],
    };
  },
  computed: {
    ...mapState('app', ['hiddenSettings']),
    featureFlags() {
      return {
        ENABLE_WIZARD: config.ENABLE_WIZARD,
        ENABLE_ORIGINAL_FIRMWARE_UPDATE: config.ENABLE_ORIGINAL_FIRMWARE_UPDATE,
      };
    },
  },
  methods: {
    ...mapActions('app', ['toggleHiddenSettings']),
    changeFlag(flag) {
      config[flag] = !config[flag];
      bus.$emit('forceRerender');
    },
  },
};

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapGetters, mapActions } from 'vuex';
import NavUserDropdown from '@/components/layouts/navbars/NavUserDropdown.vue';
import NavLocaleCountryModal from '@/components/layouts/navbars/NavLocaleCountryModal.vue';
import NavOtherCountryModal from '@/components/layouts/navbars/NavOtherCountryModal.vue';
import HiddenSettings from '@/components/HiddenSettings.vue';
import config from '@/config';

export default {
  name: 'NavBarMain',
  components: {
    NavOtherCountryModal,
    NavLocaleCountryModal,
    NavUserDropdown,
    HiddenSettings,
  },
  data() {
    return {
      version: config.PACKAGE_VERSION,
      gitHash: config.GIT_HASH,
      clickCount: 0,
      hiddenSettingsEnabled: config.ENABLE_HIDDEN_SETTINGS,
      localeCountryType: '',
      otherCountryCode: '',
      licensesUrl: '',
    };
  },
  async created() {
    if (!this.isCountrySelected) {
      // logout if country is not selected
      await this.$router.push({ name: 'logout' }).catch(() => {});
    }
    await this.getLicensesUrl();
  },
  computed: {
    ...mapState('app', ['appData', 'hiddenSettings']),
    ...mapGetters('auth', ['isAuthenticated']),
    ...mapGetters('locale', ['getLocale', 'getCountryCode', 'getLocales', 'getCountryList', 'hasCountryCode']),
    backendVersion() {
      if (this.appData && this.appData.api) {
        return this.appData.api.version;
      }
      return '';
    },
    projectId() {
      return this.$route.params.projectId || '';
    },
    locales() {
      return this.getLocales;
    },
    countries() {
      return this.getCountryList;
    },
    locale() {
      return this.getLocale;
    },
    countryCode() {
      return this.getCountryCode || 'de'; // Germany as a fallback to prevent countryLabel to be undefined
    },
    localeLabel() {
      return this.locales.find((locale) => locale.code === this.locale).name;
    },
    countryLabel() {
      return this.countries.find((country) => country.code === this.countryCode).name;
    },
    hasLicensesUrl() {
      return this.licensesUrl && this.licensesUrl.length > 0;
    },
    isCountrySelected() {
      return this.hasCountryCode;
    },
  },
  methods: {
    ...mapActions('app', ['toggleHiddenSettings']),
    ...mapActions('locale', ['getLegalDocument']),
    navTo(to) {
      this.$bvModal.hide('nav-overlay');
      this.$router.push(to).catch(() => {});
    },
    navToSupport() {
      this.$bvModal.hide('nav-overlay');
      if (this.projectId.length) {
        this.$router.push({ name: 'support', params: { projectId: this.projectId } });
      } else {
        this.$router.push({ name: 'support' });
      }
    },
    showHiddenSettings() {
      if (!this.hiddenSettingsEnabled) {
        return;
      }
      this.clickCount += 1;
      if (this.clickCount === 3) {
        this.toggleHiddenSettings(true);
        this.$bvModal.hide('nav-overlay');
        this.clickCount = 0;
      }
    },
    showLocaleCountryModal() {
      this.$bvModal.show('nav-locale-country-modal');
    },
    showOtherCountryModal(countryCode) {
      this.otherCountryCode = countryCode;
      this.$bvModal.show('nav-other-country-modal');
    },
    async getLicensesUrl() {
      this.licensesUrl = await this.getLegalDocument({ type: 'licenses', link: true });
    },
  },
};

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

/* eslint global-require: 0 */
import NavItem from './NavItem.vue';

const icons = {
  details: [require('../../../../assets/icons/sidebar/room/details.svg'), require('../../../../assets/icons/sidebar/room/details_active.svg')],
  devices: [require('../../../../assets/icons/sidebar/room/devices.svg'), require('../../../../assets/icons/sidebar/room/devices_active.svg')],
  groups: [require('../../../../assets/icons/sidebar/room/groups.svg'), require('../../../../assets/icons/sidebar/room/groups_active.svg')],
  scenes: [require('../../../../assets/icons/sidebar/room/scenes.svg'), require('../../../../assets/icons/sidebar/room/scenes_active.svg')],
  rules: [require('../../../../assets/icons/sidebar/room/room_behaviour.svg'), require('../../../../assets/icons/sidebar/room/room_behaviour_active.svg')],
};

export default {
  name: 'TemplateSidebar',
  components: { NavItem },
  data() {
    return {
      active: 'devices',
    };
  },
  created() {
    this.active = this.$route.name;
  },
  computed: {
    projectId() {
      return this.$route.params.projectId;
    },
    templateId() {
      return this.$route.params.templateId;
    },
    items() {
      const { projectId, templateId } = this;
      return [
        {
          title: this.$t('generalDetails'),
          to: { name: projectId ? 'project-template-details' : 'template-details', params: { templateId, projectId } },
          icons: icons.details,
        },
        {
          title: this.$t('pageDevices'),
          to: { name: projectId ? 'project-template-devices' : 'template-devices', params: { templateId, projectId } },
          icons: icons.devices,
        },
        {
          title: this.$t('pageLightGroups'),
          to: { name: projectId ? 'project-template-groups' : 'template-groups', params: { templateId, projectId } },
          icons: icons.groups,
        },
        {
          title: this.$t('pageScenes'),
          to: { name: projectId ? 'project-template-scenes' : 'template-scenes', params: { templateId, projectId } },
          icons: icons.scenes,
        },
        {
          title: this.$t('pageRoomBehaviour'),
          to: { name: projectId ? 'project-template-rules' : 'template-rules', params: { templateId, projectId } },
          icons: icons.rules,
        },
      ];
    },
  },
  methods: {
    onItemClick(item) {
      if (this.active !== item.to.name) {
        this.active = item.to.name;
        this.$router.push(item.to);
      }
    },
  },
};

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';
import RoomDeviceSwitchDetailsUI from './switch/RoomDeviceSwitchDetailsUI.vue';
import DeviceDetails from './devices/DeviceDetails.vue';
import deviceHelpers from '../../../mixins/deviceHelpers';
import FixedFooter from '../FixedFooter.vue';

export default {
  name: 'RoomDeviceDetailsUI',
  props: ['device', 'readOnly', 'loading', 'devices', 'lights',
    'roomsOptions', 'groupsOptions', 'sceneOptions', 'roomId', 'projectId', 'switchesOptions',
    'healthIssues',
    'saveDevice', 'copySwitchConfig',
    'hideOptions'],
  mixins: [deviceHelpers],
  components: {
    DeviceDetails,
    RoomDeviceSwitchDetailsUI,
    FixedFooter,
  },
  data() {
    return {
      editMode: false,
    };
  },
  computed: {
    ...mapGetters('groups', ['getGroupsByRoom']),
    groups() {
      return this.getGroupsByRoom(this.projectId, this.roomId);
    },
    dataOptions() {
      return {
        currentProject: this.projectId,
        currentRoom: this.roomId,
        lights: this.lights,
        groups: this.groupsOptions,
        rooms: this.roomsOptions,
        scenes: this.sceneOptions,
        switches: this.switchesOptions,
      };
    },
    sensorFooterButtons() {
      let title = this.$t('roomRulesGoToRoomBehavior');

      if (this.groups.length > 0) {
        title = this.$t('roomRulesGoToGroupBehavior');
      }
      return {
        main: {
          title,
          to: { name: 'room-rules', params: { projectId: this.projectId, roomId: this.roomId } },
        },
      };
    },
  },
  methods: {
    async onAction(action, data, onlyUpdate = false, forceEditMode = false) {
      switch (action) {
        case 'EDIT':
          this.editMode = true;
          break;
        case 'CANCEL_EDIT':
          this.editMode = false;
          break;
        case 'SAVE_EDIT':
          await this.saveDevice(data);
          if (!onlyUpdate) {
            this.editMode = false;
          }
          if (forceEditMode) {
            this.editMode = true;
          }
          break;
        case 'MOVE':
          this.$emit('move-device');
          break;
        case 'DELETE':
          this.$emit('delete');
          break;
        case 'COPY_SWITCH_CONFIG':
          await this.copySwitchConfig(data);
          break;
        default:
          break;
      }
    },
  },
};

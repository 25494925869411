import Vue from 'vue';
import _debounce from 'lodash/debounce';
import { uuidv4 } from '@/utils/helpers';
import appBus from '@/bus';
import {
  SOCKET_ONOPEN,
  SOCKET_ONCLOSE,
  SOCKET_ONERROR,
  SOCKET_ONMESSAGE,
  SOCKET_RECONNECT,
  SOCKET_RECONNECT_ERROR,
  SOCKET_SEND_MSG,
} from '../actions/socket';

const eventBus = new Vue();

const heartbeatFactory = (socket) => {
  function heartbeat() {
    if (!socket) {
      return;
    }
    if (socket.readyState === 1) {
      // Planning Tool Heartbeat
      socket.send('PTHB');
    }
    setTimeout(heartbeat, 10000);
  }
  heartbeat();
};

const updateProjectData = _debounce((message) => {
  console.debug('projectUpdated event sent', message);
  appBus.$emit('projectUpdated', message);
}, 2000);

// eslint-disable-next-line no-async-promise-executor
const sendSocketMessage = (commit, msg, state) => new Promise(async (resolve, reject) => {
  let tries = 1;
  while (!state.socket.isConnected && tries < 50) {
    // eslint-disable-next-line no-await-in-loop,no-loop-func
    await new Promise((r) => setTimeout(() => {
      tries *= 2;
      r();
    }, 50 * tries));
  }
  if (tries >= 50) {
    reject(new Error('Socket connection failed'));
    return;
  }
  eventBus.$on(msg.uuid, resolve);
  commit(SOCKET_SEND_MSG, msg);
  Vue.prototype.$socket.sendObj(msg);
});

const initialState = {
  socketConnected: false,
  socketMessages: {},
  configUpdates: {},
  socket: {
    isConnected: false,
    reconnectError: false,
  },
  sensorStatus: {},
  lightStatus: {},
  allOtaUpdateStatus: {},
};

const mutations = {
  [SOCKET_ONOPEN]: (state, event) => {
    Vue.prototype.$socket = event.currentTarget;
    heartbeatFactory(Vue.prototype.$socket);
    state.socket.isConnected = true;
    state.socketConnected = true;
  },
  [SOCKET_ONCLOSE]: (state) => {
    state.socket.isConnected = false;
    state.socketConnected = false;
  },
  [SOCKET_ONERROR]: (state, event) => {
    console.log('ERROR', state);
    console.error(state, event);
  },
  // default handler called for all methods
  [SOCKET_ONMESSAGE]: (state, message) => {
    console.debug('ONMSG', message);
    if (message.type === 'configurationupdated') {
      state.configUpdates = {
        ...state.configUpdates,
        [message.projectUUID]: [message, ...(state.configUpdates[message.projectUUID] || [])],
      };
      return;
    }
    if (message.type === 'firmwareupdated') {
      state.configUpdates = {
        ...state.configUpdates,
        [message.projectUUID]: [message, ...(state.configUpdates[message.projectUUID] || [])],
      };
      return;
    }
    if (message.type === 'jobevent') {
      state.configUpdates = {
        ...state.configUpdates,
        [message.projectUUID]: [message, ...(state.configUpdates[message.projectUUID] || [])],
      };
      return;
    }
    if (message.type === 'event' && message.target) {
      switch (message.target.type) {
        case 'project':
        case 'projectconfig':
          updateProjectData(message);
          break;
        case 'sensor':
          // eslint-disable-next-line no-case-declarations
          const alarm = message.event?.arguments?.alarm;
          if (alarm !== undefined) {
            state.sensorStatus = {
              ...state.sensorStatus,
              [message.target.uuid]: alarm === 1 ? '1' : '0',
            };
          }
          break;
        case 'light':
          // eslint-disable-next-line no-case-declarations
          const onOff = message.event?.arguments?.onOff;
          if (onOff !== undefined) {
            state.lightStatus = {
              ...state.lightStatus,
              [message.target.uuid]: onOff === 1 ? '1' : '0',
            };
          }
          break;
        case 'allotaupdate':
          state.allOtaUpdateStatus = {
            ...state.allOtaUpdateStatus,
            [message.target.uuid]: message.event?.arguments,
          };
          break;
        default:
          console.debug('unhandled event target', message.target.type);
      }
      return;
    }
    eventBus.$emit(message.uuid, message);
    const { socketMessages } = state;
    delete socketMessages[message.uuid];
    state.socketMessages = socketMessages;
  },
  // mutations for reconnect methods
  [SOCKET_RECONNECT]: (state, count) => {
    console.info(state, count);
  },
  [SOCKET_RECONNECT_ERROR]: (state) => {
    state.socket.reconnectError = true;
  },
  [SOCKET_SEND_MSG]: (state, msg) => {
    state.socketMessages[msg.uuid] = msg;
  },
  resetConfigUpdates: (state, projectUUID) => {
    state.configUpdates = { ...state.configUpdates, [projectUUID]: undefined };
  },
};

const actions = {
  async socketAuth({ rootState, commit, state }) {
    const { accessToken } = rootState.auth;
    if (!accessToken) {
      // return;
    }

    const sockAuthMessage = {
      type: 'request',
      uuid: uuidv4(),
      endpoint: '/authenticate',
      method: 'POST',
      // payload: { accessToken },
    };

    await sendSocketMessage(commit, sockAuthMessage, state);
  },
  async subscribeToProject({ commit, state, dispatch }, uuid) {
    const message = {
      type: 'subscribe',
      uuid: uuidv4(),
      projectUUID: uuid,
    };
    console.debug('SEND SOCK', message);
    await dispatch('socketAuth');
    await sendSocketMessage(commit, message, state);
  },
  unsubscribeFromProject({ commit }, uuid) {
    const message = {
      type: 'unsubscribe',
      uuid: uuidv4(),
      projectUUID: uuid,
    };
    console.debug('SEND SOCK', message);
    commit(SOCKET_SEND_MSG, message);
    Vue.prototype.$socket.sendObj(message);
  },
  resetConfigUpdates({ commit }, projectUUID) {
    commit('resetConfigUpdates', projectUUID);
  },
};

const getters = {
  getConfigUpdatesById: (state) => (projectUUID) => (state.configUpdates[projectUUID] || [])[0] || {},
  getAllOtaUpdateStatus: (state) => (projectUUID) => state.allOtaUpdateStatus[projectUUID]
      || { otaTotalFinishedCount: 0, otaTotalNumber: 0, otaTotalTime: 0 },
  getMaxProgress: (state) => (projectUUID) => (state.configUpdates[projectUUID] || [])
    .reduce((acc, configUpdateMessage) => {
      if (!configUpdateMessage.job) {
        return 0;
      }
      return configUpdateMessage.job.progress > acc ? configUpdateMessage.job.progress : acc;
    }, 0),
  getSensorStatus: (state) => (sensorUUID) => state.sensorStatus[sensorUUID],
  getLightStatus: (state) => (lightUUID) => state.lightStatus[lightUUID],
};

export default {
  state: initialState,
  mutations,
  actions,
  getters,
};

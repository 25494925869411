/* global axios */

const getLegalDocument = ({
  locale,
  countryCode,
  type,
  link,
  serverLink,
}) => {
  if (serverLink) {
    return {
      data: {
        link: `${axios.defaults.baseURL}/legal-document?locale=${locale}&countryCode=${countryCode}&type=${type}`,
      },
    };
  }
  return axios({
    method: 'GET',
    url: '/legal-document',
    params: {
      locale,
      countryCode,
      type,
      link,
    },
    responseType: link ? 'json' : 'arraybuffer',
  });
};

const getCountries = () => axios({
  method: 'GET',
  url: '/countries',
});

export default {
  getLegalDocument,
  getCountries,
};

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { ClickBus } from '@/bus';

export default {
  name: 'InlineTextEditor', // Forked from VueInlineTextEditor
  components: {},
  props: {
    autofocus: {
      type: Boolean,
      default: false,
    },
    closeOnBlur: {
      type: Boolean,
      default: false,
    },
    currencyDecimalPlaces: {
      type: Number,
      default: 2,
    },
    currencySymbol: {
      default: '$',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    hoverEffects: {
      type: Boolean,
      default: false,
    },
    maxLength: {
      type: Number,
      default: null,
    },
    minLength: {
      type: Number,
      default: null,
    },
    placeholder: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
    required: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'text',
      validator(value) {
        return ['text', 'number', 'currency', 'percentage', 'textarea'].indexOf(value) > -1;
      },
    },
    value: {
      required: true,
    },
    rows: {
      required: false,
      default: 6,
    },
    name: {
      type: String,
      required: false,
    },
    errors: {
      type: Array,
      required: false,
    },
    readOnly: {
      type: Boolean,
    },
    hideEditButton: {
      type: Boolean,
    },
    autoWidth: {
      type: Boolean,
      default: false,
    },
    clickOutsideToSave: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      editingValue: null,
      internalValue: this.value,
      textValidationErrors: [],
      loaded: false,
      active: false,
    };
  },
  computed: {
    classes() {
      const classNames = [];
      if (this.hoverEffects) {
        classNames.push('hover-effects');
      }
      if (this.editingValue !== null) {
        classNames.push('editing');
      }
      if (this.disabled) {
        classNames.push('disabled');
      }
      classNames.push(`type-${this.type}`);
      return classNames.join(' ');
    },
    errorMessages() {
      if (this.textValidationErrors.length) {
        return this.textValidationErrors.join(', ');
      }
      return null;
    },
    formattedValue() {
      if ((this.internalValue === null) || (this.internalValue === '')) {
        return this.label ? '–' : this.placeholder;
      }
      if (this.type === 'currency') {
        return this.formatCurrency(this.internalValue, 0);
      }
      if (this.type === 'percentage') {
        return `${this.internalValue}%`;
      }
      return this.internalValue;
    },
  },
  watch: {
    internalValue(newValue) {
      this.$emit('update:value', newValue);
    },
    selectValue(newValue) {
      this.internalSelectValue = newValue;
    },
    value(newValue) {
      this.internalValue = newValue;
      this.loaded = true;
    },
    editingValue(newValue) {
      if (newValue !== null) {
        this.active = true;
      }
    },
  },
  mounted() {
    ClickBus.$on('outside-click', this.handleOutsideClick);
    this.loaded = true;
    // If this field is required, but is empty, open the editor
    if (this.required) {
      if ((this.internalValue === '') || (this.internalValue === null)) {
        this.editingValue = '';
        this.showSelect = true;
      }
    }
    // If error prop is set, check if any of them are adressed to this field, then open the editor
    if (this.errors) {
      this.errors.forEach((item) => {
        if (item.path === this.name) {
          this.textValidationErrors.push(item.message);
          this.editValue();
        }
      });
    }
  },
  beforeDestroy() {
    ClickBus.$off('outside-click', this.handleOutsideClick);
  },
  methods: {
    cancelEdit() {
      this.internalSelectValue = this.originalSelectValue;
      this.closeEditor();
    },
    closeEditor() {
      this.editingValue = null;
      this.$emit('close');
      this.originalSelectValue = null;
    },
    editValue() {
      if (this.disabled || this.readOnly) {
        return;
      }
      if (this.internalValue === null) {
        // Clicking into an empty editor, set to an empty string
        this.editingValue = '';
      } else {
        this.editingValue = this.internalValue;
      }
      this.filterValue();
      this.originalSelectValue = this.internalSelectValue;

      // Set the focus to the input
      window.setTimeout(() => {
        this.showSelect = true;
        this.focus();
      }, 10);
      this.$emit('open');
    },
    emitBlur(e) {
      this.validate();
      this.$emit('blur', e);
      if (this.closeOnBlur === true) {
        this.updateValue();
      }
    },
    emitChange(e) {
      this.validate();
      this.$emit('change', e);
    },
    filterValue() {
      if (this.editingValue === null) {
        return;
      }
      if (['number', 'currency', 'percentage'].indexOf(this.type) > -1) {
        this.editingValue = this.editingValue.toString().replace(/[^0-9.]/g, '');
      }
    },
    focus() {
      try {
        this.$nextTick(() => {
          if (this.$refs && this.$refs.input) {
            this.$refs.input.focus();
          } else if (this.$refs && this.$refs.textarea) {
            this.$refs.textarea.focus();
            this.resizeTextarea();
          }
        });
      } catch (ignore) {
        // ignore
      }
    },
    /**
         * Takes a number and formats it as US currency
         * @param  {number} value The number to format
         * @return {string} The formatted value
         */
    formatCurrency(value) {
      try {
        const digitsRE = /(\d{3})(?=\d)/g;
        // Strip out any non-numeric characters
        value = parseFloat(value.toString().replace(/[^0-9.-]/g, ''), 10);

        if (!Number.isFinite(value) || (!value && value !== 0)) {
          return '';
        }

        const stringified = Math.abs(value).toFixed(this.currencyDecimalPlaces);
        const _int = this.currencyDecimalPlaces ? stringified.slice(0, -1 - this.currencyDecimalPlaces) : stringified;
        const i = _int.length % 3;
        const head = i > 0 ? (_int.slice(0, i) + (_int.length > 3 ? ',' : '')) : '';
        const _float = this.currencyDecimalPlaces ? stringified.slice(-1 - this.currencyDecimalPlaces) : '';
        const sign = value < 0 ? '-' : '';
        return sign + this.currencySymbol + head + _int.slice(i).replace(digitsRE, '$1,') + _float;
      } catch (e) {
        return '';
      }
    },
    updateValue() {
      this.validate();
      if (this.textValidationErrors.length) {
        return;
      }
      let isChanged = false;
      if (this.editingValue !== null && this.internalValue !== this.editingValue) {
        this.internalValue = this.editingValue;
        isChanged = true;
      }
      if (isChanged) {
        this.$nextTick(() => {
          this.$emit('update', { [this.name]: this.internalValue });
        });
      }
      this.closeEditor();
    },
    validate() {
      const errors = [];
      this.filterValue();
      if (this.required && !this.editingValue) {
        errors.push(this.$t('errorMessageRequired'));
      } else if (this.minLength && this.editingValue && (this.editingValue.length < this.minLength)) {
        errors.push(this.$t('errorMessageMinLength', { value: this.minLength }));
      } else if (this.maxLength && this.editingValue && (this.editingValue.length > this.maxLength)) {
        errors.push(this.$t('errorMessageMaxLength', { value: this.maxLength }));
      }
      this.textValidationErrors = errors;
    },
    validateInput(evt) {
      // Only for number, currency, and percentage fields
      if (['number', 'currency', 'percentage'].indexOf(this.type) === -1) {
        return false;
      }

      // Get the key code
      evt = (evt) || window.event;
      const charCode = (evt.which) ? evt.which : evt.keyCode;

      // Verify that it's a number or control key
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault();
        return false;
      }
      return true;
    },
    resizeTextarea() {
      const { textarea } = this.$refs;
      textarea.style.height = 'auto';
      textarea.style.height = `${textarea.scrollHeight}px`;
    },
    handleOutsideClick(uid) {
      if (uid === this._uid && this.active) {
        this.active = false;
        this.updateValue();
      }
    },
  },
};

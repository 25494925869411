import { render, staticRenderFns } from "./ProjectSidebar.vue?vue&type=template&id=3eee3d5c&scoped=true"
import script from "./ProjectSidebar.vue?vue&type=script&lang=js"
export * from "./ProjectSidebar.vue?vue&type=script&lang=js"
import style0 from "./ProjectSidebar.vue?vue&type=style&index=0&id=3eee3d5c&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3eee3d5c",
  null
  
)

export default component.exports
//
//
//
//

import lottie from 'lottie-web';
import spinnerJson from '@/assets/json/spinner.json';

export default {
  name: 'Spinner',
  data() {
    return {
      anim: null,
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      const settings = {
        container: this.$refs.animation,
        renderer: 'svg',
        loop: true,
        autoplay: true,
        animationData: spinnerJson,
      };

      this.anim = lottie.loadAnimation(settings);
    },
  },
};

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapGetters } from 'vuex';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import LocaleCountrySelect from '@/components/ui/LocaleCountrySelect.vue';

export default {
  name: 'login',
  components: {
    LocaleCountrySelect,
    ValidationObserver,
    ValidationProvider,
  },
  data() {
    return {
      email: '',
      password: '',
      rememberMe: false,
      forgot: '',
      reminderSent: false,
      loadingCountries: false,
    };
  },
  async created() {
    await this.getApiStatus();
    await this.loadCountries();
  },
  mounted() {
    if (this.$route.query.forgotpassword) {
      this.$bvModal.show('forgot-password-modal');
    }
  },
  computed: {
    ...mapGetters('locale', ['hasCountryCode']),
    authState() {
      return this.$store.state.auth;
    },
    tooManyRequests() {
      return this.authState.errorCode === 'too-many-requests';
    },
    loading() {
      return this.authState.state === 'loading';
    },
    isApiOffline() {
      return this.$store.state.api.error;
    },
    isCountrySelected() {
      return this.hasCountryCode;
    },
  },
  methods: {
    ...mapActions('api', ['getApiStatus']),
    ...mapActions('auth', ['login', 'forgotPassword']),
    ...mapActions('locale', ['getCountries']),
    async onLogin() {
      await this.getApiStatus();
      const { email, password, rememberMe } = this;
      await this.login({ email, password, rememberMe })
        .then(() => {
          this.$router.push({ name: 'projects' });
        });
    },
    async sendForgotPasswordMail() {
      const { forgot } = this;
      await this.forgotPassword(forgot).then(() => {
        this.reminderSent = true;
      });
    },
    async loadCountries() {
      this.loadingCountries = true;
      await this.getCountries().finally(() => {
        this.loadingCountries = false;
      });
    },
  },
};

import configService from '../services/configs';

const initialState = {
  revisionsByProject: {},
  configsById: {},
};

const actions = {
  async getProjectConfig({ commit }, { uuid, revision }) {
    let response;
    if (revision) {
      response = await configService.getProjectConfigRevision({ uuid, revision });
    } else {
      response = await configService.getProjectConfig({ uuid, wip: true });
    }
    const { data } = response;
    commit('getProjectConfig', { data, uuid });
  },
  async listRevisions({ commit }, { uuid }) {
    const { data } = await configService.listRevisions({ uuid });
    commit('listRevisions', { data, uuid });
  },
  async restoreRevision(ctx, { uuid, revision }) {
    await configService.restoreRevision({ uuid, revision });
  },
  async setZigbeeChannel(ctx, { uuid, channel }) {
    await configService.setZigbeeChannel({ uuid, channel });
  },
};

const mutations = {
  getProjectConfig(state, payload) {
    state.configsById = {
      ...state.configsById,
      [payload.uuid]: payload.data,
    };
  },
  listRevisions(state, payload) {
    state.revisionsByProject = {
      ...state.revisionsByProject,
      [payload.uuid]: payload.data.edges.map((e) => e.node),
    };
  },
};

const getters = {
  getProjectConfigById: (state) => (uuid) => state.configsById[uuid] || {},
  getRevisionsByProjectId: (state) => (uuid, markedAsCheckpoint) => {
    const revisions = (state.revisionsByProject[uuid] || []);
    if (!markedAsCheckpoint) {
      return revisions;
    }
    return revisions.filter((r) => r.markedAsCheckpoint);
  },
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  mutations,
};

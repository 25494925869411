export default {
  API_URL: process.env.VUE_APP_API_URL,
  WS_URL: process.env.VUE_APP_WS_URL,
  GIT_HASH: process.env.VUE_APP_GIT_HASH,
  PACKAGE_VERSION: process.env.VUE_APP_PACKAGE_VERSION,
  ENABLE_TEST_FIRMWARE: process.env.VUE_APP_ENABLE_TEST_FIRMWARE === 'true',
  ENABLE_WIZARD: process.env.VUE_APP_ENABLE_WIZARD === 'true',
  ENABLE_ORIGINAL_FIRMWARE_UPDATE: process.env.VUE_APP_ENABLE_ORIGINAL_FIRMWARE_UPDATE === 'true',
  ENABLE_HIDDEN_SETTINGS: process.env.VUE_APP_ENABLE_HIDDEN_SETTINGS === 'true',

  FIREBASE_ENABLE_EVENT_LOG: process.env.VUE_APP_FIREBASE_ENABLE_EVENT_LOG === 'true',
  FIREBASE_API_KEY: process.env.VUE_APP_FIREBASE_API_KEY,
  FIREBASE_AUTH_DOMAIN: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
  FIREBASE_PROJECT_ID: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  FIREBASE_STORAGE_BUCKET: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
  FIREBASE_MESSAGING_SENDER_ID: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
  FIREBASE_APP_ID: process.env.VUE_APP_FIREBASE_APP_ID,
  FIREBASE_MEASUREMENT_ID: process.env.VUE_APP_FIREBASE_MEASUREMENT_ID,
};

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';
import NavBarMain from '@/components/layouts/navbars/NavBarMain.vue';
import NavBarLocal from '@/components/layouts/navbars/NavBarLocal.vue';
import FixedFooter from '@/components/ui/FixedFooter.vue';
import WizardUI from '@/components/ui/wizard/WizardUI.vue';

import { WizardBus } from '@/bus';

/*
Valid layout options:
  - breadcrumbs (array)
  - browserBack (bool)
  - showProjectFilter (bool)
  - currentStep (number)
  - progressSteps (number)
 */

export default {
  name: 'MainLayout',
  props: ['options'],
  components: {
    WizardUI,
    FixedFooter,
    NavBarMain,
    NavBarLocal,
  },
  data() {
    return {
      progressSteps: 10,
      skippable: false,
      disabledFooterButton: false,
    };
  },
  computed: {
    ...mapGetters('projects', ['getProjectById']),
    ...mapGetters('rooms', ['getRoomById']),
    projectId() {
      return this.$route.params.projectId;
    },
    roomId() {
      return this.$route.params.roomId;
    },
    project() {
      return this.getProjectById(this.projectId);
    },
    room() {
      return this.getRoomById(this.roomId);
    },
    setCenterTitle() {
      let title;
      if (this.options.currentStep !== this.progressSteps) {
        title = this.room && this.room.name ? `${this.project.name} / ${this.room.name}` : this.project.name;
      }
      return title;
    },
    footerButtons() {
      return {
        roomCreate: {
          main: {
            title: this.$t('wizardContinueWizard'),
            click: () => WizardBus.$emit('createRoom'),
          },
          secondary: {
            title: this.$t('wizardContinueManually'),
            click: () => WizardBus.$emit('createRoomManually'),
          },
          tertiary: {
            title: this.$t('wizardBasedOnTemplate'),
            click: () => WizardBus.$emit('showTemplateModal'),
          },
        },
        details: {
          main: {
            title: this.options.skippable && this.skippable ? this.$t('wizardSkip') : this.$t('wizardNext'),
            click: () => WizardBus.$emit('next'),
            class: this.disabledFooterButton ? 'disabled' : '',
          },
          secondary: {
            title: this.$t('wizardAbort'),
            click: () => {
              this.$bvModal.show('abort-wizard-modal');
            },
            class: this.disabledFooterButton ? 'disabled' : '',
          },
        },
        overview: {
          main: {
            title: this.$t('wizardNoMoreRoom'),
            click: () => WizardBus.$emit('next'),
          },
          secondary: {
            title: this.$t('wizardAbort'),
            click: () => {
              this.$bvModal.show('abort-wizard-modal');
            },
          },
          tertiary: {
            title: this.$t('wizardCreateAnotherRoom'),
            click: () => {
              this.$router.push({ name: 'wizard-room-name-and-description', params: { projectId: this.projectId } });
            },
          },
        },
        summary: {
          main: {
            title: this.$t('wizardFinish'),
            to: { name: 'project-rooms', params: { projectId: this.projectId } },
          },
        },
      };
    },
  },
  methods: {
    abortWizard() {
      this.$router.push({ name: 'project-rooms', params: { projectId: this.projectId } });
    },
  },
};

//
//
//
//
//
//
//
//
//
//

export default {
  props: ['title', 'collapsible', 'value', 'readMode', 'rightText', 'disabled', 'small'],
  data() {
    return {
      show: this.value,
    };
  },
  watch: {
    value(v) {
      this.show = v;
    },
  },
  methods: {
    onSelected(v) {
      this.$emit('input', v);
    },
  },
};

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapState } from 'vuex';
import { ValidationObserver, ValidationProvider, extend } from 'vee-validate';
import { regex } from 'vee-validate/dist/rules';
import InputField from '../../components/ui/InputField.vue';

extend('regex', {
  ...regex,
  message: 'Invalid format',
});

export default {
  name: 'register',
  components: {
    InputField,
    ValidationObserver,
    ValidationProvider,
  },
  data() {
    return {
      showPassword: false,
      email: '',
      password: '',
      passwordRepeat: '',
      name: '',
      company: '',
      phone: '',
      acceptTerms: false,
      submitted: false,
      emailIsUsed: false,
    };
  },
  computed: {
    ...mapState('locale', ['country']),
    passwordFieldType() {
      return this.showPassword ? 'text' : 'password';
    },
  },
  methods: {
    ...mapActions('auth', ['register']),
    async onRegister() {
      this.submitted = true;
      const {
        email, password, name, company, phone, acceptTerms,
      } = this;
      const data = {
        email,
        password,
        name,
        company,
        phone,
        acceptTerms,
        countryId: this.country?.id,
      };
      await this.register(data)
        .then(() => this.$router.push({
          name: 'confirm',
          query: { email },
        }))
        .catch((err) => {
          const apiErrorData = err.response.data;
          if (apiErrorData.errorCode === 'email-address-is-already-used') {
            this.emailIsUsed = true;
            this.$refs.observer.setErrors({ email: 'This email is already in use' });
          }
          this.submitted = false;
        });
    },
    formatPhoneNumber() {
      this.phone = this.phone.replace(/[^\d+]/, '');
    },
  },
};

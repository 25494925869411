//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


export default {
  props: ['label', 'value', 'readMode', 'error'],
  components: {},
  computed: {
    cDays() {
      return [
        { day: this.$t('generalWeekdayMon'), val: 2 },
        { day: this.$t('generalWeekdayTue'), val: 4 },
        { day: this.$t('generalWeekdayWed'), val: 8 },
        { day: this.$t('generalWeekdayThu'), val: 16 },
        { day: this.$t('generalWeekdayFri'), val: 32 },
        { day: this.$t('generalWeekdaySat'), val: 64 },
        { day: this.$t('generalWeekdaySun'), val: 1 },
      ];
    },
    days() {
      const bin = this.decToBin(this.value).split('').reverse();
      return this.cDays.map((item, i) => ({ ...item, checked: bin[(i + 1) % 7] === '1' }));
    },
    selectAllChecked() {
      return this.value === 127;
    },
    indeterminate() {
      return !!(this.value && this.value < 127);
    },
  },
  methods: {
    decToBin(dec) {
      // eslint-disable-next-line no-bitwise
      return (dec >>> 0).toString(2).padStart(7, '0');
    },
    binToDec(bin) {
      return parseInt(bin, 2);
    },
    onSelectAll(val) {
      this.$emit('input', val ? 127 : 0);
    },
    onCheck(val, item) {
      let result = this.value;
      if (val) {
        result += item.val;
      } else {
        result -= item.val;
      }
      this.$emit('input', result);
    },
  },
};

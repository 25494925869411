/* global axios */

const listGatewayProducts = async ({ projectUUID }) => axios({
  method: 'GET',
  url: `/projects/${projectUUID}/gateway/products`,
});

const listGatewayLogs = async ({ projectUUID }) => axios({
  method: 'GET',
  url: `/projects/${projectUUID}/gateway/logs`,
});

const getGatewayLog = async ({ projectUUID, fileName }) => axios({
  method: 'GET',
  url: `/projects/${projectUUID}/gateway/logs/${fileName}`,
});

const getGatewayTime = async ({ projectUUID }) => axios({
  method: 'GET',
  url: `/projects/${projectUUID}/gateway/time`,
});

export default {
  listGatewayProducts,
  listGatewayLogs,
  getGatewayLog,
  getGatewayTime,
};

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"observer",staticClass:"d-block login-form",attrs:{"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var handleSubmit = ref.handleSubmit;
return [(!_vm.isCountrySelected)?_c('locale-country-select',{attrs:{"white":true,"loading":_vm.loadingCountries}}):_c('b-form',{attrs:{"novalidate":""},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onLogin)}}},[(_vm.isApiOffline)?_c('b-alert',{attrs:{"show":"","variant":"danger"}},[_vm._v(_vm._s(_vm.$t('authConnectionError')))]):(_vm.authState.status==='error')?_c('b-alert',{attrs:{"show":"","variant":"danger"}},[_vm._v(_vm._s(_vm.tooManyRequests ? _vm.$t('authTooManyRequests') : _vm.$t('authErrorIncorrectCred')))]):(_vm.authState.status==='loggedout')?_c('b-alert',{attrs:{"show":"","variant":"light"}},[_vm._v(_vm._s(_vm.$t('authInactivityMessage')))]):_vm._e(),_c('ValidationProvider',{attrs:{"rules":"required|email|max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('authEmailAddress'),"label-for":"email"}},[_c('b-form-input',{attrs:{"id":"email","disabled":_vm.loading,"type":"email","placeholder":_vm.$t('authEmailAddress'),"state":errors[0] ? false : (valid ? true : null)},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"space",32,$event.key,[" ","Spacebar"])){ return null; }$event.preventDefault();}},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0]))])],1)]}}])}),_c('ValidationProvider',{attrs:{"rules":"required","vid":"password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('authPassword'),"label-for":"password"}},[_c('b-form-input',{attrs:{"id":"password","disabled":_vm.loading,"type":"password","placeholder":_vm.$t('authPassword'),"state":errors[0] ? false : (valid ? true : null)},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0]))])],1)]}}])}),_c('b-form-group',{staticClass:"text-center",attrs:{"id":"input-group-3"}},[_c('b-form-checkbox',{attrs:{"id":"checkbox-1","disabled":_vm.loading},model:{value:(_vm.rememberMe),callback:function ($$v) {_vm.rememberMe=$$v},expression:"rememberMe"}},[_vm._v(" "+_vm._s(_vm.$t('authRememberMe'))+" ")])],1),_c('p',{staticClass:"text-center mb-3"},[_c('b-link',{directives:[{name:"b-modal",rawName:"v-b-modal.forgot-password-modal",modifiers:{"forgot-password-modal":true}}]},[_vm._v(_vm._s(_vm.$t('authIForgotMyPassword')))])],1),_c('locale-country-select',{attrs:{"white":true,"loading":_vm.loadingCountries}}),_c('div',{staticClass:"text-center mb-2"},[_c('b-button',{attrs:{"id":"login-button","type":"submit","pill":"","variant":"primary","disabled":!valid || _vm.loading}},[_vm._v(" "+_vm._s(_vm.$t('authLogin'))+" ")])],1),_c('p',{staticClass:"text-center"},[_c('b-link',{attrs:{"to":"register"}},[_vm._v(_vm._s(_vm.$t('authRegister')))])],1)],1),_c('b-modal',{attrs:{"id":"forgot-password-modal","size":"md","centered":"","hide-footer":""}},[_c('span',{attrs:{"slot":"modal-title"},slot:"modal-title"},[_vm._v(_vm._s(_vm.$t('authResetPassword')))]),(!_vm.reminderSent)?_c('div',[_c('p',[_vm._v(_vm._s(_vm.$t('authResetPasswordMessage')))]),_c('ValidationObserver',{ref:"observer-forgot",staticClass:"d-block",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var handleSubmit = ref.handleSubmit;
return [_c('b-form',{attrs:{"novalidate":""},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.sendForgotPasswordMail)}}},[_c('ValidationProvider',{attrs:{"rules":"required|email|max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return [_c('b-form-group',[_c('b-form-input',{attrs:{"id":"email-forgot","type":"email","value":_vm.email,"placeholder":_vm.$t('authEmailAddress'),"state":errors[0] ? false : null},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"space",32,$event.key,[" ","Spacebar"])){ return null; }$event.preventDefault();}},model:{value:(_vm.forgot),callback:function ($$v) {_vm.forgot=$$v},expression:"forgot"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('div',{staticClass:"text-center mb-1"},[_c('b-button',{attrs:{"type":"submit","pill":""}},[_vm._v(_vm._s(_vm.$t('authSend')))])],1)],1)]}}],null,true)})],1):_vm._e(),(_vm.reminderSent)?_c('div',[_c('p',[_vm._v(_vm._s(_vm.$t('authResetPasswordSentMessage')))]),_c('div',{staticClass:"text-center mb-1"},[_c('b-button',{attrs:{"pill":""},on:{"click":function($event){return _vm.$bvModal.hide('forgot-password-modal')}}},[_vm._v(_vm._s(_vm.$t('authClose')))])],1)]):_vm._e()])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

/* eslint global-require: 0 */
import { OTA_STATUS } from '@/utils/helpers';

export default {
  name: 'FirmwareUpdateProgress',
  props: ['devices', 'otaUpdateStatus'],
  computed: {
    totalTime() {
      if (this.otaUpdateStatus.otaTotalTime) {
        return `${this.otaUpdateStatus.otaTotalTime} sec`;
      }

      let otaTime = 0;
      (this.devices || []).forEach((device) => {
        const time = parseInt(device.otaStatusInfo.otaTime, 10) || -1;
        otaTime += time;
      });
      return otaTime < 0 ? '?' : `${otaTime} sec`;
    },
    updateItems() {
      const items = [
        {
          id: 'lights',
          name: this.$t('generalLights'),
          icon: require('../../../assets/icons/fwupdate/inp_light.svg'),
          total: 0,
          finished: 0,
          errored: 0,
        },
        {
          id: 'sensors',
          name: this.$t('generalSensors'),
          icon: require('../../../assets/icons/fwupdate/inp_sensor.svg'),
          total: 0,
          finished: 0,
          errored: 0,
        },
        {
          id: 'switches',
          name: this.$t('generalSwitches'),
          icon: require('../../../assets/icons/fwupdate/inp_switch.svg'),
          total: 0,
          finished: 0,
          errored: 0,
        },
        {
          id: 'all',
          name: this.$t('firmwareAllDevices'),
          icon: require('../../../assets/icons/fwupdate/inp_all.svg'),
          total: 0,
          finished: 0,
          errored: 0,
        },
      ];

      (this.devices || []).filter((device) => !!device.macAddress).forEach((device) => {
        const allItem = items.find((item) => item.id === 'all');
        allItem.total += 1;
        allItem.finished += OTA_STATUS.isFinished(device.otaStatusInfo.otaStatus) ? 1 : 0;
        allItem.errored += device.otaStatusInfo.otaStatus === OTA_STATUS.ERRORED;
        if (device.category === 'gateways') {
          return;
        }
        const catItem = items.find((item) => item.id === device.category);
        catItem.total += 1;
        catItem.finished += OTA_STATUS.isFinished(device.otaStatusInfo.otaStatus) ? 1 : 0;
        catItem.errored += device.otaStatusInfo.otaStatus === OTA_STATUS.ERRORED;
      });

      if (this.otaUpdateStatus.otaTotalNumber) {
        const allItem = items.find((item) => item.id === 'all');
        allItem.total = this.otaUpdateStatus.otaTotalNumber;
        allItem.finished = this.otaUpdateStatus.otaTotalFinishedCount;
      }

      return items.filter((item) => (item.finished - item.errored) !== item.total);
    },
  },
};

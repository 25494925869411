//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

/* eslint global-require: 0 */
import { mapActions, mapGetters } from 'vuex';
import NavItem from '@/components/layouts/navbars/sidebar/NavItem.vue';

const icons = {
  details: [require('@/assets/icons/sidebar/room/details.svg'), require('@/assets/icons/sidebar/room/details_active.svg')],
  devices: [require('@/assets/icons/sidebar/room/devices.svg'), require('@/assets/icons/sidebar/room/devices_active.svg')],
  groups: [require('@/assets/icons/sidebar/room/groups.svg'), require('@/assets/icons/sidebar/room/groups_active.svg')],
  scenes: [require('@/assets/icons/sidebar/room/scenes.svg'), require('@/assets/icons/sidebar/room/scenes_active.svg')],
  rules: [require('@/assets/icons/sidebar/room/room_behaviour.svg'), require('@/assets/icons/sidebar/room/room_behaviour_active.svg')],
};

export default {
  name: 'RoomSidebar',
  components: { NavItem },
  props: ['remoteControlEnabled'],
  data() {
    return {
      active: 'devices',
    };
  },
  mounted() {
    this.active = this.$route.name;
    this.fallbackToEnabledView();
  },
  async created() {
    await this.listGroups({ uuid: this.projectId, roomId: this.roomId });
  },
  computed: {
    ...mapGetters('projects', ['getHealthEntries']),
    ...mapGetters('groups', ['getGroupsByRoom']),
    projectId() {
      return this.$route.params.projectId;
    },
    roomId() {
      return this.$route.params.roomId;
    },
    items() {
      return [
        {
          title: this.$t('pageDevices'),
          to: { name: 'room-devices', params: { projectId: this.projectId, roomId: this.roomId } },
          icons: icons.devices,
          hasIssue: this.hasEntityHealthIssue,
        },
        {
          title: this.$t('generalGroups'),
          to: { name: 'room-groups', params: { projectId: this.projectId, roomId: this.roomId } },
          icons: icons.groups,
          hasIssue: this.hasGroupHealthIssue,
        },
        {
          title: this.$t('pageScenes'),
          to: { name: 'room-scenes', params: { projectId: this.projectId, roomId: this.roomId } },
          icons: icons.scenes,
          hasIssue: this.hasSceneHealthIssue,
        },
        {
          title: this.hasGroups ? this.$t('pageGroupBehaviour') : this.$t('pageRoomBehaviour'),
          to: { name: 'room-rules', params: { projectId: this.projectId, roomId: this.roomId } },
          icons: icons.rules,
          hasIssue: this.hasRuleHealthIssue,
        },
        {
          title: this.$t('generalDetails'),
          to: { name: 'room-details' },
          icons: icons.details,
          // disabled: this.remoteControlEnabled,
        },
      ];
    },
    entityHealth() {
      return this.getHealthEntries(this.projectId);
    },
    hasEntityHealthIssue() {
      return (((this.entityHealth.byRoomAndType || {})[this.roomId] || {}).devices || []).length;
    },
    hasGroupHealthIssue() {
      return (((this.entityHealth.byRoomAndType || {})[this.roomId] || {}).groups || []).length;
    },
    hasSceneHealthIssue() {
      return (((this.entityHealth.byRoomAndType || {})[this.roomId] || {}).scenes || []).length;
    },
    hasRuleHealthIssue() {
      return (((this.entityHealth.byRoomAndType || {})[this.roomId] || {}).rules || []).length;
    },
    groups() {
      return this.getGroupsByRoom(this.projectId, this.roomId);
    },
    hasGroups() {
      return this.groups.length > 0;
    },
  },
  watch: {
    '$route.name': function routeNameWatch(name) {
      if (this.items.find((i) => i.to.name === name)) {
        this.active = name;
      }
    },
    remoteControlEnabled() {
      this.fallbackToEnabledView();
    },
  },
  methods: {
    ...mapActions('groups', ['listGroups']),
    onItemClick(item) {
      if (this.active !== item.to.name && !item.disabled) {
        this.active = item.to.name;
        this.$router.push(item.to);
      }
    },
    fallbackToEnabledView() {
      if (this.remoteControlEnabled) {
        const currentItem = this.items.find((i) => i.to.name === this.active);
        const firstEnabledItem = this.items.find((i) => !i.disabled);
        if (currentItem && currentItem.disabled) {
          this.onItemClick(firstEnabledItem);
        }
      }
    },
  },
};

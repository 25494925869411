//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import SectionTitle from '@/components/ui/SectionTitle.vue';
import IconLink from '@/components/ui/IconLink.vue';

export default {
  components: { IconLink, SectionTitle },
  props: ['title', 'readOnly', 'bms', 'loading'],
  data() {
    return {
      enableCloudService: false,
      editMode: false,
      organisationName: '',
      originalOrganisationName: '',
      validationError: false,
    };
  },
  computed: {
    status() {
      return ({
        0: this.$t('bmsStatusNormal'),
        1: this.$t('bmsStatusPushing'),
        2: this.$t('bmsStatusError'),
        99: '-',
      })[((this.bms || {}).status || 99)];
    },
    dashboardURL() {
      return (this.bms.URL || '').replace('-backend', '').replace('/api/v1/auth/vivares', '');
    },
  },
  watch: {
    bms: {
      immediate: true,
      handler(bms) {
        if (!bms || !bms.username) {
          this.enableCloudService = false;
          this.organisationName = '';
          return;
        }
        this.enableCloudService = true;
        this.organisationName = bms.username;
      },
    },
  },
  methods: {
    async onEnable(enabled) {
      if (!this.editMode && enabled) {
        this.editMode = true;
      }

      if (enabled) {
        this.originalOrganisationName = this.organisationName;
      }
    },
    onCancel() {
      this.enableCloudService = !!this.originalOrganisationName;
      this.editMode = false;
      this.organisationName = this.originalOrganisationName;
    },
    onSave() {
      const data = {
        enabled: this.enableCloudService,
      };

      if (data.enabled) {
        data.organization = this.organisationName;
        if (this.validationError) {
          return;
        }
      }

      this.editMode = false;
      this.$emit('save', data);
    },
    onEdit() {
      this.originalOrganisationName = this.organisationName;
      this.editMode = true;
    },
    onValidateName() {
      this.validationError = !this.validateName(this.organisationName);
    },
    validateName(organization) {
      return /^[a-z][a-z0-9-]{0,61}[a-z0-9]$/.test(organization);
    },
  },
};

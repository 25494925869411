//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from 'vuex';
import RoomGroupRulesDefaultDetailUI from '@/components/ui/roomUI/RoomGroupRulesDefaultDetailUI.vue';

export default {
  name: 'GroupRulesDefaultDetail',
  components: {
    RoomGroupRulesDefaultDetailUI,
  },
  props: ['loading'],
  data() {
    return {
      projectId: this.$route.params.projectId,
      roomId: this.$route.params.roomId,
      groupId: this.$route.params.groupId,
      rerenderKey: 1,
    };
  },
  async created() {
    await this.fetchData();
  },
  computed: {
    ...mapGetters('groups', ['getGroupById']),
    ...mapGetters('devices', ['getDevicesByRoom']),
    ...mapGetters('projects', ['isReadOnly']),
    ...mapGetters('configs', ['getProjectConfigById']),
    ...mapGetters('rules', ['getDefaultRuleByGroup']),
    readOnly() {
      return this.isReadOnly(this.projectId);
    },
    group() {
      return this.getGroupById(this.groupId);
    },
    mode() {
      return this.group.defaultBehaviour || 'manual';
    },
    projectConfig() {
      return this.getProjectConfigById(this.projectId);
    },
    groupSensors() {
      if (!this.projectConfig?.devices) {
        return [];
      }
      const room = (this.projectConfig?.rooms || []).find((r) => r.uuid === this.roomId);
      if (!room) {
        return [];
      }
      return (this.projectConfig?.devices?.sensors || []).filter((sensor) => this.group.sensors.includes(sensor.uuid));
    },
    lights() {
      return this.getDevicesByRoom(this.projectId, this.roomId).lights;
    },
    hclLightWarning() {
      return this.lights.filter((light) => this.group.members.includes(light.uuid) && !light.product.capabilities.hcl).length > 0;
    },
    defaultRule() {
      return this.getDefaultRuleByGroup(this.groupId);
    },
  },
  methods: {
    ...mapActions('rules', ['getDefaultGroupRule', 'updateDefaultGroupRule']),
    ...mapActions('groups', ['listGroups']),
    ...mapActions('configs', ['getProjectConfig']),
    async fetchData() {
      await this.listGroups({ uuid: this.projectId, roomId: this.roomId });
      await this.getDefaultGroupRule({ uuid: this.projectId, roomId: this.roomId, groupId: this.groupId });
      await this.getProjectConfig({ uuid: this.projectId });
      this.rerenderKey += 1;
    },
    async saveDefaultSettings(data) {
      await this.updateDefaultGroupRule({
        uuid: this.projectId,
        roomId: this.roomId,
        groupId: this.groupId,
        data,
      });
      await this.fetchData();
    },
  },
};

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapGetters } from 'vuex';
import FloorplanDetailsUI from '@/components/ui/floorplan/FloorplanDetailsUI.vue';

export default {
  props: ['floorplan'],
  components: {
    FloorplanDetailsUI,
  },
  data() {
    return {
      projectId: this.$route.params.projectId,
      disableCreate: false,
    };
  },
  computed: {
    ...mapGetters('rooms', ['getRoomsByProjectId']),
    rooms() {
      return this.getRoomsByProjectId(this.projectId);
    },
  },
  methods: {
    ...mapActions('projects', ['updateFloorplan']),
    async onEditClicked() {
      this.disableCreate = true;
      try {
        const data = await this.$refs.form.onSave.bind(this.$refs.form)();
        if (!data) {
          return;
        }
        const [floorplan] = data;
        const { room: roomUUID, name } = floorplan;
        let { order } = this.floorplan;
        const originalRoom = this.floorplan.room;
        if (originalRoom !== roomUUID) {
          order = 1;
        }
        await this.updateFloorplan({
          uuid: this.projectId,
          attachmentUUID: floorplan.attachment,
          data: { name, roomUUID, order },
        });
        this.$bvModal.hide('edit-floorplan-modal');
        this.$emit('saved');
      } finally {
        this.disableCreate = false;
      }
    },
    onStateChanged({ enableUpload }) {
      this.disableCreate = !enableUpload;
    },
    onCancel() {
      this.$bvModal.hide('edit-floorplan-modal');
    },
  },
};

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { ValidationObserver, ValidationProvider } from 'vee-validate';
import CustomModal from '@/components/ui/CustomModal.vue';

export default {
  name: 'ContactRow', // Forked from VueInlineTextEditor
  components: {
    CustomModal,
    ValidationObserver,
    ValidationProvider,
  },
  props: {
    autofocus: {
      type: Boolean,
      default: false,
    },
    closeOnBlur: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    hoverEffects: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      required: false,
    },
    errors: {
      type: Array,
      required: false,
    },
    readOnly: {
      type: Boolean,
    },
    hideEditButton: {
      type: Boolean,
    },
    contactDetails: {
      type: Object,
      required: true,
    },
    contactIndex: {
      type: Number,
    },
    hideDeleteButton: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      editing: false,
      loaded: false,
      deleteContactModal: false,
      internalContactDetails: { ...this.contactDetails },
      contactFields: [{
        type: 'name',
        placeholder: this.$t('projectContactName'),
      }, {
        type: 'email',
        placeholder: this.$t('projectContactEmail'),
      }, {
        type: 'phone',
        placeholder: this.$t('projectContactPhone'),
      }, {
        type: 'description',
        placeholder: this.$t('projectContactDescription'),
      }],
    };
  },
  computed: {
    classes() {
      const classNames = [];
      if (this.hoverEffects) {
        classNames.push('hover-effects');
      }
      if (this.editing) {
        classNames.push('editing');
      }
      if (this.disabled) {
        classNames.push('disabled');
      }
      return classNames.join(' ');
    },
    deleteContactModalProps() {
      return {
        title: this.$t('projectContactDelete'),
        message: this.$t('projectContactDeleteMessage'),
        buttons: {
          main: {
            title: this.$t('generalDelete'),
            click: () => {
              this.updateValue(true);
            },
          },
          secondary: {
            title: this.$t('generalCancel'),
            click: () => {
              // eslint-disable-next-line vue/no-side-effects-in-computed-properties
              this.deleteContactModal = false;
            },
          },
        },
      };
    },
  },
  watch: {
    contactDetails(newObject) {
      this.internalContactDetails = { ...newObject };
      this.loaded = true;
    },
  },
  mounted() {
    this.loaded = true;
  },
  methods: {
    cancelEdit() {
      this.internalContactDetails = { ...this.contactDetails };
      this.editing = false;
    },
    editValue(type) {
      if (this.disabled || this.readOnly || !this.loaded) {
        return;
      }
      this.editing = true;
      // Set the focus to the input
      window.setTimeout(() => {
        this.focus(type);
      }, 10);
    },
    focus(ref) {
      const targetRef = ref ? this.$refs[ref] : this.$refs.name;
      this.$nextTick(() => {
        if (this.$refs && targetRef) {
          targetRef.focus();
        }
      });
    },
    async updateValue(deleteContact = false) {
      const isValid = await this.$refs.observer.validate();
      if (!isValid) {
        return;
      }
      if (deleteContact) {
        this.deleteContactModal = false;
      }
      this.$emit('update', this.internalContactDetails, this.contactIndex, deleteContact);
      this.editing = false;
    },
    formatPhoneNumber() {
      this.internalContactDetails.phone = this.internalContactDetails.phone.replace(/[^\d+]/, '');
    },
  },
};

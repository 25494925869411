//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import AdvancedTable from '@/components/ui/AdvancedTable.vue';

export default {
  name: 'RoomGroupsUI',
  props: ['groups', 'loading', 'route', 'readOnly'],
  components: {
    AdvancedTable,
  },
  data() {
    return {
      headers: [{
        title: 'Name', key: 'name', type: 'text', sortable: true, searchable: true,
      }, {
        title: 'Description', key: 'description', type: 'text', sortable: true, searchable: true,
      }],
    };
  },
  computed: {
    createIllustration() {
      return {
        // eslint-disable-next-line global-require
        icon: require('../../../assets/icons/light_group_illustration.svg'),
        texts: this.readOnly ? [this.$t('generalNoItems')] : [this.$t('generalNoItems'), this.$t('generalCreateMessage')],
        button: this.$t('roomGroupsCreateGroup'),
      };
    },
  },
  methods: {
    onDelete(group) {
      this.$emit('delete', group);
    },
    onOpenItem(group) {
      this.$router.push(`${this.route}/${group.uuid}`);
    },
    onCreateItem() {
      this.$bvModal.show('add-group-modal');
    },
  },
};

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

/* eslint-disable */
const STATUS_ICONS = {
  PLANNING: require('../../../assets/icons/projects/planning.svg'), // new project, until at least 1 device is scanned
  SCANNING_QR_CODES: require('../../../assets/icons/projects/scanning_info.svg'), // at least 1 device is scanned
  READY_TO_UPLOAD: require('../../../assets/icons/projects/ready_to_upload.svg'), // all devices are scanned
  UPLOADED_TO_GATEWAY: require('../../../assets/icons/projects/uploaded_to_gateway.svg') // gateway is connected to the project / config is uploaded
};
/* eslint-enable */
export default {
  name: 'ProjectsGridView',
  props: {
    projectList: {
      type: Array,
      required: true,
    },
  },
  computed: {
    computedProjectList() {
      return this.projectList.map((project) => {
        let { status } = project;

        if (!status || !STATUS_ICONS[status]) {
          status = 'PLANNING';
        }

        project.statusObject = {
          text: this.$t(`projectsStatus_${status}`),
          icon: STATUS_ICONS[status],
        };

        return project;
      });
    },
  },
  methods: {
    handleProjectClick(project) {
      this.$emit('onProjectClick', project);
    },
    canIDeleteProject(project) {
      return !project.locked && !project.gatewayConnectedToCloud && this.canI(project, 'delete');
    },
    handleProjectDelete(project) {
      this.$emit('onProjectDelete', project);
    },
    canI(project, action, subject = 'projects') {
      const { permissions } = project.meta;
      return permissions.some((item) => item.action === action && item.subject === subject);
    },
  },
};

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapGetters } from 'vuex';
import ListItem from '@/components/ui/ListItem.vue';

export default {
  name: 'WizardRoom',
  components: {
    ListItem,
  },
  async created() {
    this.onGetRooms();
  },
  computed: {
    ...mapGetters('projects', ['getProjectById']),
    ...mapGetters('rooms', ['getRoomsByProjectId']),
    projectId() {
      return this.$route.params.projectId;
    },
    project() {
      return this.getProjectById(this.projectId);
    },
    rooms() {
      return [...this.getRoomsByProjectId(this.projectId)].map((room) => ({ ...room }));
    },
  },
  methods: {
    ...mapActions('rooms', ['listRooms']),
    async onGetRooms() {
      await this.listRooms(this.projectId);
    },
    countDevices(property) {
      return this.rooms.reduce((acc, room) => acc + room[property].length, 0);
    },
  },
};

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapGetters } from 'vuex';
import _ from 'lodash';
import ConsentModals from '../components/ui/consents/ConsentModals.vue';

export default {
  name: 'AnalyticsDataCollection',
  components: {
    ConsentModals,
  },
  data() {
    return {
      checked: 'not_accepted',
      consents: [],
      modalKey: 1,
      loading: false,
      debounceUpdate: _.debounce((event) => {
        const analyticsEnabled = event === 'accepted';
        this.updateUser({ analyticsEnabled }).finally(() => {
          this.getProfile().finally(() => {
            this.loading = false;
          });
        });
      }, 500),
    };
  },
  methods: {
    ...mapActions('user', ['updateUser', 'getProfile']),
    update(event, force = false) {
      if (this.loading) {
        return;
      }

      if (event === 'accepted' && !force) {
        this.consents = ['analytics'];
        this.modalKey += 1;
        this.$nextTick(() => {
          this.checked = 'not_accepted';
        });
        return;
      }
      this.checked = event;
      this.loading = true;
      this.debounceUpdate(event);
    },
    consentDone(params) {
      this.consents = [];
      this.update(params.analyticsEnabled ? 'accepted' : 'not_accepted', true);
    },
  },
  computed: {
    ...mapGetters('user', ['getProfileData']),
  },
  watch: {
    getProfileData: {
      handler() {
        this.checked = this.getProfileData.analyticsEnabled ? 'accepted' : 'not_accepted';
      },
      immediate: true,
    },
  },
};

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { ValidationObserver } from 'vee-validate';
import InputField from '@/components/ui/InputField.vue';

export default {
  name: 'RoomGroupsUI',
  props: ['rooms', 'floorplan'],
  components: {
    ValidationObserver,
    InputField,
  },
  data() {
    return {
      data: {},
      files: [],
    };
  },
  created() {
    if (this.floorplan) {
      this.files = [{
        url: this.floorplan.urls.large,
        name: this.floorplan.name,
        room: this.floorplan.roomUUID || 'project',
        attachment: this.floorplan.attachmentUUID,
      }];
    }
  },
  computed: {
    options() {
      return [
        { value: 'project', text: this.$t('generalProject') },
        ...this.rooms.map((room) => ({ value: room.uuid, text: room.name })),
      ];
    },
  },
  methods: {
    async onSave() {
      const valid = await this.$refs.observer.validate();
      if (!valid) {
        return null;
      }
      return this.files.map((file) => ({ ...file, room: file.room === 'project' ? undefined : file.room }));
    },
    onFilesSelected(e) {
      this.files = Array.from(e.target.files)
        .map((file) => ({ file, url: URL.createObjectURL(file), room: 'project' }));
      if (this.files.length) {
        this.$emit('stateChanged', { enableUpload: true });
      }
    },
  },
};

import axios from 'axios';
import store from '../store';
import router from '../router';
import { logEvent } from '../firebase';

axios.defaults.baseURL = store.getters['locale/getApiUrl'];
axios.defaults.headers.common['Content-Type'] = 'application/json';
axios.defaults.responseType = 'json';
axios.defaults.withCredentials = true;
axios.interceptors.request.handlers = [];
axios.interceptors.response.handlers = [];

const getLockToken = async (uuid, forceLock) => {
  const lock = store.state.projects.projectLocks[uuid];
  if (!(lock && lock.token) || forceLock) {
    await store.dispatch('projects/lockProject', { uuid });
    return store.state.projects.projectLocks[uuid].token;
  }
  return lock.token;
};

const renewAccessToken = async () => {
  const { refreshToken } = store.state.auth;
  await store.dispatch('auth/renewToken', { refreshToken });
};

const logout = async () => {
  await router.push({ name: 'logout' }).catch(() => {});
};

const logEventHandler = async (response, params = {}) => {
  if (!response || !response.config) {
    return;
  }
  const { logEvent: getLogEvent } = response.config;
  if (getLogEvent && typeof getLogEvent === 'function') {
    const event = getLogEvent();
    if (event && event.eventName) {
      logEvent(event.eventName, { ...params, ...(event.eventParams || {}) });
    }
  }
};

axios.interceptors.request.use(async (request) => {
  await store.restored;
  const { locale } = store.state.locale;
  const needProjectLock = !!request.lock;

  if (needProjectLock) {
    const lockToken = await getLockToken(request.lock.uuid);
    request.headers['X-Vivares-Project-Lock-Token'] = lockToken;
  }

  request.headers['accept-Language'] = locale;

  return request;
});

axios.interceptors.response.use(async (response) => {
  await logEventHandler(response, { success: 'successful' });
  return response;
}, async (error) => {
  if (error.seen) {
    throw error;
  }
  if (error.name === 'CanceledError') {
    throw error;
  }
  console.error(error);
  if (!error.response || !error.response.status) {
    throw error;
  }
  await logEventHandler(error.response, { success: 'unsuccessful' });
  const { status } = error.response;
  const { errorCode } = error.response.data;
  const originalRequest = error.response.config;

  originalRequest._count = originalRequest._count || 0;
  originalRequest._count += 1;

  if (originalRequest.skipGlobalErrorHandling) {
    error.seen = true;
    throw error;
  }

  // Do not handle, when logout request fails
  if (originalRequest.isLogout) {
    return true;
  }

  if (status === 404) {
    await router.push({ name: 'error-404' });
  } else if (status === 401) {
    let forceLock = false;
    switch (errorCode) {
      case 'invalid-user':
        await logout();
        break;
      case 'project-is-already-locked':
      case 'project-edit-denied':
      case 'project-lock-expired':
      case 'project-lock-tokens-mismatch':
        // Project lock errors are handled in store/modules/projects
        break;
      case 'project-is-not-locked':
        if (originalRequest.lock.preventRelock) {
          break;
        }
        forceLock = true;
        await getLockToken(originalRequest.lock.uuid, forceLock);
        await axios.request(originalRequest);
        break;
      default:
        if (originalRequest.preventRefreshing) {
          await logout();
          break;
        }
        if (originalRequest._count > 3) {
          break;
        }
        await renewAccessToken();
        return axios.request(originalRequest);
    }
  } else if (status === 423) {
    // Handle "locked (423)" statuses in the corresponding vuex actions
    error.seen = true;
    error.resourceLocked = true;
    throw error;
  } else {
    switch (errorCode) {
      case 'email-address-is-already-used':
        break;
      default:
        await store.dispatch('app/setError', error.response.data);
    }
  }
  error.seen = true;
  throw error;
});

window.axios = axios;

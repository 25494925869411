import groupService from '../services/groups';

const initialState = {
  groups: {},
  groupsById: {},
};

const actions = {
  async listGroups({ commit }, { uuid, roomId }) {
    const { data } = await groupService.listGroups({ uuid, roomId });
    commit('listGroups', { uuid, roomId, data });
  },
  async createGroup(ctx, { uuid, roomId, data }) {
    const response = await groupService.createGroup({ uuid, roomId, data });
    return response.data;
  },
  async updateGroup({ commit }, {
    uuid, roomId, groupId, data,
  }) {
    const response = await groupService.updateGroup({
      uuid, roomId, groupId, data,
    });
    commit('getGroup', { data: response.data, groupId });
  },
  async deleteGroup(ctx, { uuid, roomId, groupId }) {
    await groupService.deleteGroup({ uuid, roomId, groupId });
  },
  async addDevicesToGroup(ctx, {
    uuid, roomId, groupId, deviceIds,
  }) {
    const results = [];
    for (let i = 0; i < deviceIds.length; i += 1) {
      const deviceId = deviceIds[i];
      results.push(groupService.addDeviceToGroup({
        uuid, roomId, groupId, deviceId,
      }));
    }
    await Promise.all(results);
  },
  async deleteDeviceFromGroup(ctx, {
    uuid, roomId, groupId, deviceId,
  }) {
    await groupService.deleteDeviceFromGroup({
      uuid, roomId, groupId, deviceId,
    });
  },
};

const mutations = {
  listGroups(state, payload) {
    const groups = { ...state.groups };
    groups[payload.uuid] = state.groups[payload.uuid] || {};
    groups[payload.uuid][payload.roomId] = payload.data;
    state.groups = groups;
    state.groupsById = payload.data.reduce((acc, item) => ({ ...acc, [item.uuid]: item }), {});
  },
  getGroup(state, payload) {
    state.groupsById = { ...state.groupsById, [payload.groupId]: payload.data };
  },
};

const getters = {
  getGroupsByProject: (state) => (uuid) => state.groups[uuid] || {},
  getGroupsByRoom: (state) => (uuid, roomId) => {
    if (state.groups[uuid]) {
      return (state.groups[uuid][roomId] || []).map((g) => ({ ...g, members: g.members || [] }));
    }
    return [];
  },
  getGroupById: (state) => (groupId) => state.groupsById[groupId] || { product: {} },
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  mutations,
};

//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from 'vuex';
import RoomScenesUI from '@/components/ui/roomUI/RoomScenesUI.vue';
import AddSceneModal from './modals/AddSceneModal.vue';
import DeleteSceneModal from './modals/DeleteSceneModal.vue';

export default {
  name: 'RoomScenes',
  components: {
    AddSceneModal,
    DeleteSceneModal,
    RoomScenesUI,
  },
  data() {
    return {
      dataLoaded: false,
      projectId: this.$route.params.projectId,
      roomId: this.$route.params.roomId,

      name: '',
      description: '',

      selectedScene: '',
      selectedUuid: '',
    };
  },
  created() {
    this.listRooms(this.projectId);
    this.onListScenes();
  },
  computed: {
    ...mapGetters('rooms', ['getRoomById']),
    ...mapGetters('scenes', ['getScenesByRoom']),
    ...mapGetters('projects', ['getProjectById', 'isReadOnly']),
    readOnly() {
      return this.isReadOnly(this.projectId);
    },
    scenes() {
      return this.getScenesByRoom(this.projectId, this.roomId);
    },
  },
  methods: {
    ...mapActions('scenes', ['listScenes', 'createScene', 'deleteScene']),
    ...mapActions('rooms', ['listRooms']),
    async onListScenes() {
      await this.listScenes({ uuid: this.projectId, roomId: this.roomId });
      this.dataLoaded = true;
    },
    openDeleteModal(scene) {
      this.selectedScene = scene;
      this.selectedUuid = scene.uuid;
      this.$bvModal.show('delete-scene-modal');
    },
    async onDeleteScene() {
      await this.deleteScene({ uuid: this.projectId, roomId: this.roomId, sceneId: this.selectedUuid });
      await this.onListScenes();
      this.$bvModal.hide('delete-scene-modal');
    },
  },
};

/* global axios */

const listScenes = async ({ uuid, roomId }) => axios({
  method: 'GET',
  url: `/projects/${uuid}/rooms/${roomId}/scenes`,
});

const getScene = async ({ uuid, roomId, sceneId }) => axios({
  method: 'GET',
  url: `/projects/${uuid}/rooms/${roomId}/scenes/${sceneId}`,
});

const createScene = async ({ uuid, roomId, data }) => axios({
  method: 'POST',
  url: `/projects/${uuid}/rooms/${roomId}/scenes`,
  lock: { uuid },
  data,
  logEvent: () => ({ eventName: 'create_scene' }),
});

const updateScene = async ({
  uuid, roomId, sceneId, data,
}) => axios({
  method: 'PUT',
  url: `/projects/${uuid}/rooms/${roomId}/scenes/${sceneId}`,
  lock: { uuid },
  data,
  logEvent: () => ({ eventName: 'edit_scene' }),
});

const deleteScene = async ({ uuid, roomId, sceneId }) => axios({
  method: 'DELETE',
  url: `/projects/${uuid}/rooms/${roomId}/scenes/${sceneId}`,
  lock: { uuid },
  data: {},
  logEvent: () => ({ eventName: 'delete_scene' }),
});

export default {
  listScenes,
  getScene,
  createScene,
  updateScene,
  deleteScene,
};

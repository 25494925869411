//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import PhoneNumber from 'awesome-phonenumber';
import { mapGetters } from 'vuex';

export default {
  props: ['value', 'placeholder', 'label', 'tooltip', 'simpleInputLabel'],
  name: 'PhoneNumberInput',
  data() {
    return {
      internalValue: null,
      errors: [],
      country: null,
      isValid: false,
      updated: false,
    };
  },
  created() {
    if (this.value) {
      const { country, input, isValid } = this.getPhoneNumberFromInput(null, this.value);
      this.isValid = isValid;
      this.country = country;
      this.internalValue = input;
    }
  },
  computed: {
    ...mapGetters('locale', ['getCountryList']),
    countries() {
      return [{ value: null, text: 'Code', disabled: true }, ...this.getCountryList.map((country) => ({
        text: `+${PhoneNumber.getCountryCodeForRegionCode(country.code)} (${country.code.toUpperCase()})`,
        value: country.code,
      })).sort((a, b) => a.text.localeCompare(b.text))];
    },
  },
  methods: {
    getPhoneNumberFromInput(code, val) {
      let phoneNumber = new PhoneNumber(val);

      if (!code && !phoneNumber.isValid()) {
        return {
          isValid: false,
          country: null,
          input: val,
          value: val,
        };
      }

      if (code && !phoneNumber.isValid()) {
        phoneNumber = new PhoneNumber(`+${PhoneNumber.getCountryCodeForRegionCode(code)}${val}`);
        if (!phoneNumber.isValid()) {
          return {
            isValid: false,
            country: null,
            input: val,
            value: val,
          };
        }
      }

      const phoneNumberInput = phoneNumber.getNumber('international');
      const country = phoneNumber.getRegionCode()?.toLowerCase();
      const countryCode = phoneNumber.getCountryCode();
      const input = phoneNumberInput.replace(`+${countryCode}`, '');
      return {
        isValid: true,
        country,
        input,
        value: phoneNumber.getNumber(),
      };
    },
    formatInput() {
      if (!this.internalValue) {
        return;
      }
      // replace 00 with +
      this.internalValue = this.internalValue.replace(/^00/, '+');

      const res = this.getPhoneNumberFromInput(this.country, this.internalValue);
      this.updated = true;
      this.isValid = res.isValid;
      if (res.isValid) {
        this.country = res.country;
        this.internalValue = res.input;
      }
      this.$emit('input', res.value);
    },
    onCountryChange() {
      this.formatInput();
    },
  },
};

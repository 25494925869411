//
//
//
//
//
//
//
//
//
//
//
//
//

import FloorplanViewer from '@/components/ui/floorplan/FloorplanViewer.vue';
import FloorplanModal from '@/components/ui/floorplan/FloorplanModal.vue';

export default {
  name: 'NavFloorplan',
  props: {
    openFloorplan: Boolean,
  },
  components: {
    FloorplanModal,
    FloorplanViewer,
  },
  data() {
    return {
      title: this.$t('floorplanFloorplan'),
      modalIndex: null,
    };
  },
  methods: {
    close() {
      this.$emit('update', false);
    },
    changeTitle(name) {
      this.title = name;
    },
    setModalIndex(index) {
      this.modalIndex = index;
    },
  },
};

/* global axios */

const listProjects = async (params, axiosOpts) => axios({
  method: 'GET',
  url: '/projects',
  params,
  ...axiosOpts,
});

const getProject = async (uuid) => axios({
  method: 'GET',
  url: `/projects/${uuid}`,
});

// data: { name, address, description, contacts }
const createProject = async (data) => axios({
  method: 'POST',
  url: '/projects',
  data,
  logEvent: () => ({ eventName: 'create_project' }),
});

const updateProject = async ({
  uuid, data, forceLock = false, contactsUpdated = false,
}) => axios({
  method: 'PUT',
  url: `/projects/${uuid}`,
  data,
  lock: forceLock ? { uuid } : undefined,
  logEvent: () => ({ eventName: contactsUpdated ? 'add_contact' : 'edit_project' }),
});

const deleteProject = async (uuid) => axios({
  method: 'DELETE',
  url: `/projects/${uuid}`,
  lock: { uuid },
  logEvent: () => ({ eventName: 'delete_project' }),
});

const lockProject = async ({ uuid, lockTTLInMinutes }) => axios({
  method: 'POST',
  url: `/projects/${uuid}/lock`,
  data: { lockTTLInMinutes },
  logEvent: () => ({ eventName: 'lock_project' }),
});

const renewProjectLock = async ({ uuid, lockTTLInMinutes }) => axios({
  method: 'POST',
  url: `/projects/${uuid}/lock/renew`,
  data: { lockTTLInMinutes },
  lock: { uuid, preventRelock: true },
});

const unlockProject = async (uuid) => axios({
  method: 'DELETE',
  url: `/projects/${uuid}/lock`,
  lock: { uuid, preventRelock: true },
  logEvent: () => ({ eventName: 'unlock_project' }),
});

const forceUnlockProject = async (uuid) => axios({
  method: 'DELETE',
  url: `/projects/${uuid}/lock/forced`,
  skipGlobalErrorHandling: true,
  logEvent: () => ({ eventName: 'force_unlock' }),
});

const uploadFloorplan = async ({ uuid, roomUUID, file }) => axios({
  method: 'POST',
  url: roomUUID ? `/projects/${uuid}/rooms/${roomUUID}/floorplans` : `/projects/${uuid}/floorplans`,
  headers: {
    'Content-Type': 'multipart/form-data',
  },
  data: file,
  logEvent: () => ({ eventName: roomUUID ? 'upload_room_floorplan' : 'upload_project_floorplan' }),
});

const deleteProjectFloorplan = async (uuid, attachmentUUID) => axios({
  method: 'DELETE',
  url: `/projects/${uuid}/floorplans/${attachmentUUID}`,
  logEvent: () => ({ eventName: 'delete_project_floorplan' }),
});

const deleteRoomFloorplan = async (uuid, roomUUID, attachmentUUID) => axios({
  method: 'DELETE',
  url: `/projects/${uuid}/rooms/${roomUUID}/floorplans/${attachmentUUID}`,
  logEvent: () => ({ eventName: 'delete_room_floorplan' }),
});

const updateFloorplan = async (uuid, attachmentUUID, data) => axios({
  method: 'PUT',
  url: `/projects/${uuid}/floorplans/${attachmentUUID}`,
  data,
  logEvent: () => ({ eventName: 'update_floorplan' }),
});

const getConfig = async (uuid) => axios({
  method: 'GET',
  url: `/projects/${uuid}/config`,
});

const updateConfig = async ({ uuid, config }) => axios({
  method: 'PUT',
  url: `/projects/${uuid}/config`,
  data: config,
  lock: { uuid },
});

const updateWipConfig = async ({ uuid, revision }) => axios({
  method: 'PATCH',
  url: `/projects/${uuid}/config/revision/${revision}/upload`,
  data: {},
  lock: { uuid },
});

const enterEditMode = async ({ uuid }) => axios({
  method: 'PATCH',
  url: `/projects/${uuid}/enter-edit-mode`,
  data: {},
  lock: { uuid },
  logEvent: () => ({ eventName: 'enter_edit_mode' }),
});

const cancelEditMode = async ({ uuid, subjectConfigRevision }) => axios({
  method: 'PATCH',
  url: `/projects/${uuid}/cancel-edit-mode`,
  data: { subjectConfigRevision },
  lock: { uuid },
  logEvent: () => ({ eventName: 'cancel_edit_mode' }),
});

const commitEditMode = async ({ uuid, subjectConfigRevision }) => axios({
  method: 'PATCH',
  url: `/projects/${uuid}/commit-edit-mode`,
  data: { subjectConfigRevision },
  lock: { uuid },
  logEvent: () => ({ eventName: 'commit_edit_mode' }),
});

const getConfigDiff = async ({ uuid }) => axios({
  method: 'GET',
  url: `/projects/${uuid}/edit-mode-diff`,
});

const downloadDocument = async ({ uuid, type }) => axios({
  method: 'GET',
  responseType: 'blob',
  url: `/projects/${uuid}/documents/${type}`,
});

const getSiteWorx = async ({ uuid }) => axios({
  method: 'GET',
  url: `/projects/${uuid}/gateway/siteworx-cloud`,
  skipGlobalErrorHandling: true,
});

const editSiteWorx = async ({ uuid, enabled, organization }) => axios({
  method: 'PUT',
  url: `/projects/${uuid}/gateway/siteworx-cloud`,
  data: { enabled, organization },
  lock: { uuid },
  logEvent: () => ({ eventName: 'bms_organization_change', eventParams: { enabled, organization } }),
});

const getTimeZone = async ({ latitude, longitude }) => axios({
  method: 'POST',
  url: '/projects/utils/timezone',
  data: { latitude, longitude },
});

export default {
  listProjects,
  getProject,
  createProject,
  updateProject,
  deleteProject,
  lockProject,
  renewProjectLock,
  unlockProject,
  forceUnlockProject,
  uploadFloorplan,
  deleteProjectFloorplan,
  deleteRoomFloorplan,
  updateFloorplan,
  getConfig,
  updateConfig,
  updateWipConfig,
  enterEditMode,
  cancelEditMode,
  commitEditMode,
  getConfigDiff,
  downloadDocument,
  getSiteWorx,
  editSiteWorx,
  getTimeZone,
};

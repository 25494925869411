//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { ValidationProvider } from 'vee-validate';
import nl2br from 'vue-nl2br';
import _defaults from 'lodash/defaults';
import { mapActions, mapGetters, mapState } from 'vuex';
import NumberField from '@/components/ui/NumberField.vue';
import ViewSelector from '@/components/ui/ViewSelector.vue';
import SearchField from '@/components/ui/SearchField.vue';
import CustomTable from '@/components/ui/CustomTable.vue';

export default {
  props: ['template'],
  name: 'TemplateVariables',
  components: {
    ValidationProvider,
    nl2br,
    NumberField,
    ViewSelector,
    SearchField,
    CustomTable,
  },
  data() {
    return {
      errors: [],
      templateValue: {},
      templateField: {
        fields: [],
      },
      selectedField: {},
      sortFields: [
        { sort: 'name-asc', text: `${this.$t('product.name')} ${this.$t('generalAsc')}`, selected: true },
        { sort: 'name-desc', text: `${this.$t('product.name')} ${this.$t('generalDesc')}` },
      ],
      properties: ['ean', 'vendor'],
      searchText: '',
      sort: 'name-asc',
    };
  },
  watch: {
    template() {
      this.onTemplateChange();
    },
    templateValue: {
      deep: true,
      handler() {
        this.$emit('change', this.templateData);
      },
    },
  },
  async created() {
    this.onTemplateChange();
    await this.listProducts();
  },
  computed: {
    ...mapState('app', ['selectedView']),
    ...mapGetters('products', ['getProductById', 'getProductsByCategory']),
    templateData() {
      return {
        uuid: this.template.uuid,
        locale: 'en',
        variables: Object
          .entries(this.templateValue)
          .map(([name, value]) => ({ name, value })),
      };
    },
    mappedDevices() {
      return this.getProductsByCategory(this.selectedField.category)
        .filter((device) => (device.name || '').toLowerCase().includes(this.searchText.toLowerCase()) || (device.ean || '').toLowerCase().includes(this.searchText.toLowerCase()))
        .sort((a, b) => {
          switch (this.sort) {
            case 'name-asc':
              return a.name.localeCompare(b.name);
            case 'name-desc':
              return -a.name.localeCompare(b.name);
            default:
              return a.name.localeCompare(b.name);
          }
        });
    },
  },
  methods: {
    ...mapActions('products', ['listProducts']),
    ...mapActions('app', ['changeListView']),
    onChangeView(view) {
      this.changeListView(view);
    },
    onFilterChange(e) {
      this.sort = e.sort;
      this.searchText = e.searchText;
    },
    openProductModal(field) {
      this.resetFilter();
      this.$bvModal.show('product-select-modal');
      this.selectedField = field;
    },
    selectDevice(device) {
      this.templateValue[this.selectedField.name] = device.uuid;
      this.$emit('change', this.templateData);
      this.$bvModal.hide('product-select-modal');
    },
    getProductName(item) {
      return this.getProductById(item).name;
    },
    onTemplateChange() {
      const item = this.template;
      const configBody = this.template.config.body || {};
      this.templateField = {
        name: (configBody.template && configBody.template.name) || item.name,
        uuid: item.uuid,
        description: (configBody.template && configBody.template.description) || item.description,
        revision: item.revision,
        fields: [],
      };
      this.templateValue = {};
      (configBody.variables || item.config.variables).forEach((field) => {
        // create template values variables and assign the default values from the template
        this.templateValue = { ...this.templateValue, [field.name]: field.default || null };

        // attach fields and infer default validators according to field type
        if (field.type === 'integer') {
          field.rules.integer = true;
        }

        if (field.type === 'percent') {
          _defaults(field.rules, {
            integer: true,
            min_value: 0,
            max_value: 100,
          });
        }

        if (field.type === 'string') {
          _defaults(field.rules, { max: 1024 });
        }

        this.templateField.fields.push(field);
      });

      this.$emit('change', this.templateData);
    },
    resetFilter() {
      this.searchText = '';
      this.sort = 'name-asc';
    },
  },
};

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapGetters } from 'vuex';
import CustomTable from '@/components/ui/CustomTable.vue';

export default {
  name: 'TemplateList',
  data() {
    return {
      loading: false,
    };
  },
  components: {
    CustomTable,
  },
  async created() {
    this.loading = true;
    await this.listTemplates({ scopes: ['user', 'global'] });
    this.loading = false;
  },
  computed: {
    ...mapGetters('templates', ['getTemplatesByScope']),
    defaultTemplates() {
      return this.getTemplatesByScope(['global']);
    },
    customTemplates() {
      return this.getTemplatesByScope(['user']);
    },
  },
  methods: {
    ...mapActions('templates', ['listTemplates']),
  },

};

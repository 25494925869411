//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import SectionTitle from '@/components/ui/SectionTitle.vue';

export default {
  name: 'MoveDevicesModal',
  components: { SectionTitle },
  props: ['category', 'deviceIndexes', 'rooms', 'groups'],
  data() {
    return {
      currentStep: 1,
      selectedRoom: '',
      selectedGroup: '',
      disabled: false,
      deviceResetAccepted: false,
    };
  },
  computed: {
    multipleRooms() {
      return this.rooms?.length > 1;
    },
    roomOptions() {
      return this.rooms?.reduce((acc, item) => [...acc, {
        text: item.name,
        value: item.uuid,
      }], []);
    },
    groupOptions() {
      const defaultOption = [{ text: this.$t('roomDevicesMoveDevicesSelectGroup'), value: '', selected: true }];
      return this.groups[this.selectedRoom].reduce((acc, item) => [...acc, {
        text: item.name,
        value: item.uuid,
      }], defaultOption);
    },
    disabledNext() {
      return this.selectedRoom === this.$route.params.roomId;
    },
    hasGroup() {
      return this.groups && this.selectedRoom && this.groups[this.selectedRoom].length > 0 && this.category !== 'switches';
    },
    isSwitch() {
      return this.category === 'switches';
    },
    isSensor() {
      return this.category === 'sensors';
    },
    isLight() {
      return this.category === 'lights';
    },
    hasNextStep() {
      return this.hasGroup && this.selectedGroup.length === 0 && !this.isSwitch;
    },
  },
  methods: {
    hideMoveDevicesModal() {
      this.$bvModal.hide(`move-devices-modal-${this.category}`);
    },
    nextStep() {
      if (this.hasGroup && this.currentStep === 1) {
        this.currentStep = 2;
      } else {
        this.$emit('move-devices', this.selectedRoom, this.selectedGroup);
        this.disabled = true;
      }
    },
    resetModal() {
      this.currentStep = 1;
      this.selectedRoom = this.$route.params.roomId;
      this.selectedGroup = '';
      this.disabled = false;
      this.deviceResetAccepted = false;
    },
  },
};

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import SectionTitle from '@/components/ui/SectionTitle.vue';
import MaintenanceErrorDetails from '@/components/ui/MaintenanceErrorDetails.vue';

export default {
  name: 'RoomRulesDefaultUI',
  components: { SectionTitle, MaintenanceErrorDetails },
  props: ['rules', 'loading', 'route', 'readOnly', 'roomSensors', 'mode', 'healthIssues'],
  data() {
    return {
      projectId: this.$route.params.projectId,
      roomId: this.$route.params.roomId,
    };
  },
  computed: {
    dlhEnabled() {
      return this.rules[0]?.actions?.value?.['dlh-enabled'];
    },
    hclEnabled() {
      return this.rules[0]?.actions?.value?.['hcl-enabled'];
    },
    modeLocale() {
      if (this.mode === 'manual') {
        return this.$t('roomRulesDefaultModeManual');
      }
      if (this.mode === 'semi-automatic') {
        return this.$t('roomRulesDefaultModeSemi');
      }
      if (this.mode === 'automatic') {
        return this.$t('roomRulesDefaultModeAutomatic');
      }
      return '';
    },
  },
};

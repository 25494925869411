import dayjs from 'dayjs';
import utc from 'dayjs-plugin-utc';
import timezones from './timezones.json';

const timezone = require('dayjs/plugin/timezone');
const ct = require('countries-and-timezones');

dayjs.extend(utc);
dayjs.extend(timezone);
// dayjs.extend(require('dayjs-timezone-iana-plugin'));

const CHANNELS = ['one', 'two', 'three', 'four'];
const defaultLevel = 100;
const defaultCCT = 4000;
const getDefaultButtons = (isEmpty) => CHANNELS.reduce((acc, key, i) => {
  const events = {
    pressed: { action: '', value: { on: true, level: defaultLevel, cct: defaultCCT } },
    hold_pressed: { action: '', value: { on: false, level: defaultLevel, cct: defaultCCT } },
    released: { action: '', value: { on: false, level: defaultLevel, cct: defaultCCT } },
  };
  return { ...acc, [key]: { number: i + 1, target: { type: isEmpty ? 'room' : null }, events } };
}, {});

const defaultMappings = [
  { deviceChannel: 1, userChannel: 1 },
  { deviceChannel: 2, userChannel: 2 },
  { deviceChannel: 3, userChannel: 3 },
  { deviceChannel: 4, userChannel: 4 },
];

// parse array actions to object format
export const serializeDeviceActions = (actionArray, mappings = [...defaultMappings]) => {
  const map = {
    1: 'one',
    2: 'two',
    3: 'three',
    4: 'four',
    pressed: 'pressed',
    'hold pressed': 'hold_pressed',
    released: 'released',
  };
  const isEmpty = !actionArray.length;
  const buttons = getDefaultButtons(isEmpty);

  actionArray.forEach((item) => {
    let number = item.button;
    const mapping = mappings.find((i) => i.deviceChannel === item.button);
    if (mapping) {
      number = mapping.userChannel;
    }
    const { event } = item;
    // set channel target type and uuid
    buttons[map[number]].target.type = item.target.type;
    buttons[map[number]].target.uuid = item.target.uuid;

    // set event properties
    if (item.action) {
      buttons[map[number]].events[map[event]].action = item.action;
    } else if (item.target.type === 'scene') {
      buttons[map[number]].target.type = 'room';
      buttons[map[number]].target.uuid = null; // calculated in SwitchButtonConfig component
      buttons[map[number]].events[map[event]].action = 'scene';
      buttons[map[number]].events[map[event]].sceneId = item.target.uuid;
    }
    if (item.value) {
      if (item.value.level) {
        buttons[map[number]].events[map[event]].value.level = item.value.level;
        buttons[map[number]].events[map[event]].value.on = item.value.on;
      } else {
        buttons[map[number]].events[map[event]].action = item.value.on ? 'on' : 'off';
      }
      if (item.value.cct) {
        buttons[map[number]].events[map[event]].value.cct = item.value.cct;
      }
    }

    // https://mondriaan.atlassian.net/browse/LSC-3717
    if (item.action === 'set') {
      if (item.value.cct) {
        buttons[map[number]].events[map[event]].action = 'set-cct';
        buttons[map[number]].events[map[event]].value = {
          on: false,
          level: 100,
          cct: item.value.cct,
        };
      } else {
        if (buttons[map[number]].events[map[event]].action === 'set') {
          buttons[map[number]].events[map[event]].action = 'set-dim';
        }
        buttons[map[number]].events[map[event]].value = {
          on: item.value.on || false,
          level: item.value.level || 100,
          cct: 0,
        };
      }
    }
  });

  return buttons;
};

// parse object actions to array format
export const deserializeDeviceActions = (buttons, mappings = [...defaultMappings]) => {
  const map = {
    pressed: 'pressed', hold_pressed: 'hold pressed', released: 'released',
  };
  let actions = [];

  buttons.forEach((button) => {
    const { target } = button;

    if (!target.type || !target.uuid) {
      return;
    }

    Object.entries(button.events)
      .filter(([, value]) => value.action)
      .forEach(([eventType, actionObj]) => {
        const action = {
          button: button.number,
          event: map[eventType],
          target: { ...target },
          action: actionObj.action,
        };
        const mapping = mappings.find((i) => i.userChannel === action.button);
        if (mapping) {
          action.button = mapping.deviceChannel;
        }

        if (actionObj.action === 'set') {
          action.value = {
            on: actionObj.value.on,
            level: actionObj.value.level,
          };
        }

        // https://mondriaan.atlassian.net/browse/LSC-3717
        if (actionObj.action === 'set-cct') {
          action.action = 'set';
          action.value = {
            cct: actionObj.value.cct,
          };
        }

        // https://mondriaan.atlassian.net/browse/LSC-3717
        if (actionObj.action === 'set-dim') {
          action.action = 'set';
          action.value = {
            on: true,
            level: actionObj.value.level,
          };
        }

        if (actionObj.action === 'on') {
          action.action = 'set';
          action.value = {
            on: true,
          };
        }
        if (actionObj.action === 'off') {
          action.action = 'set';
          action.value = {
            on: false,
          };
        }
        if (actionObj.action === 'scene') {
          action.target.type = 'scene';
          action.target.uuid = actionObj.sceneId;
          delete action.action;
        }
        actions = [...actions, action];
      });
  });

  return actions;
};

export const hasOwnProperty = (obj, key) => Object.prototype.hasOwnProperty.call(obj, key);

/* eslint-disable */
export const uuidv4 = () => 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
  const r = Math.random() * 16 | 0; const
    v = c === 'x' ? r : (r & 0x3 | 0x8);
  return v.toString(16);
});

export const deepClone = (obj) => {
  return JSON.parse(JSON.stringify(obj));
}

export const getTimezones = () => {
  return [...new Set(timezones.sort())]
}

export const getCountryCode = () => {
  const timezone = dayjs.tz.guess();
  return ct.getCountryForTimezone(timezone).id.toLowerCase() || 'de';
}

export const formatDate = (datetime, timezone = null) => {
  if (timezone) {
    return dayjs(datetime).tz(timezone).format('YYYY-MM-DD HH:mm:ss');
  }
  return dayjs(datetime).local().format('YYYY-MM-DD HH:mm:ss');
}

export const OTA_STATUS = {
  WAITING: 0,
  FINISHED: 1,
  UPDATING: 2,
  ERRORED: 3,
  isFinished(otaStatus) {
    return otaStatus === this.FINISHED || otaStatus === this.ERRORED
  }
}

export const  compareVersionStrings = (versionA, versionB) => {
  const cleanVersion = (version) => version.toLowerCase()
    .replace(/^v/, '') // Remove leading 'v'
    .replace(/^\./, '') // Remove leading '.'
    .replace(/-.*$/, '') // Remove any pre-release or build metadata
    .split('.')
    .map((n) => parseInt(n, 10) || 0); // Convert parts into numbers, defaulting to 0 for non-numeric parts

  const a = cleanVersion(versionA);
  const b = cleanVersion(versionB);
  for (let i = 0; i < Math.max(a.length, b.length); i += 1) {
    if ((a[i] || 0) - (b[i] || 0)) {
      return (a[i] || 0) > (b[i] || 0);
    }
  }
  return true;
}


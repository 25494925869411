import coworkerService from '../services/coworkers';

const initialState = {
  coworkers: {},
  coworkersById: {},
};

const actions = {
  async listCoworkers({ commit }, { uuid }) {
    const { data } = await coworkerService.listCoworkers({ uuid });
    commit('listCoworkers', { uuid, data });
  },
  async getInvitation(ctx, { uuid, coworkerId }) {
    const { data } = await coworkerService.getInvitation({ uuid, coworkerId });
    return data;
  },
  async createCoworker(ctx, { uuid, data }) {
    const response = await coworkerService.createCoworker({ uuid, data });
    return response.data;
  },
  async updateCoworker({ commit }, {
    uuid, coworkerId, data,
  }) {
    const response = await coworkerService.updateCoworker({
      uuid, coworkerId, data,
    });
    commit('getCoworker', { data: response.data, coworkerId });
  },
  async deleteCoworker(ctx, { uuid, coworkerId }) {
    await coworkerService.deleteCoworker({ uuid, coworkerId });
  },
  async confirmInvitation(ctx, { invitation, accepted }) {
    const { uuid } = invitation.project;
    const coworkerId = invitation.uuid;
    if (accepted) {
      await coworkerService.acceptInvitation({ uuid, coworkerId });
    } else {
      await coworkerService.rejectInvitation({ uuid, coworkerId });
    }
  },
};

const mutations = {
  listCoworkers(state, payload) {
    const coworkers = { ...state.coworkers };
    coworkers[payload.uuid] = payload.data;
    state.coworkers = coworkers;
    state.coworkersById = payload.data.reduce((acc, item) => ({ ...acc, [item.uuid]: item }), {});
  },
  getCoworker(state, payload) {
    state.coworkersById = { ...state.coworkersById, [payload.coworkerId]: payload.data };
  },
};

const getters = {
  getCoworkersByProject: (state) => (uuid) => {
    if (state.coworkers[uuid]) {
      const coworkers = state.coworkers[uuid] || [];
      return coworkers.map((coworker) => ({
        ...coworker,
        name: coworker.name || coworker.email,
        phone: coworker.phone || '-',
      }));
    }
    return [];
  },
  getCoworkerById: (state) => (coworkerId) => {
    const coworker = state.coworkersById[coworkerId];
    return {
      ...coworker,
      name: coworker.name || coworker.email,
      phone: coworker.phone || '-',
    };
  },
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  mutations,
};

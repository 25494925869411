//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions } from 'vuex';
import { ValidationObserver, ValidationProvider } from 'vee-validate';

export default {
  name: 'confirm',
  components: {
    ValidationObserver,
    ValidationProvider,
  },
  data() {
    return {
      email: '',
      token: '',
      error: false,
      submitted: false,
    };
  },
  methods: {
    ...mapActions('auth', ['confirmRegistration']),
    async confirmReg() {
      this.submitted = true;
      const { email, token } = this;

      const valid = await this.$refs.observer.validate();
      if (!valid) {
        this.error = true;
        this.submitted = false;
        return;
      }

      await this.confirmRegistration({ email, token })
        .then(() => {
          this.$router.push('/success');
        }).catch((error) => {
          if (error.response.status === 400) {
            this.error = true;
            this.submitted = false;
          }
        });
    },
  },
  mounted() {
    if (this.$route.query.email && this.$route.query.token) {
      this.email = this.$route.query.email;
      this.token = this.$route.query.token;
      this.$nextTick(() => this.confirmReg());
    } else if (this.$route.query.email) {
      this.email = this.$route.query.email;
    }
  },
};

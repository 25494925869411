//
//
//
//
//
//
//
//
//

import FloorplanViewer from '@/components/ui/floorplan/FloorplanViewer.vue';

export default {
  name: 'FloorplanModal',
  components: { FloorplanViewer },
  props: ['modalIndex'],
  data() {
    return {
      title: this.$t('floorplanFloorplan'),
    };
  },
  methods: {
    onClose() {
      this.$bvModal.hide('floorplan-modal');
    },
    changeTitle(name) {
      this.title = name;
    },
  },
};

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapGetters } from 'vuex';
import FixedFooter from '@/components/ui/FixedFooter.vue';
import bus from '@/bus';
import config from '@/config';
import { logEvent } from '@/firebase';
import ProjectsGridView from '../../components/ui/projectUI/ProjectsGridView.vue';
import ProjectListView from '../../components/ui/projectUI/ProjectListView.vue';

export default {
  name: 'ProjectsList',
  components: { ProjectsGridView, ProjectListView, FixedFooter },
  data() {
    return {
      selectedProject: {},
      selectedUuid: '',
      selectedUnlockConfirm: false,
      selectedForceUnlock: false,
      deleteModal: {
        error: false,
        message: '',
      },
      prevRoute: null,
      loading: false,
      wizardEnabled: config.ENABLE_WIZARD,
    };
  },
  async created() {
    await this.listProjects();
    await this.getProfile();
    if (this.invitation) {
      if (this.invitation.type === 'fromEmail') {
        await this.getInvitation({ uuid: this.invitation.project.uuid, coworkerId: this.invitation.uuid })
          .then(() => {
            this.$bvModal.show('confirm-invitation-modal');
          })
          .catch(() => {
            this.$bvModal.show('wrong-user-modal');
          });
        return;
      }
      this.$bvModal.show('confirm-invitation-modal');
    }
  },
  computed: {
    ...mapGetters('projects', ['isReadOnly', 'hasToken', 'filteredProjectList', 'projectsCount', 'isUnlockableProject', 'projectView']),
    ...mapGetters('user', ['pendingInvitations']),
    invitation() {
      if (this.$route.name === 'accept-coworker-invitation') {
        return {
          type: 'fromEmail',
          uuid: this.$route.query.invitation,
          project: {
            uuid: this.$route.query.projectUUID,
            name: this.$route.query.projectName,
          },
        };
      }
      return this.pendingInvitations[0];
    },
    projects() {
      return this.filteredProjectList;
    },
    projectList() {
      return this.projects.map((p) => ({ ...p.node }));
    },
    footerButtons() {
      return {
        main: {
          id: 'button-create-project',
          title: this.$t('projectsCreateNewProject'),
          to: { name: this.wizardEnabled ? 'wizard-project-name-and-description' : 'projects-create' },
        },
      };
    },
  },
  methods: {
    ...mapActions('user', ['getProfile']),
    ...mapActions('projects', [
      'listProjects',
      'deleteProject',
      'lockProject',
      'unlockProject',
      'forceUnlockProject',
    ]),
    ...mapActions('coworkers', ['confirmInvitation', 'getInvitation']),
    async onUnlockProject(uuid) {
      if (this.selectedForceUnlock) {
        await this.forceUnlockProject(uuid);
      } else {
        await this.unlockProject(uuid);
      }
      this.selectedUuid = '';
      if (this.selectedForceUnlock) {
        this.selectedForceUnlock = false;
        this.selectedUnlockConfirm = false;
      }
      this.$bvModal.hide('unlock-project-modal');
      // await this.$router.push({ name: 'project-rooms', params: { projectId: uuid } });
    },
    async onDeleteProject(uuid) {
      this.loading = true;
      try {
        await this.deleteProject(uuid);
        await this.listProjects();
      } finally {
        this.$bvModal.hide('delete-project-modal');
        this.selectedUuid = '';
        this.loading = false;
      }
    },
    async openProject(uuid, configEditAllowed) {
      if (this.loading) {
        return;
      }
      this.loading = true;
      if (!configEditAllowed) {
        this.loading = false;
        logEvent('open_project');
        await this.$router.push({ name: 'project-rooms', params: { projectId: uuid } });
        return;
      }
      await this.lockProject({ uuid }).finally(() => {
        this.loading = false;
      });
      logEvent('open_project');
      await this.$router.push({ name: 'project-rooms', params: { projectId: uuid } });
    },
    handleProjectClick(project) {
      if (project.locked) {
        this.openUnlockModal(project);
      } else {
        this.openProject(project.uuid, project.configEditAllowed);
      }
    },
    handleProjectDelete(project) {
      return !project.locked && !project.gatewayConnectedToCloud && this.canI(project, 'delete');
    },
    openUnlockModal(project) {
      this.selectedProject = project;
      this.selectedUuid = project.uuid;
      this.selectedForceUnlock = this.canI(project, 'forceUnlock');
      this.selectedUnlockConfirm = false;
      this.$bvModal.show('unlock-project-modal');
    },
    openDeleteModal(project) {
      this.selectedProject = project;
      this.selectedUuid = project.uuid;
      this.$bvModal.show('delete-project-modal');
    },
    async rerenderProjects() {
      if (this.$route.name === 'accept-coworker-invitation') {
        await this.$router.push({ name: 'projects' });
      }
      bus.$emit('forceRerender');
    },
    hideConfirmInvitationModal(showRevokedModal = false) {
      this.$bvModal.hide('confirm-invitation-modal');
      if (showRevokedModal) {
        this.$bvModal.show('revoked-invitation-modal');
      }
    },
    async confirmInvite(accepted) {
      await this.getProfile();
      if (!this.invitation) {
        this.hideConfirmInvitationModal(true);
        return;
      }
      await this.confirmInvitation({ invitation: this.invitation, accepted })
        .catch(() => {
          this.hideConfirmInvitationModal(true);
        })
        .then(async () => {
          await this.rerenderProjects();
          this.hideConfirmInvitationModal();
        });
    },
    canI(project, action, subject = 'projects') {
      const { permissions } = project.meta;
      return permissions.some((item) => item.action === action && item.subject === subject);
    },
  },
};

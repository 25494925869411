var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"observer-3",attrs:{"tag":"form","novalidate":""}},[_c('b-row',[_c('b-col',{staticClass:"col-lg-4 col-xl-3 col-12 ml-auto"},[_c('ValidationProvider',{attrs:{"rules":"required|max:128"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('projectContactName')}},[_c('b-input',{attrs:{"state":errors[0] ? false : null},on:{"blur":_vm.isSkippable},model:{value:(_vm.contact.name),callback:function ($$v) {_vm.$set(_vm.contact, "name", $$v)},expression:"contact.name"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{staticClass:"col-lg-4 col-xl-3 col-12 mr-auto"},[_c('ValidationProvider',{attrs:{"rules":"required|email|max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('projectContactEmail')}},[_c('b-input',{attrs:{"state":errors[0] ? false : null},on:{"blur":_vm.isSkippable,"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"space",32,$event.key,[" ","Spacebar"])){ return null; }$event.preventDefault();}},model:{value:(_vm.contact.email),callback:function ($$v) {_vm.$set(_vm.contact, "email", $$v)},expression:"contact.email"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)],1),_c('b-row',[_c('b-col',{staticClass:"col-lg-4 col-xl-3 col-12 ml-auto"},[_c('ValidationProvider',{attrs:{"rules":"required|phone|max:16"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('projectContactPhone')}},[_c('b-input',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.left",value:({ title: _vm.$t('generalPhoneNumberTooltip')}),expression:"{ title: $t('generalPhoneNumberTooltip')}",modifiers:{"left":true}}],attrs:{"state":errors[0] ? false : null},on:{"keyup":_vm.formatPhoneNumber,"blur":_vm.isSkippable},model:{value:(_vm.contact.phone),callback:function ($$v) {_vm.$set(_vm.contact, "phone", $$v)},expression:"contact.phone"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{staticClass:"col-lg-4 col-xl-3 col-12 mr-auto"},[_c('ValidationProvider',{attrs:{"rules":"required|max:1000"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('projectContactDescription')}},[_c('b-input',{attrs:{"state":errors[0] ? false : null},on:{"blur":_vm.isSkippable},model:{value:(_vm.contact.description),callback:function ($$v) {_vm.$set(_vm.contact, "description", $$v)},expression:"contact.description"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
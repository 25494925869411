import Vue from 'vue';
import Vuex from 'vuex';
import createMultiTabState from 'vuex-multi-tab-state';

import VuexPersistence from 'vuex-persist';
import localForage from 'localforage';

import api from './modules/api';
import app from './modules/app';
import locale from './modules/locale';
import user from './modules/user';
import auth from './modules/auth';
import socket from './modules/socket';
import projects from './modules/projects';
import rooms from './modules/rooms';
import devices from './modules/devices';
import groups from './modules/groups';
import scenes from './modules/scenes';
import templates from './modules/templates';
import products from './modules/products';
import rules from './modules/rules';
import coworkers from './modules/coworkers';
import schedules from './modules/schedules';
import configs from './modules/configs';
import deviceHealth from './modules/deviceHealth';
import firmwares from './modules/firmwares';
import gateway from './modules/gateway';
import wizard from './modules/wizard';
import vuexStateFixer from '../utils/vuex-state-fixer';

Vue.use(Vuex);

const modules = {
  api,
  app,
  locale,
  user,
  auth,
  socket,
  projects,
  rooms,
  devices,
  groups,
  scenes,
  templates,
  products,
  rules,
  coworkers,
  schedules,
  configs,
  deviceHealth,
  firmwares,
  gateway,
  wizard,
};

const fixState = vuexStateFixer(modules);
const stateReducer = (state) => {
  // magic state-fixer
  fixState(state);
  if (!state.auth.userId) {
    // keeping the locale state only
    return { locale: state.locale };
  }

  // keep only the state we want to persist
  return {
    app: state.app,
    auth: state.auth,
    locale: state.locale,
    devices: {
      checkboxFilters: state.devices?.checkboxFilters || {},
      savedSortByCategory: state.devices?.savedSortByCategory || {},
    },
    projects: {
      checkboxErrorFilters: state.projects?.checkboxErrorFilters || {},
      projectLocks: state.projects?.projectLocks || {},
      projectFilters: state.projects?.projectFilters || {},
      projectView: state.projects?.projectView || 'table',
      projects: state.projects?.projects || [],
      projectsById: state.projects?.projectsById || {},
    },
    user: state.user,
  };
};

const vuexLocal = new VuexPersistence({
  storage: localForage,
  asyncStorage: true,
  reducer: stateReducer,
});

const store = new Vuex.Store({
  plugins: [
    vuexLocal.plugin,
    createMultiTabState({
      statesPaths: ['app', 'auth', 'locale', 'devices.checkboxFilters', 'devices.savedSortByCategory', 'projects.checkboxErrorFilters', 'projects.projectLocks', 'projects.projectFilters', 'projects.projectView', 'projects.projects', 'projects.projectsById', 'user'],
      onBeforeReplace(state) {
        return fixState(state, true);
      },
      onBeforeSave: stateReducer,
    }),
  ],
  modules,
  state: {
    packageVersion: process.env.PACKAGE_VERSION,
    apiVersion: null,
  },
  getters: {
  },
  mutations: {
    setApiVersion: (state, payload) => {
      state.apiVersion = payload.version;
    },
  },
});

export default store;

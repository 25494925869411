//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from 'vuex';
import FixedFooter from '../../../components/ui/FixedFooter.vue';
import CustomModal from '../../../components/ui/CustomModal.vue';
import ProjectProductsFirmware from '../../../components/ui/firmwareUpdate/ProjectProductsFirmware.vue';
import Spinner from '../../../components/ui/Spinner.vue';

export default {
  name: 'FirmwareUpdate',
  data() {
    return {
      loading: false,
      projectId: this.$route.params.projectId,
      updateAllModal: false,
    };
  },
  components: {
    Spinner,
    ProjectProductsFirmware,
    FixedFooter,
    CustomModal,
  },
  async created() {
    await this.init();
  },
  computed: {
    ...mapGetters('devices', ['getProjectDevices', 'getProductUpdates']),
    ...mapGetters('projects', ['getProjectById', 'isReadOnly']),
    project() {
      return this.getProjectById(this.projectId);
    },
    projectName() {
      return this.project.name;
    },
    readOnly() {
      return this.isReadOnly(this.projectId);
    },
    everythingUpToDate() {
      return Object.values(this.products).every((item) => item.allUpdated);
    },
    updateInProgress() {
      return Object.values(this.products).some((item) => item.updating);
    },
    footerButtons() {
      if (this.everythingUpToDate) {
        return {
          tertiary: {
            title: this.$t('generalDetails'),
            click: () => {
              this.$router.push({ name: 'project-firmware-update-details' });
            },
          },
        };
      }
      return {
        main: {
          title: this.$t('firmwareUpdateAll'),
          disabled: this.everythingUpToDate || this.updateInProgress || !this.$canI('otaDevice', 'firmwares') || !this.$canI('otaGateway', 'firmwares'),
          click: () => {
            this.$router.push({ name: 'project-firmware-update-details', query: { updateAll: 'true' } });
          },
        },
        tertiary: {
          title: this.$t('generalDetails'),
          click: () => {
            this.$router.push({ name: 'project-firmware-update-details' });
          },
        },
      };
    },
    updateAllButtons() {
      return {
        main: {
          title: this.$t('firmwareUpdate'),
          disabled: this.everythingUpToDate || this.loading,
          click: this.onUpdateAll,
        },
        secondary: {
          title: this.$t('generalCancel'),
          click: () => {
            this.updateAllModal = false;
          },
        },
      };
    },
    projectDevices() {
      return this.getProjectDevices(this.projectId);
    },
    products() {
      return this.getProductUpdates(this.projectId);
    },
    productsToUpdate() {
      const prods = {};
      Object.entries(this.products)
        .filter(([, item]) => !item.allUpdated)
        .forEach(([category, item]) => {
          prods[category] = {
            ...item,
            products: item.products
              .filter((product) => product.updatedCount !== product.totalCount),
          };
        });
      return prods;
    },
    devicesToUpdate() {
      return this.projectDevices.filter((device) => device.hasNewFirmware);
    },
  },
  methods: {
    ...mapActions('projects', ['updateProject']),
    ...mapActions('devices', ['listProjectDevices', 'updateDeviceFirmware']),
    async init() {
      this.loading = true;
      await this.listProjectDevices({ uuid: this.projectId })
        .catch(() => {
          this.$nextTick(() => {
            this.$router.push({ name: 'project-rooms', params: { projectId: this.projectId } });
          });
        })
        .finally(() => {
          this.loading = false;
        });

      if (this.updateInProgress) {
        await this.$router.push({ name: 'project-firmware-update-details' });
      }
    },
    onProductClick(product) {
      this.$router.push({ name: 'project-product-firmware-update', params: { productId: product.productUuid } });
    },
  },
};

/* global axios */

const listSchedules = async ({ uuid }) => axios({
  method: 'GET',
  url: `/projects/${uuid}/schedules`,
});

const getSchedule = async ({ uuid, scheduleId }) => axios({
  method: 'GET',
  url: `/projects/${uuid}/schedules/${scheduleId}`,
});

const createSchedule = async ({ uuid, data }) => axios({
  method: 'POST',
  url: `/projects/${uuid}/schedules`,
  lock: { uuid },
  data,
  logEvent: () => ({ eventName: 'create_schedule' }),
});

const updateSchedule = async ({
  uuid, scheduleId, data,
}) => axios({
  method: 'PUT',
  url: `/projects/${uuid}/schedules/${scheduleId}`,
  lock: { uuid },
  data,
  logEvent: () => ({ eventName: 'edit_schedule' }),
});

const deleteSchedule = async ({ uuid, scheduleId }) => axios({
  method: 'DELETE',
  url: `/projects/${uuid}/schedules/${scheduleId}`,
  lock: { uuid },
  data: {},
  logEvent: () => ({ eventName: 'delete_schedule' }),
});

export default {
  listSchedules,
  getSchedule,
  createSchedule,
  updateSchedule,
  deleteSchedule,
};

//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapGetters } from 'vuex';
import RoomGroupDetailsUI from '@/components/ui/roomUI/RoomGroupDetailsUI.vue';

export default {
  name: 'TemplateGroupDetails',
  components: {
    RoomGroupDetailsUI,
  },
  data() {
    return {
      templateId: this.$route.params.templateId,
      groupId: this.$route.params.groupId,
      loading: false,
    };
  },
  async created() {
    this.loading = true;
    await this.listProducts();
    this.loading = false;
  },
  computed: {
    ...mapGetters('templates', ['getTemplateById']),
    ...mapGetters('products', ['getProductById']),
    template() {
      return this.getTemplateById(this.templateId) || {};
    },
    templateConfig() {
      if (this.template.config && this.template.config.body) {
        return this.template.config.body;
      }
      return {};
    },
    group() {
      return this.templateConfig.groups.find((g) => g.uuid === this.groupId);
    },
    items() {
      return this.templateConfig.devices.lights.map((light) => {
        const product = this.getProductById(light.product);
        return {
          value: light.uuid,
          text: product.name,
          checked: true,
          description: light.description,
        };
      }).filter((i) => (this.group.members || []).includes(i.value));
    },
    sensorItems() {
      return this.templateConfig.devices.sensors.map((sensor) => {
        const product = this.getProductById(sensor.product);
        return {
          value: sensor.uuid,
          text: product.name,
          checked: true,
          description: sensor.description,
        };
      }).filter((i) => (this.group.sensors || []).includes(i.value));
    },
  },
  methods: {
    ...mapActions('products', ['listProducts']),
  },
};

import apiService from '../services/api';

const initialState = {
  status: null,
  error: false,
};

const actions = {
  async getApiStatus({ commit }) {
    try {
      const { status } = await apiService.ping();
      commit('apiStatus', { status });
    } catch (e) {
      commit('apiError');
    }
  },
};

const mutations = {
  apiStatus(state, data) {
    state.status = data.status;
    state.error = false;
  },
  apiError(state) {
    state.error = true;
  },
};

export default {
  namespaced: true,
  state: initialState,
  actions,
  mutations,
};

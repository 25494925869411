//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions } from 'vuex';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import FloorplanDetailsUI from '@/components/ui/floorplan/FloorplanDetailsUI.vue';
import ProjectAddress from '@/components/ui/ProjectAddress.vue';
import FixedFooter from '@/components/ui/FixedFooter.vue';

export default {
  name: 'ProjectsCreate',
  data() {
    return {
      disableCreate: false,
      name: '',
      address: {},
      configParameters: {},
      description: '',
      files: [],
    };
  },
  components: {
    ValidationObserver,
    ValidationProvider,
    FloorplanDetailsUI,
    ProjectAddress,
    FixedFooter,
  },
  computed: {
    footerButtons() {
      return {
        main: {
          title: this.$t('projectCreateProject'),
          class: this.disableCreate ? 'disabled' : '',
          click: () => this.onCreateProject(),
        },
      };
    },
  },
  methods: {
    ...mapActions('projects', ['createProject', 'uploadFloorplans', 'listProjects', 'lockProject']),
    async onCreateProject() {
      const isValid = await this.$refs.observer.validate();
      if (!isValid || this.disableCreate) {
        return;
      }
      this.disableCreate = true;
      const data = {
        name: this.name,
        address: this.address,
        description: this.description,
      };
      let createdProject = null;
      data.latitude = this.configParameters.latitude;
      data.longitude = this.configParameters.longitude;
      data.timeZone = this.configParameters.timeZone;

      try {
        createdProject = await this.createProject(data);
      } catch (e) {
        this.disableCreate = false;
        throw e;
      }

      const { uuid } = createdProject;
      try {
        await this.onFloorplanUpload(uuid);
        await this.listProjects();
      } finally {
        await this.lockProject({ uuid });
        await this.$router.push({ name: 'project-rooms', params: { projectId: uuid } });
      }
    },
    async onFloorplanUpload(uuid) {
      const data = this.files;
      if (!data || !data.length) {
        return;
      }
      await this.uploadFloorplans({ uuid, files: data });
    },
    async onCreateClicked() {
      this.files = await this.$refs.form.onSave.bind(this.$refs.form)();
      this.$bvModal.hide('file-upload-modal');
    },
    onCancel() {
      this.$bvModal.hide('file-upload-modal');
    },
    setAddress(newAddress) {
      this.address = newAddress;
    },
    setConfigParameters(newConfigParameters) {
      this.configParameters = newConfigParameters;
    },
  },
};

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import IconLink from '../../IconLink.vue';

export default {
  props: ['title', 'showEdit', 'showMove', 'showDelete', 'editMode'],
  name: 'TitleWithDeviceActions',
  components: { IconLink },
  methods: {
    onAction(action) {
      this.$emit('onAction', action);
    },
  },
};

import Vue from 'vue';

/**
 * Event bus for outside click directive
 */
export const ClickBus = new Vue();

/**
 * Wizard events:
 *   $emit('next')
 *   $emit('abort')
 *   $emit('createProject')
 */

export const WizardBus = new Vue();

/**
 Active events:
 bus.$emit('forceRerender') - will re-render the actual route
 bus.$emit('projectUpdated') - will re-render the actual route if user has no lock token
 */

export default new Vue();

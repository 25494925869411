//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapGetters } from 'vuex';

import RoomScenesUI from '@/components/ui/roomUI/RoomScenesUI.vue';

export default {
  name: 'TemplateScenes',
  components: {
    RoomScenesUI,
  },
  data() {
    return {
      categories: ['lights', 'switches', 'sensors'],
      templateId: this.$route.params.templateId,
      projectId: this.$route.params.projectId,
      loading: false,
    };
  },
  async created() {
    this.loading = true;
    await this.listProducts();
    this.loading = false;
  },
  computed: {
    ...mapGetters('templates', ['getTemplateById']),
    ...mapGetters('products', ['getProductById']),
    template() {
      return this.getTemplateById(this.templateId) || {};
    },
    templateConfig() {
      if (this.template.config && this.template.config.body) {
        return this.template.config.body;
      }
      return {};
    },
    scenes() {
      return this.templateConfig.scenes;
    },
  },
  methods: {
    ...mapActions('products', ['listProducts']),
  },
};

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from 'vuex';
import RoomScheduleDetailsUI from '@/components/ui/roomUI/RoomScheduleDetailsUI.vue';
import DeleteScheduleModal from './modals/DeleteScheduleModal.vue';

export default {
  name: 'RoomScheduleDetail',
  components: {
    RoomScheduleDetailsUI,
    DeleteScheduleModal,
  },
  data() {
    return {
      projectId: this.$route.params.projectId,
      roomId: this.$route.params.roomId,
      scheduleId: this.$route.params.scheduleId,
      rerender: 1,
    };
  },
  async created() {
    await this.onCreated();
  },
  computed: {
    ...mapGetters('schedules', ['getScheduleById']),
    ...mapGetters('rooms', ['getRoomsByProjectId']),
    ...mapGetters('projects', ['getProjectById', 'isReadOnly', 'getHealthEntries']),
    healthIssues() {
      return this.getHealthEntries(this.projectId).byId[this.scheduleId] || [];
    },
    schedule() {
      return this.getScheduleById(this.scheduleId);
    },
    rooms() {
      return this.getRoomsByProjectId(this.projectId);
    },
    groups() {
      return this.rooms.reduce((acc, item) => ({ ...acc, [item.uuid]: item.groups }), {});
    },
    lights() {
      return this.rooms.reduce((acc, item) => ({ ...acc, [item.uuid]: item.members }), {});
    },
    options() {
      return [
        { value: null, text: '' },
        { value: 'room', text: this.$t('generalRooms') },
        { value: 'group', text: this.$t('generalGroups') },
        { value: 'light', text: this.$t('generalLights') },
        { value: 'gateway', text: this.$t('generalProject') },
      ];
    },
    readOnly() {
      return this.isReadOnly(this.projectId);
    },
  },
  methods: {
    ...mapActions('rooms', ['listRooms']),
    ...mapActions('schedules', ['listSchedules', 'updateSchedule', 'deleteSchedule', 'getSchedule']),
    async onCreated() {
      await this.listRooms(this.projectId);
      await this.listSchedules({ uuid: this.projectId, roomId: this.roomId });
      await this.getSchedule({ uuid: this.projectId, scheduleId: this.scheduleId });
      this.rerender += 1;
    },
    openDeleteModal() {
      this.$bvModal.show('delete-schedule-modal');
    },
    async onDeleteSchedule() {
      await this.deleteSchedule({ uuid: this.projectId, scheduleId: this.scheduleId });
      this.$bvModal.hide('delete-schedule-modal');
      await this.$router.push({ name: 'project-schedules', params: { projectId: this.projectId } });
    },
    async onSaveSchedule(data) {
      await this.updateSchedule({
        uuid: this.projectId, scheduleId: this.scheduleId, data,
      });
      await this.listSchedules({ uuid: this.projectId });
      await this.getSchedule({ uuid: this.projectId, scheduleId: this.scheduleId });
    },
  },
};

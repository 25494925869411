//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapGetters } from 'vuex';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import bus, { WizardBus } from '@/bus';
import WizardListUI from '@/components/ui/wizard/WizardListUI.vue';
import DeleteScheduleModal from '@/views/projects/schedules/modals/DeleteScheduleModal.vue';
import InputTimeField from '@/components/ui/InputTimeField.vue';
import InputDaysField from '@/components/ui/InputDaysField.vue';
import CustomSlider from '@/components/ui/CustomSlider.vue';
import CustomSection from '@/components/ui/CustomSection.vue';

export default {
  name: 'WizardRoom',
  components: {
    CustomSlider,
    CustomSection,
    InputTimeField,
    InputDaysField,
    WizardListUI,
    DeleteScheduleModal,
    ValidationObserver,
    ValidationProvider,
  },
  data() {
    return {
      dataLoaded: false,
      currentStep: 1,
      rerender: 1,
      disableCreate: false,
      repeatError: false,
      data: {},
      targets: [],
      selectedSchedule: {},
      warningAccepted: false,
    };
  },
  async created() {
    WizardBus.$on('next', this.onNextStep);
    await this.onListSchedules();
    await this.listRooms(this.projectId);
  },
  beforeDestroy() {
    WizardBus.$off('next', this.onNextStep);
  },
  watch: {
    'data.target': function clearTarget(val) {
      this.targets = [];
      if (val === 'gateway') {
        this.targets = [{ target: 'gateway', uuid: this.projectId }];
      }
    },
  },
  computed: {
    ...mapGetters('projects', ['getProjectById']),
    ...mapGetters('schedules', ['getSchedulesByProject']),
    ...mapGetters('rooms', ['getRoomsByProjectId']),
    ...mapGetters('groups', ['getGroupsByProject']),
    ...mapGetters('devices', ['getLightsByProject']),
    projectId() {
      return this.$route.params.projectId;
    },
    project() {
      return this.getProjectById(this.projectId);
    },
    schedules() {
      return this.getSchedulesByProject(this.projectId);
    },
    rooms() {
      return this.getRoomsByProjectId(this.projectId);
    },
    groups() {
      return this.rooms.reduce((acc, item) => ({ ...acc, [item.uuid]: item.groups }), {});
    },
    lights() {
      return this.rooms.reduce((acc, item) => ({ ...acc, [item.uuid]: item.members }), {});
    },
    schedule() {
      return {
        name: '',
        time: '08:00',
        repeat: 0,
        actions: [],
      };
    },
    options() {
      return [
        { value: null, text: '' },
        { value: 'room', text: this.$t('generalRooms') },
        { value: 'group', text: this.$t('generalGroups') },
        { value: 'light', text: this.$t('generalLights') },
        { value: 'gateway', text: this.$t('generalProject') },
      ];
    },
    actionTypes() {
      return [{ value: 'On', text: this.$t('generalOn') }, { value: 'Off', text: this.$t('generalOff') }];
    },
    modalTitle() {
      return `${this.currentStep}/4. ${this.$t('scheduleCreateSchedule')}`;
    },
    modalButtonLabel() {
      return this.currentStep === 4 ? this.$t('wizardFinishSchedule') : this.$t('wizardNext');
    },
    showLightsOffWarning() {
      return this.data.target === 'gateway' && this.data.actionType === 'Off';
    },
  },
  methods: {
    ...mapActions('schedules', ['createSchedule', 'listSchedules', 'deleteSchedule']),
    ...mapActions('rooms', ['listRooms']),
    async onListSchedules() {
      await this.listSchedules({ uuid: this.projectId, roomId: this.roomId });
      this.dataLoaded = true;
      this.$nextTick(this.isSkippable);
    },
    showDeleteModal(schedule) {
      this.selectedSchedule = schedule;
      this.$bvModal.show('delete-schedule-modal');
    },
    async onDeleteSchedule() {
      await this.deleteSchedule({ uuid: this.projectId, roomId: this.roomId, scheduleId: this.selectedSchedule.uuid });
      await this.onListSchedules();
      this.$bvModal.hide('delete-schedule-modal');
    },
    async onListGroups() {
      await this.listGroups({ uuid: this.projectId, roomId: this.roomId });
    },
    showScheduleModal() {
      this.resetForm();
      this.$bvModal.show('wizard-schedule-modal');
    },
    onNextStep() {
      this.$router.push({ name: this.$route.meta.nextRoute });
    },
    async modalClick() {
      if (this.currentStep === 1) {
        const valid = await this.$refs.observer.validate();
        if (!valid) {
          return;
        }
      }
      if (this.currentStep === 2 && !this.data.repeat) {
        this.repeatError = true;
        return;
      }
      if (this.currentStep === 3 && !this.targets.length) {
        return;
      }
      if (this.currentStep === 4) {
        await this.onCreate();
      } else {
        this.currentStep += 1;
      }
    },
    onCancel() {
      this.$bvModal.hide('wizard-schedule-modal');
    },
    isInterminate(type, roomId) {
      if (type === 'rooms') {
        return this[type].some((item) => this.targets.some((T) => T.uuid === item.uuid));
      }
      return this[type][roomId].some((item) => this.targets.some((T) => T.uuid === item.uuid));
    },
    selectAllChange(add, target, items) {
      const targets = items.map((i) => ({ target, uuid: i.uuid }));
      if (add) {
        this.targets = this.targets.concat(targets.filter((item) => !this.targets.find((t) => t.uuid === item.uuid)));
      } else {
        this.targets = this.targets.filter((item) => !targets.find((t) => t.uuid === item.uuid));
      }
    },
    targetChange(add, target, uuid) {
      if (add) {
        this.targets = [...this.targets, { target, uuid }];
      } else {
        this.targets = this.targets.filter((T) => !(T.target === target && T.uuid === uuid));
      }
    },
    isAllSelected(target, items) {
      return items.every((item) => this.targets.find((t) => t.target === target && t.uuid === item.uuid));
    },
    isTargetSelected(targets) {
      return this.targets.some((T1) => targets.some((T2) => T1.target === T2.target && T1.uuid === T2.uuid));
    },
    getRoomById(roomId) {
      return this.rooms.find((r) => r.uuid === roomId) || {};
    },
    async onCreate() {
      this.disableSave = true;
      if (this.showLightsOffWarning && !this.warningAccepted) {
        this.$bvModal.show('schedule-warning-modal');
        return;
      }
      const {
        name, time, repeat, action, actionType,
      } = this.data;
      const actions = this.targets.map((T) => ({
        target: { type: T.target, uuid: T.uuid },
        value: {
          on: actionType === 'On',
          level: actionType === 'On' && action.selected ? action.value : undefined,
        },
      }));
      let room;
      const data = {
        name, time, actions, repeat, room,
      };
      try {
        await this.createSchedule({ uuid: this.projectId, roomId: this.roomId, data });
        await this.onListSchedules();
      } finally {
        this.disableSave = false;
        this.$bvModal.hide('wizard-schedule-modal');
        bus.$emit('forceRerender');
      }
    },
    onChange(item, data) {
      this.data[item] = { ...data };
    },
    resetForm() {
      this.currentStep = 1;
      this.repeatError = false;
      const {
        name, time, repeat, actions,
      } = this.schedule;
      const firstAction = actions[0] || {};
      const value = firstAction.value || {};
      const target = (firstAction.target && firstAction.target.type) || '';
      const actionType = value.on ? 'On' : 'Off';
      const action = { on: value.on, value: value.level || 100 };
      this.targets = actions.map((a) => ({ target: a.target.type, uuid: a.target.uuid }));
      this.data = {
        name, time, target, repeat, action, actionType,
      };
      this.rerender += 1;
    },
    isSkippable() {
      this.$parent.$parent.$data.skippable = !this.schedules.length;
    },
    acceptWarningModal() {
      this.warningAccepted = true;
      this.hideWarningModal();
      this.onCreate();
    },
    hideWarningModal() {
      this.$bvModal.hide('schedule-warning-modal');
    },
  },
};

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from 'vuex';
import RoomSceneDetailsUI from '@/components/ui/roomUI/RoomSceneDetailsUI.vue';
import DeleteSceneModal from './modals/DeleteSceneModal.vue';

export default {
  name: 'RoomSceneDetail',
  components: {
    RoomSceneDetailsUI,
    DeleteSceneModal,
  },
  data() {
    return {
      projectId: this.$route.params.projectId,
      roomId: this.$route.params.roomId,
      sceneId: this.$route.params.sceneId,

      editMode: false,

      disableUpdate: false,
      name: '',
      type: '',
      lightItems: [],
      groupItems: [],
      wholeRoom: {},
      description: '',
      loaded: false,
      defaultLevel: 0,
      defaultInitialLevel: 100,
      defaultCct: 4000,
    };
  },
  async created() {
    await this.listRooms(this.projectId);
    await this.getScene({ uuid: this.projectId, roomId: this.roomId, sceneId: this.sceneId });
    await this.listDevices({ uuid: this.projectId, roomId: this.roomId });
    await this.listGroups({ uuid: this.projectId, roomId: this.roomId });
    this.resetForm();
  },
  computed: {
    ...mapGetters('rooms', ['getRoomById']),
    ...mapGetters('scenes', ['getSceneById']),
    ...mapGetters('devices', ['getDevicesByRoom']),
    ...mapGetters('groups', ['getGroupsByRoom']),
    ...mapGetters('projects', ['getProjectById', 'isReadOnly', 'getHealthEntries']),
    healthIssues() {
      return this.getHealthEntries(this.projectId).byId[this.sceneId] || [];
    },
    readOnly() {
      return this.isReadOnly(this.projectId);
    },
    lights() {
      return this.getDevicesByRoom(this.projectId, this.roomId).lights;
    },
    groups() {
      return this.getGroupsByRoom(this.projectId, this.roomId);
    },
    scene() {
      return this.getSceneById(this.sceneId);
    },
    room() {
      return this.getRoomById(this.roomId);
    },
    breadcrumbs() {
      const project = this.getProjectById(this.projectId);
      return {
        show: true,
        showSeparator: true,
        parents: [{
          name: project.name,
          link: { name: 'project-rooms' },
        }, {
          name: this.room.name,
          link: { name: 'room-scenes' },
        }],
        current: this.scene.name,
      };
    },
  },
  methods: {
    ...mapActions('rooms', ['listRooms']),
    ...mapActions('devices', ['listDevices']),
    ...mapActions('groups', ['listGroups']),
    ...mapActions('scenes', ['listScenes', 'updateScene', 'deleteScene', 'getScene']),
    async onSaveScene(sceneData) {
      this.disableCreate = true;
      const { name, description, values } = sceneData;
      const data = { name, description, values };
      await this.updateScene({
        uuid: this.projectId, roomId: this.roomId, sceneId: this.sceneId, data,
      });
      await this.listScenes({ uuid: this.projectId, roomId: this.roomId });
      await this.getScene({ uuid: this.projectId, roomId: this.roomId, sceneId: this.sceneId });
    },
    openDeleteModal() {
      this.$bvModal.show('delete-scene-modal');
    },
    async onDeleteScene() {
      await this.deleteScene({ uuid: this.projectId, roomId: this.roomId, sceneId: this.sceneId });
      this.$bvModal.hide('delete-scene-modal');
      await this.$router.push({ name: 'room-scenes', params: { projectId: this.projectId, roomId: this.roomId } });
    },
    switchMode(editMode) {
      if (!editMode) {
        this.resetForm();
        return;
      }
      this.editMode = true;
    },
    getLevel(saved, deviceType) {
      if (saved && saved.value.level) {
        return saved.value.level;
      }
      if (this.type !== deviceType) {
        return this.defaultInitialLevel;
      }
      return this.defaultLevel;
    },
    resetForm() {
      this.editMode = false;
      this.disableUpdate = false;
      this.loaded = true;
      this.name = this.scene.name;
      this.type = (this.scene.values[0] && this.scene.values[0].target.type) || 'group';

      if (this.type === 'room') {
        this.type = 'group';
      }

      this.lightItems = this.lights.map((light) => {
        const saved = this.scene.values.find((s) => s.target.uuid === light.uuid);
        return ({
          light,
          selected: true,
          data: {
            target: {
              type: 'light',
              uuid: light.uuid,
            },
            value: {
              on: (saved && saved.value.on) || false,
              level: this.getLevel(saved, 'light'),
              cct: saved && saved.value.cct ? saved.value.cct : this.defaultCct,
            },
          },
        });
      });

      this.groupItems = this.groups.map((group) => {
        const saved = this.scene.values.find((s) => s.target.uuid === group.uuid);
        return ({
          group,
          selected: !!saved,
          data: {
            target: {
              type: 'group',
              uuid: group.uuid,
            },
            value: {
              on: (saved && saved.value.on) || false,
              level: this.getLevel(saved, 'group'),
              cct: saved && saved.value.cct ? saved.value.cct : this.defaultCct,
            },
          },
        });
      });

      const saved = this.scene.values.find((s) => s.target.uuid === this.room.uuid);
      this.wholeRoom = {
        room: this.room,
        selected: !!saved,
        data: {
          target: {
            type: 'room',
            uuid: this.room.uuid,
          },
          value: {
            on: (saved && saved.value.on) || false,
            level: this.getLevel(saved, 'group'),
            cct: saved && saved.value.cct ? saved.value.cct : this.defaultCct,
          },
        },
      };
    },
  },
};

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'Maintenance',
  data() {
    return {
      loaded: false,
    };
  },
  async created() {
    await this.listProjects();
    this.loaded = true;
  },
  computed: {
    ...mapGetters('projects', ['maintainedProjects', 'getHealthEntries']),
    projects() {
      return this.maintainedProjects;
    },
    createIllustration() {
      return {
        // eslint-disable-next-line global-require
        icon: require('../../assets/icons/images/maintenance.svg'),
        texts: [this.$t('maintenanceNoContract'), this.$t('maintenanceNoContractMessage')],
      };
    },
  },
  methods: {
    ...mapActions('projects', [
      'listProjects',
      'deleteProject',
      'lockProject',
      'unlockProject',
    ]),
    getHealthIssues(uuid) {
      return this.getHealthEntries(uuid).allErrors;
    },
    onClick(projectId) {
      this.$router.push({ name: 'maintenance-details', params: { projectId } });
    },
    formattedAddress(address) {
      const city = address.city && address.city.length ? `${address.city}, ` : '';
      const country = address.country && address.country.length ? address.country : '';
      const county = address.county && address.county.length ? `${address.county}, ` : '';
      const line1 = address.line1 && address.line1.length ? `${address.line1}, ` : '';
      const line2 = address.line2 && address.line2.length ? `${address.line2}, ` : '';
      const state = address.state && address.state.length ? `${address.state}, ` : '';
      const zipCode = address.zipCode && address.zipCode.length ? `${address.zipCode} ` : '';
      const formatted = line1 + line2 + zipCode + city + county + state + country;
      return Object.keys(address).length ? formatted : '';
    },
  },
};

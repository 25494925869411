//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: ['lights'],
  name: 'ChooseLightModal',
  methods: {
    selectTargetLight(uuid) {
      this.$emit('lightSelected', uuid);
    },
  },
};

//
//
//
//
//
//
//
//
//
//
//

const iconTableActive = require('@/assets/icons/general/table_view_active.svg');
const iconTableInactive = require('@/assets/icons/general/table_view_inactive.svg');
const iconListActive = require('@/assets/icons/general/list_view_active.svg');
const iconListInactive = require('@/assets/icons/general/list_view_inactive.svg');

export default {
  name: 'ViewSelector',
  props: ['value'],
  data() {
    return {
      states: [
        {
          key: 'list',
          icons: {
            active: iconListActive,
            inactive: iconListInactive,
          },
        }, {
          key: 'table',
          icons: {
            active: iconTableActive,
            inactive: iconTableInactive,
          },
        }],
    };
  },
  mounted() {
    this.changeState(this.value || 'table');
  },
  methods: {
    changeState(state) {
      this.$emit('input', state);
    },
  },
};

import deviceHealthService from '../services/deviceHealth';

const initialState = {
  deviceErrors: {
    gateway: [],
    devices: {},
  },
};

const actions = {
  async listDeviceErrors({ commit }) {
    const { data } = await deviceHealthService.listDeviceErrors();
    commit('listDeviceErrors', data);
  },
};

const mutations = {
  listDeviceErrors(state, payload) {
    state.deviceErrors = payload;
  },
};

const getters = { };

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  mutations,
};

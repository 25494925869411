//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapGetters } from 'vuex';
import RoomDevicesUI from '../../../../components/ui/roomUI/RoomDevicesUI.vue';

export default {
  name: 'RoomDevices',
  components: {
    RoomDevicesUI,
  },
  data() {
    return {
      dataLoaded: false,
      updatingDevice: false,
      projectId: this.$route.params.projectId,
      roomId: this.$route.params.roomId,
    };
  },
  async created() {
    await this.onGetDevices(true);
    await this.listRooms(this.projectId);
  },
  computed: {
    ...mapGetters('devices', ['getDevicesByRoom']),
    ...mapGetters('rooms', ['getRoomById', 'getRoomsByProjectId']),
    ...mapGetters('projects', ['getProjectById', 'isReadOnly', 'getHealthEntries']),
    readOnly() {
      return this.isReadOnly(this.projectId);
    },
    devices() {
      return this.getDevicesByRoom(this.projectId, this.roomId);
    },
    rooms() {
      return this.getRoomsByProjectId(this.projectId);
    },
    groups() {
      return this.rooms.reduce((acc, item) => ({ ...acc, [item.uuid]: item.groups }), {});
    },
    breadcrumbs() {
      const project = this.getProjectById(this.projectId);
      const room = this.getRoomById(this.roomId);
      return {
        show: true,
        showSeparator: true,
        parents: [{
          name: project.name,
          link: { name: this.$route.meta.parent },
        }],
        current: room.name,
      };
    },
    entityHealth() {
      return this.getHealthEntries(this.projectId);
    },
    hasEntityHealthIssue() {
      return (((this.entityHealth.byRoomAndType || {})[this.roomId] || {}).devices || []).length;
    },
  },
  methods: {
    ...mapActions('devices', ['listDevices', 'deleteDevice', 'updateDevice', 'bulkDeleteDevices', 'moveDevices']),
    ...mapActions('rooms', ['listRooms']),
    async onGetDevices(needLoader = false) {
      this.dataLoaded = !needLoader;
      await this.listDevices({ uuid: this.projectId, roomId: this.roomId });
      this.dataLoaded = true;
    },
    async onDeleteDevice(deviceId) {
      this.dataLoaded = false;
      await this.deleteDevice({ uuid: this.projectId, roomId: this.roomId, deviceId }).finally(() => {
        this.dataLoaded = true;
      });
      await this.onGetDevices(true);
    },
    async onBulkDeleteDevices(devices) {
      await this.bulkDeleteDevices({ uuid: this.projectId, roomId: this.roomId, deviceUUIDs: devices.map((device) => device.uuid) });
      await this.onGetDevices();
    },
    async onBulkMoveDevices(deviceIds, roomId, groupId) {
      const data = {
        deviceUUIDs: deviceIds,
        dstRoomUUID: roomId,
      };
      if (groupId.length > 0) {
        data.dstGroupUUIDs = [groupId];
      }
      await this.moveDevices({ uuid: this.projectId, roomId: this.roomId, data });
      await this.onGetDevices();
    },
    async onUpdateDevice({ deviceId, data }) {
      this.updatingDevice = true;
      await this.updateDevice({
        uuid: this.projectId, roomId: this.roomId, deviceId, data,
      });
      await this.onGetDevices();
      this.updatingDevice = false;
    },
  },
};

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions, mapState } from 'vuex';
import CustomTable from '@/components/ui/CustomTable.vue';
import SectionTitle from '@/components/ui/SectionTitle.vue';
import CustomModal from '@/components/ui/CustomModal.vue';
import ControllerOfflineModal from '@/components/ui/controller/ControllerOfflineModal.vue';
import { formatDate } from '@/utils/helpers';

export default {
  name: 'FirmwareUpdate',
  components: {
    ControllerOfflineModal,
    CustomModal,
    SectionTitle,
    CustomTable,
  },
  data() {
    return {
      updateInProgress: false,
      dataLoaded: false,
      projectId: this.$route.params.projectId,

      selectedFirmwareVersion: null,
      selectedFirmware: null,
      selectedProduct: null,
      updateFailed: false,

      progressModal: false,
    };
  },
  created() {
    this.onListGatewayProducts();
  },
  watch: {
    progress(newValue) {
      if (newValue === 100) {
        this.$bvModal.show('update-firmware-done-modal');
        this.projectUnlock();
      }
    },
  },
  computed: {
    ...mapGetters('projects', ['getProjectById', 'isReadOnly', 'getWorkInProgressData']),
    ...mapGetters('gateway', ['getGatewayProductById']),
    ...mapState('firmwares', ['firmwares']),
    ...mapGetters(['getConfigUpdatesById']),
    project() {
      return this.getProjectById(this.projectId);
    },
    progress() {
      const configUpdateMessage = this.getConfigUpdatesById(this.projectId);
      return configUpdateMessage.job ? configUpdateMessage.job.progress : 0;
    },
    gatewayProducts() {
      return this.getGatewayProductById(this.projectId, this.project.gatewayEthernetMACAddress);
    },
    progressModalProps() {
      return {
        title: this.$t('firmwareFirmwareUpdateToVersion', { version: this.selectedFirmwareVersion }),
        message: this.$t('firmwareRestoreToVersionMessage'),
        buttons: {
          main: { title: this.$t('generalClose'), click: this.closeProgressModal },
        },
      };
    },
    workInProgressData() {
      return this.getWorkInProgressData(this.projectId);
    },
  },
  methods: {
    ...mapActions(['resetConfigUpdates']),
    ...mapActions('firmwares', ['listFirmwareUpdates', 'updateFirmware', 'updateProductsFirmware']),
    ...mapActions('gateway', ['listGatewayProducts']),
    ...mapActions(['subscribeToProject', 'unsubscribeFromProject']),
    ...mapActions('projects', ['unlockProject']),
    async onListGatewayProducts() {
      await this.listGatewayProducts({ projectUUID: this.projectId });
    },
    async openFirmwareUpdatesModal(product) {
      await this.listFirmwareUpdates({
        uuid: this.projectId,
        bodyData: {
          identities: [{
            productUUID: product.productUuid,
            version: '0.0.0',
          }],
        },
      });
      this.selectedProduct = product;
      this.$bvModal.show('project-firmware-updates');
    },
    openUpdateFirmwareModal(firmware) {
      this.selectedFirmware = { ...firmware };
      this.selectedFirmwareVersion = firmware.displayVersion;
      this.$bvModal.hide('project-firmware-updates');
      this.$bvModal.show('update-firmware-modal');
    },
    async onUpdateFirmware() {
      if (!this.project.gatewayConnectedToCloud) {
        this.onCancel();
        this.$bvModal.show('controller-offline-modal');
        return;
      }
      this.updateFailed = false;
      this.updateInProgress = true;
      this.resetConfigUpdates(this.projectId);
      await this.subscribeToProject(this.projectId);
      await this.updateFirmware({ uuid: this.projectId, imageId: this.selectedFirmware.imageId })
        .then(() => {
          this.updateInProgress = false;
          this.$bvModal.hide('update-firmware-modal');
          this.progressModal = true;
        }).catch(() => {
          this.updateInProgress = false;
          this.updateFailed = true;
          this.$bvModal.hide('update-firmware-modal');
          this.$bvModal.show('update-firmware-done-modal');
          this.projectUnlock();
        });
    },
    async onUpdateProductFirmware() {
      if (!this.project.gatewayConnectedToCloud) {
        this.onCancel();
        this.$bvModal.show('controller-offline-modal');
        return;
      }
      this.updateFailed = false;
      this.updateInProgress = true;
      await this.updateProductsFirmware({
        uuid: this.projectId,
        productUUID: this.selectedProduct.productUuid,
        imageId: this.selectedFirmware.imageId,
      }).then(() => {
        this.updateInProgress = false;
        this.$bvModal.hide('update-firmware-modal');
        this.$bvModal.show('upload-product-firmware-done-modal');
        this.projectUnlock();
      }).catch(() => {
        this.updateInProgress = false;
        this.updateFailed = true;
        this.$bvModal.hide('update-firmware-modal');
        this.$bvModal.show('upload-product-firmware-done-modal');
        this.projectUnlock();
      });
    },
    onCancel() {
      this.$bvModal.hide('project-firmware-updates');
      this.$bvModal.hide('update-firmware-modal');
      this.$bvModal.hide('update-firmware-done-modal');
      this.$bvModal.hide('upload-product-firmware-done-modal');
      this.progressModal = false;
      this.updateFailed = false;
      this.selectedFirmware = null;
      this.selectedFirmwareVersion = null;
    },
    closeProgress() {
      this.progressModal = false;
      this.$bvModal.show('update-firmware-done-modal');
      this.projectUnlock();
    },
    closeProgressModal() {
      this.progressModal = false;
    },
    humanFileSize(size) {
      const i = Math.floor(Math.log(size) / Math.log(1024));
      return `${(size / (1024 ** i)).toFixed(2) * 1} ${['B', 'kB', 'MB', 'GB', 'TB'][i]}`;
    },
    async projectUnlock() {
      if (this.workInProgressData.hasWorkInProgressChanges || this.workInProgressData.lockedByOthers) {
        return;
      }
      await this.unlockProject(this.projectId).catch((e) => {
        throw e;
      });
    },
    getDate(value) {
      return formatDate(value);
    },
  },
};

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions } from 'vuex';
import EditSwitchLayoutUI from './EditSwitchLayoutUI.vue';
import CustomModal from '../../CustomModal.vue';
import DeviceDetails from '../devices/DeviceDetails.vue';
import TitleWithDeviceActions from '../devices/TitleWithDeviceActions.vue';
import deviceHelpers from '../../../../mixins/deviceHelpers';
import FixedFooter from '../../FixedFooter.vue';
import EditSwitchChannelsUI from './edit/EditSwitchChannelsUI.vue';
import IconLink from '../../IconLink.vue';
import CopySwitchModal from './CopySwitchModal.vue';
import MaintenanceErrorDetails from '../../MaintenanceErrorDetails.vue';

export default {
  name: 'RoomDeviceSwitchDetailsUI',
  components: {
    MaintenanceErrorDetails,
    CopySwitchModal,
    IconLink,
    EditSwitchChannelsUI,
    FixedFooter,
    TitleWithDeviceActions,
    DeviceDetails,
    CustomModal,
    EditSwitchLayoutUI,
  },
  mixins: [deviceHelpers],
  props: ['device', 'editMode', 'readOnly', 'hideOptions', 'loading', 'dataOptions', 'onDeviceAction', 'healthIssues'],
  data() {
    return {
      deviceDetailsEditMode: false,
      setupStep: 1,
      deviceDetailsModal: false,
      typeAndLayoutEditModal: false,
      layoutEditConfirmModal: false,
      typeAndLayout: {
        buttonType: 'push button',
        layout: 4,
      },
      updatedTypeAndLayout: null,
      copyType: 'from',
      copySwitchModal: false,
    };
  },
  watch: {
    device: {
      handler() {
        if (this.viewMode === 'DETAILS') {
          this.typeAndLayout = { ...this.calcTypeAndLayout(this.device) };
        }
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    setupFooterButtons() {
      if (this.setupStep === 1) {
        return {
          main: {
            title: this.$t('generalNext'),
            click: async () => {
              await this.onAction('SAVE_EDIT', {
                buttonType: this.typeAndLayout.buttonType,
                switchLayout: this.typeAndLayout.layout,
              });
              await this.onAction('EDIT');
              this.setupStep = 2;
            },
            loading: this.loading,
            disabled: this.loading || !this.typeAndLayout.buttonType || !this.typeAndLayout.layout,
          },
        };
      }

      return {
        main: {
          title: this.$t('generalSave'),
          click: async () => {
            const buttonsData = this.$refs.editSwitchChannelsUI.getButtonData();
            await this.validateChannels(buttonsData);
            const actions = this.deserializeButtons(buttonsData);
            await this.onAction('SAVE_EDIT', {
              buttonType: this.typeAndLayout.buttonType,
              actions,
            });
          },
        },
        secondary: {
          title: this.$t('generalBack'),
          click: async () => {
            await this.onAction('CANCEL_EDIT');
            this.setupStep = 1;
          },
        },
      };
    },
    editFooterButtons() {
      return {
        main: {
          title: this.$t('generalSave'),
          click: async () => {
            const buttonsData = this.$refs.editSwitchChannelsUI.getButtonData();
            await this.validateChannels(buttonsData);
            const actions = this.deserializeButtons(buttonsData);
            await this.onAction('SAVE_EDIT', {
              buttonType: this.typeAndLayout.buttonType,
              actions,
            });
          },
        },
        secondary: {
          title: this.$t('generalBack'),
          click: async () => {
            await this.onAction('CANCEL_EDIT');
            this.setupStep = 1;
          },
        },
      };
    },
    deviceDetailsModalProps() {
      let buttons = {
        main: {
          title: this.$t('generalClose'),
          click: () => {
            this.deviceDetailsModal = false;
          },
        },
      };

      if (this.deviceDetailsEditMode) {
        buttons = {
          main: {
            title: this.$t('generalSaveChanges'),
            click: async () => {
              await this.onDeviceDetailsAction('SAVE_EDIT', this.$refs.deviceDetails.updateData);
            },
            disabled: this.loading,
            loading: this.loading,
          },
          secondary: {
            title: this.$t('generalCancel'),
            click: () => {
              this.onDeviceDetailsAction('CANCEL_EDIT');
            },
          },
        };
      }
      return {
        title: this.$t('roomDevicesSavedSuccessfully'),
        buttons,
      };
    },
    typeAndLayoutModalProps() {
      const changed = this.updatedTypeAndLayout?.buttonType !== this.typeAndLayout?.buttonType
        || this.updatedTypeAndLayout?.layout !== this.typeAndLayout?.layout;
      const buttons = {
        main: {
          title: this.$t('generalSave'),
          click: () => {
            this.typeAndLayoutEditModal = false;
            this.layoutEditConfirmModal = true;
          },
          disabled: !changed,
        },
        secondary: {
          title: this.$t('generalCancel'),
          click: () => {
            this.updatedTypeAndLayout = null;
            this.typeAndLayoutEditModal = false;
          },
        },
      };

      return {
        title: this.$t('roomDevicesSwitchConfigurationEditMessage'),
        buttons,
      };
    },
    layoutEditConfirmModalProps() {
      return {
        title: this.$t('roomDevicesSwitchChangeLayout'),
        buttons: {
          main: {
            title: this.$t('generalConfirm'),
            click: async () => {
              await this.onAction('SAVE_EDIT', {
                buttonType: this.updatedTypeAndLayout.buttonType,
                switchLayout: this.updatedTypeAndLayout.layout,
                actions: [],
              }, true, true);
              this.typeAndLayout = { ...this.updatedTypeAndLayout };
              this.setupStep = 2;
              this.layoutEditConfirmModal = false;
            },
            disabled: this.loading,
            loading: this.loading,
          },
          secondary: {
            title: this.$t('generalCancel'),
            click: () => {
              this.layoutEditConfirmModal = false;
              this.typeAndLayoutEditModal = true;
            },
          },
        },
      };
    },
    viewMode() {
      return this.isSwitchConfigured() ? 'DETAILS' : 'SETUP';
    },
  },
  methods: {
    ...mapActions('app', ['setError']),
    onEditTypeAndLayout() {
      this.updatedTypeAndLayout = { ...this.typeAndLayout };
      this.typeAndLayoutEditModal = true;
    },
    async validateChannels(buttonsData) {
      let invalidChannel = null;
      buttonsData.forEach((button, i) => {
        const { target } = button;

        if (target.type && !target.uuid) {
          invalidChannel = { channel: i + 1, type: 'Target' };
        }

        if (target.type && target.uuid) {
          const selectedEvents = Object.entries(button.events)
            .filter(([, value]) => value.action).length;

          if (!selectedEvents) {
            invalidChannel = { channel: i + 1, type: 'Event' };
          }
        }
      });

      if (invalidChannel) {
        // await this.setError({
        //   errorMessage: 'Invalid action',
        //   errorCode: `${invalidChannel.type} missing at CHANNEL ${invalidChannel.channel}`,
        // });
        // throw new Error('Invalid action');
      }
    },
    onLayoutChange(data) {
      this.typeAndLayout = data;
    },
    onLayoutEdit(data) {
      this.updatedTypeAndLayout = data;
    },
    async onAction(action, data, onlyUpdate, forceEditMode = false) {
      await this.onDeviceAction(action, data, onlyUpdate, forceEditMode);
    },
    async onDeviceDetailsAction(action, data) {
      switch (action) {
        case 'EDIT':
          this.deviceDetailsEditMode = true;
          break;
        case 'SAVE_EDIT':
          await this.onAction(action, data, true);
          this.deviceDetailsEditMode = false;
          break;
        case 'CANCEL_EDIT':
          this.deviceDetailsEditMode = false;
          break;
        default:
          await this.onAction(action, data);
          break;
      }
    },
    showDeviceDetailsModal() {
      this.deviceDetailsModal = true;
    },
    showReuseSwitchModal(type) {
      this.copyType = type;
      this.copySwitchModal = true;
    },
  },
};

//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapState } from 'vuex';
import bus from '@/bus';

export default {
  name: 'NotificationToaster',
  computed: {
    ...mapState('app', ['projectUpdatedState']),
    projectId() {
      return this.$route.params.projectId || '';
    },
  },
  created() {
    if (!this.projectId.length) {
      this.onDismiss();
    }
  },
  methods: {
    ...mapActions('app', ['projectUpdatedEvent']),
    onDismiss() {
      this.projectUpdatedEvent({ updated: false });
    },
    onRefresh() {
      bus.$emit('forceRerender');
      this.projectUpdatedEvent({ updated: false });
    },
  },
};

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: ['label', 'value', 'readMode', 'list'],
  data() {
    return {
      internalItems: [],
      internalSelectedItems: [],
    };
  },
  mounted() {
    this.internalItems = [...this.value];
  },
  methods: {
    onInputChange(item, val) {
      item.checked = val;
      this.internalSelectedItems = this.internalItems.filter((itemObj) => itemObj.checked).map((i) => i.device.uuid);
      this.$emit('update', this.internalSelectedItems);
    },
    isItemSelected(item, itemId) {
      const checked = this.list.some((i) => i === itemId);
      item.checked = checked;
      return checked;
    },
  },
};

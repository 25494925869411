/* global axios */

const listRules = async ({ uuid, roomId }) => axios({
  method: 'GET',
  url: `/projects/${uuid}/rooms/${roomId}/rules`,
});

const getDefaultRule = async ({ uuid, roomId }) => axios({
  method: 'GET',
  url: `/projects/${uuid}/rooms/${roomId}/rules/defaultRule`,
});

const updateDefaultRule = async ({ uuid, roomId, data }) => axios({
  method: 'PUT',
  url: `/projects/${uuid}/rooms/${roomId}/rules/defaultRule`,
  lock: { uuid },
  data,
  logEvent: () => ({ eventName: 'edit_room_behaviour' }),
});

const getDefaultGroupRule = async ({ uuid, roomId, groupId }) => axios({
  method: 'GET',
  url: `/projects/${uuid}/rooms/${roomId}/groups/${groupId}/rules/defaultRule`,
});

const updateDefaultGroupRule = async ({
  uuid, roomId, groupId, data,
}) => axios({
  method: 'PUT',
  url: `/projects/${uuid}/rooms/${roomId}/groups/${groupId}/rules/defaultRule`,
  lock: { uuid },
  data,
  logEvent: () => ({ eventName: 'edit_group_behaviour' }),
});

const getRule = async ({ uuid, roomId, ruleId }) => axios({
  method: 'GET',
  url: `/projects/${uuid}/rooms/${roomId}/rules/${ruleId}`,
});

const createRule = async ({ uuid, roomId, data }) => axios({
  method: 'POST',
  url: `/projects/${uuid}/rooms/${roomId}/rules`,
  lock: { uuid },
  data,
});

const updateRule = async ({
  uuid, roomId, ruleId, data,
}) => axios({
  method: 'PUT',
  url: `/projects/${uuid}/rooms/${roomId}/rules/${ruleId}`,
  lock: { uuid },
  data,
});

const deleteRule = async ({ uuid, roomId, ruleId }) => axios({
  method: 'DELETE',
  url: `/projects/${uuid}/rooms/${roomId}/rules/${ruleId}`,
  lock: { uuid },
  data: {},
});

export default {
  listRules,
  getDefaultRule,
  updateDefaultRule,
  getDefaultGroupRule,
  updateDefaultGroupRule,
  getRule,
  createRule,
  updateRule,
  deleteRule,
};

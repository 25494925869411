//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

/* eslint global-require: 0 */
import { mapGetters } from 'vuex';

import NavItem from '@/components/layouts/navbars/sidebar/NavItem.vue';

const icons = {
  rooms: [require('../../../../assets/icons/sidebar/project/rooms.svg'), require('../../../../assets/icons/sidebar/project/rooms_active.svg')],
  details: [require('../../../../assets/icons/sidebar/project/details.svg'), require('../../../../assets/icons/sidebar/project/details_active.svg')],
  coworkers: [require('../../../../assets/icons/sidebar/project/coworkers.svg'), require('../../../../assets/icons/sidebar/project/coworkers_active.svg')],
  documents: [require('../../../../assets/icons/sidebar/project/documents.svg'), require('../../../../assets/icons/sidebar/project/documents_active.svg')],
  gateway: [require('../../../../assets/icons/sidebar/project/gateway.svg'), require('../../../../assets/icons/sidebar/project/gateway_active.svg')],
  maintenance: [require('../../../../assets/icons/sidebar/project/maintenance.svg'), require('../../../../assets/icons/sidebar/project/maintenance_active.svg')],
  firmwareUpdate: [require('../../../../assets/icons/sidebar/project/fwupdate.svg'), require('../../../../assets/icons/sidebar/project/fwupdate_active.svg')],
  schedules: [require('../../../../assets/icons/sidebar/project/schedules.svg'), require('../../../../assets/icons/sidebar/project/schedules_active.svg')],
  projectTemplates: [require('../../../../assets/icons/sidebar/project/project_templates.svg'), require('../../../../assets/icons/sidebar/project/project_templates_active.svg')],
  floorplan: [require('../../../../assets/icons/sidebar/project/floorplan.svg'), require('../../../../assets/icons/sidebar/project/floorplan_active.svg')],
};

export default {
  name: 'ProjectSidebar',
  components: { NavItem },
  props: ['remoteControlEnabled'],
  data() {
    return {
      active: 'project-rooms',
    };
  },
  created() {
    this.active = this.$route.name;
    this.fallbackToEnabledView();
  },
  watch: {
    remoteControlEnabled() {
      this.fallbackToEnabledView();
    },
    $route() {
      this.active = this.$route.name;
    },
  },
  computed: {
    ...mapGetters('projects', ['getHealthEntries', 'isUploadedAndConnected']),
    projectId() {
      return this.$route.params.projectId;
    },
    items() {
      return [
        {
          title: this.$t('pageRooms'),
          to: { name: 'project-rooms', params: { projectId: this.projectId } },
          icons: icons.rooms,
          hasIssue: this.hasRoomHealthIssue,
        },
        {
          title: this.$t('generalDetails'),
          to: { name: 'project-details', params: { projectId: this.projectId } },
          icons: icons.details,
        },
        {
          title: this.$t('pageCoworkers'),
          to: { name: 'project-coworkers', params: { projectId: this.projectId } },
          icons: icons.coworkers,
        },
        {
          title: this.$t('pageDocuments'),
          to: { name: 'project-documents', params: { projectId: this.projectId } },
          icons: icons.documents,
          disabled: this.remoteControlEnabled,
        },
        {
          title: this.$t('pageGateway'),
          to: { name: 'project-remote-maintenance', params: { projectId: this.projectId } },
          icons: icons.gateway,
          hasIssue: this.hasGatewayHealthIssue,
        },
        {
          title: this.$t('pageFirmwareUpdate'),
          to: { name: 'project-firmware-update', params: { projectId: this.projectId } },
          icons: icons.firmwareUpdate,
          disabled: !this.fwUpdateEnabled,
        },
        {
          title: this.$t('pageMaintenance'),
          to: { name: 'project-maintenance', params: { projectId: this.projectId } },
          icons: icons.maintenance,
        },
        {
          title: this.$t('pageSchedules'),
          to: { name: 'project-schedules', params: { projectId: this.projectId } },
          icons: icons.schedules,
          hasIssue: this.hasScheduleHealthIssue,
        },
        {
          title: this.$t('pageProjectTemplates'),
          to: { name: 'project-templates', params: { projectId: this.projectId } },
          icons: icons.projectTemplates,
        },
        {
          title: this.$t('pageFloorplanManager'),
          to: { name: 'project-floorplans', params: { projectId: this.projectId } },
          icons: icons.floorplan,
          disabled: this.remoteControlEnabled,
        },
      ];
    },
    entityHealth() {
      return this.getHealthEntries(this.projectId);
    },
    hasRoomHealthIssue() {
      return !!Object.keys(this.entityHealth.byRoom).length;
    },
    hasScheduleHealthIssue() {
      return !!(this.entityHealth.byCategory.schedule || []).length;
    },
    hasGatewayHealthIssue() {
      return !!(this.entityHealth.byCategory.gateway || []).length;
    },
    fwUpdateEnabled() {
      return this.isUploadedAndConnected(this.projectId);
    },
  },
  methods: {
    onItemClick(item) {
      if (this.active !== item.to.name && !item.disabled) {
        this.active = item.to.name;
        this.$router.push(item.to);
      }
    },
    fallbackToEnabledView() {
      if (this.remoteControlEnabled) {
        const currentItem = this.items.find((i) => i.to.name === this.active);
        const firstEnabledItem = this.items.find((i) => !i.disabled);
        if (currentItem && currentItem.disabled) {
          this.onItemClick(firstEnabledItem);
        }
      }
    },
  },
};

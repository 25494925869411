import sceneService from '../services/scenes';

const initialState = {
  scenes: {},
  scenesById: {},
};

const actions = {
  async listScenes({ commit }, { uuid, roomId }) {
    const { data } = await sceneService.listScenes({ uuid, roomId });
    commit('listScenes', { uuid, roomId, data });
  },
  async getScene({ commit }, { uuid, roomId, sceneId }) {
    const { data } = await sceneService.getScene({ uuid, roomId, sceneId });
    commit('getScene', { data, sceneId });
  },
  async createScene(ctx, { uuid, roomId, data }) {
    const response = await sceneService.createScene({ uuid, roomId, data });
    return response.data;
  },
  async updateScene({ commit }, {
    uuid, roomId, sceneId, data,
  }) {
    const response = await sceneService.updateScene({
      uuid, roomId, sceneId, data,
    });
    commit('getScene', { data: response.data, sceneId });
  },
  async deleteScene(ctx, { uuid, roomId, sceneId }) {
    await sceneService.deleteScene({ uuid, roomId, sceneId });
  },
};

const mutations = {
  listScenes(state, payload) {
    const scenes = { ...state.scenes };
    scenes[payload.uuid] = state.scenes[payload.uuid] || {};
    scenes[payload.uuid][payload.roomId] = payload.data;
    state.scenes = scenes;
    state.scenesById = payload.data.reduce((acc, item) => ({ ...acc, [item.uuid]: item }), {});
  },
  getScene(state, payload) {
    state.scenesById = { ...state.scenesById, [payload.sceneId]: payload.data };
  },
};

const getters = {
  getScenesByRoom: (state, actGetters, rootState, rootGetters) => (uuid, roomId) => {
    if (state.scenes[uuid]) {
      return (state.scenes[uuid][roomId] || [])
        .map((scene) => ({
          ...scene,
          values: scene.values || [],
          hasIssue: !!(rootGetters['projects/getHealthEntries'](uuid).byId[scene.uuid] || []).length,
        }));
    }
    return [];
  },
  getSceneById: (state) => (sceneId) => state.scenesById[sceneId] || {},
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  mutations,
};

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapActions } from 'vuex';
import ProductItem from '@/components/ui/productUI/ProductItem.vue';
import FixedFooter from '@/components/ui/FixedFooter.vue';
import SearchField from '@/components/ui/SearchField.vue';
import ViewSelector from '@/components/ui/ViewSelector.vue';
import CustomTable from '@/components/ui/CustomTable.vue';
import NumberField from '@/components/ui/NumberField.vue';

const PLACEHOLDER_FAMILY_IMAGE = require('../../../assets/placeholders/placeholder_lights.svg');

export default {
  name: 'ProductListUI',
  components: {
    FixedFooter, ProductItem, SearchField, ViewSelector, CustomTable, NumberField,
  },
  props: ['products', 'families', 'projectMode', 'productNavigate', 'loaded'],
  data() {
    return {
      searchText: '',
      sort: 'name-asc',
      deviceCollection: {},

      selectedProduct: null,

      categoryTranslates: {
        switches: 'generalSwitches',
        sensors: 'generalSensors',
        lights: 'generalLights',
      },
      rerenderTitle: 1,
    };
  },
  created() {
    this.searchText = '';
    this.sort = this.productSort;
  },
  watch: {
    family() {
      // there is an issue with family prop which sometimes does not seem to be reactive
      this.rerenderTitle += 1;
    },
    deviceCollection: {
      handler(val) {
        // LSC-6560 - greenPower: 2.3.1
        const greenPowerProducts = this.products.filter((p) => p.familyMeta?.name === 'green-power' && val[p.uuid] > 0);
        if (!greenPowerProducts.length) {
          return;
        }
        this.$nextTick(async () => {
          const supported = await this.ensureMinimumVersion({ uuid: this.projectId, version: '2.3.1' });
          if (!supported) {
            greenPowerProducts.forEach((p) => {
              this.$set(this.deviceCollection, p.uuid, 0);
            });
          }
        });
      },
      deep: true,
    },
  },
  computed: {
    ...mapState('app', ['selectedView', 'productSearch', 'productSort']),
    projectId() {
      return this.$route.params.projectId;
    },
    roomId() {
      return this.$route.params.roomId;
    },
    category() {
      return this.$route.params.deviceCategory;
    },
    family() {
      return this.$route.params.deviceFamily;
    },
    showFamilies() {
      return !this.family && this.showProducts && this.availableFamilyMeta.length > 0;
    },
    showProducts() {
      return this.loaded && this.products.length > 0;
    },
    categoryTitle() {
      if (this.family) {
        return this.family.replace(/-/g, ' '); // todo: family localization
      }
      if (this.category === 'lights') {
        return this.$t('productAllLights');
      }
      return this.$t(this.categoryTranslates[this.category]);
    },
    productsWithFamilyMeta() {
      return this.products.filter((p) => p.familyMeta);
    },
    availableFamilyMeta() {
      const families = this.productsWithFamilyMeta.reduce((acc, p) => {
        if (p.familyMeta) {
          acc[p.familyMeta.name] = p.familyMeta;
        }
        return acc;
      }, {});
      return Object.values(families).sort((a, b) => a.name.localeCompare(b.name));
    },
    footerButtons() {
      if (this.projectMode) {
        return {
          main: {
            title: this.$t('roomDevicesAddToRoom'),
            click: this.onAddToRoom,
          },
        };
      }
      return null;
    },
    categoryProducts() {
      if (this.family) {
        return this.products.filter((product) => product.family === this.family);
      }
      return this.products;
    },
    propsByCategory() {
      return {
        // there must be exactly two values per array
        lights: ['nominalWattage', 'luminousFlux'],
        sensors: ['sensingCapability', 'powerType'],
        switches: ['buttonType', 'powerType'],
      };
    },
    sortFields() {
      const sortFields = [];
      sortFields.push(
        { sort: 'name-asc', text: `${this.$t('product.name')} ${this.$t('generalAsc')}` },
        { sort: 'name-desc', text: `${this.$t('product.name')} ${this.$t('generalDesc')}` },
      );
      this.propsByCategory[this.category].map((prop) => sortFields.push(
        { sort: `${this.toKebabCase(prop)}-asc`, text: `${this.$t(`productPropertyKeys.${prop}`)} ${this.$t('generalAsc')}` },
        { sort: `${this.toKebabCase(prop)}-desc`, text: `${this.$t(`productPropertyKeys.${prop}`)} ${this.$t('generalDesc')}` },
      ));
      return sortFields.map((option) => ({ ...option, selected: option.sort === this.sort }));
    },
    mappedProducts() {
      return this.categoryProducts.map((product) => {
        product.props = {
          ean: product.ean || '',
          vendor: product.vendor || '',
          [this.propsByCategory[this.category][0]]: this.propertyValueByKey(this.propsByCategory[this.category][0], product) || '',
          [this.propsByCategory[this.category][1]]: this.propertyValueByKey(this.propsByCategory[this.category][1], product) || '',
        };
        return product;
      }).filter((product = {}) => {
        const searchFields = `${product.name || ''}&${product.ean || ''}&${product.vendor || ''}&${product.props?.nominalWattage || ''}&${product.props?.luminousFlux || ''}&${product.props?.buttonType || ''}&${product.props?.powerType || ''}`;
        return searchFields.toLowerCase().includes(this.searchText.toLowerCase());
      }).sort((a, b) => {
        switch (this.sort) {
          case 'name-asc':
            return a.name.localeCompare(b.name);
          case 'name-desc':
            return -a.name.localeCompare(b.name);
          case 'nominal-wattage-asc':
            return a.props.nominalWattage.localeCompare(b.props.nominalWattage);
          case 'nominal-wattage-desc':
            return -a.props.nominalWattage.localeCompare(b.props.nominalWattage);
          case 'luminous-flux-asc':
            return a.props.luminousFlux.localeCompare(b.props.luminousFlux);
          case 'luminous-flux-desc':
            return -a.props.luminousFlux.localeCompare(b.props.luminousFlux);
          case 'sensing-capability-asc':
            return a.props.sensingCapability.localeCompare(b.props.sensingCapability);
          case 'sensing-capability-desc':
            return -a.props.sensingCapability.localeCompare(b.props.sensingCapability);
          case 'power-type-asc':
            return a.props.powerType.localeCompare(b.props.powerType);
          case 'power-type-desc':
            return -a.props.powerType.localeCompare(b.props.powerType);
          case 'button-type-asc':
            return a.props.buttonType.localeCompare(b.props.buttonType);
          case 'button-type-desc':
            return -a.props.buttonType.localeCompare(b.props.buttonType);
          default:
            return a.name.localeCompare(b.name);
        }
      });
    },
  },
  methods: {
    ...mapActions('app', ['changeListView', 'changeProductSearch', 'changeProductSort']),
    ...mapActions('projects', ['ensureMinimumVersion']),
    onChangeView(view) {
      this.changeListView(view);
    },
    toKebabCase(str) {
      return str.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase();
    },
    familyRouterLink(familyMeta) {
      return this.projectMode ? `/projects/${this.projectId}/rooms/${this.roomId}/add/${this.category}/${familyMeta.name}` : `/products/${this.category}/${familyMeta.name}`;
    },
    propertyValueByKey(key, product) {
      const property = product.productProperties.find((prop) => prop.key === key);
      return property && property.value.toString().length > 0 ? `${property.value} ${property.unit}` : null;
    },
    getFamilyImageUrl(familyMeta) {
      if (familyMeta.image && familyMeta.image.large) {
        return familyMeta.image.large;
      }

      // fallback to placeholder
      return PLACEHOLDER_FAMILY_IMAGE;
    },
    onProductClick(product) {
      this.$router.push({ name: this.productNavigate || 'products-details', params: { productId: product.uuid } });
    },
    onFilterChange(e) {
      this.sort = e.sort;
      this.searchText = e.searchText;
      this.changeProductSort(e.sort);
      this.changeProductSearch(e.searchText);
    },
    onAddToRoom() {
      this.$emit('addDevicesToRoom', this.deviceCollection);
    },
    onAction(type, product) {
      this.selectedProduct = product;
    },
  },
};

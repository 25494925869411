import { render, staticRenderFns } from "./Step3PrimaryContact.vue?vue&type=template&id=1bab9420&scoped=true"
import script from "./Step3PrimaryContact.vue?vue&type=script&lang=js"
export * from "./Step3PrimaryContact.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1bab9420",
  null
  
)

export default component.exports
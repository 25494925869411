//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapGetters } from 'vuex';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { WizardBus } from '@/bus';
import WizardListUI from '@/components/ui/wizard/WizardListUI.vue';
import DeleteSceneModal from '@/views/projects/rooms/scenes/modals/DeleteSceneModal.vue';
import CustomSlider from '@/components/ui/CustomSlider.vue';

export default {
  name: 'WizardRoom',
  components: {
    CustomSlider,
    DeleteSceneModal,
    WizardListUI,
    ValidationObserver,
    ValidationProvider,
  },
  data() {
    return {
      dataLoaded: false,
      selectedScene: {},
      currentStep: 1,
      disableCreate: false,
      name: '',
      type: 'group',
      lightItems: [],
      groupItems: [],
      wholeRoom: {},
    };
  },
  async created() {
    WizardBus.$on('next', this.onNextStep);
    await this.onListScenes();
    await this.onListGroups();
    await this.onListDevices();
  },
  beforeDestroy() {
    WizardBus.$off('next', this.onNextStep);
  },
  computed: {
    ...mapGetters('scenes', ['getScenesByRoom']),
    ...mapGetters('groups', ['getGroupsByRoom']),
    ...mapGetters('devices', ['getDevicesByRoom']),
    ...mapGetters('rooms', ['getRoomById']),
    projectId() {
      return this.$route.params.projectId;
    },
    roomId() {
      return this.$route.params.roomId;
    },
    scenes() {
      return this.getScenesByRoom(this.projectId, this.roomId);
    },
    groups() {
      return this.getGroupsByRoom(this.projectId, this.roomId);
    },
    lights() {
      return this.getDevicesByRoom(this.projectId, this.roomId).lights;
    },
    room() {
      return this.getRoomById(this.roomId);
    },
    options() {
      return [
        { value: 'group', text: this.$t('generalGroups') },
        { value: 'light', text: this.$t('generalLights') },
      ];
    },
    roomSelectDisabled() {
      return this.groupItems.some((i) => i.selected);
    },
    groupSliders() {
      const selectedLights = this.groupItems
        .reduce((acc, item) => [...acc, ...(item.selected ? item.group.members : [])], []);
      return this.groupItems.map((item) => {
        const selectDisabled = !item.selected
          && (selectedLights.some((r) => item.group.members.includes(r))
            || this.wholeRoom.selected);
        return { ...item, selectDisabled };
      });
    },
    values() {
      let values = [];
      if (this.type === 'light') {
        values = this.lightItems
          .map((i) => {
            const on = !!i.data.value.level;
            i.data.value.on = on;
            i.data.value.level = on ? i.data.value.level : undefined;
            return i.data;
          });
      }
      if (this.type === 'group') {
        values = this.groupItems
          .map((i) => {
            const on = i.selected && !!i.data.value.level;
            i.data.value.on = on;
            i.data.value.level = on ? i.data.value.level : undefined;
            return i.data;
          });
        const { wholeRoom } = this;
        const on = wholeRoom.selected && !!wholeRoom.data.value.level;
        wholeRoom.data.value.on = on;
        wholeRoom.data.value.level = on ? wholeRoom.data.value.level : undefined;
        values.push(wholeRoom.data);
      }
      return values;
    },
    modalTitle() {
      return this.currentStep === 2
        ? `${this.currentStep}/2. ${this.$t('roomScenesDimLevel')}`
        : `${this.currentStep}/2. ${this.$t('roomScenesCreateScene')}`;
    },
    modalButtonLabel() {
      return this.currentStep === 2 ? this.$t('wizardFinishScene') : this.$t('wizardNext');
    },
  },
  methods: {
    ...mapActions('devices', ['listDevices']),
    ...mapActions('groups', ['listGroups']),
    ...mapActions('scenes', ['createScene', 'listScenes', 'deleteScene']),
    async onListScenes() {
      await this.listScenes({ uuid: this.projectId, roomId: this.roomId });
      this.dataLoaded = true;
      this.$nextTick(this.isSkippable);
    },
    async onListGroups() {
      await this.listGroups({ uuid: this.projectId, roomId: this.roomId });
    },
    async onListDevices() {
      await this.listDevices({ uuid: this.projectId, roomId: this.roomId });
    },
    async onDeleteScene() {
      await this.deleteScene({ uuid: this.projectId, roomId: this.roomId, sceneId: this.selectedScene.uuid });
      await this.onListScenes();
      this.$bvModal.hide('delete-scene-modal');
    },
    async showSceneModal() {
      await this.onListDevices();
      await this.resetForm();
      this.$bvModal.show('room-scene-modal');
    },
    showDeleteModal(scene) {
      this.selectedScene = scene;
      this.$bvModal.show('delete-scene-modal');
    },
    onNextStep() {
      this.$router.push({ name: this.$route.meta.nextRoute });
    },
    async modalClick() {
      if (this.currentStep === 1) {
        const valid = await this.$refs.observer.validate();
        if (!valid) {
          return;
        }
        this.currentStep += 1;
      } else {
        if (!this.wholeRoom.selected && !this.groupItems.some((g) => g.selected) && this.type === 'group') {
          return;
        }
        await this.onCreate();
      }
    },
    onChange(item, data) {
      item.selected = data.selected;
      item.data.value.on = !!data.value;
      item.data.value.level = data.value;
    },
    onCancel() {
      this.$bvModal.hide('room-scene-modal');
    },
    async onCreate() {
      this.disableCreate = true;
      const { name, description } = this;
      const data = { name, description, values: this.values };
      try {
        await this.createScene({ uuid: this.projectId, roomId: this.roomId, data });
        await this.onListScenes();
        this.$bvModal.hide('room-scene-modal');
      } finally {
        this.disableCreate = false;
      }
    },
    async resetForm() {
      this.currentStep = 1;
      this.name = '';
      this.type = 'group';
      this.lightItems = this.lights.map((light) => ({
        light,
        selected: false,
        data: {
          target: {
            type: 'light',
            uuid: light.uuid,
          },
          value: {
            on: true,
            level: 100,
          },
        },
      }));
      this.groupItems = this.groups.map((group) => ({
        group,
        selected: false,
        data: {
          target: {
            type: 'group',
            uuid: group.uuid,
          },
          value: {
            on: true,
            level: 100,
          },
        },
      }));
      this.wholeRoom = {
        room: this.room,
        selected: false,
        data: {
          target: {
            type: 'room',
            uuid: this.roomId,
          },
          value: {
            on: true,
            level: 100,
          },
        },
      };
    },
    isSkippable() {
      this.$parent.$parent.$data.skippable = !this.scenes.length;
    },
  },
};

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapState, mapActions } from 'vuex';
import PinchScrollZoom from '@coddicat/vue-pinch-scroll-zoom';

export default {
  name: 'FloorplanViewer',
  props: {
    modalIndex: Number,
    modal: {
      default: false,
      type: Boolean,
    },
  },
  components: {
    PinchScrollZoom,
  },
  data() {
    return {
      images: [],
      loaded: false,
      loadedViewer: false,
      selectedIndex: 0,
      viewer: {
        width: 400,
        height: 500,
        scale: 1,
        minScale: 1,
        maxScale: 5,
        zoomStep: 1,
        landscape: false,
        originX: 200,
        originY: 250,
        translateX: 0,
        translateY: 0,
        within: false,
      },
      image: {
        originalWidth: 0,
        originalHeight: 0,
        scaledWidth: 0,
        scaledHeight: 0,
      },
    };
  },
  mounted() {
    window.addEventListener('resize', this.resizeViewer);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.resizeViewer);
  },
  async created() {
    await this.listFloorplans({ uuid: this.projectId });
    this.loaded = true;
    this.getFloorplans();
  },
  watch: {
    selectedIndex() {
      this.changeTitle();
      this.resetViewer();
    },
    floorplanViewerReload(status) {
      this.onFloorplanViewerUpdate(status);
    },
  },
  computed: {
    ...mapState('projects', ['floorplans', 'roomFloorplans']),
    ...mapGetters('projects', ['getProjectById']),
    ...mapState('app', ['floorplanViewerReload']),
    projectId() {
      return this.$route.params.projectId;
    },
    project() {
      return this.getProjectById(this.projectId);
    },
  },
  methods: {
    ...mapActions('projects', ['listFloorplans']),
    ...mapActions('app', ['floorplanViewerUpdate']),
    getFloorplans() {
      if (this.floorplans.length) {
        this.floorplans.forEach((item) => {
          this.images.push({
            thumbnail: item.urls.thumbnail,
            source: item.urls.large,
            mini: item.urls.mini,
            name: item.name,
            uuid: item.uuid,
          });
        });
      }
      if (this.roomFloorplans.length) {
        [...this.roomFloorplans].map((room) => room.floorplans.map((floorplan) => (this.images.push({
          thumbnail: floorplan.urls.thumbnail,
          source: floorplan.urls.large,
          mini: floorplan.urls.mini,
          name: floorplan.name,
          uuid: floorplan.uuid,
        }))));
      }
      if (this.images.length) {
        this.changeTitle();
        this.loadedViewer = true;
        this.setModalIndex();
        this.$nextTick(() => this.resizeViewer());
      }
    },
    zoomIn() {
      if (Math.ceil(this.viewer.scale) === this.viewer.maxScale || this.viewer.scale > this.viewer.maxScale) {
        this.viewer.scale = this.viewer.maxScale;
      } else {
        this.viewer.scale += this.viewer.zoomStep;
      }
    },
    zoomOut() {
      if (Math.floor(this.viewer.scale) === this.viewer.minScale || this.viewer.scale < this.viewer.minScale) {
        this.viewer.scale = this.viewer.minScale;
      } else {
        this.viewer.scale -= this.viewer.zoomStep;
      }
    },
    nextPlan() {
      if (this.images.length === this.selectedIndex + 1) {
        this.selectedIndex = 0;
        return;
      }
      this.selectedIndex += 1;
    },
    prevPlan() {
      if (this.selectedIndex === 0) {
        this.selectedIndex = this.images.length - 1;
        return;
      }
      this.selectedIndex -= 1;
    },
    openModal() {
      this.$bvModal.show('floorplan-modal');
      this.$emit('setModalIndex', this.selectedIndex);
    },
    selectIndex(index) {
      this.selectedIndex = index;
    },
    setModalIndex() {
      if (this.modal && this.modalIndex) {
        this.selectedIndex = this.modalIndex;
      }
    },
    changeTitle() {
      if (this.floorplans.length) {
        this.$emit('titleChange', this.images[this.selectedIndex].name);
      }
    },
    getImageDimensions() {
      const img = new Image();
      img.onload = () => {};
      img.src = this.images[this.selectedIndex].source;
      this.image.originalWidth = img.width;
      this.image.originalHeight = img.height;
      this.resizeViewerImage();
    },
    resizeViewer() {
      if (!this.loaded || !this.images.length) {
        return;
      }
      const containerHeight = this.modal
        ? window.innerHeight - 32 // this 32 could be a margin, I'm not sure, but it eliminates the overflow problem
        : document.getElementById('floorplan-panel').offsetHeight;
      const containerWidth = this.modal
        ? window.innerWidth - 112 // nav button width on each side
        : this.$refs.floorplanViewerWrapper.clientWidth;
      const containerHeaderHeight = this.modal
        ? document.getElementById('floorplan-modal-title').offsetHeight
        : document.getElementById('floorplan-panel-header').offsetHeight;
      const viewerFooterHeight = this.$refs.floorplanViewerFooter.scrollHeight;
      this.viewer.width = Math.floor(containerWidth);
      this.viewer.height = Math.floor(containerHeight - containerHeaderHeight - viewerFooterHeight);
      this.viewer.landscape = this.viewer.width > this.viewer.height;
      this.resizeViewerImage();
    },
    resizeViewerImage() {
      this.image.scaledWidth = this.viewer.landscape
        ? Math.floor((this.viewer.height / this.image.originalHeight) * this.image.originalWidth)
        : this.viewer.width;
      this.image.scaledHeight = !this.viewer.landscape
        ? Math.floor((this.viewer.width / this.image.originalWidth) * this.image.originalHeight)
        : this.viewer.height;
      this.setOriginViewer();
      this.setTranslateViewer();
    },
    setTranslateViewer() {
      const centerX = Math.floor((this.viewer.originX - (this.image.scaledWidth / 2)) * this.viewer.scale);
      const centerY = Math.floor((this.viewer.originY - (this.image.scaledHeight / 2)) * this.viewer.scale);
      this.viewer.translateX = this.viewer.landscape ? centerX : 0;
      this.viewer.translateY = !this.viewer.landscape ? centerY : 0;
    },
    setOriginViewer() {
      this.viewer.originX = this.viewer.width / 2;
      this.viewer.originY = this.viewer.height / 2;
    },
    resetViewer() {
      this.viewer.scale = 1;
      this.setOriginViewer();
      this.setTranslateViewer();
    },
    onFloorplanViewerUpdate(status) {
      if (status) {
        this.images = [];
        this.getFloorplans();
        this.floorplanViewerUpdate(false);
      }
    },
  },
};

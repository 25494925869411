//
//
//
//
//
//

import { mapActions, mapGetters } from 'vuex';
import ProductListUI from '@/components/ui/productUI/ProductListUI.vue';

export default {
  name: 'ProductsList',
  components: {
    ProductListUI,
  },
  data() {
    return {
      dataLoaded: false,
    };
  },
  async created() {
    await this.listProducts({ category: this.category });
    this.dataLoaded = true;
  },
  computed: {
    ...mapGetters('products', ['getProductsByCategory']),
    projectId() {
      return this.$route.params.projectId;
    },
    roomId() {
      return this.$route.params.roomId;
    },
    category() {
      return this.$route.params.deviceCategory;
    },
    products() {
      return this.getProductsByCategory(this.category);
    },
    families() {
      return ['lamps', 'accessories', 'converters',
        'floodlights', 'downlights', 'spotlights',
        'panel-luminaires', 'linear-luminaires', 'biolux-human-centric-lighting-hcl'];
    },
  },
  methods: {
    ...mapActions('products', ['listProducts']),
  },
};

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from 'vuex';

import RoomGroupDetailsUI from '../../../../components/ui/roomUI/RoomGroupDetailsUI.vue';
import DeleteGroupModal from './modals/DeleteGroupModal.vue';

export default {
  name: 'RoomGroupDetail',
  components: {
    DeleteGroupModal,
    RoomGroupDetailsUI,
  },
  data() {
    return {
      projectId: this.$route.params.projectId,
      roomId: this.$route.params.roomId,
      groupId: this.$route.params.groupId,
      loaded: false,
      editMode: false,

      name: '',
      description: '',
      items: [],
      sensorItems: [],
    };
  },
  async created() {
    await this.listRooms(this.projectId);
    await this.getProjectConfig({ uuid: this.projectId });
    await this.listGroups({ uuid: this.projectId, roomId: this.roomId });
    await this.listDevices({ uuid: this.projectId, roomId: this.roomId });
    await this.getDefaultGroupRule({ uuid: this.projectId, roomId: this.roomId, groupId: this.groupId });
    this.resetForm();
    this.loaded = true;
  },
  computed: {
    ...mapGetters('rooms', ['getRoomById']),
    ...mapGetters('groups', ['getGroupById']),
    ...mapGetters('devices', ['getDevicesByRoom']),
    ...mapGetters('projects', ['getProjectById', 'isReadOnly']),
    ...mapGetters('configs', ['getProjectConfigById']),
    ...mapGetters('rules', ['getDefaultRuleByGroup']),
    projectConfig() {
      return this.getProjectConfigById(this.projectId);
    },
    readOnly() {
      return this.isReadOnly(this.projectId);
    },
    lights() {
      return this.getDevicesByRoom(this.projectId, this.roomId).lights;
    },
    sensors() {
      return this.getDevicesByRoom(this.projectId, this.roomId).sensors;
    },
    group() {
      return this.getGroupById(this.groupId);
    },
    defaultRule() {
      return this.getDefaultRuleByGroup(this.groupId);
    },
    mode() {
      return this.group.defaultBehaviour || 'manual';
    },
    hclLightWarning() {
      return this.lights.filter((light) => this.group.members.includes(light.uuid) && !light.product.capabilities.hcl).length > 0;
    },
    room() {
      return (this.projectConfig?.rooms || []).find((r) => r.uuid === this.roomId);
    },
    groups() {
      return (this.projectConfig?.groups || []).filter((group) => this.room.groups.includes(group.uuid));
    },
  },
  methods: {
    ...mapActions('groups', ['listGroups', 'updateGroup', 'deleteGroup']),
    ...mapActions('devices', ['listDevices']),
    ...mapActions('configs', ['getProjectConfig']),
    ...mapActions('rules', ['getDefaultGroupRule']),
    ...mapActions('rooms', ['listRooms']),
    async onSaveGroup(groupData) {
      const { name, description } = groupData;
      const members = groupData.items.filter((i) => i.checked).map((i) => i.value);
      const sensors = groupData.sensorItems.filter((i) => i.checked).map((i) => i.value);
      const data = {
        name, description, members, sensors,
      };
      await this.updateGroup({
        uuid: this.projectId, roomId: this.roomId, groupId: this.groupId, data,
      });
      await this.listGroups({ uuid: this.projectId, roomId: this.roomId });
    },
    openDeleteModal() {
      this.$bvModal.show('delete-group-modal');
    },
    async onDeleteGroup() {
      await this.deleteGroup({ uuid: this.projectId, roomId: this.roomId, groupId: this.groupId });
      this.$bvModal.hide('delete-group-modal');
      await this.$router.push({ name: 'room-groups', params: { projectId: this.projectId, roomId: this.roomId } });
    },
    switchMode(editMode) {
      if (!editMode) {
        this.resetForm();
        return;
      }
      this.items = this.lights.map((light) => {
        const groupsWithLight = this.groups.filter((group) => group.members && group.members.length);
        const disabled = groupsWithLight.some((group) => group.uuid !== this.groupId && (group.members || []).includes(light.uuid)) && !this.group.members.includes(light.uuid);
        return {
          value: light.uuid,
          text: light.product.name,
          sequenceNumber: light.sequenceNumber,
          checked: (this.group.members || []).includes(light.uuid),
          description: light.description,
          product: light.product,
          disabled,
          group: light.groups[0] && light.groups[0].uuid !== this.groupId ? light.groups[0] : null,
        };
      });
      this.sensorItems = this.sensors.map((sensor) => {
        const groupsWithSensor = this.groups.filter((group) => group.sensors && group.sensors.length);
        const disabled = groupsWithSensor.some((group) => group.uuid !== this.groupId && (group.sensors || []).includes(sensor.uuid));
        return {
          value: sensor.uuid,
          text: sensor.product.name,
          sequenceNumber: sensor.sequenceNumber,
          checked: (this.group.sensors || []).includes(sensor.uuid),
          description: sensor.description,
          product: sensor.product,
          disabled,
          group: sensor.groups[0] && sensor.groups[0].uuid !== this.groupId ? sensor.groups[0] : null,
        };
      });
    },
    resetForm() {
      this.name = this.group.name;
      this.description = this.group.description;
      this.items = this.lights.map((light) => ({
        value: light.uuid,
        text: light.product.name,
        sequenceNumber: light.sequenceNumber,
        checked: true,
        description: light.description,
        product: light.product,
      })).filter((i) => (this.group.members || []).includes(i.value));
      this.sensorItems = this.sensors.map((sensor) => ({
        value: sensor.uuid,
        text: sensor.product.name,
        sequenceNumber: sensor.sequenceNumber,
        checked: true,
        description: sensor.description,
        product: sensor.product,
      })).filter((i) => (this.group.sensors || []).includes(i.value));
    },
  },
};

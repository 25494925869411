import gatewayService from '../services/gateway';

const initialState = {
  gatewayProductsById: {},
  gatewayLogs: {},
  timeByProjectId: {},
};

const actions = {
  async listGatewayProducts({ commit }, { projectUUID } = {}) {
    const { data } = await gatewayService.listGatewayProducts({ projectUUID });
    commit('listGatewayProducts', { projectUUID, data });
  },
  async listGatewayLogs({ commit }, { projectUUID } = {}) {
    const { data } = await gatewayService.listGatewayLogs({ projectUUID });
    commit('listGatewayLogs', { projectUUID, data });
  },
  async getGatewayLog(ctx, { projectUUID, fileName }) {
    const { data } = await gatewayService.getGatewayLog({ projectUUID, fileName });
    return data;
  },
  async getGatewayTime({ commit }, { projectUUID } = {}) {
    const { data } = await gatewayService.getGatewayTime({ projectUUID });
    commit('getGatewayTime', { projectUUID, data });
  },
};

const mutations = {
  listGatewayProducts(state, { projectUUID, data }) {
    state.gatewayProductsById = { ...state.gatewayProductsById, [projectUUID]: data };
  },
  listGatewayLogs(state, payload) {
    state.gatewayLogs = { ...state.gatewayLogs, [payload.projectUUID]: payload.data };
  },
  getGatewayTime(state, payload) {
    state.timeByProjectId = { ...state.timeByProjectId, [payload.projectUUID]: payload.data };
  },
};

const getters = {
  getGatewayProductById: (state) => (projectUUID, hasGateway) => {
    const products = state.gatewayProductsById[projectUUID] || [];
    const result = {
      gateway: null,
      products: [],
    };
    products.forEach((p) => {
      if (p.category === 'gateways') {
        result.gateway = p;
        result.gateway = { ...result.gateway, actualVersion: (result.gateway.actualVersions[0] || {}).version || '?' };
        return;
      }
      const versions = (p.actualVersions || []).filter((v) => v && v.version && v.version !== 'null');
      if (versions.length) {
        result.products = [...result.products, p];
      }
    });
    if (!result.gateway && hasGateway) {
      // For pre-1.2.6 gateways, if backend does not return data
      result.gateway = {
        manufacturer: '1189',
        model: '007E',
        category: 'gateways',
        actualVersion: '<=1.2.6',
      };
    }
    return result;
  },
  getGatewayLogs: (state) => (projectUUID) => state.gatewayLogs[projectUUID] || [],
  getTimeByProjectId: (state) => (projectUUID) => state.timeByProjectId[projectUUID] || [],
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  mutations,
};

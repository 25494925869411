//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import NavBarMain from '@/components/layouts/navbars/NavBarMain.vue';
import NavBarLocal from '@/components/layouts/navbars/NavBarLocal.vue';
import FixedFooter from '@/components/ui/FixedFooter.vue';
import WizardUI from '@/components/ui/wizard/WizardUI.vue';

import { WizardBus } from '@/bus';

/*
Valid layout options:
  - breadcrumbs (array)
  - browserBack (bool)
  - showProjectFilter (bool)
  - currentStep (number)
  - progressSteps (number)
 */

export default {
  name: 'MainLayout',
  props: ['options'],
  components: {
    WizardUI,
    FixedFooter,
    NavBarMain,
    NavBarLocal,
  },
  data() {
    return {
      skippable: false,
      disabledFooterButton: false,
    };
  },
  computed: {
    footerButtons() {
      return {
        details: {
          main: {
            title: this.options.skippable && this.skippable ? this.$t('wizardSkip') : this.$t('wizardNext'),
            click: () => WizardBus.$emit('next'),
          },
          secondary: {
            title: this.$t('wizardAbort'),
            click: () => {
              this.$bvModal.show('abort-wizard-modal');
            },
          },
        },
        overview: {
          main: {
            title: this.$t('wizardCreateRoom'),
            click: () => WizardBus.$emit('next'),
            class: this.disabledFooterButton ? 'disabled' : '',
          },
          secondary: {
            title: this.$t('wizardFinishProject'),
            click: () => WizardBus.$emit('createProject'),
            class: this.disabledFooterButton ? 'disabled' : '',
          },
        },
      };
    },
  },
  methods: {
    abortWizard() {
      WizardBus.$emit('abort');
      this.$router.push({ name: 'projects' });
    },
  },
};

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from 'vuex';
import MoveDevicesModal from '../../../../components/ui/roomUI/modals/MoveDevicesModal.vue';
import deviceHelpers from '../../../../mixins/deviceHelpers';
import DeleteDeviceModal from './DeleteDeviceModal.vue';
import RoomDeviceDetailsUI from '../../../../components/ui/roomUI/RoomDeviceDetailsUI.vue';

export default {
  name: 'RoomDeviceDetail',
  components: {
    MoveDevicesModal,
    DeleteDeviceModal,
    RoomDeviceDetailsUI,
  },
  mixins: [deviceHelpers],
  data() {
    return {
      loading: false,
      projectId: this.$route.params.projectId,
      roomId: this.$route.params.roomId,
      deviceId: this.$route.params.deviceId,
      activeButton: '',
    };
  },
  async created() {
    await this.listRooms(this.projectId);
    await this.listGroups({ uuid: this.projectId, roomId: this.roomId });
    await this.onGetDevice();
    await this.listProducts({ uuid: this.projectId, category: 'lights' });
    await this.getProjectConfig({ uuid: this.projectId });
  },
  computed: {
    ...mapGetters('rooms', ['getRoomsByProjectId']),
    ...mapGetters('configs', ['getProjectConfigById']),
    ...mapGetters('devices', ['getDevicesByRoom', 'getDeviceById']),
    ...mapGetters('products', ['getProductById']),
    ...mapGetters('projects', ['getProjectById', 'isReadOnly', 'getHealthEntries']),
    hideOptions() {
      return {
        hideDeleteLight: !this.$canI('deleteLight', 'devices'),
        hideDeleteSensor: !this.$canI('deleteSensor', 'devices'),
        hideDeleteSwitch: !this.$canI('deleteSwitch', 'devices'),

        hideEditLight: !this.$canI('addLight', 'groups') || !this.$canI('deleteLight', 'groups'),
        hideEditSensor: !this.$canI('addSensor', 'groups') || !this.$canI('deleteSensor', 'groups'),
        hideEditSwitch: !this.$canI('updateSwitchActions', 'devices'),

        hideEditDescription: !this.$canI('updateDescription', 'devices'),
        hideCreateRule: !this.$canI('create', 'rules'),
      };
    },
    healthIssues() {
      return this.getHealthEntries(this.projectId).byId[this.deviceId] || [];
    },
    readOnly() {
      return this.isReadOnly(this.projectId);
    },
    project() {
      return this.getProjectById(this.projectId);
    },
    projectConfig() {
      return this.getProjectConfigById(this.projectId);
    },
    rooms() {
      return this.getRoomsByProjectId(this.projectId);
    },
    devices() {
      return this.getDevicesByRoom(this.projectId, this.roomId);
    },
    device() {
      return this.getDeviceById(this.deviceId);
    },
    groupsByRooms() {
      return this.rooms.reduce((acc, item) => ({ ...acc, [item.uuid]: item.groups }), {});
    },
    roomsOptions() {
      return this.rooms
        .map((room) => ({ value: room.uuid, text: room.name }));
    },
    groupsOptions() {
      const currentRoom = (this.projectConfig?.rooms || []).find((room) => room.uuid === this.roomId) || {};
      const currentRoomGroups = currentRoom.groups || [];
      let hasGroups = false;
      let hasOtherGroups = false;
      const groupsOptions = (this.projectConfig?.groups || [])
        .map((group) => {
          const includesCurrentRoom = currentRoomGroups.includes(group.uuid);
          if (includesCurrentRoom) {
            hasGroups = true;
          } else {
            hasOtherGroups = true;
          }
          const order = includesCurrentRoom ? '2' : '4';
          return {
            value: group.uuid,
            text: group.name,
            order,
          };
        });

      if (hasGroups) {
        groupsOptions.push({
          order: '1', value: '-', text: 'Groups in this room:', disabled: true,
        });
      }
      if (hasOtherGroups) {
        groupsOptions.push({
          order: '3', value: '-', text: 'Groups in other rooms:', disabled: true,
        });
      }

      groupsOptions.sort((a, b) => {
        const aString = a.order + a.text;
        const bString = b.order + b.text;
        return aString > bString ? 1 : -1;
      });

      return groupsOptions;
    },
    sceneOptions() {
      return (this.projectConfig?.scenes || [])
        .map((scene) => ({ value: scene.uuid, room: scene.room, text: scene.name }));
    },
    allLights() {
      const allDevices = this.projectConfig?.devices || {};
      return (allDevices.lights || []).map((light) => {
        const product = ((this.projectConfig?.products || {})?.lights || [])
          .find((prod) => prod.uuid === light.product);
        return { ...light, product: { ...product, ...(this.getProductById(light.product) || {}) } };
      });
    },
    lights() {
      const currentRoom = (this.projectConfig?.rooms || []).find((room) => room.uuid === this.roomId) || {};

      const lightsData = this.allLights.map((light) => {
        const order = (currentRoom.members || []).includes(light.uuid) ? '1' : '3';
        return { order, light };
      });

      lightsData.push({ order: '2', light: { uuid: 'separator', product: {} } });

      lightsData.sort((a, b) => {
        const aString = a.order + (a.light.product || {}).name + a.light.description;
        const bString = b.order + (b.light.product || {}).name + b.light.description;
        return aString > bString ? 1 : -1;
      });

      return lightsData
        .reduce((acc, data) => ({ ...acc, [data.light.uuid]: data.light }), {});
    },
    allSwitches() {
      const allDevices = this.projectConfig?.devices || {};
      return (allDevices.switches || []).map((switchDevice) => {
        const product = ((this.projectConfig?.products || {})?.switches || [])
          .find((prod) => prod.uuid === switchDevice.product);
        return {
          ...switchDevice,
          product: { ...product, ...(this.getProductById(switchDevice.product) || {}) },
          room: this.projectConfig.rooms.find((room) => room.switches.includes(switchDevice.uuid))?.uuid,
        };
      });
    },
    switchesOptions() {
      return this.allSwitches.filter((sw) => sw.uuid)
        .map((sw) => ({
          value: sw.uuid,
          text: `${sw.sequenceNumber} ${sw.product.name}${sw.description ? ` (${sw.description})` : ''}`,
          device: sw,
        }));
    },
  },
  methods: {
    ...mapActions('rooms', ['listRooms']),
    ...mapActions('products', ['listProducts']),
    ...mapActions('configs', ['getProjectConfig']),
    ...mapActions('devices', ['getDevice', 'deleteDevice', 'updateDevice', 'moveDevices', 'copySwitch']),
    ...mapActions('groups', ['listGroups']),

    /** Save device */
    async saveDevice(params = {}) {
      const data = {
        name: this.device.product.name,
        ...params,
      };
      this.loading = true;
      try {
        await this.updateDevice({
          uuid: this.projectId, roomId: this.roomId, deviceId: this.deviceId, data,
        });
        await this.onGetDevice();
      } finally {
        this.loading = false;
      }
    },

    /** Delete device */
    openDeleteModal() {
      this.$bvModal.show('delete-device-modal');
    },
    async onDeleteDevice() {
      this.loading = true;
      await this.deleteDevice({ uuid: this.projectId, roomId: this.roomId, deviceId: this.deviceId })
        .finally(() => {
          this.loading = false;
        });
      this.$bvModal.hide('delete-device-modal');
      await this.$router.push({ name: 'room-devices', params: { projectId: this.projectId, roomId: this.roomId } });
    },

    /** Get device */
    async onGetDevice() {
      this.loading = true;
      await this.getDevice({ uuid: this.projectId, roomId: this.roomId, deviceId: this.deviceId }).finally(() => {
        this.loading = false;
      });
    },

    /** Move device */
    showMoveModal() {
      this.$bvModal.show(`move-devices-modal-${this.category}`);
    },
    async onMoveDevice(roomId, groupId) {
      const data = {
        deviceUUIDs: [this.deviceId],
        dstRoomUUID: roomId,
      };
      if (groupId.length > 0) {
        data.dstGroupUUIDs = [groupId];
      }
      await this.moveDevices({ uuid: this.projectId, roomId: this.roomId, data });
      await this.$router.push({ name: 'device-detail', params: { projectId: this.projectId, roomId, deviceId: this.deviceId } });
      await this.$router.go(0);
      this.$bvModal.hide(`move-devices-modal-${this.category}`);
    },

    /** Copy switch */
    async copySwitchConfig({ fromDeviceId, toDeviceIds }) {
      const data = {
        source: fromDeviceId,
        target: toDeviceIds,
      };
      this.loading = true;
      try {
        await this.copySwitch({ uuid: this.projectId, roomId: this.roomId, data });
        await this.onGetDevice();
        await this.getProjectConfig({ uuid: this.projectId });
      } finally {
        this.loading = false;
      }
    },
  },
};

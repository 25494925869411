//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapGetters } from 'vuex';
import FloorplanDetailsUI from '@/components/ui/floorplan/FloorplanDetailsUI.vue';

export default {
  components: {
    FloorplanDetailsUI,
  },
  data() {
    return {
      projectId: this.$route.params.projectId,
      disableCreate: true,
    };
  },
  computed: {
    ...mapGetters('rooms', ['getRoomsByProjectId']),
    rooms() {
      return this.getRoomsByProjectId(this.projectId);
    },
  },
  methods: {
    ...mapActions('projects', ['uploadFloorplans']),
    async onCreateClicked() {
      this.disableCreate = true;
      try {
        const data = await this.$refs.form.onSave.bind(this.$refs.form)();
        if (!data) {
          return;
        }
        await this.uploadFloorplans({ uuid: this.projectId, files: data });
        this.$bvModal.hide('add-floorplan-modal');
        this.$emit('saved');
      } finally {
        this.disableCreate = false;
      }
    },
    onStateChanged({ enableUpload }) {
      this.disableCreate = !enableUpload;
    },
    onCancel() {
      this.$bvModal.hide('add-floorplan-modal');
    },
  },
};

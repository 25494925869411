//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

/*
 items - Array of items
 item - Object: { value: uniq string, text: text to show }
 */
export default {
  props: ['value', 'label', 'items', 'readMode', 'hideLabel', 'description'],
  data() {
    return {};
  },
};

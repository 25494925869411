//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapGetters } from 'vuex';
import { logEvent } from '@/firebase';

export default {
  name: 'ProjectDocuments',
  data() {
    return {
      loading: false,
      projectId: this.$route.params.projectId,
      error: null,
    };
  },
  computed: {
    ...mapGetters('projects', ['getProjectById']),
    projectName() {
      return this.getProjectById(this.projectId).name;
    },
  },
  methods: {
    ...mapActions('projects', ['downloadDocument']),
    async downloadFile(type) {
      this.loading = true;
      await this.downloadDocument({ uuid: this.projectId, type })
        .then((data) => {
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(new Blob([data]));
          link.setAttribute('download', `${this.projectName}.${type}.pdf`);
          document.body.appendChild(link);
          link.click();
          logEvent('download_document', { type });
        }).catch((err) => {
          this.error = err;
        }).finally(() => {
          this.loading = false;
        });
    },
    clearError() {
      this.error = null;
    },
  },
};

//
//
//
//
//
//
//
//
//
//
//
//

import LocaleCountrySelect from '@/components/ui/LocaleCountrySelect.vue';

export default {
  components: {
    LocaleCountrySelect,
  },
  props: ['isAuthenticated'],
  methods: {
    closeModal() {
      this.$bvModal.hide('nav-locale-country-modal');
    },
    otherCountrySelected(country) {
      this.$emit('otherCountrySelected', country);
    },
  },
};

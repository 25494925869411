//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import CustomModal from '../../CustomModal.vue';
import SectionTitle from '../../SectionTitle.vue';
import CustomSelect from '../../CustomSelect.vue';
import deviceHelpers from '../../../../mixins/deviceHelpers';
import EditSwitchChannelsUI from './edit/EditSwitchChannelsUI.vue';
import RoomDeviceCheckboxSelect from '../RoomDeviceCheckboxSelect.vue';

export default {
  name: 'CopySwitchModal',
  mixins: [deviceHelpers],
  props: ['value', 'dataOptions', 'copyType', 'device', 'onDeviceAction', 'loading'],
  components: {
    RoomDeviceCheckboxSelect,
    EditSwitchChannelsUI,
    CustomSelect,
    SectionTitle,
    CustomModal,
  },
  data() {
    return {
      selectedDeviceId: '',
      selectedDeviceIds: [],
      roomId: this.$route.params.roomId,
    };
  },
  computed: {
    selectedSwitchDevice() {
      const option = this.dataOptions.switches.find((s) => s.value === this.selectedDeviceId);
      if (option && option.device) {
        return option.device;
      }
      return null;
    },
    typeAndLayout() {
      return this.calcTypeAndLayout(this.copyType === 'from' ? this.selectedSwitchDevice : this.device);
    },
    switchOptionsTo() {
      return this.dataOptions.switches
        .filter((item) => item.device?.room === this.roomId)
        .map((item) => ({ ...item, disabled: this.buttonType !== item.device.buttonType || item.value === this.device.uuid }));
    },
    switchOptionsFrom() {
      return [{
        text: this.$t('roomDevicesSwitchConfigurationSelectSwitch'), value: '', selected: true, disabled: true,
      }, ...this.dataOptions.switches.filter((item) => item.device?.room === this.roomId).map((item) => ({
        ...item,
        disabled: this.buttonType !== item.device.buttonType || item.value === this.device.uuid,
      }))];
    },
    copySwitchModalProps() {
      return {
        title: this.copyType === 'from'
          ? this.$t('roomDevicesSwitchConfigurationCopyFrom')
          : this.$t('roomDevicesSwitchConfigurationCopyTo'),
        buttons: {
          main: this.copyType === 'from' ? {
            title: this.$t('roomDevicesSwitchConfigurationCopy'),
            click: async () => {
              await this.onDeviceAction('COPY_SWITCH_CONFIG', { fromDeviceId: this.selectedDeviceId, toDeviceIds: [this.device.uuid] });
              this.$emit('input', false);
            },
            disabled: this.loading || !this.selectedSwitchDevice,
            loading: this.loading,
          } : {
            title: this.$t('roomDevicesSwitchConfigurationCopy'),
            click: async () => {
              await this.onDeviceAction('COPY_SWITCH_CONFIG', { fromDeviceId: this.device.uuid, toDeviceIds: this.selectedDeviceIds });
              this.closeModal();
            },
            disabled: this.loading || !this.selectedDeviceIds.length,
            loading: this.loading,
          },
          secondary: {
            title: this.$t('generalCancel'),
            click: () => {
              this.closeModal();
            },
          },
        },
      };
    },
  },
  methods: {
    onSwitchCheck(value) {
      this.selectedDeviceIds = value;
    },
    closeModal() {
      this.$emit('input', false);
      this.selectedDeviceId = '';
      this.selectedDeviceIds = [];
    },
  },
};

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { ValidationObserver } from 'vee-validate';
import MaintenanceErrorDetails from '@/components/ui/MaintenanceErrorDetails.vue';
import InputField from '@/components/ui/InputField.vue';
import IconLink from '@/components/ui/IconLink.vue';
import CustomSlider from '@/components/ui/CustomSlider.vue';
import SectionTitle from '@/components/ui/SectionTitle.vue';

export default {
  name: 'RoomGroupsUI',
  props: ['name', 'description', 'type', 'lightItems', 'groupItems', 'wholeRoom', 'saveScene',
    'readOnly', 'hideEdit', 'hideDelete', 'healthIssues', 'loaded'],
  components: {
    ValidationObserver,
    InputField,
    CustomSlider,
    IconLink,
    SectionTitle,
    MaintenanceErrorDetails,
  },
  data() {
    return {
      editMode: false,
      disableSave: false,
      internalType: '',
      data: {},
      defaultLevel: 0,
      defaultInitialLevel: 100,
      defaultCct: 4000,
      levelRange: {
        from: 0,
        to: 100,
        unit: '%',
      },
    };
  },
  created() {
    const {
      name, description, lightItems, groupItems, wholeRoom,
    } = this;
    this.data = {
      name, description, lightItems, groupItems, wholeRoom,
    };
  },
  watch: {
    name(val) {
      this.data.name = val;
    },
    description(val) {
      this.data.description = val;
    },
    lightItems(val) {
      this.data.lightItems = val;
    },
    groupItems(val) {
      this.data.groupItems = val;
    },
    wholeRoom(val) {
      this.data.wholeRoom = val;
    },
    type(val) {
      this.internalType = val;
    },
  },
  computed: {
    roomSelectDisabled() {
      return this.data.groupItems.some((i) => i.selected);
    },
    groupSliders() {
      const selectedGroups = this.data.groupItems
        .reduce((acc, item) => [...acc, ...(item.selected ? item.group.members : [])], []);
      return this.data.groupItems.map((item) => {
        const selectDisabled = !item.selected && (selectedGroups.some((r) => item.group.members.includes(r)) || this.data.wholeRoom.selected);
        return { ...item, selectDisabled };
      });
    },
    values() {
      let values = [];
      if (this.internalType === 'light') {
        values = this.data.lightItems
          .map((i) => {
            const on = !!i.data.value.level && !!i.data.value.cct;
            i.data.value.on = on;
            i.data.value.level = on ? i.data.value.level : undefined;
            i.data.value.cct = on ? i.data.value.cct : undefined;
            return i.data;
          });
      }
      if (this.internalType === 'group') {
        values = this.data.groupItems
          .filter((g) => g.selected)
          .map((i) => {
            const on = !!i.data.value.level && !!i.data.value.cct;
            i.data.value.on = on;
            i.data.value.level = on ? i.data.value.level : undefined;
            i.data.value.cct = on ? i.data.value.cct : undefined;
            return i.data;
          });
        const { wholeRoom } = this.data;
        const on = !!wholeRoom.data.value.level && !!wholeRoom.data.value.cct;
        wholeRoom.data.value.on = on;
        wholeRoom.data.value.level = on ? wholeRoom.data.value.level : undefined;
        wholeRoom.data.value.cct = on ? wholeRoom.data.value.cct : undefined;
        if (wholeRoom.selected) {
          values.push(wholeRoom.data);
        }
      }
      return values;
    },
    options() {
      return [
        { value: 'group', text: this.$t('roomScenesSceneBasedOnGroups') },
        { value: 'light', text: this.$t('roomScenesSceneBasedOnLights') },
      ];
    },
    disableSaveButton() {
      return this.disableSave || (!this.wholeRoom.selected && !this.groupItems.some((g) => g.selected) && this.internalType === 'group');
    },
  },
  methods: {
    switchMode(mode) {
      this.$emit('switchMode', mode);
      this.editMode = mode;
      this.disableSave = false;
      this.internalType = this.type;
    },
    async onSave() {
      const valid = await this.$refs.observer.validate();
      if (!valid) {
        return;
      }
      this.disableSave = true;
      await this.saveScene({
        name: this.data.name,
        description: this.data.description,
        values: this.values,
      });
      this.switchMode(false);
    },
    onDelete() {
      this.$emit('delete');
    },
    onChange(item, data, type) {
      if (type === 'selected') {
        item.selected = data;
        item.data.value.on = !!data;
        item.data.value.level = item.data.value.on ? this.defaultInitialLevel : undefined;
        item.data.value.cct = item.data.value.on ? this.defaultCct : undefined;
        return;
      }
      item.selected = data.selected;
      item.data.value.on = !!data.value;
      if (type === 'level') {
        item.data.value.level = data.value;
        if (item.data.value.cct === undefined) {
          item.data.value.cct = this.defaultCct;
        }
      }
      if (type === 'cct') {
        item.data.value.cct = data.value;
        if (item.data.value.level === undefined) {
          item.data.value.level = this.defaultInitialLevel;
        }
      }
    },
    cctRange(item) {
      return {
        from: this.getPropertyValue(item, 'cctMin') || 2700,
        to: this.getPropertyValue(item, 'cctMax') || 6500,
      };
    },
    valueLabel(label, value, unit) {
      return this.editMode ? `${label}` : `${label}: ${value}${unit}`;
    },
    getPropertyValue(item, propertyKey) {
      return item.product?.productProperties?.find((prop) => prop.key === propertyKey)?.value || null;
    },
  },
};

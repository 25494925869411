//
//
//
//
//
//
//
//

export default {
  name: 'NavItem',
  props: ['title', 'icons', 'isActive', 'disabled', 'hasIssue'],
  methods: {
    onClick(e) {
      if (this.disabled) {
        return;
      }
      this.$emit('click', e);
    },
  },
};

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import CustomSlider from '@/components/ui/CustomSlider.vue';

export default {
  components: { CustomSlider },
  props: ['label', 'description', 'value', 'disabled', 'readOnly'],
  data() {
    return {
      num: this.value.value,
      geometry: this.value.geometry,
      innerGeometry: this.value.geometry || 3,
    };
  },
  created() {
    this.num = this.value.value;
    this.geometry = this.value.geometry;
    this.onChange();
  },
  computed: {
    dlhRange() {
      return {
        from: 1,
        to: 100,
        unit: '%',
      };
    },
    geometryFactorRange() {
      return {
        from: 1,
        to: 5,
        unit: '',
      };
    },
  },
  methods: {
    onChange(val) {
      if (val?.value) {
        this.num = val.value;
      }
      let { num, geometry } = this;
      num = parseInt(num, 10) || 0;
      if (geometry !== '') {
        const gstr = `${geometry}`;
        geometry = parseInt(gstr[gstr.length - 1], 10) || 0;
        geometry = Math.min(Math.max(geometry, 1), 5);
        this.$nextTick(() => {
          this.geometry = geometry;
        });
      }
      const value = Math.min(100, Math.max(0, num));
      const data = {
        value, geometry,
      };
      this.$emit('input', data);
    },
    onGeometryFactorSetClick() {
      this.$bvModal.hide('geometry-factor-modal');
      this.$bvModal.show('geometry-factor-warning-modal');
    },
    resetGeometryFactor() {
      this.innerGeometry = this.geometry;
    },
    onGeometryFactorConfirm() {
      this.geometry = this.innerGeometry;
      this.$bvModal.hide('geometry-factor-warning-modal');
      this.onChange();
    },
    onGeometryFactorInputChange(geometry) {
      this.innerGeometry = geometry.value;
    },
  },
};

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from 'vuex';
import SectionTitle from '@/components/ui/SectionTitle.vue';
import MaintenanceErrorList from '@/components/ui/MaintenanceErrorList.vue';

export default {
  name: 'ProjectMaintenance',
  data() {
    return {
      projectId: this.$route.params.projectId,
    };
  },
  components: {
    MaintenanceErrorList,
    SectionTitle,
  },
  computed: {
    ...mapGetters('projects', ['getProjectById', 'getHealthEntries', 'isReadOnly']),
    ...mapGetters(['getConfigUpdatesById']),
    ...mapGetters('rooms', ['getRoomsByProjectId']),
    healthIssues() {
      return this.getHealthEntries(this.projectId).allErrors || [];
    },
    project() {
      return this.getProjectById(this.projectId);
    },
    rooms() {
      return this.getRoomsByProjectId(this.projectId);
    },
    projectName() {
      return this.project.name;
    },
    readOnly() {
      return this.isReadOnly(this.projectId);
    },
  },
  methods: {
    ...mapActions('projects', ['updateProject']),
    onMaintenanceContractChanged(maintenanceContract) {
      this.updateProject({ uuid: this.projectId, data: { maintenanceContract } });
    },
  },
};

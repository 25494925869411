//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import CustomSlider from '@/components/ui/CustomSlider.vue';

export default {
  components: { CustomSlider },
  props: ['label', 'description', 'value', 'downMinVal', 'offMinVal', 'disabled', 'defaultSelected', 'checkboxDisabled'],
  data() {
    return {
      num: this.value,
      selected: this.defaultSelected || false,
      downMin: this.downMinVal || 5,
      offMin: this.offMinVal || 10,
    };
  },
  created() {
    this.onChange();
  },
  computed: {
    range() {
      return {
        from: 1,
      };
    },
  },
  methods: {
    onChange(v, field) {
      if (v?.value && field === 'num') {
        this.num = v.value;
      }
      let { num, downMin, offMin } = this;
      num = parseInt(num, 10) || 1;
      downMin = Math.max(parseInt(downMin, 10) || 1, 1);
      offMin = Math.max(parseInt(offMin, 10) || 1, 1);
      // eslint-disable-next-line no-nested-ternary
      const value = Math.min(100, Math.max(1, num));
      this.num = num;
      this.downMin = downMin;
      this.offMin = offMin;
      const data = {
        selected: true, value, downMin, offMin, dimSelected: this.selected,
      };
      this.$emit('input', data);
    },
    onInputChange(type) {
      if (type === 'downMin') {
        this.downMin = this.downMin.replace(/[^0-9]/, '');
        if (!this.downMin.length) {
          return;
        }
        this.downMin = parseInt(this.downMin, 10);
        if (this.downMin < 1) {
          this.downMin = 1;
        }
        this.onChange(this.downMin);
        return;
      }
      this.offMin = this.offMin.replace(/[^0-9]/, '');
      if (!this.offMin.length) {
        return;
      }
      this.offMin = parseInt(this.offMin, 10);
      if (this.offMin < 1) {
        this.offMin = 1;
      }
      this.onChange(this.offMin);
    },
  },
};

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapGetters, mapState } from 'vuex';
import { WizardBus } from '@/bus';
import ListItem from '@/components/ui/ListItem.vue';

export default {
  name: 'WizardProject',
  components: {
    ListItem,
  },
  data() {
    return {
      disableCreateProject: false,
    };
  },
  created() {
    WizardBus.$on('next', this.onNextStep);
    WizardBus.$on('createProject', this.onCreateProject);
    WizardBus.$on('abort', this.onAbort);
  },
  beforeDestroy() {
    WizardBus.$off('next', this.onNextStep);
    WizardBus.$off('createProject', this.onCreateProject);
    WizardBus.$off('abort', this.onAbort);
  },
  computed: {
    ...mapState('wizard', ['projectState']),
    ...mapGetters('wizard', ['isContactEmpty']),
  },
  methods: {
    ...mapActions('projects', ['createProject', 'uploadFloorplans', 'listProjects', 'lockProject']),
    ...mapActions('wizard', ['updateProjectState', 'resetState']),
    async onNextStep() {
      await this.onCreateProject(true);
    },
    async onCreateProject(createRoom = false) {
      if (this.disableCreateProject) {
        return;
      }
      this.disableCreateProject = true;
      this.$parent.$parent.$data.disabledFooterButton = true;
      const data = {
        name: this.projectState.name, address: this.projectState.address, description: this.projectState.description,
      };
      if (!this.isContactEmpty) {
        data.contacts = [this.projectState.contact];
      }
      let createdProject = null;
      try {
        createdProject = await this.createProject(data);
      } catch (e) {
        this.disableCreateProject = false;
        this.$parent.$parent.$data.disabledFooterButton = false;
        throw e;
      }

      const { uuid } = createdProject;
      try {
        await this.onFloorplanUpload(uuid);
        await this.listProjects();
      } finally {
        this.resetState();
        await this.lockProject({ uuid });
        this.$parent.$parent.$data.disabledFooterButton = false;
        if (createRoom) {
          await this.$router.push({ name: 'wizard-room-name-and-description', params: { projectId: uuid } });
        } else {
          await this.$router.push({ name: 'project-rooms', params: { projectId: uuid } });
        }
      }
    },
    async onFloorplanUpload(uuid) {
      const data = this.projectState.files;
      if (!data || !data.length) {
        return;
      }
      await this.uploadFloorplans({ uuid, files: data });
    },
    onAbort() {
      this.resetState();
    },
  },
};

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { ValidationObserver } from 'vee-validate';
import InputField from '../InputField.vue';
import IconLink from '../IconLink.vue';
import InputTimeIntevalField from '../InputTimeIntevalField.vue';
import InputDaysField from '../InputDaysField.vue';
import CustomSlider from '../CustomSlider.vue';
import CustomSection from '../CustomSection.vue';
import AutoOffSlider from '../AutoOffSlider.vue';
import DLHSlider from '../DLHSlider.vue';

export default {
  name: 'RoomRuleDetailsUI',
  props: ['rule', 'readOnly', 'options', 'saveRule', 'roomId', 'createMode', 'projectConfig', 'healthIssues', 'hideEdit', 'hideDelete'],
  components: {
    DLHSlider,
    ValidationObserver,
    InputField,
    CustomSlider,
    IconLink,
    InputTimeIntevalField,
    InputDaysField,
    CustomSection,
    AutoOffSlider,
  },
  data() {
    return {
      rerender: 1,
      editMode: this.createMode,
      disableSave: false,
      data: {},
      repeatError: false,
    };
  },
  created() {
    this.resetForm();
  },
  watch: {
    'data.dlh.selected': function selectedWatcher(dlhEnabled) {
      if (dlhEnabled) {
        this.$set(this.data.autoOn, 'selected', true);
        this.$set(this.data.autoOff, 'selected', true);
        this.rerender += 1;
      }
    },
    projectConfig() {
      this.resetForm();
      this.rerender += 1;
    },
  },
  methods: {
    switchMode(mode) {
      this.resetForm();
      this.$emit('switchMode', mode);
      this.editMode = mode;
    },
    async onSave() {
      const valid = await this.$refs.observer.validate();
      if (!valid) {
        return;
      }
      if (this.data.repeat < 1) {
        this.repeatError = true;
        return;
      }
      this.disableSave = true;
      const {
        name, duration, repeat, autoOn, autoOff, dlh,
      } = this.data;
      const actions = {
        target: {
          type: 'room',
          uuid: this.roomId,
        },
        value: {
          'auto-on': autoOn.selected || false,
          // eslint-disable-next-line no-nested-ternary
          level: autoOn.selected ? (autoOn.dimSelected ? (autoOn.value || 100) : 100) : undefined,
          'auto-off': autoOff.selected || false,
          delay: autoOff.selected ? autoOff.offMin * 60 : undefined,
          'delay-dim': autoOff.selected && autoOff.dimSelected ? autoOff.downMin * 60 : undefined,
          'level-dim': autoOff.selected && autoOff.dimSelected ? autoOff.value : undefined,
          'dlh-enabled': dlh.selected,
        },
      };
      const data = {
        name, duration, repeat, actions, dlh: dlh.selected ? this.getDLHData() : undefined,
      };
      await this.saveRule(data);
      this.disableSave = false;
      this.switchMode(false);
    },
    onDelete() {
      this.$emit('delete');
    },
    onChange(item, data) {
      if (item === 'autoOn' && this.data.autoOff.value > data.value) {
        this.data = { ...this.data, autoOff: { ...this.data.autoOff, value: data.value } };
      }
      if (item === 'autoOff' && data.value >= this.data.autoOn.value) {
        this.data.autoOn.value = data.value;
      }
      this.data[item] = { ...this.data[item], ...data };
      this.data = { ...this.data };
    },
    onDLHChange(i, dlh) {
      this.data.dlh.values[i] = { ...this.data.dlh.values[i], ...dlh };
      this.onChange('dlh', this.data.dlh);
    },
    getDLHData() {
      let dlhData;
      if (this.data.dlh.values.length) {
        const { values } = this.data.dlh;
        if (values[0].type === 'room') {
          dlhData = {
            room: {
              uuid: values[0].uuid,
              'regulation-value': values[0].value,
              'geometry-factor': values[0].geometry,
            },
          };
        } else {
          dlhData = {
            groups: values.map((item) => ({
              uuid: item.uuid,
              'regulation-value': item.value,
              'geometry-factor': item.geometry,
            })),
          };
        }
      }
      return dlhData;
    },
    getDLHValues() {
      if (!this.projectConfig) {
        return [];
      }
      const room = (this.projectConfig?.rooms || []).find((r) => r.uuid === this.roomId);
      if (!room || !room.sensors.length) {
        return [];
      }
      const groups = (this.projectConfig?.groups || []).filter((group) => room.groups.includes(group.uuid));
      const groupsWithSensor = groups.filter((group) => group.sensors && group.sensors.length);

      if (!groupsWithSensor.length) {
        const dlh = (room.dlh && room.dlh[0]) || {};
        return [{
          type: 'room',
          uuid: room.uuid,
          label: room.name,
          value: dlh['regulation-value'] || 80,
          geometry: dlh['geometry-factor'] || 3,
        }];
      }

      return groupsWithSensor.map((group) => {
        const dlh = (group.dlh && group.dlh[0]) || {};

        return {
          type: 'group',
          uuid: group.uuid,
          label: group.name,
          value: dlh['regulation-value'] || 0,
          geometry: dlh['geometry-factor'] || 3,
        };
      });
    },
    resetForm() {
      const {
        name, duration, repeat, actions,
      } = this.rule;
      let target = 'room';
      if (actions.target && actions.target.type !== 'room') {
        target = actions.target.uuid;
      }
      const dlh = {
        selected: actions.value['dlh-enabled'],
        values: this.getDLHValues(),
      };
      const autoOn = {
        selected: dlh.selected || actions.value['auto-on'],
        value: actions.value.level || 100,
        dimSelected: !!actions.value.level,
      };
      const autoOff = {
        selected: dlh.selected || actions.value['auto-off'],
        dimSelected: !!actions.value['level-dim'],
        value: actions.value['level-dim'] || 30,
        downMin: (actions.value['delay-dim'] || 0) / 60,
        offMin: (actions.value.delay || 0) / 60,
      };
      this.data = {
        name, duration, repeat, target, autoOn, autoOff, dlh,
      };
      this.rerender += 1;
    },
  },
};

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapGetters } from 'vuex';
import SectionTitle from '@/components/ui/SectionTitle.vue';
import CustomSection from '@/components/ui/CustomSection.vue';
import ListItem from '@/components/ui/ListItem.vue';
import CloudService from '@/components/ui/controller/CloudService.vue';
import ControllerOfflineModal from '@/components/ui/controller/ControllerOfflineModal.vue';
import MaintenanceErrorDetails from '@/components/ui/MaintenanceErrorDetails.vue';
import IconLink from '@/components/ui/IconLink.vue';
import config from '@/config';
import { formatDate, getTimezones } from '@/utils/helpers';
import RadioSelect from '@/components/ui/RadioSelect.vue';

export default {
  name: 'ProjectRemoteMaintenance',
  data() {
    return {
      projectId: this.$route.params.projectId,

      filterCheckpoints: true,
      selectedRevision: null,
      disabledRestore: false,

      testFirmwareEnabled: config.ENABLE_TEST_FIRMWARE,
      originalFirmwareUpdateEnabled: config.ENABLE_ORIGINAL_FIRMWARE_UPDATE,
      selectedLog: {
        fileName: '',
        content: '',
      },
      loadingContent: false,
      showLogDetails: false,
      timezones: getTimezones(),
      projectTimezone: '',
      loadingTimezone: false,
      internalChannel: null,
      expertMode: false,
      loadingChannel: false,
      recommendedChannels: [11, 15, 20, 25],
      expertChannels: [12, 13, 14, 16, 17, 18, 19, 21, 22, 23, 24],
      zigbeeAutoSelect: true,

      loadingSiteWorx: false,
    };
  },
  components: {
    RadioSelect,
    IconLink,
    ListItem,
    ControllerOfflineModal,
    CloudService,
    SectionTitle,
    CustomSection,
    MaintenanceErrorDetails,
  },
  async created() {
    await this.initSiteWorx();
    if (this.project.gatewayEthernetMACAddress && this.project.gatewayEthernetMACAddress.length > 0) {
      await this.listGatewayLogs({ projectUUID: this.projectId });
    }
    await this.getTimeSettings();
    this.projectTimezone = this.project.configParameters.timeZone;
    this.internalChannel = this.project.configParameters.zigbeeChannel || null;
    this.zigbeeAutoSelect = !this.internalChannel;
  },
  watch: {
    progress(newValue) {
      if (newValue === 100) {
        this.projectUnlock();
      }
    },
  },
  computed: {
    ...mapGetters('projects', ['getProjectById', 'getHealthEntries', 'isReadOnly', 'getSiteWorxByProject', 'getWorkInProgressData', 'isConfigEditAllowed']),
    ...mapGetters('configs', ['getRevisionsByProjectId', 'getProjectConfigById']),
    ...mapGetters(['getConfigUpdatesById']),
    ...mapGetters('rooms', ['getRoomsByProjectId']),
    ...mapGetters('gateway', ['getGatewayLogs', 'getTimeByProjectId']),
    healthIssues() {
      return this.getHealthEntries(this.projectId).byCategory.gateway || [];
    },
    project() {
      return this.getProjectById(this.projectId);
    },
    siteWorx() {
      const { bms } = this.getProjectConfigById(this.projectId);
      const siteworxExtra = this.getSiteWorxByProject(this.projectId);
      if (!bms || !bms.length) {
        return null;
      }
      const siteworx = (bms.find((item) => item.type === 'SiteWorx') || {});
      let result = {};
      if (siteworx.username === siteworxExtra.username) {
        result = { ...siteworxExtra };
      }
      return { ...result, ...siteworx };
    },
    rooms() {
      return this.getRoomsByProjectId(this.projectId);
    },
    projectName() {
      return this.project?.name;
    },
    revisions() {
      return this.getRevisionsByProjectId(this.projectId, this.filterCheckpoints);
    },
    configUpdateMessage() {
      return this.getConfigUpdatesById(this.projectId);
    },
    progress() {
      return this.configUpdateMessage.job ? this.configUpdateMessage.job.progress : 0;
    },
    readOnly() {
      return this.isReadOnly(this.projectId);
    },
    configEditAllowed() {
      return this.isConfigEditAllowed(this.projectId);
    },
    canIUpdateFirmware() {
      return this.$canI('otaDevice', 'firmwares')
          || this.$canI('otaGateway', 'firmwares');
    },
    workInProgressData() {
      return this.getWorkInProgressData(this.projectId);
    },
    gatewayLogs() {
      return this.getGatewayLogs(this.projectId);
    },
    timeSettings() {
      return this.getTimeByProjectId(this.projectId);
    },
    channel() {
      return this.getProjectConfigById(this.projectId).zigbee?.channel || null;
    },
    recommendedRadioOptions() {
      return this.recommendedChannels.map((channel) => ({
        text: this.$t('remoteMaintenanceChannel', { channel }),
        value: channel,
      }));
    },
    expertRadioOptions() {
      return this.expertChannels.map((channel) => ({
        text: this.$t('remoteMaintenanceChannel', { channel }),
        value: channel,
      }));
    },
    gatewayTime() {
      if (this.timeSettings && this.timeSettings.gatewayTime && this.projectTimezone.length) {
        const time = this.timeSettings.gatewayTime;
        return formatDate(time, this.projectTimezone);
      }
      return '';
    },
    createdBySectionTitle() {
      return this.selectedRevision && this.selectedRevision.updatedByUser ? this.$t('remoteMaintenanceUpdatedBy') : this.$t('remoteMaintenanceCreatedBy');
    },
    createdByDisplayedUserName() {
      if (this.selectedRevision && this.selectedRevision.updatedByUser) {
        return this.selectedRevision.updatedByUser.name;
      }

      if (this.selectedRevision && this.selectedRevision.createdByUser) {
        return this.selectedRevision.createdByUser.name;
      }

      if (this.project && this.project.createdByUser) {
        return this.project.createdByUser.name;
      }

      return '';
    },
  },
  methods: {
    ...mapActions(['resetConfigUpdates']),
    ...mapActions('configs', ['listRevisions', 'restoreRevision', 'getProjectConfig', 'setZigbeeChannel']),
    ...mapActions('projects', ['updateProject', 'editSiteWorx', 'getSiteWorx', 'unlockProject']),
    ...mapActions('gateway', ['listGatewayLogs', 'getGatewayLog', 'getGatewayTime']),
    ...mapActions('app', ['setError']),
    async openHistoryModal() {
      this.$bvModal.show('project-history-modal');
      await this.listRevisions({ uuid: this.projectId });
      if (this.filterCheckpoints && !this.revisions.length) {
        this.filterCheckpoints = false;
      }
    },
    onTestFirmwaresChanged(eligibleForTestFwImages) {
      this.updateProject({ uuid: this.projectId, data: { eligibleForTestFwImages } });
    },
    onMaintenanceContractChanged(maintenanceContract) {
      this.updateProject({ uuid: this.projectId, data: { maintenanceContract } });
    },
    openFirmwareUpdates() {
      this.$router.push(`/projects/${this.projectId}/remote-maintenance/firmware-update`);
    },
    openRestoreModal(item) {
      this.selectedRevision = item;
      this.$bvModal.hide('project-history-modal');
      this.$bvModal.show('restore-config-modal');
    },
    async onRestore() {
      if (!this.project.gatewayConnectedToCloud) {
        this.onCancel();
        this.$bvModal.show('controller-offline-modal');
        return;
      }
      this.disabledRestore = true;
      await this.resetConfigUpdates(this.projectId);
      await this.restoreRevision({ uuid: this.projectId, revision: this.selectedRevision.revision })
        .then(() => {
          this.$bvModal.hide('restore-config-modal');
          this.$bvModal.show('restore-progress-modal');
          this.disabledRestore = false;
        });
    },
    onCancel() {
      this.$bvModal.hide('restore-config-modal');
    },
    onCancelProgress() {
      this.$bvModal.hide('restore-progress-modal');
    },
    onCancelLogs() {
      this.$bvModal.hide('controller-logs-modal');
    },
    openControllerLogsModal() {
      this.selectedLog.fileName = '';
      this.selectedLog.content = '';
      this.loadingContent = false;
      this.showLogDetails = false;
      this.$bvModal.show('controller-logs-modal');
    },
    async downloadLog(fileName) {
      if (this.loadingContent) {
        return;
      }
      this.loadingContent = true;
      const log = await this.getGatewayLog({ projectUUID: this.projectId, fileName });
      this.downloadFile(fileName, log.data);
      this.loadingContent = false;
    },
    async openLog(fileName) {
      if (this.loadingContent) {
        return;
      }
      this.loadingContent = true;
      const log = await this.getGatewayLog({ projectUUID: this.projectId, fileName });
      this.selectedLog.fileName = fileName;
      this.selectedLog.content = log.data;
      this.loadingContent = false;
      this.showLogDetails = true;
    },
    downloadFile(fileName, content, fileType = 'text/plain') {
      const blob = new Blob([content], { type: fileType });
      const a = document.createElement('a');
      a.download = fileName;
      a.href = URL.createObjectURL(blob);
      a.dataset.downloadurl = [fileType, a.download, a.href].join(':');
      a.style.display = 'none';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      setTimeout(() => URL.revokeObjectURL(a.href), 1500);
    },
    async initSiteWorx() {
      this.loadingSiteWorx = true;
      try {
        if (this.project.workInProgressRevision) {
          await this.getProjectConfig({
            uuid: this.projectId,
            revision: this.project.workInProgressRevision,
          });
        } else {
          await this.getProjectConfig({ uuid: this.projectId });
        }
        if (!this.configEditAllowed) {
          await this.getSiteWorx({ uuid: this.projectId }).catch(() => {
            this.setError({ errorMessage: this.$t('bmsFailedToRetrieveData'), errorCode: this.$t('firmwareGatewayNotConnectedMessage') });
          });
        }
      } finally {
        this.loadingSiteWorx = false;
      }
    },
    async saveSiteWorx(data) {
      await this.editSiteWorx({ uuid: this.projectId, ...data });
      await this.initSiteWorx();
    },
    async projectUnlock() {
      if (this.workInProgressData.hasWorkInProgressChanges || this.workInProgressData.lockedByOthers) {
        return;
      }
      await this.unlockProject(this.projectId).catch((e) => {
        throw e;
      });
    },
    formatLogData(data) {
      return data.toString().replace(/(\r\n|\r|\n|\\n)/g, '<br>');
    },
    async getTimeSettings() {
      if (this.project.gatewayEthernetMACAddress && this.project.gatewayEthernetMACAddress.length > 0 && this.project.gatewayConnectedToCloud) {
        await this.getGatewayTime({ projectUUID: this.projectId });
      }
    },
    async updateTimezone() {
      this.loadingTimezone = true;
      await this.updateProject({ uuid: this.projectId, data: { timeZone: this.projectTimezone }, forceLock: true });
      this.loadingTimezone = false;
    },
    getDate(value) {
      return formatDate(value);
    },
    onExpertModeConfirm() {
      this.expertMode = true;
      this.$bvModal.hide('expert-mode-warning-modal');
    },
    async saveZigbeeChannel() {
      this.loadingChannel = true;
      await this.setZigbeeChannel({ uuid: this.projectId, channel: this.zigbeeAutoSelect ? null : this.internalChannel });
      await this.getProjectConfig({ uuid: this.projectId });
      this.loadingChannel = false;
      this.hideZigbeeChannelModal();
    },
    hideZigbeeChannelModal() {
      this.$bvModal.hide('zigbee-channel-modal');
    },
    showZigbeeChannelModal() {
      this.$bvModal.show('zigbee-channel-modal');
      this.internalChannel = this.channel;
      this.zigbeeAutoSelect = !this.channel;
      this.expertMode = this.expertChannels.includes(this.channel);
    },
    onAutoSelectChange() {
      this.internalChannel = this.zigbeeAutoSelect ? null : this.channel;
    },
  },
};

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapActions, mapGetters } from 'vuex';
import AdvancedTable from '@/components/ui/AdvancedTable.vue';
import SectionTitle from '@/components/ui/SectionTitle.vue';
import { formatDate } from '@/utils/helpers';

export default {
  name: 'MaintenanceErrorList',
  components: {
    SectionTitle,
    AdvancedTable,
  },
  props: ['healthIssues', 'rooms', 'loading'],
  data() {
    return {
      projectId: this.$route.params.projectId,
      types: ['light', 'switch', 'sensor', 'scene', 'rule', 'group', 'room', 'schedule', 'gateway'],
      typeTranslate: {
        light: 'maintenanceLight',
        sensor: 'maintenanceSensor',
        switch: 'maintenanceSwitch',
        schedule: 'maintenanceSchedule',
        scene: 'maintenanceScene',
        gateway: 'maintenanceController',
        rule: 'maintenanceBehaviour',
        group: 'maintenanceGroup',
        room: 'maintenanceRoom',
      },
      severityTranslate: {
        recoverable: 'maintenanceRecoverable',
        maintenancereq: 'maintenanceMaintenanceReq',
        info: 'maintenanceInfo',
        fatal: 'maintenanceFatal',
      },
      headers: [{
        title: '',
      }, {
        title: this.$t('generalName'), key: 'entityName', type: 'text', sortable: true, searchable: true,
      }, {
        // hidden header for searchable entity description
        title: '', key: 'entityDescription', type: 'text', sortable: false, searchable: true, hidden: true,
      }, {
        title: this.$t('generalDescription'), key: 'description', type: 'text', sortable: false, searchable: true,
      }, {
        title: this.$t('maintenanceSeverity'), key: 'severity', type: 'text', sortable: true, searchable: false,
      }, {
        title: this.$t('maintenanceHealthObjectType'), key: 'objType', type: 'text', sortable: true, searchable: false,
      }, {
        title: this.$t('generalDate'), key: 'createdAt', type: 'text', sortable: true, searchable: false,
      }, {
        title: this.$t('generalStatus'), key: 'createdAt', type: 'text', sortable: true, searchable: false,
      }],
    };
  },
  computed: {
    ...mapState('projects', ['checkboxErrorFilters']),
    ...mapGetters(['getSensorStatus', 'getLightStatus']),
    showResetFilters() {
      return Object.values(this.errorFilters).some((f) => f === false);
    },
    hasIssues() {
      return this.healthIssues.length !== 0;
    },
    hasFilteredIssues() {
      return this.filteredHealthIssues.length !== 0;
    },
    errorFilters() {
      return this.checkboxErrorFilters;
    },
    filteredHealthIssues() {
      return this.healthIssues
        .filter((item) => this.errorFilters[item.objType])
        .map((error) => ({
          ...error,
          entityName: error.entity !== null ? error.entity.name : '-',
          entityDescription: error.entity !== null ? error.entity.description : '',
        }));
    },
    rightText() {
      return this.hasIssues ? this.$tc('maintenanceErrorFound', this.healthIssues.length, { value: this.healthIssues.length }) : '';
    },
  },
  methods: {
    ...mapActions('projects', ['checkboxErrorFiltersUpdate']),
    onCheckboxFilterChange(reset = false) {
      if (reset) {
        this.errorFilters.light = true;
        this.errorFilters.sensor = true;
        this.errorFilters.switch = true;
        this.errorFilters.schedule = true;
        this.errorFilters.scene = true;
        this.errorFilters.gateway = true;
        this.errorFilters.rule = true;
        this.errorFilters.group = true;
        this.errorFilters.room = true;
      }
      this.checkboxErrorFiltersUpdate(this.errorFilters);
    },
    async onOpenItem(item) {
      if (item.objType === 'light' || item.objType === 'switch' || item.objType === 'sensor') {
        await this.$router.push({ name: 'device-detail', params: { projectId: this.projectId, roomId: item.roomUuid, deviceId: item.entity.uuid } });
      }
      if (item.objType === 'rule') {
        await this.$router.push({ name: 'room-rules', params: { projectId: this.projectId, roomId: item.roomUuid } });
      }
      if (item.objType === 'scene') {
        await this.$router.push({ name: 'scene-detail', params: { projectId: this.projectId, roomId: item.roomUuid, sceneId: item.entity.uuid } });
      }
      if (item.objType === 'group') {
        await this.$router.push({ name: 'group-detail', params: { projectId: this.projectId, roomId: item.roomUuid, groupId: item.entity.uuid } });
      }
      if (item.objType === 'room') {
        await this.$router.push({ name: 'room-details', params: { projectId: this.projectId, roomId: item.roomUuid } });
      }
      if (item.objType === 'schedule') {
        await this.$router.push({ name: 'schedule-detail', params: { projectId: this.projectId, scheduleId: item.entity.uuid } });
      }
      if (item.objType === 'gateway') {
        await this.$router.push({ name: 'project-remote-maintenance', params: { projectId: this.projectId } });
      }
    },
    disableFilterItem(type) {
      return !this.healthIssues.some((issue) => issue.objType === type);
    },
    getDate(value) {
      return formatDate(value);
    },
  },
};

import Vue from 'vue';
import VueI18n from 'vue-i18n';

import en from './en.json';
import de from './de.json';
import es from './es.json';
import fr from './fr.json';
import it from './it.json';
import nl from './nl.json';
import pt from './pt.json';

Vue.use(VueI18n);

const messages = {
  en,
  de,
  es,
  fr,
  it,
  nl,
  pt,
};
const i18n = new VueI18n({
  locale: 'en',
  fallbackLocale: 'en',
  formatFallbackMessages: true,
  messages,
});

export default i18n;

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"observer",staticClass:"d-block",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var handleSubmit = ref.handleSubmit;
return [_c('b-form',{attrs:{"novalidate":""},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.updatePassword)}}},[_c('h1',{staticClass:"mb-5 text-center text-uppercase"},[_vm._v(_vm._s(_vm.$t('authResetPasswordNew')))]),_c('p',{staticClass:"mb-5"},[_vm._v(_vm._s(_vm.$t('authResetPasswordNewMessage')))]),(_vm.error)?_c('b-alert',{attrs:{"show":"","variant":"danger"}},[_vm._v(" "+_vm._s(_vm.$t('authResetPasswordNewError'))+" ")]):_vm._e(),_c('ValidationProvider',{attrs:{"rules":"required|email|max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return [_c('b-form-group',[_c('b-form-input',{attrs:{"name":"email","placeholder":_vm.$t('authEmailAddress')+'*',"autocomplete":"off","state":errors[0] ? false : (valid ? true : null)},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"space",32,$event.key,[" ","Spacebar"])){ return null; }$event.preventDefault();}},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return [_c('b-form-group',{staticClass:"mb-5"},[_c('b-form-input',{attrs:{"name":"token","placeholder":_vm.$t('authResetPasswordNewToken')+'*',"state":errors[0] ? false : (valid ? true : null)},model:{value:(_vm.token),callback:function ($$v) {_vm.token=$$v},expression:"token"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":{ required: true, regex: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/, min: 8, max: 30 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return [_c('b-form-group',{staticClass:"mb-5"},[_c('b-input-group',[_c('b-form-input',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.left",value:({ customClass: 'tooltip-auth', title: _vm.$t('generalPasswordTooltip') }),expression:"{ customClass: 'tooltip-auth', title: $t('generalPasswordTooltip') }",modifiers:{"left":true}}],attrs:{"id":"password","type":_vm.passwordFieldType,"placeholder":_vm.$t('authNewPassword')+'*',"autocomplete":"new-password","state":errors[0] ? false : null},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('button',{staticClass:"password-toggle",attrs:{"type":"button"},on:{"click":function($event){_vm.showPassword = !_vm.showPassword}}},[(_vm.showPassword)?_c('img',{attrs:{"src":require("../../assets/icons/password/hide.svg"),"alt":"Hide password"}}):_c('img',{attrs:{"src":require("../../assets/icons/password/show.svg"),"alt":"Show password"}})]),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0]))])],1)],1)]}}],null,true)}),_c('div',{staticClass:"text-center mb-1"},[_c('b-button',{attrs:{"disabled":!valid||_vm.submitted,"type":"submit","pill":"","variant":"primary"}},[_vm._v(_vm._s(_vm.$t('authResetPasswordNewSave')))])],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }
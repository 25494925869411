//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapGetters } from 'vuex';
import { WizardBus } from '@/bus';
import ListItem from '@/components/ui/ListItem.vue';

export default {
  name: 'WizardRoom',
  components: {
    ListItem,
  },
  data() {
    return {};
  },
  async created() {
    WizardBus.$on('next', this.onNextStep);
    await this.onCreated();
  },
  beforeDestroy() {
    WizardBus.$off('next', this.onNextStep);
  },
  computed: {
    ...mapGetters('devices', ['getDevicesByRoom']),
    ...mapGetters('groups', ['getGroupsByRoom']),
    ...mapGetters('scenes', ['getScenesByRoom']),
    ...mapGetters('rules', ['getRulesByRoom']),
    ...mapGetters('rooms', ['getRoomById']),
    projectId() {
      return this.$route.params.projectId;
    },
    roomId() {
      return this.$route.params.roomId;
    },
    room() {
      return this.getRoomById(this.roomId);
    },
    devices() {
      return this.getDevicesByRoom(this.projectId, this.roomId);
    },
    groups() {
      return this.getGroupsByRoom(this.projectId, this.roomId);
    },
    scenes() {
      return this.getScenesByRoom(this.projectId, this.roomId);
    },
    rules() {
      return this.getRulesByRoom(this.projectId, this.roomId);
    },
    cDays() {
      return this.$t('generalWeekdays').split(',');
    },
    mode() {
      return this.room.defaultRoomBehaviour || 'manual';
    },
    modeLocale() {
      if (this.mode === 'manual') {
        return this.$t('roomRulesDefaultModeManual');
      }
      if (this.mode === 'semi-automatic') {
        return this.$t('roomRulesDefaultModeSemi');
      }
      if (this.mode === 'automatic') {
        return this.$t('roomRulesDefaultModeAutomatic');
      }
      return '';
    },
  },
  methods: {
    ...mapActions('devices', ['listDevices']),
    ...mapActions('groups', ['listGroups']),
    ...mapActions('scenes', ['listScenes']),
    ...mapActions('rules', ['listRules']),
    ...mapActions('rooms', ['getRoom']),
    async onCreated() {
      await this.getRoom({ uuid: this.projectId, roomId: this.roomId });
      await this.listDevices({ uuid: this.projectId, roomId: this.roomId });
      await this.listGroups({ uuid: this.projectId, roomId: this.roomId });
      await this.listScenes({ uuid: this.projectId, roomId: this.roomId });
      await this.listRules({ uuid: this.projectId, roomId: this.roomId });
    },
    decToBin(dec) {
      // eslint-disable-next-line no-bitwise
      return (dec >>> 0).toString(2).padStart(7, '0');
    },
    repeatDays(rule) {
      const bin = this.decToBin(rule.repeat).split('').reverse();
      return this.cDays
        .reduce((acc, item, i) => (bin[(i + 1) % 7] === '1' ? [...acc, item] : acc), [])
        .join().trim();
    },
    formatWeek(repeatDays) {
      const weekFormatsShort = [this.$t('generalWeekdaysShort'), this.$t('generalWeekendShort'), this.$t('generalAllWeekShort')];
      const weekFormats = [this.$t('generalWeekWeekdays'), this.$t('generalWeekWeekend'), this.$t('generalWeekAllWeek')];
      const index = weekFormatsShort.indexOf(repeatDays);
      return index !== -1 ? weekFormats[index] : repeatDays;
    },
    onNextStep() {
      this.$router.push({ name: this.$route.meta.nextRoute });
    },
  },
};

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

/*
 items - Array of items
 item - Object: { value: uniq string, text: text to show, checked: boolean }
 */
export default {
  props: ['label', 'value', 'readMode', 'hideLabel', 'showWarning', 'selectAll'],
  data() {
    return {};
  },
  computed: {
    selectAllItems() {
      return this.value.filter((item) => !item.disabled).every((item) => item.checked);
    },
  },
  methods: {
    onCheck(val, item) {
      item.checked = val;
      this.$emit('input', this.value);
    },
    onSelectAll() {
      const allSelected = this.selectAllItems;
      const updatedValue = this.value.map((item) => {
        if (item.disabled) {
          return { ...item };
        }
        return { ...item, checked: !allSelected };
      });
      this.$emit('input', updatedValue);
    },
  },
};

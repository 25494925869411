//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from 'vuex';
import RoomSchedulesUI from '@/components/ui/roomUI/RoomSchedulesUI.vue';
import AddScheduleModal from './modals/AddScheduleModal.vue';
import DeleteScheduleModal from './modals/DeleteScheduleModal.vue';

export default {
  name: 'RoomSchedules',
  components: {
    RoomSchedulesUI,
    AddScheduleModal,
    DeleteScheduleModal,
  },
  data() {
    return {
      dataLoaded: false,
      projectId: this.$route.params.projectId,
      roomId: this.$route.params.roomId,

      name: '',
      description: '',

      selectedSchedule: '',
      selectedUuid: '',
    };
  },
  created() {
    this.onListSchedules();
  },
  computed: {
    ...mapGetters('rooms', ['getRoomById']),
    ...mapGetters('schedules', ['getSchedulesByProject']),
    ...mapGetters('projects', ['getProjectById', 'isReadOnly']),
    readOnly() {
      return this.isReadOnly(this.projectId);
    },
    schedules() {
      return this.getSchedulesByProject(this.projectId);
    },
  },
  methods: {
    ...mapActions('schedules', ['listSchedules', 'createSchedule', 'deleteSchedule']),
    async onListSchedules() {
      await this.listSchedules({ uuid: this.projectId, roomId: this.roomId });
      this.dataLoaded = true;
    },
    openDeleteModal(schedule) {
      this.selectedSchedule = schedule;
      this.selectedUuid = schedule.uuid;
      this.$bvModal.show('delete-schedule-modal');
    },
    async onDeleteSchedule() {
      await this.deleteSchedule({ uuid: this.projectId, roomId: this.roomId, scheduleId: this.selectedUuid });
      await this.onListSchedules();
      this.$bvModal.hide('delete-schedule-modal');
    },
  },
};

/* global axios */

const listFirmwareUpdates = async ({ uuid, bodyData }) => axios({
  method: 'POST',
  url: `/projects/${uuid}/firmwares/updates`,
  data: bodyData,
});

const updateFirmware = async ({ uuid, imageId }) => axios({
  method: 'PATCH',
  url: `/projects/${uuid}/gateway/firmware/update`,
  data: { imageId },
  lock: { uuid },
  logEvent: () => ({ eventName: 'update_gateway_firmware', eventParams: { imageId } }),
});

const updateProductsFirmware = async ({ uuid, productUUID, imageId }) => axios({
  method: 'PATCH',
  url: `/projects/${uuid}/products/${productUUID}/firmware/update`,
  data: { imageId },
  lock: { uuid },
  logEvent: () => ({ eventName: 'update_product_firmware', eventParams: { imageId } }),
});

export default {
  listFirmwareUpdates,
  updateFirmware,
  updateProductsFirmware,
};

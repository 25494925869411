import Vue from 'vue';
import store from '@/store';

Vue.mixin({
  methods: {
    $canI(action, subject) {
      const projectUUID = this.$route.params && this.$route.params.projectId;
      const permissions = store.getters['projects/getPermissionsByProject'](projectUUID);
      return permissions.some((item) => item.action === action && item.subject === subject);
    },
    async $leaveGuard(to, from, next) {
      const projectUUID = from.params.projectId;
      // eslint-disable-next-line max-len
      const remoteControlEnabled = !!store.getters['projects/getWorkInProgressData'](projectUUID)?.hasWorkInProgressChanges;
      if ((from.params.projectId !== !to.params.projectId) && !to.params.projectId && remoteControlEnabled) {
        await store.dispatch('projects/setRemoteControlLeaveRoute', to);
        next(false);
        return;
      }
      next();
    },
  },
});

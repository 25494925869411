//
//
//
//
//
//
//
//

import { mapActions, mapGetters } from 'vuex';

import RoomGroupsUI from '@/components/ui/roomUI/RoomGroupsUI.vue';

export default {
  name: 'TemplateGroups',
  components: {
    RoomGroupsUI,
  },
  data() {
    return {
      templateId: this.$route.params.templateId,
      projectId: this.$route.params.projectId,
      loading: false,
    };
  },
  async created() {
    this.loading = true;
    this.loading = false;
  },
  computed: {
    ...mapGetters('templates', ['getTemplateById']),
    ...mapGetters('products', ['getProductById']),
    template() {
      return this.getTemplateById(this.templateId) || {};
    },
    templateConfig() {
      if (this.template.config && this.template.config.body) {
        return this.template.config.body;
      }
      return {};
    },
    groups() {
      return this.templateConfig.groups;
    },
  },
  methods: {
    ...mapActions('products', []),
  },
};

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapGetters } from 'vuex';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import TemplateVariables from '@/components/ui/roomUI/TemplateVariables.vue';

export default {
  name: 'RoomsCreate',
  components: {
    TemplateVariables,
    ValidationObserver,
    ValidationProvider,
  },
  data() {
    return {
      disableCreate: false,

      projectId: this.$route.params.projectId,

      name: '',
      description: '',
      template: undefined,

      roomTemplate: null,
      templateFields: {},
      templateValues: {},

      templates: {},

      devices: {},

      selectedField: {},
      selectedDeviceName: '',
    };
  },
  async created() {
    await this.listTemplates({ scopes: ['project', 'global', 'user'], projectUUID: this.projectId });
  },
  computed: {
    ...mapGetters('templates', ['getTemplatesByScope', 'getTemplateById']),
    templateOptions() {
      const defaultOptions = [{ text: this.$t('roomNoTemplate'), value: null }];
      return this.getTemplatesByScope(['project', 'user', 'global']).reduce((acc, item) => [...acc, {
        text: (item.config.body && item.config.body.template && item.config.body.template.name) || item.name,
        value: item.uuid,
      }], defaultOptions);
    },
    selectedTemplate() {
      if (!this.roomTemplate) {
        return null;
      }
      return this.getTemplateById(this.roomTemplate);
    },
  },
  methods: {
    ...mapActions('rooms', ['createRoom']),
    ...mapActions('templates', ['listTemplates']),
    onTemplateChange(template) {
      this.template = template;
    },
    async onCreateRoom() {
      const valid = await this.$refs.observer.validate();
      if (!valid) {
        return;
      }
      this.disableCreate = true;

      const {
        name, description, template, roomTemplate,
      } = this;
      const data = { name, description };
      if (roomTemplate) {
        data.template = template;
      }

      try {
        await this.createRoom({ uuid: this.projectId, data });
        await this.$router.push(`/projects/${this.projectId}/rooms`);
      } catch (e) {
        console.error(e);
        this.disableCreate = false;
      }
    },
  },
};

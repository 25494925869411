//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapGetters } from 'vuex';
import { serializeDeviceActions } from '@/utils/helpers';
import EditSwitchChannelsUI from '../../components/ui/roomUI/switch/edit/EditSwitchChannelsUI.vue';

export default {
  name: 'TemplateDeviceDetail',
  components: {
    EditSwitchChannelsUI,
  },
  data() {
    return {
      categories: ['lights', 'switches', 'sensors'],
      templateId: this.$route.params.templateId,
      deviceId: this.$route.params.deviceId,
      loading: false,
    };
  },
  async created() {
    this.loading = true;
    await this.listProducts();
    this.loading = false;
  },
  computed: {
    ...mapGetters('templates', ['getTemplateById']),
    ...mapGetters('products', ['getProductById']),
    template() {
      return this.getTemplateById(this.templateId) || {};
    },
    templateConfig() {
      if (this.template.config && this.template.config.body) {
        return this.template.config.body;
      }
      return {};
    },
    devices() {
      return this.categories.reduce((devices, cat) => {
        const catDevices = (this.templateConfig.devices[cat] || [])
          .map((device) => ({ ...device, product: this.getProductById(device.product) }));
        return { ...devices, [cat]: catDevices };
      }, {});
    },
    lights() {
      return this.devices.lights.reduce((acc, light) => ({ ...acc, [light.uuid]: light }), {});
    },
    switchDevice() {
      return this.devices.switches.find((s) => s.uuid === this.deviceId);
    },
    buttons() {
      return serializeDeviceActions(this.switchDevice.actions);
    },
    typeAndLayout() {
      return {
        buttonType: this.switchDevice.buttonType || 'push button',
        layout: 4,
      };
    },
    dataOptions() {
      return {
        currentProject: '',
        currentRoom: '',
        lights: Object.values(this.lights),
        groups: [],
        rooms: [],
        scenes: [],
        switches: [],
      };
    },
  },
  methods: {
    ...mapActions('products', ['listProducts']),
  },
};

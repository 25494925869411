import roomService from '../services/rooms';

const initialState = {
  rooms: {},
  roomsByProject: {},
};

const actions = {
  async getRoom({ commit }, { uuid, roomId }) {
    const { data } = await roomService.getRoom({ uuid, roomId });
    commit('getRoom', { data, uuid, roomId });
  },
  async listRooms({ commit }, uuid) {
    const { data } = await roomService.listRooms(uuid);
    commit('listRooms', { data: data.edges, uuid });
  },
  async createRoom(ctx, { uuid, data }) {
    await roomService.createRoom({ uuid, data });
  },
  async updateRoom({ commit }, { uuid, roomId, data }) {
    const response = await roomService.updateRoom({ uuid, roomId, data });
    commit('getRoom', { data: response.data, uuid, roomId });
  },
  async deleteRoom(ctx, { uuid, roomId }) {
    await roomService.deleteRoom({ uuid, roomId });
  },
  async addProductsToRoom(ctx, { uuid, roomId, products }) {
    for (let i = 0; i < products.length; i += 1) {
      const product = products[i];
      // eslint-disable-next-line no-await-in-loop
      await roomService.addProductToRoom({ uuid, roomId, product });
    }
  },
};

const mutations = {
  getRoom(state, payload) {
    const room = payload.data;
    state.rooms = { ...state.rooms, [room.uuid]: room };
  },
  listRooms(state, payload) {
    const rooms = payload.data.map((r) => r.node);
    const roomsById = payload.data.reduce((result, room) => {
      if (room && room.node) {
        result[room.node.uuid] = room.node;
      }
      return result;
    }, {});
    state.rooms = { ...state.rooms, ...roomsById };
    state.roomsByProject = { ...state.roomsByProject, [payload.uuid]: rooms };
  },
};

const getters = {
  getRoomById: (state) => (roomId) => state.rooms[roomId] || {},
  getRoomsByProjectId: (state) => (uuid) => (state.roomsByProject[uuid] || []),
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  mutations,
};

//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from 'vuex';
import RoomRulesDefaultDetailUI from '@/components/ui/roomUI/RoomRulesDefaultDetailUI.vue';

export default {
  name: 'RulesDefaultDetail',
  components: {
    RoomRulesDefaultDetailUI,
  },
  props: ['loading'],
  data() {
    return {
      projectId: this.$route.params.projectId,
      roomId: this.$route.params.roomId,
      rerenderKey: 1,
    };
  },
  async created() {
    await this.fetchData();
  },
  computed: {
    ...mapGetters('groups', ['getGroupsByRoom']),
    ...mapGetters('projects', ['isReadOnly']),
    ...mapGetters('rooms', ['getRoomById']),
    ...mapGetters('devices', ['getDevicesByRoom']),
    ...mapGetters('configs', ['getProjectConfigById']),
    ...mapGetters('rules', ['getDefaultRuleByRoom']),
    readOnly() {
      return this.isReadOnly(this.projectId);
    },
    room() {
      return this.getRoomById(this.roomId);
    },
    mode() {
      return this.room.defaultRoomBehaviour || 'manual';
    },
    projectConfig() {
      return this.getProjectConfigById(this.projectId);
    },
    roomSensors() {
      if (!this.projectConfig?.devices) {
        return [];
      }
      const room = (this.projectConfig?.rooms || []).find((r) => r.uuid === this.roomId);
      if (!room) {
        return [];
      }
      return (this.projectConfig?.devices?.sensors || []).filter((sensor) => room.sensors.includes(sensor.uuid));
    },
    lights() {
      return this.getDevicesByRoom(this.projectId, this.roomId).lights;
    },
    hclLightWarning() {
      return this.lights.filter((light) => !light.product.capabilities.hcl).length > 0;
    },
    defaultRule() {
      return this.getDefaultRuleByRoom(this.roomId);
    },
  },
  methods: {
    ...mapActions('rules', ['getDefaultRule', 'updateDefaultRule']),
    ...mapActions('rooms', ['getRoom']),
    ...mapActions('configs', ['getProjectConfig']),
    async fetchData() {
      await this.getRoom({ uuid: this.projectId, roomId: this.roomId });
      await this.getDefaultRule({ uuid: this.projectId, roomId: this.roomId });
      await this.getProjectConfig({ uuid: this.projectId });
      this.rerenderKey += 1;
    },
    async saveDefaultSettings(data) {
      await this.updateDefaultRule({
        uuid: this.projectId,
        roomId: this.roomId,
        data,
      });
      await this.fetchData();
    },
  },
};

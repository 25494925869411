import authService from '../services/auth';

const initialState = {
  userId: null,
  rememberMe: false,
  status: null,
  errorCode: null,
};

const actions = {
  async login({ commit }, { email, password, rememberMe }) {
    commit('loginRequest');
    try {
      const { data } = await authService.login({ email, password });
      commit('loginSuccess', { ...data, rememberMe });
    } catch (e) {
      commit('loginFailed', e.response.data.errorCode);
      throw e;
    }
  },
  async logout({ commit, rootState }) {
    const { refreshToken } = rootState.auth;
    await authService.logout({ refreshToken });
    commit('logout');
  },
  async register(ctx, payload) {
    await authService.register(payload);
  },
  async renewToken({ commit }) {
    const { data } = await authService.renewToken();
    commit('renewTokenSuccess', data);
  },
  async confirmRegistration(ctx, { email, token }) {
    await authService.confirmRegistration({ email, token });
  },
  async forgotPassword(ctx, email) {
    await authService.forgotPassword(email);
  },
  async resetPassword(ctx, { email, token, password }) {
    await authService.resetPassword({ email, token, password });
  },
};

const mutations = {
  loginRequest: (state) => {
    state.status = 'loading';
  },
  loginFailed: (state, errorCode) => {
    state.status = 'error';
    state.errorCode = errorCode;
  },
  loginSuccess: (state, data) => {
    state.userId = data.uuid;
    state.rememberMe = data.rememberMe;
    state.status = 'loggedin';
    state.errorCode = null;
  },
  logout: (state) => {
    state.userId = null;
    state.rememberMe = false;
    state.status = null;
    state.errorCode = null;
  },
  renewTokenSuccess: (state, data) => {
    state.userId = data.uuid;
  },
};

const getters = {
  isAuthenticated: (state) => !!state.userId,
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  mutations,
};

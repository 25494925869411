//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: ['title', 'loading', 'light', 'stickyHead', 'stickyTop', 'borderTop'],
  data() {
    return {
    };
  },
  computed: {
    hasHeadSlot() {
      return !!this.$slots.head;
    },
    headStyle() {
      return this.stickyTop && this.stickyHead ? `top: ${this.stickyTop}px;` : '';
    },
  },
};

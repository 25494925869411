//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapState } from 'vuex';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { WizardBus } from '@/bus';

export default {
  name: 'WizardProject',
  components: {
    ValidationObserver,
    ValidationProvider,
  },
  data() {
    return {
      contact: {
        name: '',
        email: '',
        phone: '',
        description: '',
        primary: true,
        onsite: false,
      },
    };
  },
  created() {
    this.contact = { ...this.projectState.contact };
    WizardBus.$on('next', this.onNextStep);
    WizardBus.$on('abort', this.onAbort);
    this.$nextTick(this.isSkippable);
  },
  beforeDestroy() {
    WizardBus.$off('next', this.onNextStep);
    WizardBus.$off('abort', this.onAbort);
  },
  computed: {
    ...mapState('wizard', ['projectState']),
    emptyContact() {
      return !this.contact.name.length
          && !this.contact.email.length
          && !this.contact.phone.length
          && !this.contact.description.length;
    },
  },
  methods: {
    ...mapActions('wizard', ['updateProjectState', 'resetState']),
    async onNextStep() {
      if (!this.emptyContact) {
        const isValid = await this.$refs['observer-3'].validate();
        if (!isValid) {
          return;
        }
      }
      this.updateProjectState({ contact: this.contact });
      await this.$router.push({ name: this.$route.meta.nextRoute });
    },
    onAbort() {
      this.resetState();
    },
    isSkippable() {
      if (this.emptyContact) {
        this.$refs['observer-3'].reset();
      }
      this.$parent.$parent.$data.skippable = this.emptyContact;
    },
    formatPhoneNumber() {
      this.contact.phone = this.contact.phone.replace(/[^\d+]/, '');
    },
  },
};

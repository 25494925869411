import firmwareService from '../services/firmwares';

const initialState = {
  firmwares: [],
};

const actions = {
  async listFirmwareUpdates({ commit }, { uuid, bodyData }) {
    const { data } = await firmwareService.listFirmwareUpdates({ uuid, bodyData });
    commit('listFirmwareUpdates', { data });
  },
  async updateFirmware(ctx, { uuid, imageId }) {
    const response = await firmwareService.updateFirmware({ uuid, imageId });
    return response.data;
  },
  async updateProductsFirmware(ctx, { uuid, productUUID, imageId }) {
    const response = await firmwareService.updateProductsFirmware({ uuid, productUUID, imageId });
    return response.data;
  },
};

const mutations = {
  listFirmwareUpdates(state, payload) {
    state.firmwares = payload.data || [];
  },
};

const getters = {
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  mutations,
};

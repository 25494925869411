/* global axios */

const ping = async () => axios({
  method: 'GET',
  url: 'ping/',
});

export default {
  ping,
};

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapGetters } from 'vuex';
import { ValidationObserver } from 'vee-validate';
import bus from '@/bus';
import InlineTextEditor from '@/components/ui/InlineTextEditor.vue';
import ContactRow from '@/components/ui/ContactRow.vue';
import IconLink from '@/components/ui/IconLink.vue';
import ProjectAddress from '@/components/ui/ProjectAddress.vue';
import ListItem from '@/components/ui/ListItem.vue';

export default {
  name: 'ProjectDetails',
  components: {
    'inline-text-editor': InlineTextEditor,
    ContactRow,
    IconLink,
    ProjectAddress,
    ValidationObserver,
    ListItem,
  },
  data() {
    return {
      localProject: { address: {} },
      localContactsUpdated: false,
      localContacts: [],
      localContactPrimary: {},
      localContactOnsite: {},
      localContactsOther: [],
      projectLoaded: false,
      projectId: this.$route.params.projectId,
      editingAddress: false,
      addContactButton: true,
      hideEdit: !this.$canI('update', 'projects'),
      hideEditDescription: !this.$canI('updateDescription', 'projects'),
      hideEditAddress: !this.$canI('updateAddress', 'projects'),
      hideEditContact: !this.$canI('updateContact', 'projects'),
      hideCreateContact: !this.$canI('createContact', 'projects'),
      hideDeleteContact: !this.$canI('deleteContact', 'projects'),
    };
  },
  created() {
    this.onGetProject();
  },
  computed: {
    ...mapGetters('projects', ['getProjectById', 'isReadOnly', 'getWorkInProgressData']),
    ...mapGetters('configs', ['getProjectConfigById']),
    readOnly() {
      // if project is not uploaded, returns default readOnly
      if (this.projectNotUploaded) {
        return this.isReadOnly(this.projectId);
      }
      // returns read only when other locks the project or this is a non-remote-only feature
      return this.remoteControlEnabled || this.lockedByOthers;
    },
    readOnlyRemote() {
      if (this.projectNotUploaded) {
        return false;
      }
      return this.remoteControlEnabled ? this.isReadOnly(this.projectId) : true;
    },
    projectNotUploaded() {
      return this.project.configEditAllowed;
    },
    workInProgressData() {
      return this.getWorkInProgressData(this.projectId);
    },
    remoteControlEnabled() {
      return this.workInProgressData.hasWorkInProgressChanges;
    },
    lockedByOthers() {
      return this.workInProgressData.lockedByOthers;
    },
    project() {
      return this.getProjectById(this.projectId);
    },
    projectConfig() {
      return this.getProjectConfigById(this.projectId);
    },
    configParametersChanged() {
      return JSON.stringify(this.localProject.configParameters) !== JSON.stringify(this.project.configParameters);
    },
  },
  methods: {
    ...mapActions('projects', ['getProject', 'updateProject', 'lockProject']),
    ...mapActions('configs', ['getProjectConfig']),
    async onGetProject() {
      await this.getProject(this.projectId);
      await this.getProjectConfig({ uuid: this.projectId });

      this.localProject = { ...this.project };

      if (!this.localProject.address) {
        this.localProject.address = {
          country: '',
          state: '',
          county: '',
          city: '',
          zipCode: '',
          line1: '',
          line2: '',
        };
      } else {
        this.localProject.address = { ...this.project.address };
      }

      if (!this.localProject.configParameters) {
        this.localProject.configParameters = {
          latitude: '',
          longitude: '',
          timeZone: '',
        };
      } else {
        this.localProject.configParameters = { ...this.project.configParameters };
      }

      this.localContacts = this.project.contacts;
      const others = this.project.contacts.filter((contact) => !contact.primary && !contact.onsite);
      this.localContactPrimary = this.project.contacts.find((contact) => contact.primary && !contact.onsite) || this.contactTemplate(true, false);
      this.localContactOnsite = this.project.contacts.find((contact) => !contact.primary && contact.onsite) || this.contactTemplate(false, true);
      this.localContactsOther = this.getOtherContacts(others);
      this.addContactButton = !!others.length;
      this.projectLoaded = true;
    },
    async onUpdateProject() {
      if (this.editingAddress) {
        const isValid = await this.$refs.addressObserver.validate();
        if (!isValid) {
          return;
        }
      }
      const { localProject: project } = this;
      const data = {};
      if (this.projectNotUploaded || !this.remoteControlEnabled) {
        data.name = project.name;
        data.description = project.description;
        data.contacts = this.localContacts;
      }
      if (this.projectNotUploaded || this.remoteControlEnabled) {
        data.address = project.address;
      }
      if (this.configParametersChanged && (this.projectNotUploaded || this.remoteControlEnabled)) {
        data.latitude = this.localProject.configParameters.latitude;
        data.longitude = this.localProject.configParameters.longitude;
        data.timeZone = this.localProject.configParameters.timeZone;
      }

      await this.updateProject({
        uuid: this.projectId, data, forceLock: this.configParametersChanged, contactsUpdated: this.localContactsUpdated,
      });
      this.localProject = { ...this.project };
      this.editingAddress = false;
      bus.$emit('forceRerender');
    },
    updateContacts(newContact, contactIndex, deleteContact) {
      if (newContact.primary && !newContact.onsite) {
        this.localContactPrimary = deleteContact ? this.contactTemplate(true, false) : newContact;
      } else if (!newContact.primary && newContact.onsite) {
        this.localContactOnsite = deleteContact ? this.contactTemplate(false, true) : newContact;
      } else if (!newContact.primary && !newContact.onsite) {
        if (deleteContact && this.localContactsOther.length > 1) {
          this.localContactsOther.splice(contactIndex, 1);
        } else {
          this.localContactsOther[contactIndex] = deleteContact ? this.contactTemplate(false, false) : newContact;
        }
      }
      const contacts = [this.localContactPrimary, this.localContactOnsite, ...this.localContactsOther];
      this.localContacts = [];

      // eslint-disable-next-line no-restricted-syntax
      for (const contact of contacts) {
        if (contact.name && contact.email && contact.phone && contact.phone) {
          this.localContacts.push(contact);
        }
      }
      this.localContactsUpdated = true;
      this.onUpdateProject();
    },
    contactTemplate(primary, onsite) {
      return {
        name: '',
        email: '',
        phone: '',
        description: '',
        primary,
        onsite,
      };
    },
    getOtherContacts(others) {
      return others.length ? others : [this.contactTemplate(false, false)];
    },
    editAddress() {
      if (this.disabled || this.readOnlyRemote || !this.projectLoaded || this.hideEditAddress) {
        return;
      }
      this.editingAddress = true;
    },
    cancelEditAddress() {
      this.localProject.address = { ...this.project.address };
      this.editingAddress = false;
    },
    addNewOtherContact() {
      if (this.addContactButton) {
        this.localContactsOther.push(this.contactTemplate(false, false));
        this.addContactButton = false;
        this.$nextTick(() => this.focusLastContact());
      } else {
        this.focusLastContact();
      }
    },
    focusLastContact() {
      const index = Number(this.localContactsOther.length - 1);
      this.$refs[`other-contact-${index}`][0].editValue();
    },
    setAddress(newAddress) {
      this.localProject.address = newAddress;
    },
    setConfigParameters(newConfigParameters) {
      this.localProject.configParameters = newConfigParameters;
    },
  },
};

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


export default {
  props: ['label', 'value', 'readMode'],
  components: {},
  created() {
    [this.from, this.to] = this.value.split('-');
  },
  data() {
    return {
      from: '',
      to: '',
    };
  },
  methods: {
    onInput() {
      this.$emit('input', `${this.from}-${this.to}`);
    },
  },
};

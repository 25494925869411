//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: ['label', 'value', 'readMode', 'list', 'onlyOne'],
  data() {
    return {
      internalGroups: [],
      internalSelectedGroups: [],
    };
  },
  mounted() {
    this.internalGroups = [...this.value];
  },
  methods: {
    onInputChange(item, val, index) {
      if (this.onlyOne) {
        // eslint-disable-next-line no-restricted-syntax
        for (const [i, itemGroup] of this.internalGroups.entries()) {
          const element = this.$refs[`checkbox-${i}`];
          itemGroup.checked = false;
          element[0].$refs.input.checked = val ? index === i : false;
        }
      }
      item.checked = val;
      this.internalSelectedGroups = this.internalGroups.filter((groupObj) => groupObj.checked).map((group) => group.uuid);
      this.$emit('update', this.internalSelectedGroups);
    },
    isGroupSelected(item, groupId) {
      const checked = this.list.some((group) => group === groupId);
      item.checked = checked;
      return checked;
    },
  },
};

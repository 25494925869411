//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'CustomSelect',
  props: ['options', 'value'],
  model: {
    prop: 'value',
    event: 'input',
  },
  computed: {
    selected() {
      return this.options.find((option) => option.value === this.value) || {};
    },
  },
  methods: {
    onSelect(option) {
      this.$emit('input', option.value);
    },
  },
};

/* global axios */

const listCoworkers = async ({ uuid }) => axios({
  method: 'GET',
  url: `/projects/${uuid}/coworkers`,
});

const getInvitation = async ({ uuid, coworkerId }) => axios({
  method: 'GET',
  url: `/projects/${uuid}/coworkers/${coworkerId}`,
  skipGlobalErrorHandling: true,
});

const createCoworker = async ({ uuid, data }) => axios({
  method: 'POST',
  url: `/projects/${uuid}/coworkers`,
  data,
  logEvent: () => ({ eventName: 'send_coworker_invite', eventParams: { role: data?.role } }),
});

const updateCoworker = async ({
  uuid, coworkerId, data,
}) => axios({
  method: 'PUT',
  url: `/projects/${uuid}/coworkers/${coworkerId}`,
  data,
  logEvent: () => ({ eventName: 'edit_coworker', eventParams: { role: data?.role } }),
});

const acceptInvitation = async ({
  uuid, coworkerId,
}) => axios({
  method: 'PATCH',
  url: `/projects/${uuid}/coworkers/${coworkerId}/acceptinvitation`,
  data: {},
  logEvent: () => ({ eventName: 'accept_invite' }),
});

const rejectInvitation = async ({
  uuid, coworkerId,
}) => axios({
  method: 'PATCH',
  url: `/projects/${uuid}/coworkers/${coworkerId}/rejectinvitation`,
  data: {},
  logEvent: () => ({ eventName: 'reject_invite' }),
});

const deleteCoworker = async ({ uuid, coworkerId }) => axios({
  method: 'DELETE',
  url: `/projects/${uuid}/coworkers/${coworkerId}`,
  lock: { uuid },
  data: {},
  logEvent: () => ({ eventName: 'delete_coworker' }),
});

export default {
  listCoworkers,
  getInvitation,
  createCoworker,
  updateCoworker,
  deleteCoworker,
  acceptInvitation,
  rejectInvitation,
};

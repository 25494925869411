//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'WizardUI',
  props: ['title', 'subtitle', 'currentStep', 'allSteps', 'question', 'answer'],
};

//
//
//
//
//
//
//
//

export default {
  name: 'NumberField',
  props: ['value', 'minValue'],
  data() {
    return {
      number: 0,
      min: 0,
    };
  },
  created() {
    this.number = this.value || 0;
    this.min = this.minValue || 0;
  },
  watch: {
    value(v) {
      this.number = v;
    },
  },
  methods: {
    add(diff) {
      const result = this.number + diff;
      if (result >= this.min) {
        this.number = result;
        this.$emit('input', result);
      }
    },
    onChange(val) {
      const result = parseInt(val, 10) || 0;
      if (result >= this.min) {
        this.number = result;
        this.$emit('input', result);
      }
    },
  },
};

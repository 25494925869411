//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import AdvancedTable from '@/components/ui/AdvancedTable.vue';

export default {
  name: 'RoomSchedulesUI',
  components: { AdvancedTable },
  props: ['schedules', 'loading', 'route', 'readOnly'],
  data() {
    return {
      headers: [{
        title: 'Name', key: 'name', type: 'text', sortable: true, searchable: true,
      }],
    };
  },
  computed: {
    cDays() {
      return this.$t('generalWeekdays').split(',');
    },
    createIllustration() {
      return {
        // eslint-disable-next-line global-require
        icon: require('../../../assets/icons/no_schedule.svg'),
        texts: this.readOnly ? [this.$t('scheduleNoScheduleMessage')] : [this.$t('scheduleNoScheduleMessage'), this.$t('scheduleCreateMessage')],
        button: this.$t('scheduleCreateSchedule'),
      };
    },
  },
  methods: {
    onOpenItem(schedule) {
      this.$router.push(`${this.route}/${schedule.uuid}`);
    },
    onCreateItem() {
      this.$bvModal.show('add-schedule-modal');
    },
    onDelete(schedule) {
      this.$emit('delete', schedule);
    },
    decToBin(dec) {
      // eslint-disable-next-line no-bitwise
      return (dec >>> 0).toString(2).padStart(7, '0');
    },
    repeatDays(schedule) {
      const bin = this.decToBin(schedule.repeat).split('').reverse();
      return this.cDays
        .reduce((acc, item, i) => (bin[(i + 1) % 7] === '1' ? [...acc, item] : acc), [])
        .join().trim();
    },
    formatWeek(repeatDays) {
      const weekFormatsShort = [this.$t('generalWeekdaysShort'), this.$t('generalWeekendShort'), this.$t('generalAllWeekShort')];
      const weekFormats = [this.$t('generalWeekWeekdays'), this.$t('generalWeekWeekend'), this.$t('generalWeekAllWeek')];
      const index = weekFormatsShort.indexOf(repeatDays);
      return index !== -1 ? weekFormats[index] : repeatDays;
    },
  },
};

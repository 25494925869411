//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapState } from 'vuex';
import { WizardBus } from '@/bus';
import FloorplanDetailsUI from '@/components/ui/floorplan/FloorplanDetailsUI.vue';
import DeleteFloorplanModal from '@/views/projects/floorplans/modals/DeleteFloorplanModal.vue';
import WizardListUI from '@/components/ui/wizard/WizardListUI.vue';

export default {
  name: 'WizardProject',
  components: {
    WizardListUI,
    DeleteFloorplanModal,
    FloorplanDetailsUI,
  },
  data() {
    return {
      files: [],
      disableCreate: false,
      selectedFloorplan: {},
    };
  },
  created() {
    this.files = [...this.projectState.files];
    WizardBus.$on('next', this.onNextStep);
    WizardBus.$on('abort', this.onAbort);
  },
  beforeDestroy() {
    WizardBus.$off('next', this.onNextStep);
    WizardBus.$off('abort', this.onAbort);
  },
  watch: {
    files() {
      this.$nextTick(this.isSkippable);
    },
  },
  computed: {
    ...mapState('wizard', ['projectState']),
  },
  methods: {
    ...mapActions('wizard', ['updateProjectState', 'resetState']),
    showUploadModal() {
      this.disableCreate = true;
      this.$bvModal.show('file-upload-modal');
    },
    showDeleteModal(index) {
      this.selectedFloorplan = { ...this.files[index], index };
      this.$bvModal.show('delete-floorplan-modal');
    },
    onStateChanged({ enableUpload }) {
      this.disableCreate = !enableUpload;
    },
    async onCreateClicked() {
      const isValid = await this.$refs.floorplanForm.$refs.observer.validate();
      if (!isValid) {
        return;
      }
      this.$bvModal.hide('file-upload-modal');
      this.localFiles = await this.$refs.floorplanForm.onSave.bind(this.$refs.floorplanForm)();
      this.files = this.files.concat(this.localFiles);
    },
    onDeleteFloorplan() {
      this.files.splice(this.selectedFloorplan.index, 1);
      this.selectedFloorplan = {};
      this.$bvModal.hide('delete-floorplan-modal');
    },
    onCancel() {
      this.localFiles = this.files;
      this.$bvModal.hide('file-upload-modal');
    },
    async onNextStep() {
      this.updateProjectState({ files: this.files });
      await this.$router.push({ name: this.$route.meta.nextRoute });
    },
    onAbort() {
      this.resetState();
    },
    isSkippable() {
      this.$parent.$parent.$data.skippable = !this.files.length;
    },
  },
};

import Vue from 'vue';
import VueRouter from 'vue-router';

import AuthLayout from '@/components/layouts/AuthLayout.vue';
import MainLayout from '@/components/layouts/MainLayout.vue';
import ProjectLayout from '@/components/layouts/ProjectLayout.vue';
import WizardProjectLayout from '@/components/layouts/WizardProjectLayout.vue';
import WizardRoomLayout from '@/components/layouts/WizardRoomLayout.vue';

import Settings from '@/views/Settings.vue';
import Support from '@/views/Support.vue';
import AnalyticsDataCollection from '@/views/AnalyticsDataCollection.vue';

import Login from '@/views/Auth/Login.vue';
import Register from '@/views/Auth/Register.vue';
import Confirm from '@/views/Auth/Confirm.vue';
import Success from '@/views/Auth/Success.vue';
import ResetPassword from '@/views/Auth/ResetPassword.vue';

import Projects from '@/views/projects/Projects.vue';
import ProjectsCreate from '@/views/projects/Create.vue';
import ProjectDetails from '@/views/projects/details/Details.vue';
import ProjectCoworkers from '@/views/projects/coworkers/List.vue';
import ProjectCoworkerDetails from '@/views/projects/coworkers/Details.vue';
import ProjectFloorplans from '@/views/projects/floorplans/List.vue';
import ProjectDocuments from '@/views/projects/documents/Documents.vue';
import ProjectRemoteMaintenance from '@/views/projects/remoteMaintenance/RemoteMaintenance.vue';
import ProjectMaintenance from '@/views/projects/maintenance/Maintenance.vue';
import FirmwareUpdate from '@/views/projects/firmwareUpdate/FirmwareUpdate.vue';
import ProductFirmwareUpdate from '@/views/projects/firmwareUpdate/ProductFirmwareUpdate.vue';
import ProjectRemoteMaintenanceFirmwareUpdate from '@/views/projects/remoteMaintenance/FirmwareUpdate.vue';
import ProjectTemplateList from '@/views/projects/templates/ProjectTemplateList.vue';
import ProjectSchedules from '@/views/projects/schedules/Schedules.vue';
import ProjectScheduleDetail from '@/views/projects/schedules/ScheduleDetail.vue';

import ProjectRooms from '@/views/projects/rooms/Rooms.vue';
import RoomsCreate from '@/views/projects/rooms/Create.vue';
import RoomsDetails from '@/views/projects/rooms/details/Details.vue';
import RoomDevices from '@/views/projects/rooms/devices/Devices.vue';
import RoomDeviceDetail from '@/views/projects/rooms/devices/DeviceDetail.vue';
import RoomAddDevice from '@/views/projects/rooms/devices/AddDevice.vue';
import RoomGroups from '@/views/projects/rooms/groups/Groups.vue';
import RoomGroupDetail from '@/views/projects/rooms/groups/GroupDetail.vue';
import RoomScenes from '@/views/projects/rooms/scenes/Scenes.vue';
import RoomSceneDetail from '@/views/projects/rooms/scenes/SceneDetail.vue';
import RoomRules from '@/views/projects/rooms/rules/Rules.vue';
import RoomRuleDetail from '@/views/projects/rooms/rules/RuleDetail.vue';
import RoomRuleDefaultDetail from '@/views/projects/rooms/rules/RuleDefaultDetail.vue';
import RoomGroupRuleDefaultDetail from '@/views/projects/rooms/rules/GroupRuleDefaultDetail.vue';

import TemplateList from '@/views/templates/List.vue';
import TemplateDetails from '@/views/templates/Details.vue';
import TemplateDevices from '@/views/templates/Devices.vue';
import TemplateDeviceDetail from '@/views/templates/DeviceDetail.vue';
import TemplateGroups from '@/views/templates/Groups.vue';
import TemplateGroupDetail from '@/views/templates/GroupDetail.vue';
import TemplateScenes from '@/views/templates/Scenes.vue';
import TemplateSceneDetail from '@/views/templates/SceneDetail.vue';
import TemplateRules from '@/views/templates/Rules.vue';
import TemplateRuleDetail from '@/views/templates/RuleDetail.vue';

import ProductsCategories from '@/views/products/Categories.vue';
import ProductsList from '@/views/products/List.vue';
import ProductsDetails from '@/views/products/Details.vue';

import Maintenance from '@/views/maintenance/Maintenance.vue';
import MaintenanceDetails from '@/views/maintenance/MaintenanceDetails.vue';

import WPStep1NameAndDesc from '@/views/wizard/project/Step1NameAndDesc.vue';
import WPStep2Address from '@/views/wizard/project/Step2Address.vue';
import WPStep3PrimaryContact from '@/views/wizard/project/Step3PrimaryContact.vue';
import WPStep4Floorplan from '@/views/wizard/project/Step4Floorplan.vue';
import WPStep5Overview from '@/views/wizard/project/Step5Overview.vue';

import WRStep01NameAndDesc from '@/views/wizard/room/Step01NameAndDesc.vue';
import WRStep02Switches from '@/views/wizard/room/Step02Switches.vue';
import WRStep03Sensors from '@/views/wizard/room/Step03Sensors.vue';
import WRStep04Lights from '@/views/wizard/room/Step04Lights.vue';
import WRStep05Groups from '@/views/wizard/room/Step05Groups.vue';
import WRStep06Scenes from '@/views/wizard/room/Step06Scenes.vue';
import WRStep07Rules from '@/views/wizard/room/Step07Rules.vue';
import WRStep08Overview from '@/views/wizard/room/Step08Overview.vue';
import WRStep09Schedules from '@/views/wizard/room/Step09Schedules.vue';
import WRStep10Summary from '@/views/wizard/room/Step10Summary.vue';

// Sidebars
import ProjectSidebar from '@/components/layouts/navbars/sidebar/ProjectSidebar.vue';
import RoomSidebar from '@/components/layouts/navbars/sidebar/RoomSidebar.vue';
import TemplateSidebar from '@/components/layouts/navbars/sidebar/TemplateSidebar.vue';
import FirmwareUpdateDetails from '@/views/projects/firmwareUpdate/FirmwareUpdateDetails.vue';

import store from '../store';
import { logEvent } from '../firebase';

const TAB_ID = Math.floor(Math.random() * 10000000);

Vue.use(VueRouter);

const ifNotAuthenticated = (to, from, next) => {
  if (!store.getters['auth/isAuthenticated']) {
    next();
    return;
  }
  next('/projects');
};

const trackTabs = async (to) => {
  if (to.params && to.params.projectId) {
    await store.dispatch('app/trackTab', { projectId: to.params.projectId, tabId: TAB_ID });
  } else {
    await store.dispatch('app/trackTab', { projectId: undefined, tabId: TAB_ID });
  }
};

const ifAuthenticated = async (to, from, next) => {
  if (store.getters['auth/isAuthenticated']) {
    await trackTabs(to, from);
    next();
    return;
  }
  next('/login');
};

const redirectIfAuthed = async (to, from, next) => {
  if (store.getters['auth/isAuthenticated']) {
    next('/projects');
    return;
  }
  next('/login');
};

const redirectIfProject = (to, from, next) => {
  if (from.params.projectId !== undefined) {
    next(`/projects/${from.params.projectId}/rooms`);
    return;
  }
  next('/error/404');
};

const checkInvitation = async (to, from, next) => {
  if (store.getters['auth/isAuthenticated']) {
    next();
    return;
  }
  next('/login');
};

const generalRoutes = [
  {
    path: '/',
    beforeEnter: redirectIfAuthed,
  },
  {
    path: '/accept-coworker-invitation',
    name: 'accept-coworker-invitation',
    component: Projects,
    beforeEnter: checkInvitation,
    meta: {
      layout: {
        component: MainLayout,
        options: { showProjectFilter: true },
      },
    },
  },
  {
    path: '/error/404',
    name: 'error-404',
    component: () => import('@/views/errors/404.vue'),
    beforeEnter: redirectIfProject,
    meta: { layout: AuthLayout },
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
    beforeEnter: ifNotAuthenticated,
    meta: { layout: AuthLayout },
  },
  {
    path: '/logout',
    name: 'logout',
    async beforeEnter(to, from, next) {
      await store.dispatch('auth/logout');
      next('/login');
    },
  },
  {
    path: '/register',
    name: 'register',
    component: Register,
    beforeEnter: ifNotAuthenticated,
    meta: { layout: AuthLayout },
  },
  {
    path: '/confirm',
    name: 'confirm',
    component: Confirm,
    meta: { layout: AuthLayout },
    // beforeEnter: ifNotAuthenticated,
  },
  {
    path: '/success',
    name: 'success',
    component: Success,
    beforeEnter(to, from, next) {
      if (store.getters['auth/isAuthenticated']) {
        next('settings');
      } else {
        next();
      }
    },
    meta: { layout: AuthLayout },
  },
  {
    path: '/reset-password',
    name: 'reset-password',
    component: ResetPassword,
    beforeEnter: ifNotAuthenticated,
    meta: { layout: AuthLayout },
  },
  {
    path: '/settings',
    name: 'settings',
    component: Settings,
    beforeEnter: ifAuthenticated,
    meta: {
      layout: {
        component: MainLayout,
        options: {
          breadcrumbs: [
            { title: 'pageProfileSettings' },
          ],
        },
      },
    },
  },
  {
    path: '/analytics-data-collection',
    name: 'analytics-data-collection',
    component: AnalyticsDataCollection,
    beforeEnter: ifAuthenticated,
    meta: {
      layout: {
        component: MainLayout,
        options: {
          breadcrumbs: [
            { title: 'pageAnalyticsDataCollection' },
          ],
        },
      },
    },
  },
  {
    path: '/support/:projectId?',
    name: 'support',
    component: Support,
    beforeEnter: ifAuthenticated,
    meta: {
      layout: {
        component: MainLayout,
        options: {
          breadcrumbs: [
            { title: 'pageContactSupport' },
          ],
        },
      },
    },
  },
];

const projectRoutes = [
  {
    path: '/projects',
    name: 'projects',
    component: Projects,
    beforeEnter: (to, from, next) => {
      ifAuthenticated(to, from, next);
    },
    meta: {
      layout: {
        component: MainLayout,
        options: { showProjectFilter: true },
      },
    },
  },
  {
    path: '/projects/create',
    name: 'projects-create',
    component: ProjectsCreate,
    beforeEnter: ifAuthenticated,
    meta: {
      layout: {
        component: MainLayout,
        options: {
          breadcrumbs: [
            { routeName: 'projects', back: true },
            { title: 'pageCreateProject' },
          ],
        },
      },
    },
  },
  {
    path: '/projects/:projectId/details',
    name: 'project-details',
    component: ProjectDetails,
    beforeEnter: ifAuthenticated,
    meta: {
      layout: {
        component: ProjectLayout,
        options: {
          sidebarComponent: ProjectSidebar,
          breadcrumbs: [
            { routeName: 'projects', back: true },
            { title: '$CURRENT_PROJECT_NAME', routeName: 'project-rooms' },
            { title: 'generalDetails' },
          ],
        },
      },
    },
  },
  {
    path: '/projects/:projectId/coworkers',
    name: 'project-coworkers',
    component: ProjectCoworkers,
    beforeEnter: ifAuthenticated,
    meta: {
      layout: {
        component: ProjectLayout,
        options: {
          sidebarComponent: ProjectSidebar,
          breadcrumbs: [
            { routeName: 'projects', back: true },
            { title: '$CURRENT_PROJECT_NAME', routeName: 'project-rooms' },
            { title: 'pageCoworkers' },
          ],
        },
      },
    },
  },
  {
    path: '/projects/:projectId/coworkers/:coworkerId',
    name: 'coworker-detail',
    component: ProjectCoworkerDetails,
    beforeEnter: ifAuthenticated,
    meta: {
      layout: {
        component: ProjectLayout,
        options: {
          breadcrumbs: [
            { title: '$CURRENT_PROJECT_NAME', routeName: 'project-rooms' },
            { title: 'pageCoworkers', routeName: 'project-coworkers', back: true },
            { title: '$CURRENT_COWORKER' },
          ],
        },
      },
    },
  },
  {
    path: '/projects/:projectId/documents',
    name: 'project-documents',
    component: ProjectDocuments,
    beforeEnter: ifAuthenticated,
    meta: {
      layout: {
        component: ProjectLayout,
        options: {
          sidebarComponent: ProjectSidebar,
          breadcrumbs: [
            { routeName: 'projects', back: true },
            { title: '$CURRENT_PROJECT_NAME', routeName: 'project-rooms' },
            { title: 'pageDocuments' },
          ],
        },
      },
      remoteDisabled: true,
    },
  },
  {
    path: '/projects/:projectId/controller',
    name: 'project-remote-maintenance',
    component: ProjectRemoteMaintenance,
    beforeEnter: ifAuthenticated,
    meta: {
      layout: {
        component: ProjectLayout,
        options: {
          sidebarComponent: ProjectSidebar,
          breadcrumbs: [
            { routeName: 'projects', back: true },
            { title: '$CURRENT_PROJECT_NAME', routeName: 'project-rooms' },
            { title: 'pageGateway' },
          ],
        },
      },
    },
  },
  {
    path: '/projects/:projectId/firmware-update',
    name: 'project-firmware-update',
    component: FirmwareUpdate,
    beforeEnter: ifAuthenticated,
    meta: {
      layout: {
        component: ProjectLayout,
        options: {
          sidebarComponent: ProjectSidebar,
          breadcrumbs: [
            { routeName: 'projects', back: true },
            { title: '$CURRENT_PROJECT_NAME', routeName: 'project-firmware-update' },
            { title: 'pageFirmwareUpdate' },
          ],
        },
      },
    },
  },
  {
    path: '/projects/:projectId/firmware-update/details',
    name: 'project-firmware-update-details',
    component: FirmwareUpdateDetails,
    beforeEnter: ifAuthenticated,
    meta: {
      layout: {
        component: ProjectLayout,
        options: {
          sidebarComponent: ProjectSidebar,
          breadcrumbs: [
            { routeName: 'projects' },
            { title: '$CURRENT_PROJECT_NAME', routeName: 'project-firmware-update' },
            { title: 'pageFirmwareUpdate', routeName: 'project-firmware-update', back: true },
            { title: 'pageFirmwareUpdateDetails' },
          ],
        },
      },
    },
  },
  {
    path: '/projects/:projectId/firmware-update/:productId',
    name: 'project-product-firmware-update',
    component: ProductFirmwareUpdate,
    beforeEnter: ifAuthenticated,
    meta: {
      layout: {
        component: ProjectLayout,
        options: {
          sidebarComponent: ProjectSidebar,
          breadcrumbs: [
            { routeName: 'projects' },
            { title: '$CURRENT_PROJECT_NAME', routeName: 'project-firmware-update' },
            { title: 'pageFirmwareUpdate', routeName: 'project-firmware-update' },
            { title: 'pageFirmwareUpdateDetails', routeName: 'project-firmware-update-details', back: true },
            { title: '$CURRENT_PRODUCT_NAME' },
          ],
        },
      },
    },
  },
  {
    path: '/projects/:projectId/maintenance',
    name: 'project-maintenance',
    component: ProjectMaintenance,
    beforeEnter: ifAuthenticated,
    meta: {
      layout: {
        component: ProjectLayout,
        options: {
          sidebarComponent: ProjectSidebar,
          breadcrumbs: [
            { routeName: 'projects', back: true },
            { title: '$CURRENT_PROJECT_NAME', routeName: 'project-rooms' },
            { title: 'pageMaintenance' },
          ],
        },
      },
    },
  },
  {
    path: '/projects/:projectId/remote-maintenance/firmware-update',
    name: 'project-remote-maintenance-firmware-update',
    component: ProjectRemoteMaintenanceFirmwareUpdate,
    beforeEnter: ifAuthenticated,
    meta: {
      layout: {
        component: ProjectLayout,
        options: {
          breadcrumbs: [
            { title: '$CURRENT_PROJECT_NAME', routeName: 'project-rooms' },
            { title: 'pageGateway', routeName: 'project-remote-maintenance', back: true },
            { title: 'pageFirmwareUpdate' },
          ],
        },
      },
    },
  },
  {
    path: '/projects/:projectId/schedules',
    name: 'project-schedules',
    component: ProjectSchedules,
    beforeEnter: ifAuthenticated,
    meta: {
      layout: {
        component: ProjectLayout,
        options: {
          sidebarComponent: ProjectSidebar,
          breadcrumbs: [
            { routeName: 'projects', back: true },
            { title: '$CURRENT_PROJECT_NAME', routeName: 'project-rooms' },
            { title: 'pageSchedules' },
          ],
        },
      },
    },
  },
  {
    path: '/projects/:projectId/schedules/:scheduleId',
    name: 'schedule-detail',
    component: ProjectScheduleDetail,
    beforeEnter: ifAuthenticated,
    meta: {
      layout: {
        component: ProjectLayout,
        options: {
          breadcrumbs: [
            { title: '$CURRENT_PROJECT_NAME', routeName: 'project-rooms' },
            { title: 'pageSchedules', routeName: 'project-schedules', back: true },
            { title: '$CURRENT_SCHEDULE_NAME' },
          ],
        },
      },
    },
  },
  {
    path: '/projects/:projectId/floorplans',
    name: 'project-floorplans',
    component: ProjectFloorplans,
    beforeEnter: ifAuthenticated,
    meta: {
      layout: {
        component: ProjectLayout,
        options: {
          sidebarComponent: ProjectSidebar,
          breadcrumbs: [
            { routeName: 'projects', back: true },
            { title: '$CURRENT_PROJECT_NAME', routeName: 'project-rooms' },
            { title: 'pageFloorplanManager' },
          ],
        },
      },
      remoteDisabled: true,
    },
  },
];

const projectTemplateRoutes = [
  {
    path: '/projects/:projectId/templates',
    name: 'project-templates',
    component: ProjectTemplateList,
    beforeEnter: ifAuthenticated,
    meta: {
      layout: {
        component: ProjectLayout,
        options: {
          sidebarComponent: ProjectSidebar,
          breadcrumbs: [
            { routeName: 'projects', back: true },
            { title: '$CURRENT_PROJECT_NAME', routeName: 'project-rooms' },
            { title: 'pageProjectTemplates' },
          ],
        },
      },
    },
  },
  {
    path: '/projects/:projectId/templates/:templateId/details',
    name: 'project-template-details',
    component: TemplateDetails,
    beforeEnter: ifAuthenticated,
    meta: {
      layout: {
        component: ProjectLayout,
        options: {
          sidebarComponent: TemplateSidebar,
          breadcrumbs: [
            { title: '$CURRENT_PROJECT_NAME', routeName: 'project-rooms' },
            { title: 'pageProjectTemplates', routeName: 'project-templates', back: true },
            { title: '$CURRENT_TEMPLATE_NAME' },
          ],
        },
      },
    },
  },
  {
    path: '/projects/:projectId/templates/:templateId/devices',
    name: 'project-template-devices',
    component: TemplateDevices,
    beforeEnter: ifAuthenticated,
    meta: {
      layout: {
        component: ProjectLayout,
        options: {
          sidebarComponent: TemplateSidebar,
          breadcrumbs: [
            { title: '$CURRENT_PROJECT_NAME', routeName: 'project-rooms' },
            { title: 'pageProjectTemplates', routeName: 'project-templates', back: true },
            { title: '$CURRENT_TEMPLATE_NAME' },
          ],
        },
      },
    },
  },
  {
    path: '/projects/:projectId/templates/:templateId/devices/:deviceId',
    name: 'project-template-device-detail',
    component: TemplateDeviceDetail,
    beforeEnter: ifAuthenticated,
    meta: {
      layout: {
        component: ProjectLayout,
        options: {
          sidebarComponent: TemplateSidebar,
          breadcrumbs: [
            { title: '$CURRENT_PROJECT_NAME', routeName: 'project-rooms' },
            { title: 'pageProjectTemplates', routeName: 'project-templates' },
            { title: '$CURRENT_TEMPLATE_NAME', routeName: 'project-template-devices', back: true },
            { title: '$CURRENT_DEVICE_PRODUCT_NAME' },
          ],
        },
      },
    },
  },
  {
    path: '/projects/:projectId/templates/:templateId/groups',
    name: 'project-template-groups',
    component: TemplateGroups,
    beforeEnter: ifAuthenticated,
    meta: {
      layout: {
        component: ProjectLayout,
        options: {
          sidebarComponent: TemplateSidebar,
          breadcrumbs: [
            { title: '$CURRENT_PROJECT_NAME', routeName: 'project-rooms' },
            { title: 'pageProjectTemplates', routeName: 'project-templates', back: true },
            { title: '$CURRENT_TEMPLATE_NAME' },
          ],
        },
      },
    },
  },
  {
    path: '/projects/:projectId/templates/:templateId/groups/:groupId',
    name: 'project-template-group-detail',
    component: TemplateGroupDetail,
    beforeEnter: ifAuthenticated,
    meta: {
      layout: {
        component: ProjectLayout,
        options: {
          sidebarComponent: TemplateSidebar,
          breadcrumbs: [
            { title: '$CURRENT_PROJECT_NAME', routeName: 'project-rooms' },
            { title: 'pageProjectTemplates', routeName: 'project-templates' },
            { title: '$CURRENT_TEMPLATE_NAME', routeName: 'project-template-groups', back: true },
            { title: '$CURRENT_GROUP_NAME' },
          ],
        },
      },
    },
  },
  {
    path: '/projects/:projectId/templates/:templateId/scenes',
    name: 'project-template-scenes',
    component: TemplateScenes,
    beforeEnter: ifAuthenticated,
    meta: {
      layout: {
        component: ProjectLayout,
        options: {
          sidebarComponent: TemplateSidebar,
          breadcrumbs: [
            { title: '$CURRENT_PROJECT_NAME', routeName: 'project-rooms' },
            { title: 'pageProjectTemplates', routeName: 'project-templates', back: true },
            { title: '$CURRENT_TEMPLATE_NAME' },
          ],
        },
      },
    },
  },
  {
    path: '/projects/:projectId/templates/:templateId/scenes/:sceneId',
    name: 'project-template-scene-detail',
    component: TemplateSceneDetail,
    beforeEnter: ifAuthenticated,
    meta: {
      layout: {
        component: ProjectLayout,
        options: {
          sidebarComponent: TemplateSidebar,
          breadcrumbs: [
            { title: '$CURRENT_PROJECT_NAME', routeName: 'project-rooms' },
            { title: 'pageProjectTemplates', routeName: 'project-templates' },
            { title: '$CURRENT_TEMPLATE_NAME', routeName: 'project-template-scenes', back: true },
            { title: '$CURRENT_SCENE_NAME' },
          ],
        },
      },
    },
  },
  {
    path: '/projects/:projectId/templates/:templateId/rules',
    name: 'project-template-rules',
    component: TemplateRules,
    beforeEnter: ifAuthenticated,
    meta: {
      layout: {
        component: ProjectLayout,
        options: {
          sidebarComponent: TemplateSidebar,
          breadcrumbs: [
            { title: '$CURRENT_PROJECT_NAME', routeName: 'project-rooms' },
            { title: 'pageProjectTemplates', routeName: 'project-templates', back: true },
            { title: '$CURRENT_TEMPLATE_NAME' },
          ],
        },
      },
    },
  },
  {
    path: '/projects/:projectId/templates/:templateId/rules/:ruleId',
    name: 'project-template-rule-detail',
    component: TemplateRuleDetail,
    beforeEnter: ifAuthenticated,
    meta: {
      layout: {
        component: ProjectLayout,
        options: {
          sidebarComponent: TemplateSidebar,
          breadcrumbs: [
            { title: '$CURRENT_PROJECT_NAME', routeName: 'project-rooms' },
            { title: 'pageProjectTemplates', routeName: 'project-templates' },
            { title: '$CURRENT_TEMPLATE_NAME', routeName: 'project-template-rules', back: true },
            { title: '$CURRENT_RULE_NAME' },
          ],
        },
      },
    },
  },
];

const projectRoomRoutes = [
  {
    path: '/projects/:projectId/rooms',
    name: 'project-rooms',
    component: ProjectRooms,
    beforeEnter: ifAuthenticated,
    meta: {
      layout: {
        component: ProjectLayout,
        options: {
          sidebarComponent: ProjectSidebar,
          breadcrumbs: [
            { routeName: 'projects', back: true },
            { title: '$CURRENT_PROJECT_NAME', routeName: 'project-rooms' },
            { title: 'pageRooms' },
          ],
        },
      },
    },
  },
  {
    path: '/projects/:projectId/rooms/create',
    name: 'rooms-create',
    component: RoomsCreate,
    beforeEnter: ifAuthenticated,
    meta: {
      layout: {
        component: ProjectLayout,
        options: {
          sidebarComponent: ProjectSidebar,
          breadcrumbs: [
            { routeName: 'projects', back: true },
            { title: '$CURRENT_PROJECT_NAME', routeName: 'project-rooms' },
            { title: 'pageCreateRoom' },
          ],
        },
      },
      remoteRedirect: 'project-rooms',
    },
  },
  {
    path: '/projects/:projectId/rooms/:roomId/details',
    name: 'room-details',
    component: RoomsDetails,
    beforeEnter: ifAuthenticated,
    meta: {
      layout: {
        component: ProjectLayout,
        options: {
          sidebarComponent: RoomSidebar,
          breadcrumbs: [
            { title: '$CURRENT_PROJECT_NAME', routeName: 'project-rooms', back: true },
            { title: '$CURRENT_ROOM_NAME' },
          ],
        },
      },
      remoteDisabled: true,
    },
  },
  {
    path: '/projects/:projectId/rooms/:roomId/groups',
    name: 'room-groups',
    component: RoomGroups,
    beforeEnter: ifAuthenticated,
    meta: {
      layout: {
        component: ProjectLayout,
        options: {
          sidebarComponent: RoomSidebar,
          breadcrumbs: [
            { title: '$CURRENT_PROJECT_NAME', routeName: 'project-rooms', back: true },
            { title: '$CURRENT_ROOM_NAME' },
          ],
        },
      },
    },
  },
  {
    path: '/projects/:projectId/rooms/:roomId/groups/:groupId',
    name: 'group-detail',
    component: RoomGroupDetail,
    beforeEnter: ifAuthenticated,
    meta: {
      layout: {
        component: ProjectLayout,
        options: {
          sidebarComponent: RoomSidebar,
          hideSidebar: true,
          breadcrumbs: [
            { title: '$CURRENT_PROJECT_NAME', routeName: 'project-rooms' },
            { title: '$CURRENT_ROOM_NAME', routeName: 'room-groups', back: true },
            { title: '$CURRENT_GROUP_NAME' },
          ],
        },
      },
    },
  },
  {
    path: '/projects/:projectId/rooms/:roomId/scenes',
    name: 'room-scenes',
    component: RoomScenes,
    beforeEnter: ifAuthenticated,
    meta: {
      layout: {
        component: ProjectLayout,
        options: {
          sidebarComponent: RoomSidebar,
          breadcrumbs: [
            { title: '$CURRENT_PROJECT_NAME', routeName: 'project-rooms', back: true },
            { title: '$CURRENT_ROOM_NAME' },
          ],
        },
      },
    },
  },
  {
    path: '/projects/:projectId/rooms/:roomId/scenes/:sceneId',
    name: 'scene-detail',
    component: RoomSceneDetail,
    beforeEnter: ifAuthenticated,
    meta: {
      layout: {
        component: ProjectLayout,
        options: {
          breadcrumbs: [
            { title: '$CURRENT_PROJECT_NAME', routeName: 'project-rooms' },
            { title: '$CURRENT_ROOM_NAME', routeName: 'room-scenes', back: true },
            { title: '$CURRENT_SCENE_NAME' },
          ],
        },
      },
    },
  },
  {
    path: '/projects/:projectId/rooms/:roomId/rules',
    name: 'room-rules',
    component: RoomRules,
    beforeEnter: ifAuthenticated,
    meta: {
      layout: {
        component: ProjectLayout,
        options: {
          sidebarComponent: RoomSidebar,
          breadcrumbs: [
            { title: '$CURRENT_PROJECT_NAME', routeName: 'project-rooms', back: true },
            { title: '$CURRENT_ROOM_NAME' },
          ],
        },
      },
    },
  },
  {
    path: '/projects/:projectId/rooms/:roomId/rules/:ruleId',
    name: 'rule-detail',
    component: RoomRuleDetail,
    beforeEnter: ifAuthenticated,
    meta: {
      layout: {
        component: ProjectLayout,
        options: {
          breadcrumbs: [
            { title: '$CURRENT_PROJECT_NAME', routeName: 'project-rooms' },
            { title: '$CURRENT_ROOM_NAME', routeName: 'room-rules', back: true },
            { title: '$CURRENT_RULE_NAME' },
          ],
        },
      },
    },
  },
  {
    path: '/projects/:projectId/rooms/:roomId/rule-default',
    name: 'rule-default-detail',
    component: RoomRuleDefaultDetail,
    beforeEnter: ifAuthenticated,
    meta: {
      layout: {
        component: ProjectLayout,
        options: {
          sidebarComponent: RoomSidebar,
          breadcrumbs: [
            { title: '$CURRENT_PROJECT_NAME', routeName: 'project-rooms' },
            { title: '$CURRENT_ROOM_NAME', routeName: 'room-rules', back: true },
            { title: '$DEFAULT_RULE_NAME' },
          ],
        },
      },
    },
  },
  {
    path: '/projects/:projectId/rooms/:roomId/groups/:groupId/rule-default',
    name: 'group-rule-default-detail',
    component: RoomGroupRuleDefaultDetail,
    beforeEnter: ifAuthenticated,
    meta: {
      layout: {
        component: ProjectLayout,
        options: {
          breadcrumbs: [
            { title: '$CURRENT_PROJECT_NAME', routeName: 'project-rooms' },
            { title: '$DEFAULT_GROUP_RULE_NAME', routeName: 'room-rules', back: true },
            { title: '$CURRENT_GROUP_NAME' },
          ],
        },
      },
    },
  },
  {
    path: '/projects/:projectId/rooms/:roomId/devices',
    name: 'room-devices',
    component: RoomDevices,
    beforeEnter: ifAuthenticated,
    meta: {
      layout: {
        component: ProjectLayout,
        options: {
          sidebarComponent: RoomSidebar,
          breadcrumbs: [
            { title: '$CURRENT_PROJECT_NAME', routeName: 'project-rooms', back: true },
            { title: '$CURRENT_ROOM_NAME' },
          ],
        },
      },
    },
  },
  {
    path: '/projects/:projectId/rooms/:roomId/devices/:deviceId',
    name: 'device-detail',
    component: RoomDeviceDetail,
    beforeEnter: ifAuthenticated,
    meta: {
      layout: {
        component: ProjectLayout,
        options: {
          breadcrumbs: [
            { title: '$CURRENT_PROJECT_NAME', routeName: 'project-rooms' },
            { title: '$CURRENT_ROOM_NAME', routeName: 'room-devices', back: true },
            { title: '$CURRENT_DEVICE_PRODUCT_NAME' },
          ],
        },
      },
      remoteRedirect: 'room-devices',
    },
  },
  {
    path: '/projects/:projectId/rooms/:roomId/add/:deviceCategory',
    name: 'room-add-device',
    component: RoomAddDevice,
    beforeEnter: ifAuthenticated,
    meta: {
      layout: {
        component: ProjectLayout,
        options: {
          breadcrumbs: [
            { title: '$CURRENT_PROJECT_NAME', routeName: 'project-rooms' },
            { title: '$CURRENT_ROOM_NAME', routeName: 'room-devices', back: true },
            { title: '$CURRENT_ADD_DEVICE_CATEGORY' },
          ],
        },
      },
      remoteRedirect: 'room-devices',
      noLayoutPadding: true,
    },
  },
  {
    path: '/projects/:projectId/rooms/:roomId/add/:deviceCategory/:deviceFamily',
    name: 'room-add-family',
    component: RoomAddDevice,
    beforeEnter: ifAuthenticated,
    meta: {
      layout: {
        component: ProjectLayout,
        options: {
          breadcrumbs: [
            { title: '$CURRENT_PROJECT_NAME', routeName: 'project-rooms' },
            { title: '$CURRENT_ROOM_NAME', routeName: 'room-devices' },
            { title: '$CURRENT_ADD_DEVICE_CATEGORY', routeName: 'room-add-device', back: true },
            { title: '$CURRENT_DEVICE_FAMILY' },
          ],
        },
      },
      remoteRedirect: 'room-devices',
      noLayoutPadding: true,
    },
  },
  {
    path: '/projects/:projectId/rooms/:roomId/add/:deviceCategory/details',
    name: 'room-products-details',
    component: ProductsDetails,
    beforeEnter: ifAuthenticated,
    meta: {
      layout: {
        component: ProjectLayout,
        options: {
          breadcrumbs: [
            { title: '$CURRENT_PROJECT_NAME', routeName: 'project-rooms' },
            { title: '$CURRENT_ROOM_NAME', routeName: 'room-devices' },
            { title: '$CURRENT_ADD_DEVICE_CATEGORY', routeName: 'room-add-device', back: true },
            { title: '$CURRENT_PRODUCT_NAME' },
          ],
        },
      },
      remoteRedirect: 'room-devices',
      noLayoutPadding: true,
    },
  },
];

const templateRoutes = [
  {
    path: '/templates',
    name: 'templates',
    component: TemplateList,
    beforeEnter: ifAuthenticated,
    meta: {
      layout: {
        component: ProjectLayout,
        options: {
          hideSidebar: true,
          hideFloorplan: true,
          breadcrumbs: [
            { title: 'pageTemplates' },
          ],
        },
      },
    },
  },
  {
    path: '/templates/:templateId/details',
    name: 'template-details',
    component: TemplateDetails,
    beforeEnter: ifAuthenticated,
    meta: {
      layout: {
        component: ProjectLayout,
        options: {
          hideFloorplan: true,
          sidebarComponent: TemplateSidebar,
          breadcrumbs: [
            { title: 'pageTemplates', routeName: 'templates', back: true },
            { title: '$CURRENT_TEMPLATE_NAME' },
          ],
        },
      },
    },
  },
  {
    path: '/templates/:templateId/devices',
    name: 'template-devices',
    component: TemplateDevices,
    beforeEnter: ifAuthenticated,
    meta: {
      layout: {
        component: ProjectLayout,
        options: {
          hideFloorplan: true,
          sidebarComponent: TemplateSidebar,
          breadcrumbs: [
            { title: 'pageTemplates', routeName: 'templates', back: true },
            { title: '$CURRENT_TEMPLATE_NAME' },
          ],
        },
      },
    },
  },
  {
    path: '/templates/:templateId/devices/:deviceId',
    name: 'template-device-detail',
    component: TemplateDeviceDetail,
    beforeEnter: ifAuthenticated,
    meta: {
      layout: {
        component: ProjectLayout,
        options: {
          hideFloorplan: true,
          sidebarComponent: TemplateSidebar,
          breadcrumbs: [
            { title: 'pageTemplates', routeName: 'templates' },
            { title: '$CURRENT_TEMPLATE_NAME', routeName: 'template-devices', back: true },
            { title: '$CURRENT_DEVICE_PRODUCT_NAME' },
          ],
        },
      },
    },
  },
  {
    path: '/templates/:templateId/groups',
    name: 'template-groups',
    component: TemplateGroups,
    beforeEnter: ifAuthenticated,
    meta: {
      layout: {
        component: ProjectLayout,
        options: {
          hideFloorplan: true,
          sidebarComponent: TemplateSidebar,
          breadcrumbs: [
            { title: 'pageTemplates', routeName: 'templates', back: true },
            { title: '$CURRENT_TEMPLATE_NAME' },
          ],
        },
      },
    },
  },
  {
    path: '/templates/:templateId/groups/:groupId',
    name: 'template-group-detail',
    component: TemplateGroupDetail,
    beforeEnter: ifAuthenticated,
    meta: {
      layout: {
        component: ProjectLayout,
        options: {
          hideFloorplan: true,
          sidebarComponent: TemplateSidebar,
          breadcrumbs: [
            { title: 'pageTemplates', routeName: 'templates' },
            { title: '$CURRENT_TEMPLATE_NAME', routeName: 'template-groups', back: true },
            { title: '$CURRENT_GROUP_NAME' },
          ],
        },
      },
    },
  },
  {
    path: '/templates/:templateId/scenes',
    name: 'template-scenes',
    component: TemplateScenes,
    beforeEnter: ifAuthenticated,
    meta: {
      layout: {
        component: ProjectLayout,
        options: {
          hideFloorplan: true,
          sidebarComponent: TemplateSidebar,
          breadcrumbs: [
            { title: 'pageTemplates', routeName: 'templates', back: true },
            { title: '$CURRENT_TEMPLATE_NAME' },
          ],
        },
      },
    },
  },
  {
    path: '/templates/:templateId/scenes/:sceneId',
    name: 'template-scene-detail',
    component: TemplateSceneDetail,
    beforeEnter: ifAuthenticated,
    meta: {
      layout: {
        component: ProjectLayout,
        options: {
          hideFloorplan: true,
          sidebarComponent: TemplateSidebar,
          breadcrumbs: [
            { title: 'pageTemplates', routeName: 'templates' },
            { title: '$CURRENT_TEMPLATE_NAME', routeName: 'template-scenes', back: true },
            { title: '$CURRENT_SCENE_NAME' },
          ],
        },
      },
    },
  },
  {
    path: '/templates/:templateId/rules',
    name: 'template-rules',
    component: TemplateRules,
    beforeEnter: ifAuthenticated,
    meta: {
      layout: {
        component: ProjectLayout,
        options: {
          hideFloorplan: true,
          sidebarComponent: TemplateSidebar,
          breadcrumbs: [
            { title: 'pageTemplates', routeName: 'templates', back: true },
            { title: '$CURRENT_TEMPLATE_NAME' },
          ],
        },
      },
    },
  },
  {
    path: '/templates/:templateId/rules/:ruleId',
    name: 'template-rule-detail',
    component: TemplateRuleDetail,
    beforeEnter: ifAuthenticated,
    meta: {
      layout: {
        component: ProjectLayout,
        options: {
          hideFloorplan: true,
          sidebarComponent: TemplateSidebar,
          breadcrumbs: [
            { title: 'pageTemplates', routeName: 'templates' },
            { title: '$CURRENT_TEMPLATE_NAME', routeName: 'template-rules', back: true },
            { title: '$CURRENT_RULE_NAME' },
          ],
        },
      },
    },
  },
];

const productRoutes = [
  {
    path: '/products',
    name: 'products-categories',
    component: ProductsCategories,
    beforeEnter: ifAuthenticated,
    meta: {
      layout: {
        component: MainLayout,
        options: {
          breadcrumbs: [
            { title: 'pageProducts' },
          ],
        },
      },
    },
  },
  {
    path: '/products/:deviceCategory',
    name: 'products-list',
    component: ProductsList,
    beforeEnter: ifAuthenticated,
    meta: {
      layout: {
        component: MainLayout,
        options: {
          breadcrumbs: [
            { title: 'pageProducts', routeName: 'products-categories', back: true },
            { title: '$CURRENT_DEVICE_CATEGORY' },
          ],
        },
      },
    },
  },
  {
    path: '/products/:deviceCategory/:deviceFamily',
    name: 'products-list-family',
    component: ProductsList,
    beforeEnter: ifAuthenticated,
    meta: {
      layout: {
        component: MainLayout,
        options: {
          breadcrumbs: [
            { title: 'pageProducts', routeName: 'products-categories' },
            { title: '$CURRENT_DEVICE_CATEGORY', routeName: 'products-list', back: true },
            { title: '$CURRENT_DEVICE_FAMILY' },
          ],
        },
      },
    },
  },
  {
    path: '/products/:deviceCategory/details/:productId',
    name: 'products-details',
    component: ProductsDetails,
    beforeEnter: ifAuthenticated,
    meta: {
      layout: {
        component: MainLayout,
        options: {
          breadcrumbs: [
            { title: 'pageProducts', routeName: 'products-categories' },
            { title: '$CURRENT_DEVICE_CATEGORY', routeName: 'products-list', back: true },
            { title: '$CURRENT_PRODUCT_NAME' },
          ],
        },
      },
    },
  },
];

const maintenanceRoutes = [
  {
    path: '/maintenance',
    name: 'maintenance',
    component: Maintenance,
    beforeEnter: ifAuthenticated,
    meta: {
      layout: {
        component: MainLayout,
        options: {
          breadcrumbs: [
            { title: 'pageMaintenance' },
          ],
        },
      },
    },
  },
  {
    path: '/maintenance/:projectId',
    name: 'maintenance-details',
    component: MaintenanceDetails,
    beforeEnter: ifAuthenticated,
    meta: {
      layout: {
        component: MainLayout,
        options: {
          breadcrumbs: [
            { title: 'pageMaintenance', routeName: 'maintenance', back: true },
            { title: '$CURRENT_PROJECT_NAME' },
          ],
        },
      },
    },
  },
];

const wizardRoutes = [
  {
    path: '/wizard/project/name-and-description',
    name: 'wizard-project-name-and-description',
    component: WPStep1NameAndDesc,
    beforeEnter: ifAuthenticated,
    meta: {
      nextRoute: 'wizard-project-address',
      layout: {
        component: WizardProjectLayout,
        options: {
          browserBack: false,
          titleLocale: 'wizardAddNameAndDesc',
          subtitleLocale: 'wizardCreateProject',
          currentStep: 1,
          progressSteps: 5,
        },
      },
    },
  },
  {
    path: '/wizard/project/address',
    name: 'wizard-project-address',
    component: WPStep2Address,
    beforeEnter: ifAuthenticated,
    meta: {
      nextRoute: 'wizard-project-contact',
      layout: {
        component: WizardProjectLayout,
        options: {
          browserBack: true,
          titleLocale: 'wizardAddAddress',
          subtitleLocale: 'wizardCreateProject',
          currentStep: 2,
          progressSteps: 5,
        },
      },
    },
  },
  {
    path: '/wizard/project/contact',
    name: 'wizard-project-contact',
    component: WPStep3PrimaryContact,
    beforeEnter: ifAuthenticated,
    meta: {
      nextRoute: 'wizard-project-floorplan',
      layout: {
        component: WizardProjectLayout,
        options: {
          browserBack: true,
          titleLocale: 'wizardAddPrimaryContact',
          subtitleLocale: 'wizardCreateProject',
          currentStep: 3,
          progressSteps: 5,
          skippable: true,
        },
      },
    },
  },
  {
    path: '/wizard/project/floorplan',
    name: 'wizard-project-floorplan',
    component: WPStep4Floorplan,
    beforeEnter: ifAuthenticated,
    meta: {
      nextRoute: 'wizard-project-overview',
      layout: {
        component: WizardProjectLayout,
        options: {
          browserBack: true,
          titleLocale: 'wizardAddFloorplan',
          subtitleLocale: 'wizardCreateProject',
          currentStep: 4,
          progressSteps: 5,
          skippable: true,
        },
      },
    },
  },
  {
    path: '/wizard/project/overview',
    name: 'wizard-project-overview',
    component: WPStep5Overview,
    beforeEnter: ifAuthenticated,
    meta: {
      nextRoute: 'wizard-room-name-and-description',
      layout: {
        component: WizardProjectLayout,
        options: {
          browserBack: true,
          titleLocale: 'wizardProjectOverview',
          subtitleLocale: 'wizardCreateProject',
          currentStep: 5,
          progressSteps: 5,
        },
      },
    },
  },
  {
    path: '/wizard/:projectId/room/name-and-description',
    name: 'wizard-room-name-and-description',
    component: WRStep01NameAndDesc,
    beforeEnter: ifAuthenticated,
    meta: {
      nextRoute: '',
      layout: {
        component: WizardRoomLayout,
        options: {
          browserBack: false,
          titleLocale: 'wizardCreateRoom',
          currentStep: 1,
        },
      },
    },
  },
  {
    path: '/wizard/:projectId/room/:roomId/switches',
    name: 'wizard-room-switches',
    component: WRStep02Switches,
    beforeEnter: ifAuthenticated,
    meta: {
      nextRoute: 'wizard-room-sensors',
      layout: {
        component: WizardRoomLayout,
        options: {
          browserBack: false,
          titleLocale: 'generalSwitches',
          subtitleLocale: 'generalDevices',
          currentStep: 2,
          currentDeviceStep: 1,
          allDeviceSteps: 3,
          skippable: true,
        },
      },
    },
  },
  {
    path: '/wizard/:projectId/room/:roomId/sensors',
    name: 'wizard-room-sensors',
    component: WRStep03Sensors,
    beforeEnter: ifAuthenticated,
    meta: {
      nextRoute: 'wizard-room-lights',
      layout: {
        component: WizardRoomLayout,
        options: {
          browserBack: true,
          titleLocale: 'generalSensors',
          subtitleLocale: 'generalDevices',
          currentStep: 3,
          currentDeviceStep: 2,
          allDeviceSteps: 3,
          skippable: true,
        },
      },
    },
  },
  {
    path: '/wizard/:projectId/room/:roomId/lights',
    name: 'wizard-room-lights',
    component: WRStep04Lights,
    beforeEnter: ifAuthenticated,
    meta: {
      nextRoute: 'wizard-room-groups',
      layout: {
        component: WizardRoomLayout,
        options: {
          browserBack: true,
          titleLocale: 'generalLights',
          subtitleLocale: 'generalDevices',
          currentStep: 4,
          currentDeviceStep: 3,
          allDeviceSteps: 3,
          skippable: true,
        },
      },
    },
  },
  {
    path: '/wizard/:projectId/room/:roomId/groups',
    name: 'wizard-room-groups',
    component: WRStep05Groups,
    beforeEnter: ifAuthenticated,
    meta: {
      nextRoute: 'wizard-room-scenes',
      layout: {
        component: WizardRoomLayout,
        options: {
          browserBack: true,
          titleLocale: 'generalGroups',
          subtitleLocale: 'roomGroupsCreateGroupSensorsLights',
          currentStep: 5,
          skippable: true,
        },
      },
    },
  },
  {
    path: '/wizard/:projectId/room/:roomId/scenes',
    name: 'wizard-room-scenes',
    component: WRStep06Scenes,
    beforeEnter: ifAuthenticated,
    meta: {
      nextRoute: 'wizard-room-rules',
      layout: {
        component: WizardRoomLayout,
        options: {
          browserBack: true,
          titleLocale: 'generalScenes',
          subtitleLocale: 'roomScenesCreateSceneLightsGroups',
          currentStep: 6,
          skippable: true,
        },
      },
    },
  },
  {
    path: '/wizard/:projectId/room/:roomId/rules',
    name: 'wizard-room-rules',
    component: WRStep07Rules,
    beforeEnter: ifAuthenticated,
    meta: {
      nextRoute: 'wizard-room-overview',
      layout: {
        component: WizardRoomLayout,
        options: {
          browserBack: true,
          titleLocale: 'generalRules',
          subtitleLocale: 'wizardRoomBehaviourModesSubtitle',
          questionLocale: 'wizardWhatIsRoomBehaviour',
          answerLocale: 'wizardRoomBehaviourModesDetails',
          currentStep: 7,
          skippable: true,
        },
      },
    },
  },
  {
    path: '/wizard/:projectId/room/:roomId/overview',
    name: 'wizard-room-overview',
    component: WRStep08Overview,
    beforeEnter: ifAuthenticated,
    meta: {
      nextRoute: 'wizard-project-schedules',
      layout: {
        component: WizardRoomLayout,
        options: {
          browserBack: false,
          titleLocale: 'wizardRoomOverview',
          currentStep: 8,
        },
      },
    },
  },
  {
    path: '/wizard/:projectId/schedules',
    name: 'wizard-project-schedules',
    component: WRStep09Schedules,
    beforeEnter: ifAuthenticated,
    meta: {
      nextRoute: 'wizard-summary',
      layout: {
        component: WizardRoomLayout,
        options: {
          browserBack: true,
          titleLocale: 'generalSchedules',
          questionLocale: 'wizardWhatIsSchedule',
          answerLocale: 'wizardScheduleDetails',
          currentStep: 9,
          skippable: true,
        },
      },
    },
  },
  {
    path: '/wizard/:projectId/summary',
    name: 'wizard-summary',
    component: WRStep10Summary,
    beforeEnter: ifAuthenticated,
    meta: {
      nextRoute: '',
      layout: {
        component: WizardRoomLayout,
        options: {
          browserBack: true,
          titleLocale: 'wizardProjectOverview',
          subtitleLocale: 'wizardCongratulations',
          currentStep: 10,
        },
      },
    },
  },
];

const staticPageRoutes = [
  {
    path: '/legal-page/:type',
    name: 'LegalPage',
    component: () => import('@/views/static/LegalPage.vue'),
    props: true,
    beforeEnter: (to, from, next) => {
      if (!to.params || !to.params.type) {
        return next('/');
      }
      // Cut off the extension from type (eg. tos.html ==> tos)
      // eslint-disable-next-line prefer-destructuring
      to.params.type = to.params.type.split('.')[0];
      const validTypes = ['imprint', 'tos', 'privacy', 'licenses', 'troubleshooting', 'gdpr']; // gdpr type is for displaying account delete only
      to.meta.layout.options.browserBack = store.getters['auth/isAuthenticated'];
      if (validTypes.includes(to.params.type)) {
        return next();
      }
      return next('/');
    },
    meta: {
      layout: {
        component: MainLayout,
        options: {
          browserBack: true,
          breadcrumbs: [
            { title: '$CURRENT_LEGAL_PAGE' },
          ],
        },
      },
    },
  },
];

const errorRoutes = [
  { path: '*', redirect: '/error/404' },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    ...generalRoutes,
    ...projectRoutes,
    ...projectTemplateRoutes,
    ...projectRoomRoutes,
    ...templateRoutes,
    ...productRoutes,
    ...staticPageRoutes,
    ...errorRoutes,
    ...maintenanceRoutes,
    ...wizardRoutes,
  ],
});

router.beforeEach(async (to, from, next) => {
  logEvent('page_view', { type: 'internal', name: to.name });
  await store.restored;
  next();
});

export default router;

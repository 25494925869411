//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { OpenStreetMapProvider } from 'leaflet-geosearch';
import L from 'leaflet';
import { mapActions, mapGetters } from 'vuex';
import { getTimezones } from '@/utils/helpers';

const pinIconUrl = require('@/assets/icons/location_pin.svg');

export default {
  name: 'ProjectAddress',
  components: {
    ValidationObserver,
    ValidationProvider,
  },
  props: {
    address: {
      type: Object,
    },
    configParameters: {
      type: Object,
    },
    showMap: {
      type: Boolean,
      default: true,
    },
    alignMapCenter: {
      type: Boolean,
      default: true,
    },
    hideLongLatInputs: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      localAddress: {},
      localConfigParameters: {},
      disabledSearch: false,
      noMapPreview: false,
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      zoom: 10,
      center: [48.137154, 11.576124],
      markerCoordinates: [48.137154, 11.576124],
      prevMarkerCoordinates: null,
      bounds: null,
      options: {
        attributionControl: false,
        doubleClickZoom: false,
      },
      geosearchOptions: {
        provider: new OpenStreetMapProvider({
          params: {
            addressdetails: 1,
            'accept-language': this.$i18n.locale,
            // email: 'insider@mondriaan.com',
            // https://smeijer.github.io/leaflet-geosearch/providers/openstreetmap
          },
        }),
      },
      timezones: getTimezones(),
      rerenderKey: 1,
    };
  },
  created() {
    this.localAddress = { ...this.address };
    this.setLocalConfigParameters();
    this.setMarkerByCoordinates();
    if ((this.localAddress.country && this.localAddress.country.length) && ((this.localAddress.city && this.localAddress.city.length) || (this.localAddress.line1 && this.localAddress.line1.length))) {
      this.searchAddress();
    }
  },
  watch: {
    localAddress() {
      this.emitAddress();
    },
    localConfigParameters() {
      this.emitConfigParameters();
    },
  },
  computed: {
    ...mapGetters('locale', ['getCountryList']),
    ...mapGetters('user', ['getProfileData']),
    countries() {
      const countries = this.getCountryList || [];
      const usersCountry = (countries).find((c) => c.id === this.getProfileData?.countryId);
      if (!usersCountry) {
        return countries.map((c) => c.name);
      }
      return countries.filter((c) => c.businessRegionName === usersCountry.businessRegionName).map((c) => c.name);
    },
    countryCodesToCountry() {
      const countries = this.getCountryList || [];
      const usersCountry = (countries).find((c) => c.id === this.getProfileData?.countryId);
      if (!usersCountry) {
        return countries.reduce((acc, item) => ({ ...acc, [item.code]: item.name }), {});
      }
      return countries
        .filter((c) => c.businessRegionName === usersCountry.businessRegionName)
        .reduce((acc, item) => ({ ...acc, [item.code]: item.name }), {});
    },
    icon() {
      return L.icon({
        iconUrl: pinIconUrl,
        iconSize: [40, 52],
        shadowSize: [0, 0],
        iconAnchor: [20, 50],
      });
    },
    addressFieldsClasses() {
      if (this.showMap) {
        if (this.alignMapCenter) {
          return 'col-lg-12 mt-4';
        }
        return 'col-lg-6 mt-0';
      }
      return 'col-lg-12 mt-0';
    },
  },
  methods: {
    ...mapActions('app', ['setError']),
    ...mapActions('projects', ['getTimeZone']),
    zoomUpdated(zoom) {
      this.zoom = zoom;
    },
    centerUpdated(center) {
      this.center = center;
    },
    boundsUpdated(bounds) {
      this.bounds = bounds;
    },
    async searchAddress() {
      if (this.disabledSearch || !this.showMap) {
        return;
      }
      this.disableSearch();
      this.noMapPreview = false;
      const address = `${this.localAddress.country} ${this.localAddress.zipCode} ${this.localAddress.city} ${this.localAddress.line1}`;
      const results = await this.geosearchOptions.provider.search({ query: address });
      if (!this.localAddress.country || !this.localAddress.zipCode || !this.localAddress.city) {
        return;
      }
      if (results.length > 0) {
        this.center = [results[0].y, results[0].x];
        this.markerCoordinates = [results[0].y, results[0].x];
        this.localConfigParameters.latitude = results[0].y;
        this.localConfigParameters.longitude = results[0].x;
        await this.setTimezone();
        setTimeout(() => this.zoomUpdated(17), 300);
        // eslint-disable-next-line no-plusplus
        this.rerenderKey++;
      } else {
        this.noMapPreview = true;
      }
    },
    addMarker(event) {
      this.prevMarkerCoordinates = this.markerCoordinates;
      this.markerCoordinates = event.latlng;
      this.getAddress(event.latlng);
    },
    async getAddress(coordinates) {
      if (this.disabledSearch || !this.showMap) {
        return;
      }
      this.disableSearch();
      this.noMapPreview = false;
      const results = await this.geosearchOptions.provider.search({ query: `${coordinates.lat}, ${coordinates.lng}` });
      if (results.length > 0) {
        const { address, lat, lon } = results[0].raw;
        if (!this.countryCodesToCountry[address.country_code]) {
          this.setError({ errorMessage: this.$t('generalCountryOtherRegion'), errorCode: this.$t('generalCountryOtherRegionMessage') });
          if (this.prevMarkerCoordinates) {
            this.markerCoordinates = this.prevMarkerCoordinates;
            this.prevMarkerCoordinates = null;
          }
          return;
        }
        this.setAddress(address);
        this.localConfigParameters.latitude = lat;
        this.localConfigParameters.longitude = lon;
        await this.setTimezone();
        // eslint-disable-next-line no-plusplus
        this.rerenderKey++;
      } else {
        this.noMapPreview = true;
      }
    },
    setAddress(address) {
      const country = this.countryCodesToCountry[address.country_code];
      this.localAddress.country = country || this.localAddress.country;
      this.localAddress.city = address.city ? address.city : '';
      this.localAddress.state = address.state ? address.state : '';
      this.localAddress.county = address.county ? address.county : '';
      if (address.village) {
        this.localAddress.city = address.village;
      } else if (address.town) {
        this.localAddress.city = address.town;
      } else if (!address.city && address.region) {
        this.localAddress.city = address.region;
      } else {
        this.localAddress.city = address.city ? address.city : '';
      }
      this.localAddress.zipCode = address.postcode ? address.postcode : '';
      if (address.house_number) {
        this.localAddress.line1 = address.road ? `${address.road} ${address.house_number}` : '';
      } else {
        this.localAddress.line1 = address.road ? address.road : '';
      }
    },
    setLocalConfigParameters() {
      this.localConfigParameters.latitude = Object.keys(this.configParameters).length && this.configParameters.latitude ? this.configParameters.latitude : '';
      this.localConfigParameters.longitude = Object.keys(this.configParameters).length && this.configParameters.longitude ? this.configParameters.longitude : '';
      this.localConfigParameters.timeZone = Object.keys(this.configParameters).length && this.configParameters.timeZone ? this.configParameters.timeZone : '';
    },
    async setTimezone() {
      const timeZone = await this.getTimeZone({ latitude: this.localConfigParameters.latitude, longitude: this.localConfigParameters.longitude });
      if (!timeZone.length || !this.timezones.includes(timeZone)) {
        return;
      }
      this.localConfigParameters.timeZone = timeZone;
      this.emitConfigParameters();
    },
    setMarkerByCoordinates() {
      if (!this.localConfigParameters.latitude || !this.localConfigParameters.longitude) {
        return;
      }
      this.center = [this.localConfigParameters.latitude, this.localConfigParameters.longitude];
      this.markerCoordinates = [this.localConfigParameters.latitude, this.localConfigParameters.longitude];
    },
    disableSearch() {
      this.disabledSearch = true;
      setTimeout(() => {
        this.disabledSearch = false;
      }, 500);
    },
    emitAddress() {
      this.$emit('setAddress', this.localAddress);
    },
    emitConfigParameters() {
      this.$emit('setConfigParameters', this.localConfigParameters);
    },
  },
};

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


export default {
  props: ['label', 'value', 'readMode'],
  components: {},
  created() {
    this.time = this.value;
  },
  data() {
    return {
      time: '',
    };
  },
  methods: {
    onInput() {
      this.$emit('input', this.time);
    },
  },
};

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapGetters } from 'vuex';
import CustomTable from '@/components/ui/CustomTable.vue';

export default {
  name: 'ProjectTemplateList',
  data() {
    return {
      loading: false,
      projectId: this.$route.params.projectId,
    };
  },
  components: {
    CustomTable,
  },
  async created() {
    this.loading = true;
    await this.listTemplates({ scopes: ['project', 'user', 'global'], projectUUID: this.projectId });
    this.loading = false;
  },
  computed: {
    ...mapGetters('templates', ['getTemplatesByScope']),
    ...mapGetters('projects', ['getProjectById']),
    project() {
      return this.getProjectById(this.projectId);
    },
    defaultTemplates() {
      return this.getTemplatesByScope(['global']);
    },
    customTemplates() {
      return this.getTemplatesByScope(['user', 'project']);
    },
  },
  methods: {
    ...mapActions('templates', ['listTemplates']),
  },

};

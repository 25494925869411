//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import NavBarGuest from '@/components/layouts/navbars/NavBarGuest.vue';

export default {
  name: 'AuthLayout',
  components: { NavBarGuest },
};

import scheduleService from '../services/schedules';

const initialState = {
  schedules: {},
  schedulesById: {},
};

const actions = {
  async listSchedules({ commit }, { uuid }) {
    const { data } = await scheduleService.listSchedules({ uuid });
    commit('listSchedules', { uuid, data });
  },
  async getSchedule({ commit }, { uuid, scheduleId }) {
    const { data } = await scheduleService.getSchedule({ uuid, scheduleId });
    commit('getSchedule', { data, scheduleId });
  },
  async createSchedule(ctx, { uuid, data }) {
    const response = await scheduleService.createSchedule({ uuid, data });
    return response.data;
  },
  async updateSchedule({ commit }, {
    uuid, scheduleId, data,
  }) {
    const response = await scheduleService.updateSchedule({
      uuid, scheduleId, data,
    });
    commit('getSchedule', { data: response.data, scheduleId });
  },
  async deleteSchedule(ctx, { uuid, scheduleId }) {
    await scheduleService.deleteSchedule({ uuid, scheduleId });
  },
};

const mutations = {
  listSchedules(state, payload) {
    const schedules = { ...state.schedules };
    schedules[payload.uuid] = payload.data;
    state.schedules = schedules;
    state.schedulesById = payload.data.reduce((acc, item) => ({ ...acc, [item.uuid]: item }), {});
  },
  getSchedule(state, payload) {
    state.schedulesById = { ...state.schedulesById, [payload.scheduleId]: payload.data };
  },
};

const getters = {
  getSchedulesByProject: (state, actGetters, rootState, rootGetters) => (uuid) => {
    if (state.schedules[uuid]) {
      return (state.schedules[uuid] || [])
        .map((s) => ({
          ...s,
          actions: s.actions || [],
          hasIssue: !!(rootGetters['projects/getHealthEntries'](uuid).byId[s.uuid] || []).length,
        }));
    }
    return [];
  },
  getScheduleById: (state) => (scheduleId) => state.schedulesById[scheduleId] || {},
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  mutations,
};

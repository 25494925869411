//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapGetters } from 'vuex';
import RoomSceneDetailsUI from '@/components/ui/roomUI/RoomSceneDetailsUI.vue';

export default {
  name: 'TemplateSceneDetail',
  components: {
    RoomSceneDetailsUI,
  },
  data() {
    return {
      templateId: this.$route.params.templateId,
      sceneId: this.$route.params.sceneId,
      loading: false,
    };
  },
  async created() {
    this.loading = true;
    await this.listProducts();
    this.loading = false;
  },
  computed: {
    ...mapGetters('templates', ['getTemplateById']),
    ...mapGetters('products', ['getProductById']),
    template() {
      return this.getTemplateById(this.templateId) || {};
    },
    templateConfig() {
      if (this.template.config && this.template.config.body) {
        return this.template.config.body;
      }
      return {};
    },
    scene() {
      return this.templateConfig.scenes.find((g) => g.uuid === this.sceneId);
    },
    lights() {
      return this.templateConfig.devices.lights;
    },
    groups() {
      return this.templateConfig.groups;
    },
    type() {
      return (this.scene.values[0] && this.scene.values[0].target.type) || 'group';
    },
    lightItems() {
      if (this.type !== 'light') {
        return [];
      }
      const res = this.lights.map((light) => {
        const saved = this.scene.values.find((s) => s.target.uuid === light.uuid);
        return ({
          light: {
            ...light,
            product: this.getProductById(light.product),
          },
          selected: true,
          data: {
            target: {
              type: 'light',
              uuid: light.uuid,
            },
            value: {
              on: true,
              level: saved && saved.value.level ? saved.value.level : 0,
            },
          },
        });
      });
      return res;
    },
    groupItems() {
      if (this.type === 'light') {
        return [];
      }
      const res = this.groups.map((group) => {
        const saved = this.scene.values.find((s) => s.target.uuid === group.uuid);
        return ({
          group,
          selected: (saved && saved.value.on) || false,
          data: {
            target: {
              type: 'group',
              uuid: group.uuid,
            },
            value: {
              on: (saved && saved.value.on) || false,
              level: (saved && saved.value.level) || 0,
            },
          },
        });
      });
      return res;
    },
    wholeRoom() {
      const roomValue = this.scene.values.find((s) => s.target.type === 'room');
      return {
        room: {},
        selected: (roomValue && roomValue.value.on) || false,
        data: roomValue,
      };
    },
  },
  methods: {
    ...mapActions('products', ['listProducts']),
  },
};

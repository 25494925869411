//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import CustomSection from '../CustomSection.vue';

export default {
  props: ['floorplan', 'hideMove'],
  components: { CustomSection },
  data() {
    return {
      hover: false,
      draggable: false,
      dragging: false,
    };
  },
  methods: {
    startDrag(evt, item) {
      evt.dataTransfer.dropEffect = 'move';
      evt.dataTransfer.effectAllowed = 'move';
      evt.dataTransfer.setData('floorplan', JSON.stringify(item));
      this.dragging = true;
    },
    endDrag() {
      this.dragging = false;
    },
    onDrop(evt, to) {
      const floorplan = JSON.parse(evt.dataTransfer.getData('floorplan'));
      if (floorplan.room === to.room && floorplan.attachmentUUID !== to.attachmentUUID) {
        this.$emit('move', { from: floorplan.attachmentUUID, to: to.attachmentUUID, room: to.room });
      }
    },
    setDraggedOver(evt) {
      evt.preventDefault();
    },
  },
};

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapGetters } from 'vuex';

import ProductListUI from '@/components/ui/productUI/ProductListUI.vue';

export default {
  name: 'RoomAddDevice',
  components: { ProductListUI },
  data() {
    return {
      projectId: this.$route.params.projectId,
      roomId: this.$route.params.roomId,
      category: this.$route.params.deviceCategory,

      dataLoaded: false,
      addSuccess: false,
      disableAddButton: false,

      devices: [],
      collection: {},
      deviceCount: 0,

      progress: 0,
      categoryAddTitle: {
        lights: this.$t('roomDevicesAddLights'),
        sensors: this.$t('roomDevicesAddSensors'),
        switches: this.$t('roomDevicesAddSwitches'),
      },
      categoryType: {
        lights: this.$t('generalLights'),
        sensors: this.$t('generalSensors'),
        switches: this.$t('generalSwitches'),
      },
    };
  },
  async created() {
    await Promise.all([
      this.getRoom({ uuid: this.projectId, roomId: this.roomId }),
      this.listProducts({ category: this.category }),
    ]);
    this.dataLoaded = true;
  },
  computed: {
    ...mapGetters('products', ['getProductsByCategory']),
    ...mapGetters('rooms', ['getRoomById']),
    ...mapGetters('projects', ['getProjectById']),
    products() {
      return this.getProductsByCategory(this.category, this.projectId);
    },
    room() {
      return this.getRoomById(this.roomId);
    },
    project() {
      return this.getProjectById(this.projectId);
    },
  },
  methods: {
    ...mapActions('products', ['listProducts']),
    ...mapActions('rooms', ['addProductsToRoom', 'getRoom']),
    plus(deviceUuid) {
      const collection = { ...this.collection };
      collection[deviceUuid] = collection[deviceUuid] || 0;
      collection[deviceUuid] += 1;
      this.collection = collection;
    },
    minus(deviceUuid) {
      const collection = { ...this.collection };
      collection[deviceUuid] = collection[deviceUuid] || 0;
      if (collection[deviceUuid] > 0) {
        collection[deviceUuid] -= 1;
        this.collection = collection;
      }
    },
    async addProducts(collection) {
      this.disableAddButton = true;
      const {
        projectId, roomId,
      } = this;

      const products = Object.entries(collection)
        .filter(([, count]) => count)
        .map(([product, count]) => ({ product, count }));
      this.deviceCount = products.reduce((acc, prod) => acc + prod.count, 0);

      // TODO: temporary solution
      this.progress = 0;
      this.$bvModal.show('add-device-progress-modal');
      for (let i = 0; i < products.length; i += 1) {
        // eslint-disable-next-line no-await-in-loop
        await this.addProductsToRoom({ uuid: projectId, roomId, products: [products[i]] });
        this.progress = Math.floor((i / products.length) * 100);
      }

      this.addSuccess = true;
      this.$bvModal.hide('add-device-progress-modal');
      this.$bvModal.show('add-to-room-modal');
      this.disableAddButton = false;
    },
  },
};

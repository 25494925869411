import templateService from '../services/templates';

const localizeName = (name, localization, language) => {
  const myRegexp = /%{(.*)}/g;
  const match = myRegexp.exec(name);
  if (match && match[1] && localization[match[1]]) {
    const loc = localization[match[1]];

    if (loc[language]) {
      return loc[language];
    }

    if (loc.en) {
      return loc.en;
    }

    const [firstLang] = Object.keys(loc);
    if (loc[firstLang]) {
      return loc[firstLang];
    }
  }
  return name;
};

const initialState = {
  templates: [],
  templatesById: {},
};

const actions = {
  async listTemplates({ commit }, params) {
    const { data } = await templateService.listTemplates(params);
    commit('listTemplates', data);
  },
  async createTemplateByRoom(ctx, {
    uuid, roomId, scope, name, description,
  }) {
    const { data } = await templateService.createTemplateByRoom({
      uuid, roomId, scope, name, description,
    });
    return data;
  },
  async deleteTemplate(ctx, { templateId }) {
    await templateService.deleteTemplate({ templateId });
  },
};

const mutations = {
  listTemplates(state, templates) {
    const templatesById = templates.reduce((result, template) => {
      if (template && template.uuid) {
        result[template.uuid] = template;
      }
      return result;
    }, {});
    state.templatesById = { ...state.templatesById, ...templatesById };
    state.templates = templates;
  },
};

const getters = {
  getTemplatesByScope: (state, _getters) => (scopes = []) => scopes
    .reduce((acc, scope) => [...acc, ...state.templates.filter((t) => t.scope === scope)], [])
    .map((template) => _getters.getTemplateById(template.uuid)),
  getTemplateById: (state, _getters, rootState) => (templateId) => {
    const template = state.templatesById[templateId] || {};
    if (!template.config || !template.config.body) {
      return template;
    }
    const sectionCategories = ['scenes', 'groups', 'rules', 'schedules', 'template', 'variables'];
    const localizedTemplate = { ...template.config.body };
    sectionCategories.forEach((s) => {
      const section = localizedTemplate[s];

      if (!section) {
        return;
      }

      if (!Array.isArray(section)) {
        localizedTemplate[s] = {
          ...section,
          name: localizeName(section.name, localizedTemplate.localization, rootState.app.locale),
          description: localizeName(section.description, localizedTemplate.localization, rootState.app.locale),
          question: localizeName(section.question, localizedTemplate.localization, rootState.app.locale),
        };
      } else {
        localizedTemplate[s] = [...section
          .map(((item) => ({
            ...item,
            name: localizeName(item.name, localizedTemplate.localization, rootState.app.locale),
            description: localizeName(item.description, localizedTemplate.localization, rootState.app.locale),
            question: localizeName(item.question, localizedTemplate.localization, rootState.app.locale),
          }))),
        ];
      }
    });
    return {
      ...template,
      config: {
        ...template.config,
        body: {
          ...localizedTemplate,
        },
      },
    };
  },

};

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  mutations,
};

//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions } from 'vuex';

export default {
  props: ['isAuthenticated', 'countryCode'],
  methods: {
    ...mapActions('auth', ['logout']),
    ...mapActions('locale', ['changeCountry']),
    async logoutAndRestart() {
      await this.logout();
      await this.changeCountry(this.countryCode);
      await this.$router.push({ name: 'register' });
    },
    closeModal() {
      this.$bvModal.hide('nav-other-country-modal');
    },
  },
};

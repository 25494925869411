//
//
//
//
//
//

import { mapActions, mapState } from 'vuex';
import { ValidationObserver } from 'vee-validate';
import { WizardBus } from '@/bus';
import ProjectAddress from '@/components/ui/ProjectAddress.vue';

export default {
  name: 'WizardProject',
  components: {
    ProjectAddress,
    ValidationObserver,
  },
  data() {
    return {
      address: {
        country: '',
        state: '',
        county: '',
        city: '',
        zipCode: '',
        line1: '',
        line2: '',
      },
    };
  },
  created() {
    this.address = { ...this.projectState.address };
    WizardBus.$on('next', this.onNextStep);
    WizardBus.$on('abort', this.onAbort);
  },
  beforeDestroy() {
    WizardBus.$off('next', this.onNextStep);
    WizardBus.$off('abort', this.onAbort);
  },
  computed: {
    ...mapState('wizard', ['projectState']),
  },
  methods: {
    ...mapActions('wizard', ['updateProjectState', 'resetState']),
    setAddress(newAddress) {
      this.address = newAddress;
    },
    async onNextStep() {
      const isValid = await this.$refs['observer-2'].validate();
      if (!isValid) {
        return;
      }
      this.updateProjectState({ address: this.address });
      await this.$router.push({ name: this.$route.meta.nextRoute });
    },
    onAbort() {
      this.resetState();
    },
  },
};

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapGetters, mapState } from 'vuex';

import NavBarMain from '@/components/layouts/navbars/NavBarMain.vue';
import NavBarLocal from '@/components/layouts/navbars/NavBarLocal.vue';
import NavFloorplan from '@/components/layouts/navbars/NavFloorplan.vue';
import RemoteControlBar from '@/components/layouts/navbars/RemoteControlBar.vue';

/*
Valid layout options:
  - breadcrumbs (array)
  - sidebarComponent (component)
 */

export default {
  name: 'ProjectLayout',
  props: ['options'],
  components: {
    RemoteControlBar,
    NavBarMain,
    NavBarLocal,
    NavFloorplan,
  },
  computed: {
    ...mapState('app', ['floorplanOpened']),
    ...mapGetters('projects', ['getWorkInProgressData']),
    layoutPadding() {
      return !this.$route.meta.noLayoutPadding;
    },
    workInProgressData() {
      return this.getWorkInProgressData(this.$route.params.projectId);
    },
    remoteControlEnabled() {
      return this.workInProgressData.hasWorkInProgressChanges;
    },
    contentHeightCSS() {
      const navBarMainHeight = '4.375rem';
      const navBarLocalHeight = '3rem';
      const remoteControlBarHeight = '3rem';
      const heights = ['100vh', navBarMainHeight, navBarLocalHeight];
      if (this.remoteControlEnabled) {
        heights.push(remoteControlBarHeight);
      }
      return { height: `calc(${heights.join(' - ')})` };
    },
  },
  methods: {
    ...mapActions('app', ['floorplanUpdate']),
  },
};
